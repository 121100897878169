import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  NurseryHoursDiaryDetailResponse,
  NurseryHoursDiaryResponse,
} from "chaild-api/lib";
import ApiNurseryHoursDiary from "@/api/ApiNurseryHoursDiary";

export interface NurseryHoursDiaryState {
  diaries: NurseryHoursDiaryResponse[];
  diary: NurseryHoursDiaryDetailResponse | null;
}

@Module({ dynamic: true, store, name: "nursery-hours-diary", namespaced: true })
class NurseryHoursDiaryModule
  extends VuexModule
  implements NurseryHoursDiaryState
{
  public diaries: NurseryHoursDiaryResponse[] = [];
  public diary: NurseryHoursDiaryDetailResponse | null = null;

  // Action
  @Action({ rawError: true })
  public async listDiary(input: {
    nurseryId: string;
    limit: number;
    skip: number;
    from?: string;
    to?: string;
  }) {
    const response = await ApiNurseryHoursDiary.listNurseryHoursDiary(input);
    if (response) {
      this.setDiaries(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getDiary(input: {
    nurseryId: string;
    date: string; // yyyy-mm-dd
  }) {
    const response = await ApiNurseryHoursDiary.getNurseryHoursDiary(input);
    if (response) {
      this.setDiary(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async createDiary(input: {
    nurseryId: number;
    date: string;
    activity: string;
    remarks: string;
    message: string;
    status: "draft" | "applied";
    appliedBy?: number;
    staff?: number[];
  }) {
    const response = await ApiNurseryHoursDiary.createNurseryHoursDiary(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateDiary(input: {
    nurseryId: number;
    nurseryHoursDiaryId: string;
    date: string;
    activity: string;
    remarks: string;
    message: string;
    status: "draft" | "applied";
    appliedBy?: number;
    staff?: number[];
  }) {
    const response = await ApiNurseryHoursDiary.updateNurseryHoursDiary(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateApprovalStatus(input: {
    nurseryId: number;
    nurseryHoursDiaryId: string;
    approvalStatus: "approved" | "rejected";
  }) {
    const response =
      await ApiNurseryHoursDiary.updateNurseryHoursDiaryApprovalStatus(input);
    if (response) {
      return response;
    }
  }

  // Mutation
  @Mutation
  public setDiaries(value: NurseryHoursDiaryResponse[]) {
    this.diaries = value;
  }

  @Mutation
  public setDiary(value: NurseryHoursDiaryDetailResponse) {
    this.diary = value;
  }
}

export const nurseryHoursDiaryModule = getModule(NurseryHoursDiaryModule);
