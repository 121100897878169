
import LocalDataService from "@/service/LocalDataService";
import { memoruModule } from "@/store/dataModules/memoruModule";
import { MemoruSalesListResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "MemoruSales",

  async mounted() {
    this.searchFilter.monthTo = dayjs().format("YYYY-MM");
    this.searchFilter.monthFrom = dayjs()
      .subtract(2, "month")
      .format("YYYY-MM");
    await this.listSales();
  },

  data() {
    return {
      loading: false,
      searchFilter: {
        monthTo: "",
        monthFrom: "",
      },
    };
  },

  computed: {
    sales: function (): MemoruSalesListResponse | null {
      return memoruModule.sales;
    },
  },

  methods: {
    async listSales() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await memoruModule
          .listSales({
            nurseryId,
            ...this.searchFilter,
          })
          .catch(() => (this.loading = false));
      }
      this.loading = false;
    },
  },
});
