import LocalDataService from "@/service/LocalDataService";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiMe from "@/api/ApiMe";

import { MeResponse } from "chaild-api/lib/index";
import { PostMeRequest } from "@/model/Request";

export interface MeState {
  me: MeResponse | null;
  nurseryId: number | null; // Navigation Drawerでの出し分け用
}

@Module({ dynamic: true, store, name: "me", namespaced: true })
class MeModule extends VuexModule implements MeState {
  public me: MeResponse | null = null;
  public nurseryId: number | null = null;

  @Action({ rawError: true })
  public async fetchMe() {
    try {
      const response = await ApiMe.fetchMe();
      if (response) {
        LocalDataService.setUser(response);
        this.setMe(response);
      }
      return response;
    } catch {
      return null;
    }
  }

  @Action({ rawError: true })
  public async postMe(request: PostMeRequest) {
    const response: MeResponse = await ApiMe.postMe(request);
    LocalDataService.setUser(response);
    return true;
  }

  @Action({ rawError: true })
  public async postWithNoBody() {
    const response = await ApiMe.postWithNoBody();
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async putMe(input: { defaultNurseryId: number }) {
    const response = await ApiMe.putMe(input);
    if (response) {
      this.setMe(response);
      return response;
    }
  }

  @Mutation
  public setMe(value: MeResponse) {
    this.me = value;
  }

  @Mutation
  public setNurseryId(value: number) {
    this.nurseryId = value;
  }
}

export const meModule = getModule(MeModule);
