/**
 * Todo: 動作確認後に徐々に移行していく
 */
import axios, { AxiosError } from 'axios';
import querystring from 'query-string';
import Swal from 'sweetalert2';
import LocalDataService from '@/service/LocalDataService';
import ErrorMessageUtil from '@/utils/ErrorMessageUtil';

const client = axios.create();

const envs = [
  {
    name: 'default',
    baseUrl: process.env.VUE_APP_API_URL,
    xApiKey: process.env.VUE_APP_X_API_KEY,
  },
  {
    name: 'admin',
    baseUrl: process.env.VUE_APP_ADMIN_API_URL,
    xApiKey: process.env.VUE_APP_ADMIN_X_API_KEY,
  },
  {
    name: 'memoru',
    baseUrl: process.env.VUE_APP_MEMORU_API_URL,
    xApiKey: process.env.VUE_APP_MEMORU_X_API_KEY,
  }
];

const getHeaders = async (name = 'default') => {
  const token = LocalDataService.getAccessToken();
  return {
    'Authorization': `${token}`,
    'Content-Type': 'application/json; charset=utf-8',
    'x-api-key': envs.find((e) => e.name == name)?.xApiKey || '',
  }
}

const getBaseUrl = (name = 'default') => {
  return envs.find((e) => e.name == name)?.baseUrl || '';
}

interface Response {
  status: number;
  config: {
    url: string;
  };
  data: {
    [key: string]: string;
  };
}

client.interceptors.response.use(
  async (response) => {
    const r: Response = response as Response;
    if (r.config.url) {
      if (r.status !== 200) {
        throw Promise.reject(response);
      }
      if (r.data.error) {
        if (
          r.data.error != null &&
          r.data.error != 'E004' &&
          r.data.error != 'E003'
        ) {
          const errorMessage = ErrorMessageUtil.getErrorMessage(r.data.error);
          console.warn('200 Error!')
          alert(`${r.data.error + '\n' +  errorMessage}`);
          throw Promise.reject(response);
        }
      }
      return response;
    } else {
      if (r.data.status !== '0') { // status != 0の時は、何かしらのエラー
        throw Promise.reject(response);
      }
      return response;
    }
  },
  async (error) => {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      if (axiosError.response.status >= 400) {
        const status = String(axiosError.response.status);
        Swal.fire('Error:' + status, '', 'error');
      }
    }
    throw error;
  }
);

export default class ApiClient {

  static async get(
    endpoint: string,
    params: { [key: string]: any } | null,
    type?: string,
  ) {
    const response = await client.get(
      `${getBaseUrl(type)}${endpoint}?${params ? querystring.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
      }) : ''}`,
      {
        headers: await getHeaders(type),
      },
    ).catch((e) => {
      throw e;
    });

    return response.data;
  }

  static async post(
    endpoint: string,
    params: { [key: string]: any } | null,
    type?: string,
  ) {
    const response = await client.post(
      `${getBaseUrl(type)}${endpoint}`,
      JSON.stringify(params),
      {
        headers: await getHeaders(type),
      },
    ).catch((e) => {
      throw e;
    });

    return response.data;
  }

  static async put(
    endpoint: string,
    params: { [key: string]: any } | null,
    type?: string,
  ) {
    const response = await client.put(
      `${getBaseUrl(type)}${endpoint}`,
      JSON.stringify(params),
      {
        headers: await getHeaders(type),
      },
    ).catch((e) => {
      throw e;
    });

    return response.data;
  }

  static async delete(
    endpoint: string,
    params: { [key: string]: any } | null,
    type?: string,
  ) {
    const response = await client.delete(
      `${getBaseUrl(type)}${endpoint}?${params ? querystring.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
      }) : ''}`,
      {
        headers: await getHeaders(type),
      },
    ).catch((e) => {
      throw e;
    });

    return response.data;
  }

}