import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiSchoolService from '@/api/ApiSchoolService';
import { NurseryGrowthDetailResponse, UniqueServiceMastResponse, UniqueServiceRegistrationDetailResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';

// perUse: 'perUse', // 回数
// perUseMonth: 'useMonth', // 利用月額
// monthly: 'monthly' // 固定月額


@Module({ dynamic: true, store, name: 'school-service-monthly-usage', namespaced: true })
class SchoolServiceMonthlyUsageModule extends VuexModule {
  public classId: number | null = null;
  public usages: UniqueServiceRegistrationDetailResponse[] = [];
  public services: UniqueServiceMastResponse[] = [];

  @Action
  public async listUsages({
    yearMonth,
  }: {
    yearMonth: string;
  }) {
    if (this.classId) {
      const response = await ApiSchoolService.listSchoolServiceUsageWithMonth({
        classId: this.classId,
        month: yearMonth,
      });
  
      if (response) {
        this.setUsages(response.data);
      }
    }
  }

  @Mutation
  public setUsages(usages: UniqueServiceRegistrationDetailResponse[]) {
    this.usages = usages;
  }

  @Mutation
  public setClassId(classId: number) {
    this.classId = classId;
  }

  @Action
  public async listSchoolServices() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiSchoolService.listSchoolServices({ nurseryId });
      if (response) {
        this.setSchoolServices(response);
      }
    }
  }

  @Mutation
  public setSchoolServices(services: UniqueServiceMastResponse[]) {
    this.services = services;
  }
}

export const schoolServiceMonthlyUsageModule = getModule(SchoolServiceMonthlyUsageModule);
