
import Vue from 'vue';

import { foodMenusModule } from '@/store/viewModules/food-menu/foodMenusModule';

import PageTitle from '@/components/atoms/PageTitle.vue';
import { classModule } from '@/store/dataModules/classModule';
import LocalDataService from '@/service/LocalDataService';
import ApiMeal from '@/api/ApiMeal';
import dayjs from 'dayjs';
import ApiResource from '@/api/ApiResource';


interface DataType {
  isImporting: boolean;
  file: File | null;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    isImporting: false,
    file: null,
  }),

  computed: {
    isDialogOpen() {
      return foodMenusModule.isImportDialogOpen;
    },
    year() {
      return dayjs(foodMenusModule.importYearMonth).year();
    },
    month() {
      return dayjs(foodMenusModule.importYearMonth).month() + 1;
    },
  },

  methods: {
    closeDialog() {
      foodMenusModule.setIsImportDataDialogOpen(false);
    },
    async startImport() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.file) {
        this.isImporting = true;
        const uploadTargetResponse = await ApiMeal.getUploadUrl({
          nurseryId,
          year: this.year,
          month: this.month,
          filename: this.file.name
        });
        if (uploadTargetResponse) {
          const uploadResponse = await ApiResource.uploadFile({
            url: uploadTargetResponse.url,
            file: this.file,
          });
        }
        this.isImporting = false;
        this.closeDialog();
      }
    }
  },

  mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      classModule.listClass({ nurseryId });
    }
  }
});
