import ApiClient from '@/api/ApiClient';
import { NurseryGrowthDetailResponse } from 'chaild-api/lib';

export interface ChildGrowthRecordRequest {
  childId: number;
  height?: string | number;
  weight?: string | number;
  remarks?: string;
}

export interface GrowthPercentile {
  month: number;
  percentile: number;
  sex: 'male' | 'female';
  valueType: 'height' | 'weight';
  value: number;
}

export default class ApiGrowthRecord {
  public static async listGrowthRecords({
    nurseryId,
    classId,
    month,
    year,
  }: {
    nurseryId: number;
    classId?: number;
    month?: string | number;
    year?: string | number;
  }) {
    const response = await ApiClient.get(`/growth`, {
      nurseryId,
      classId,
      month,
      year,
    });
    if (response && response.growth) {
      return response.growth as NurseryGrowthDetailResponse[];
    }
    return null;
  }

  public static async getChildGrowthRecords({
    childId,
    year,
  }: {
    childId: number;
    year?: string | number;
  }) {
    const response = await ApiClient.get(`/growth/children/${childId}`, {
      year,
    });
    if (response) {
      return response as NurseryGrowthDetailResponse;
    }
    return null;
  }

  public static async saveRecords({
    year,
    month,
    records,
    nurseryId,
  }: {
    year: number;
    month: number;
    records: ChildGrowthRecordRequest[];
    nurseryId: number;
  }) {
    const response = await ApiClient.post('/growth', {
      year,
      month,
      records,
      nurseryId
    });
    return response;
  }

  public static async getPercentiles({
    nurseryId,
  }: {
    nurseryId: number;
  }) {
    const response = await ApiClient.get(`/growth/percentile`, {
      nurseryId
    });
    if (response) {
      return response as GrowthPercentile[];
    }
    return null;
  }

  public static async donwloadRecords({
    nurseryId,
    classId,
    year
  }: {
    nurseryId: number | string;
    classId: number | string;
    year: number | string;
  }) {
    const response = await ApiClient.post(`/growth/download`, {
      nurseryId,
      classId,
      year
    });
  }
}