import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiEvent from "@/api/ApiEvent";
import { YearlyEventResponseForNursery } from "chaild-api/lib";

export interface EventState {
  events: YearlyEventResponseForNursery[];
}

@Module({ dynamic: true, store, name: "event", namespaced: true })
class EventModule extends VuexModule implements EventState {
  public events: YearlyEventResponseForNursery[] = [];

  @Action({ rawError: true })
  public async listEvents(input: {
    nurseryId: number;
    from?: string;
    to?: string;
  }) {
    const response = await ApiEvent.listEvent(input);
    if (response) {
      this.setEvents(response.yearlyEvents);
      return response;
    }
  }

  @Mutation
  public setEvents(value: YearlyEventResponseForNursery[]) {
    this.events = value;
  }
}

export const eventModule = getModule(EventModule);
