import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiStaff from "@/api/ApiStaff";
import {
  ChildDevelopmentListResponse,
  ChildDevelopmentMilestoneSchema,
  NurseryChildResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import ApiChildDevelopment, {
  ChildDevelopmentUpdateRequestBody,
} from "@/api/ApiChildDevelopment";
import ApiChildren from "@/api/ApiChildren";
import { ChildDevelopmentMilestoneType } from "chaild-api/src/constant/childDevelopmentMilestone";

type MilestonesWithPrevYear = {
  prevYears: ChildDevelopmentMilestoneType | null;
} & ChildDevelopmentMilestoneSchema;

const cleanMilestone = (milestone: MilestonesWithPrevYear) => {
  if (milestone.apr === "done") {
    milestone.may = null;
    milestone.june = null;
    milestone.july = null;
    milestone.aug = null;
    milestone.sept = null;
    milestone.oct = null;
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.may === "done") {
    milestone.june = null;
    milestone.july = null;
    milestone.aug = null;
    milestone.sept = null;
    milestone.oct = null;
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.june === "done") {
    milestone.july = null;
    milestone.aug = null;
    milestone.sept = null;
    milestone.oct = null;
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.july === "done") {
    milestone.aug = null;
    milestone.sept = null;
    milestone.oct = null;
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.aug === "done") {
    milestone.sept = null;
    milestone.oct = null;
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.sept === "done") {
    milestone.oct = null;
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.oct === "done") {
    milestone.nov = null;
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.nov === "done") {
    milestone.dec = null;
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.dec === "done") {
    milestone.jan = null;
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.jan === "done") {
    milestone.feb = null;
    milestone.mar = null;
  }

  if (milestone.feb === "done") {
    milestone.mar = null;
  }

  return milestone;
};

export interface ChildDevelopmentChildState {
  developmentRecords: ChildDevelopmentListResponse;
  child: NurseryChildResponse | null;
}

@Module({
  dynamic: true,
  store,
  name: "child-development-child",
  namespaced: true,
})
class ChildDevelopmentChildModule
  extends VuexModule
  implements ChildDevelopmentChildState
{
  developmentRecords: ChildDevelopmentListResponse = [];
  child: NurseryChildResponse | null = null;

  @Action
  async listRecords({
    childId,
    businessYear,
  }: {
    childId: number;
    businessYear: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildDevelopment.getChildDevelopments({
        nurseryId,
        businessYear,
        childId,
      });
      if (response) {
        this.setRecords(response);
      }
    }
  }

  @Mutation
  setRecords(records: ChildDevelopmentListResponse) {
    this.developmentRecords = records;
  }

  @Action
  async getChild({ childId }: { childId: number }) {
    const response = await ApiChildren.getChild(childId);
    this.setChild(response);
  }

  @Mutation
  setChild(child: NurseryChildResponse | null) {
    this.child = child;
  }

  @Action
  async updateRecords({
    childId,
    businessYear,
  }: {
    childId: number;
    businessYear: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const requestBody: ChildDevelopmentUpdateRequestBody[] =
        this.developmentRecords.map((r) => {
          const milestone = cleanMilestone(r.milestone);
          return {
            childDevelopmentId: r.childDevelopmentId,
            milestone: milestone,
          };
        });
      await ApiChildDevelopment.updateChildDevelopments({
        childId,
        businessYear,
        nurseryId,
        body: requestBody,
      });
    }
  }
}

export const childDevelopmentChildModule = getModule(
  ChildDevelopmentChildModule
);
