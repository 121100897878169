
import dayjs from 'dayjs';
import Vue from 'vue';
import PageTitle from '@/components/atoms/PageTitle.vue';

import { childDevelopmentClassModule } from '@/store/viewModules/child-development/childDevelopmentClassModule'
import { NurseryClassResponse } from 'chaild-api/lib';

interface DataType {
  isFetching: boolean;
  // showQuitChildren: boolean;
  // showDonwloadMessage: boolean;
  classId?: number;
  year?: number;
}

export default Vue.extend({
  components: {
    PageTitle
  },
  data: (): DataType => {
    return {
      isFetching: false,
      classId: undefined,
      year: undefined,
    }
  },
  computed: {
    children() {
      return childDevelopmentClassModule.children
    },
    nurseryClass(): NurseryClassResponse | null {
      return childDevelopmentClassModule.nurseryClass
    },
    className(): string {
      if (this.nurseryClass) {
        return this.nurseryClass.className
      }
      return ''
    }
  },
  methods: {
    async listChildren() {
      if(this.classId) {
        this.isFetching = true;
        await childDevelopmentClassModule.listChildren({ classId: this.classId });
        this.isFetching = false;
      }
    },
    async getClass() {
      if(this.classId) {
        this.isFetching = true;
        await childDevelopmentClassModule.getClass({ classId: this.classId });
        this.isFetching = false;
      }
    },
    formatDate(dateString: string | null) {
      if (!dateString) {
        return '不明';
      }
      return dayjs(dateString).format('YYYY-MM-DD HH:mm')
    },
    getAgeBasedonBirthdate(birthdate: string): number {
      const thisYear = dayjs();
      return thisYear.diff(birthdate, 'year');
    },
  },
  mounted() {
    const classId = this.$route.params.classId;
    if (classId) {
      this.classId = parseInt(classId);
      this.listChildren();
      this.getClass();
    }

    const year = this.$route.params.businessYear;
    if (year) {
      this.year = parseInt(year);
    }
  }
})
