
import Vue from "vue";
import dayjs from "dayjs";
import ApiGuidance from "@/api/ApiGuidance";
import LocalDataService from "@/service/LocalDataService";
import { meModule } from "@/store/dataModules/meModule";
import {
  MeNurserySchoolResponse,
  MeResponse,
  NurseryGuidancePlanResponse,
} from "chaild-api/lib";
import { GuidancePlanTypeItems } from "@/constants/guidance";

export default Vue.extend({
  name: "GuidancePlanDetail",

  async mounted() {
    this.planId = this.$route.params.id;
    await this.getPlan();
  },

  data() {
    return {
      loading: false,
      planId: "",
      planTypeItems: GuidancePlanTypeItems,
      planResponse: null as null | NurseryGuidancePlanResponse,
    };
  },

  computed: {
    me: function (): MeResponse | null {
      return meModule.me;
    },
    currentSchool(): MeNurserySchoolResponse | null {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        return (
          this.me.nurserySchools.find((s) => s.nurseryId === nurseryId) || null
        );
      } else {
        return null;
      }
    },
    isDirector(): boolean {
      return !!(this.currentSchool && this.currentSchool.isDirector);
    },
    isChief(): boolean {
      return !!(this.currentSchool && this.currentSchool.isChief);
    },
  },

  methods: {
    async getPlan() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.getPlan({
          nurseryId,
          planId: this.planId,
        }).catch(() => (this.loading = false));
        if (response) {
          this.planResponse = response;
        }
      }
      this.loading = false;
    },
    async updatePlan() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.planResponse) {
        const _itemListBody = this.planResponse.itemList.map((item) => {
          return {
            id: item.item.id,
            body: item.item.body || null,
            childTextList: item.item.childTextList
              ? item.item.childTextList.map((c) => {
                  return {
                    childId: c.childId,
                    body: c.body || null,
                  };
                })
              : undefined,
            grid: item.item.grid || null,
          };
        });
        const response = await ApiGuidance.updatePlan({
          nurseryId,
          planId: this.planResponse.id,
          itemList: _itemListBody,
        }).catch(() => (this.loading = false));
        if (response) {
          alert("保存しました");
          await this.getPlan();
        }
      }
      this.loading = false;
    },
    async updateApproval() {
      const answer = confirm("承認しますか？");
      if (answer) {
        this.loading = true;
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId && this.planResponse && this.currentSchool) {
          const _input = {
            applicationId: undefined as undefined | number,
            chiefId: undefined as undefined | number,
            directorId: undefined as undefined | number,
          };
          if (this.isDirector) {
            _input.directorId = this.currentSchool.userId;
          } else if (this.isChief) {
            _input.chiefId = this.currentSchool.userId;
          }
          const response = await ApiGuidance.updatePlanApproval({
            planId: this.planResponse.id,
            nurseryId,
            ..._input,
          }).catch(() => (this.loading = false));
          if (response) {
            alert("更新しました");
            await this.getPlan();
          }
        }
        this.loading = false;
      }
    },
    async downloadGuidancePlan() {
      if (this.planId) {
        this.loading = true;
        const response = await ApiGuidance.exportPlan({
          planId: this.planId,
        }).catch(() => (this.loading = false));
        if (response) {
          window.open(response.preSignedDownloadUrl, "_blank");
        }
      }
      this.loading = false;
    },
    getPlanTypeText(value: string): string {
      const item = this.planTypeItems.find((p) => p.value == value);
      return item?.text || "";
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
