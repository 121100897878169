
import Vue from 'vue';

import { foodMenusModule } from '@/store/viewModules/food-menu/foodMenusModule';
import dayjs from 'dayjs';

import PageTitle from '@/components/atoms/PageTitle.vue';
import { MealAllergenResponse, NurseryClassResponse } from 'chaild-api/lib';

interface DataType {
  isUpdating: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    isUpdating: false,
  }),

  computed: {
    editingMenu() {
      return foodMenusModule.editingMenu;
    },
    categoryId: {
      get(): number | null {
        if (this.editingMenu) {
          return this.editingMenu.category.categoryId;
        }
        return null;
      },
      set(value: number) {
        if (this.editingMenu) {
          const menu = {...this.editingMenu};
          const category = this.menuCategories.find((c) => c.categoryId === value);
          if (category) {
            menu.category = category;
            foodMenusModule.setEditingMenu(menu);
          }
        }
      }
    },
    body: {
      get(): string | null {
        if (this.editingMenu) {
          return this.editingMenu.body;
        }
        return null;
      },
      set(value: string) {
        if (this.editingMenu) {
          const menu = {...this.editingMenu};
          menu.body = value;
          foodMenusModule.setEditingMenu(menu);
        }
      }
    },
    note: {
      get(): string | null {
        if (this.editingMenu) {
          return this.editingMenu.note;
        }
        return null;
      },
      set(value: string) {
        if (this.editingMenu) {
          const menu = {...this.editingMenu};
          menu.note = value;
          foodMenusModule.setEditingMenu(menu);
        }
      }
    },
    allergenIds: {
      get(): number[] | null {
        if (this.editingMenu) {
          return this.editingMenu.allergens.map((a) => a.allergenId);
        }
        return null;
      },
      set(value: number[]) {
        if (this.editingMenu) {
          const menu = {...this.editingMenu};
          const allergens = value.map((allergenId) => {
            const find = this.allergens.find((a) => a.allergenId === allergenId);
            return find || null;
          }).filter((a) => a !== null) as MealAllergenResponse[];
          menu.allergens = allergens;
          foodMenusModule.setEditingMenu(menu);
        }
      }
    },
    isDialogOpen() {
      if (this.editingMenu) {
        return true;
      }
      return false;
    },
    menuCategories() {
      return foodMenusModule.menuCategories;
    },
    allergens() {
      return foodMenusModule.allergens;
    },
    formattedDate(): string | null {
      if (this.editingMenu) {
        return dayjs(this.editingMenu.date).format('MM/DD (ddd)');
      }
      return null;
    },
  },

  methods: {
    closeDialog() {
      foodMenusModule.setEditingMenu(null);
    },
    openCreateCategoryDialog() {
      foodMenusModule.setIsCreateCategoryDialogOpen(true);
    },
    formatClassNames(classes: NurseryClassResponse[]) {
      return classes.map((c) => c.className).join(',');
    },
    async updateMenu() {
      this.isUpdating = true;
      await foodMenusModule.updateMenu();
      this.isUpdating = false;

      this.closeDialog();
    }
  }
});
