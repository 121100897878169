import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { AccountTitleResponse, CashbookResponse } from "chaild-api/lib";
import ApiCashBook from "@/api/ApiCashBook";

export interface CashbookState {
  cashbook: CashbookResponse | null;
  accountTitleList: AccountTitleResponse[];
}

@Module({ dynamic: true, store, name: "cashbook", namespaced: true })
class CashbookModule extends VuexModule implements CashbookState {
  public cashbook: CashbookResponse | null = null;
  public accountTitleList: AccountTitleResponse[] = [];

  // 出納帳
  @Action({ rawError: true })
  public async listCashbook(input: { nurseryId: number; yearMonth: string }) {
    const response = await ApiCashBook.listCashBook(input);
    if (response) {
      this.setCashbook(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateCashbook(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    isFinalized: boolean;
    carriedAmount: number;
    attachments: string[];
  }) {
    const response = await ApiCashBook.updateCashBook(input);
    if (response) {
      return response;
    }
  }

  // 出納帳:詳細
  @Action({ rawError: true })
  public async createCashbookDetail(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    details: {
      day: string; // DD
      titleId?: number;
      description?: string;
      supplier?: string;
      incomeAmount?: number;
      outgoAmount?: number;
      taxation?: string;
      note?: string;
    }[];
  }) {
    const response = await ApiCashBook.createCashbookDetail(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateCashbookDetail(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    details: {
      day: string; // DD
      detailId: number;
      titleId?: number;
      description?: string;
      supplier?: string;
      incomeAmount?: number;
      outgoAmount?: number;
      taxation?: string;
      note?: string;
    }[];
  }) {
    const response = await ApiCashBook.updateCashbookDetail(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteCashbookDetails(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    detailIds: number[];
  }) {
    const response = await ApiCashBook.deleteCashBookDetail(input);
    if (response) {
      return response;
    }
  }

  // 勘定科目
  @Action({ rawError: true })
  public async listAccountTitle(input: { nurseryId: number }) {
    const response = await ApiCashBook.listAccountTitle(input);
    if (response) {
      this.setAccountTitleList(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async createAccountTitle(input: { name: string; nurseryId: number }) {
    const response = await ApiCashBook.createAccountTitle(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteAccountTitle(input: { titleId: string }) {
    const response = await ApiCashBook.deleteAccountTitle(input);
    if (response) {
      return response;
    }
  }

  @Mutation
  public setCashbook(value: CashbookResponse) {
    this.cashbook = value;
  }

  @Mutation
  public setAccountTitleList(value: AccountTitleResponse[]) {
    this.accountTitleList = value;
  }
}

export const cashbookModule = getModule(CashbookModule);
