
import { auth0Module } from "@/store/dataModules/auth0Module";
import Vue from "vue";
export default Vue.extend({
  name: "Logout",

  mounted() {
    this.logout();
  },

  methods: {
    logout() {
      localStorage.clear();
      auth0Module.logout();
    },
  },
});
