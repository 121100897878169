import ApiClient from '@/api/ApiClient';

export default class ApiEvent {
  // 行事
  public static async listEvent(input: {
    nurseryId: number;
    from?: string;
    to?: string;
  }) {
    const response = await ApiClient.get(`/nursery/${input.nurseryId}/events`, {
      from: input.from,
      to: input.to,
    });
    return response;
  }

  public static async addEvent(input: {
    nurseryId: number;
    eventName: string;
    classIds: number[];
    fromDate: string;
    toDate: string;
    fromTime?: string;
    toTime?: string;
  }) {
    const response = await ApiClient.post(`/nursery/${input.nurseryId}/events`, {
      eventName: input.eventName,
      classIds: input.classIds,
      fromDate: input.fromDate,
      toDate: input.toDate,
      fromTime: input.fromTime,
      toTime: input.toTime,
    });
    return response;
  }

  public static async deleteEvent(input: {
    nurseryId: number;
    eventId: string;
  }) {
    const response = await ApiClient.delete(`/nursery/${input.nurseryId}/events/${input.eventId}`, null)
    return response;
  }

  public static async updateEvent(input: {
    nurseryId: number;
    eventId: string;
    eventName: string;
    classIds: number[];
    date: string;
    fromTime?: string;
    toTime?: string;
  }) {
    const response = await ApiClient.put(`/nursery/${input.nurseryId}/events/${input.eventId}`, {
      eventName: input.eventName,
      classIds: input.classIds,
      date: input.date,
      fromTime: input.fromTime,
      toTime: input.toTime,
    });
    return response;
  }
}