import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiWorkSchedule from "@/api/ApiWorkSchedule";
import {
  ShiftPatternMonthlyResponse,
  ShiftPatternResponse,
  AttendanceScheduleMonthlySchema,
  AttendanceScheduleDailyPartSchema,
  ChildDefaultSchedulePartSchema,
  NurseryClassResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import { ShiftTimetableCategoryType } from "chaild-api/src/constant/shiftTimetableCategory";
import ApiClass from "@/api/ApiClass";
import CommonUtil from "@/utils/CommonUtil";

export interface AttendanceScheduleState {
  yearMonth: string;
  attendanceSchedules: AttendanceScheduleMonthlySchema[];
  editingAttendance: AttendanceScheduleMonthlySchema | null;
  classes: NurseryClassResponse[];
}

@Module({ dynamic: true, store, name: "attendance-schedule", namespaced: true })
class AttendanceScheduleModule
  extends VuexModule
  implements AttendanceScheduleState
{
  public yearMonth: string = dayjs().format("YYYY-MM");
  public attendanceSchedules: AttendanceScheduleMonthlySchema[] = [];
  public editingAttendance: AttendanceScheduleMonthlySchema | null = null;
  public classes: NurseryClassResponse[] = [];

  @Action
  public async onYearMonthChange(yearMonth: string) {
    this.setYearMonth(yearMonth);
    this.setAttendanceSchedules([]);
    this.listClass();
    await this.listAttendanceSchedules();
  }

  @Mutation
  public setYearMonth(yearMonth: string) {
    this.yearMonth = yearMonth;
  }

  @Action
  public async listAttendanceSchedules(classId?: string | number) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));

      const response = await ApiWorkSchedule.listAttendanceSchedules({
        year: yearInt,
        month: monthInt,
        nurseryId,
        classId,
      });

      // console.log(response)

      if (response) {
        this.setAttendanceSchedules(response);
      }
    }
  }

  @Mutation
  public setAttendanceSchedules(schedules: AttendanceScheduleMonthlySchema[]) {
    this.attendanceSchedules = schedules;
  }

  @Action
  public async addDefaultAttendanceSchedules() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));

      const response = await ApiWorkSchedule.addDefaultAttendanceSchedules({
        year: yearInt,
        month: monthInt,
        nurseryId,
      });

      if (response) {
        this.setAttendanceSchedules(response);
      }
    }
  }

  @Action
  public async updateChildAttendanceSchedule({
    date,
    childId,
    abbreviation,
    color,
    status,
    statusReason,
    startMin,
    statusComment,
    endMin,
  }: {
    date: string | number | null;
    childId: string | number;
    abbreviation?: string;
    color?: string;
    status?: "attending" | "isAbsent";
    statusReason?: string;
    statusComment?: string;
    startMin?: number;
    endMin?: number;
  }) {
    const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
    const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));
    await ApiWorkSchedule.updateChildAttendanceSchedule({
      year: yearInt,
      month: monthInt,
      date,
      childId,
      abbreviation,
      color,
      status,
      statusReason,
      statusComment,
      startMin,
      endMin,
    });

    await this.listAttendanceSchedules();
  }

  @Action
  public async updateChildAttendanceMonthlySchedule({
    childId,
    dailySchedules,
  }: {
    childId: string | number;
    dailySchedules: AttendanceScheduleDailyPartSchema[];
  }) {
    const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
    const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));
    await ApiWorkSchedule.updateChildAttendanceMonthlySchedule({
      year: yearInt,
      month: monthInt,
      childId,
      dailySchedules,
    });

    await this.listAttendanceSchedules();
  }

  @Action({ rawError: true })
  public async getChildDefaultSchedule(childId: number) {
      const response = await ApiWorkSchedule.getChildDefaultSchedule(childId);
      if(response){
        return response
      }
  }

  @Action
  public async updateChildDefaultSchedule(
    arrayAddChildDefaultSchedule: {
      childId: number;
      Mins: ChildDefaultSchedulePartSchema;
    },
  ) {
    await ApiWorkSchedule.updateChildDefaultSchedule(arrayAddChildDefaultSchedule);
  }

  @Action
  public async deleteChildDefaultSchedule(
    arrayAddChildDefaultSchedule: {
      childId: number;
      Mins: ChildDefaultSchedulePartSchema;
    },
  ) {
    await ApiWorkSchedule.deleteChildDefaultSchedule(arrayAddChildDefaultSchedule);
  }

  @Action({ rawError: true })
  public async listClass() {
    const nurseryId = LocalDataService.getNurseryId();
    const y = CommonUtil.getFiscalYear(this.yearMonth);
    if (nurseryId) {
      const response = await ApiClass.listClass({
        nurseryId,
        year: y,
      });
      this.setClasses(response);
      if (response) {
        return response;
      }
    }
  }

  @Mutation
  public setClasses(value: NurseryClassResponse[]) {
    this.classes = value;
  }
}

export const attendanceScheduleModule = getModule(AttendanceScheduleModule);
