import ApiClient from '@/api/ApiClient';
import { NurseryNapTemplateResponse } from 'chaild-api/lib';

export default class ApiNapTemplate {
  public static async listNapTemplates(input: {
    nurseryId: number;
  }) {
    const response = await ApiClient.get(`/nap/template`, input);
    if (response) {
      return response as NurseryNapTemplateResponse[];
    }
    return [];
  }
}