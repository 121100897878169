import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiSchoolService from "@/api/ApiSchoolService";
import {
  TimecardListResponse,
  TimecardStaffListResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import ApiTimecard from "@/api/ApiTimecard";

@Module({
  dynamic: true,
  store,
  name: "staff-attendance-list",
  namespaced: true,
})
class StaffAttandanceListModule extends VuexModule {
  public attendances: TimecardListResponse = [];

  @Action
  async getAttendances({ date, classId }: { date: string; classId?: string }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiTimecard.getAttendanceList({
        nurseryId,
        date,
        classId,
      });

      if (response) {
        this.setAttendances(response);
      }
    }
  }

  @Mutation
  setAttendances(attendances: TimecardListResponse) {
    this.attendances = attendances;
  }
}

export const staffAttandanceListModule = getModule(StaffAttandanceListModule);
