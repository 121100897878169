import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryFeeResponse } from "chaild-api/lib";
import ApiFee from "@/api/ApiFee";

export interface FeeState {
  feeList: NurseryFeeResponse[];
  fee: NurseryFeeResponse | null;
}

@Module({ dynamic: true, store, name: "fee", namespaced: true })
class FeeModule extends VuexModule implements FeeState {
  public feeList: NurseryFeeResponse[] = [];
  public fee: NurseryFeeResponse | null = null;

  @Action({ rawError: true })
  public async searchFee(input: {
    nurseryId: number;
    age?: string;
    classId?: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    limit?: number;
    skip?: number;
  }) {
    const response = await ApiFee.searchFee(input);
    if (response) {
      this.setFeeList(response);
    }
  }

  @Action({ rawError: true })
  public async createFee(input: {
    childId: string;
    certificationNumber: string;
    periodStart: string; // YYYY-MM-DD
    periodEnd?: string | null; // YYYY-MM-DD
    rank?: string;
    certifiedCategory: string;
    childcareCategory: string;
    fee: string;
    isFoodCostExempted: boolean;
    prefecture?: string;
    city?: string;
    localCode?: string;
  }) {
    const response = await ApiFee.createFee(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateFee(input: {
    childId: string;
    nurseryFeeId: string;
    certificationNumber: string;
    periodStart: string; // YYYY-MM-DD
    periodEnd?: string | null; // YYYY-MM-DD
    rank?: string;
    certifiedCategory: string;
    childcareCategory: string;
    fee: string;
    isFoodCostExempted: boolean;
    prefecture?: string;
    city?: string;
    localCode?: string;
  }) {
    const response = await ApiFee.updateFee(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteFee(input: { childId: string; nurseryFeeId: string }) {
    const response = await ApiFee.deleteFee(input);
    if (response) {
      return response;
    }
  }

  @Mutation
  public setFee(value: NurseryFeeResponse) {
    this.fee = value;
  }

  @Mutation
  public setFeeList(value: NurseryFeeResponse[]) {
    this.feeList = value;
  }
}

export const feeModule = getModule(FeeModule);
