
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MailTableContainer',
  
  props: {
    chats: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        { text: '日時', value: 'date', },
        { text: 'タイトル', value: 'title', },
        { text: '既読', value: 'chatReadCount', },
        { text: '詳細', value: 'edit', },
        { text: '削除', value: 'delete', },
      ],
    }
  },

  methods: {
    clickItem(item: any) {
      this.$emit('click', item);
    },
    clickDelete(item: any) {
      this.$emit('delete', item);
    },
  }
})
