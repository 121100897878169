
import CommonUtil from '@/utils/CommonUtil';
import dayjs from 'dayjs';
import Vue from 'vue';
import PageTitle from '@/components/atoms/PageTitle.vue';

import { childDevelopmentsModule } from '@/store/viewModules/child-development/childDevelopmentsModule'
import LocalDataService from '@/service/LocalDataService';
import ApiChildDevelopment from '@/api/ApiChildDevelopment';
import ApiResource from '@/api/ApiResource';
import { ImportExportStatusResponse } from 'chaild-api/lib';

interface DataType {
  isFetching: boolean;
  // showQuitChildren: boolean;
  // showDonwloadMessage: boolean;
  year: number;
  isImportDialogOpen: boolean;
  isImportProcessing: boolean;
  importFile?: File;
  fileType: string;
  watchingTimer: number | null;
}

export default Vue.extend({
  components: {
    PageTitle
  },
  data: (): DataType => {
    return {
      isFetching: false,
      year: dayjs().year(),
      isImportDialogOpen: false,
      isImportProcessing: false,
      importFile: undefined,
      fileType: 'domainField',
      watchingTimer: null
    }
  },
  computed: {
    classes() {
      return childDevelopmentsModule.classes
    },
    importStatuses() {
      return childDevelopmentsModule.statuses
    }
  },
  methods: {
    async listDevelopmentRecords() {
      this.isFetching = true;
      await childDevelopmentsModule.listClasses({ year: this.year });
      this.isFetching = false;
    },
    async updateDevelopmentRecords() {
      this.isFetching = true;
      await childDevelopmentsModule.updateRecords({ year: this.year });
      await this.listDevelopmentRecords();
      this.isFetching = false;
    },
    async openFile(path: string | null) {
      const nurseryId = LocalDataService.getNurseryId();
      if (path && nurseryId) {
        this.isFetching = true;
        const url = await ApiResource.getDownloadUrl({
          nurseryId,
          path
        })
        this.isFetching = false;
        window.open(url.preSignedDownloadUrl, '_blank')
      }
    },
    formatDate(dateString: string | null) {
      if (!dateString) {
        return '不明';
      }
      return dayjs(dateString).format('YYYY-MM-DD HH:mm')
    },
    closeImportDialog() {
      this.isImportDialogOpen = false;
    },
    async startImport() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.importFile) {
        this.isImportProcessing = true;
        const uploadTargetResponse = await ApiChildDevelopment.getImportUploadUrl({
          nurseryId,
          fileType: this.fileType
        });
        if (uploadTargetResponse) {
          const uploadResponse = await ApiResource.uploadFile({
            url: uploadTargetResponse.preSignedUploadUrl,
            file: this.importFile,
          });
          this.startWatchingStatus({
            statusId: String(uploadTargetResponse.statusId),
            category: uploadTargetResponse.category
          })
          alert('インポートを開始しました')
        }
        this.isImportProcessing = false;
        this.closeImportDialog();
      }
    },
    startWatchingStatus({
      category,
      statusId,
    }: {
      statusId: string;
      category: string;
    }) {
      this.watchingTimer = setInterval(async () => {
        const response = await childDevelopmentsModule.listImportStatus({
          statusId,
          category
        })

        if (response && response.status === 'success') {
          alert('インポートが完了しました')
          this.stopWatchingStatus();
        }

        if (response && response.status === 'failed') {
          alert('インポートに失敗しました。ファイルの内容を確認してください。')
          this.stopWatchingStatus();
        }
      }, 2500);
    },
    stopWatchingStatus() {
      if (this.watchingTimer) {
        clearInterval(this.watchingTimer)
        this.watchingTimer = null;
      }
    },
    async exportFile(fileType: string) {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiChildDevelopment.getExportUrl({
          nurseryId,
          fileType
        })

        if (response) {
          window.open(response.preSignedDownloadUrl, '_blank');
        }
      }
    }
  },
  watch: {
    year() {
      this.listDevelopmentRecords();
    }
  },
  mounted() {
    this.year = parseInt(CommonUtil.getFiscalYear(dayjs().format('YYYY-MM')))
    this.listDevelopmentRecords();
  }
})
