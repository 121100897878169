
import Vue from 'vue';

import { childDocumentModule } from  '@/store/dataModules/childDocumentModule';
import { ChildInfoData } from 'chaild-api/lib';

import ChildInfoPage from '@/components/organisms/ChildInfoPage.vue';

interface DataType {
  tab: number;
  childId: number | null;
  isSaving: boolean;
  loading: boolean;
}

export default Vue.extend({
  components: {
    ChildInfoPage,
  },

  data: (): DataType => {
    return {
      tab: 0,
      childId: null,
      isSaving: false,
      loading: false,
    };
  },

  computed: {
    doc() {
      return childDocumentModule.doc;
    },
    child() {
      return childDocumentModule.child;
    },
    basicInfo(): ChildInfoData[] {
      if (this.doc) {
        return this.doc.basicInfo;
      }
      return [];
    },
    lifeStyleInfo(): ChildInfoData[] {
      if (this.doc) {
        return this.doc.lifestyleInfo;
      }
      return [];
    },
    healthInfo(): ChildInfoData[] {
      if (this.doc) {
        return this.doc.healthInfo;
      }
      return [];
    },
  },

  methods: {
    updateBasicInfo(value: ChildInfoData) {
      childDocumentModule.updateBasicInfo(value);
      childDocumentModule.updateDataToUpdate(value);
    },
    updateLifestyleInfo(value: ChildInfoData) {
      childDocumentModule.updateLifestyleInfo(value);
      childDocumentModule.updateDataToUpdate(value);
    },
    updateHealthInfo(value: ChildInfoData) {
      childDocumentModule.updateHealthInfo(value);
      childDocumentModule.updateDataToUpdate(value);
    },
    async save() {
      this.isSaving = true;
      await childDocumentModule.saveUpdates();
      this.isSaving = false;
    }
  },
  
  async mounted() {
    this.loading = true;

    const childId = this.$route.params.childId as string;
    if (childId) {
      try {
        childDocumentModule.clearLocalData()
        const parsed = parseInt(childId);
        childDocumentModule.setChildId(parsed);
        await childDocumentModule.getChildInfo();
        await childDocumentModule.getChild();
      } catch {
        return;
      }
    }
    this.loading = false;
  }
});
