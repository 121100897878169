import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiChildren from "@/api/ApiChildren";
import { NurseryChildResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";

export interface ChildrenListState {
  children: NurseryChildResponse[];

  childCode: string | null;
  classId: number | null;
  familyId: number | null;
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  age: number | null;
  nurseryId: number | null;
  limit: number;
  skip: number;
}

@Module({ dynamic: true, store, name: "children-list", namespaced: true })
class ChildrenListModule extends VuexModule implements ChildrenListState {
  public children: NurseryChildResponse[] = [];

  public childCode: string | null = null;
  public classId: number | null = null;
  public familyId: number | null = null;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public nurseryId: number | null = null;
  public gender: string | null = null;
  public age: number | null = null;
  public limit = 100;
  public skip = 0;

  @Action
  public async search() {
    const nurseryId = LocalDataService.getNurseryId();

    if (nurseryId) {
      const searchParams: {
        childCode?: string;
        classId?: number;
        familyId?: number;
        firstName?: string;
        lastName?: string;
        gender?: string;
        age?: number;
        nurseryId: number;
        limit?: number;
        skip?: number;
      } = {
        nurseryId,
        // limit: this.limit,
        skip: this.skip,
      };

      if (this.childCode) {
        searchParams.childCode = this.childCode;
      }
      if (this.classId) {
        searchParams.classId = this.classId;
      }
      if (this.familyId) {
        searchParams.familyId = this.familyId;
      }
      if (this.firstName) {
        searchParams.firstName = this.firstName;
      }
      if (this.lastName) {
        searchParams.lastName = this.lastName;
      }
      if (this.age) {
        searchParams.age = this.age;
      }
      if (this.gender) {
        searchParams.gender = this.gender;
      }

      const response = await ApiChildren.listChildren(searchParams);
      if (response) {
        this.setChildren(response);
      }
    }
  }

  @Mutation
  public setChildren(children: NurseryChildResponse[]) {
    this.children = children;
  }

  @Mutation
  public setClassId(classId: number | null) {
    this.classId = classId;
  }

  @Mutation
  public setLastName(lastName: string | null) {
    this.lastName = lastName;
  }

  @Mutation
  public setFirstName(firstName: string | null) {
    this.firstName = firstName;
  }

  @Mutation
  public setGender(gender: string | null) {
    this.gender = gender;
  }

  @Mutation
  public setAge(age: number | null) {
    this.age = age;
  }
}

export const childrenListModule = getModule(ChildrenListModule);
