
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    initialScrollX: {
      type: Number,
      default: 0
    },
    leftWidth: {
      type: Number,
      default: 120
    },
  },
  computed: {
    leftStyle(): string {
      return `width: ${this.leftWidth}px`;
    }
  },
  mounted() {
    const centerPanel = this.$refs.panelCenter as HTMLDivElement

    if (centerPanel) {
      centerPanel.scrollLeft = this.initialScrollX
    }
  }
})
