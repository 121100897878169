
import Vue, { PropType } from 'vue';
import DialogMixin from '@/mixins/DialogMixin';
import { classEditModule } from '@/store/dataModules/classEditModule';
import ValidationMixin from '@/mixins/ValidationMixin';
import LocalDataService from '@/service/LocalDataService';
import Swal from 'sweetalert2';

export type dialogType = "create" | "update";

export default Vue.extend({
  name: 'ClassDialogContainer',

  mixins: [DialogMixin,ValidationMixin],

  props: {
    dialogType: {
      type: String as PropType<dialogType>,
      default: "create",
    },
  },

  data() {
    return{
      valid: false,
      loading: false,
    }
  },

  computed: {
    className: {
      get() {
        return classEditModule.className;
      },
      set(value: string) {
        classEditModule.setClassName(value);
      }
    },
    year: {
      get() {
        return classEditModule.year;
      },
      set(value: number) {
        classEditModule.setYear(value);
      }
    },
    childrenAge: {
      get() {
        return classEditModule.childrenAge;
      },
      set(value: string) {
        classEditModule.setChildrenAge(value);
      }
    },
  },

  methods: {
    async addClass() {
      /* eslint-disable */
      this.loading = true;

      const id = LocalDataService.getNurseryId();
      if (id) {
        const response = await classEditModule.addClass({
          nurseryId: id,
        });
        if (response) {
          Swal.fire({ title: 'クラスを作成しました', icon: 'success', });
          this.$emit('is-open', false);
        }
      }
      this.loading = false;
    },
    async updateClass() {
      this.loading = true;

      const id = LocalDataService.getNurseryId();
      if (id) {
        const response = await classEditModule.updateClass({
          nurseryId: id,
        });
        if (response) {
          Swal.fire({ title: 'クラスを更新しました', icon: 'success', });
          this.$emit('is-open', false);
        }
      }

      this.loading = false;
    },
    async deleteClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        Swal.fire({
          title: `${this.className} を削除しますか？`,
          text: "この操作は取り消せません",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'red',
          cancelButtonColor: 'grey',
          confirmButtonText: '削除します',
          cancelButtonText: 'キャンセル',
        }).then((result) => {
          if (result.isConfirmed) {
            classEditModule.deleteClass({
              nurseryId: nurseryId,
            }).then(() => {
              Swal.fire({ title: '削除しました', icon: 'success' });
              this.$emit('is-open', false);
            }).catch((e) => {
              Swal.fire({ title: '削除に失敗しました', icon: 'error' });
            })
          }
        })

      }
      
    },
  }
})
