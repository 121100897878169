
import Vue from "vue";

import { attendanceEditModule } from "@/store/dataModules/attendanceEditModule";
import { staffAttandanceEditModule } from "@/store/viewModules/timecard/staffAttendanceEditModule";
import dayjs from "dayjs";
import ApiAttendance from "@/api/ApiAttendance";
import LocalDataService from "@/service/LocalDataService";
import { TimecardResponse } from "chaild-api/lib";
import ApiTimecard from "@/api/ApiTimecard";
import Swal from "sweetalert2";

interface DataType {
  isSaving: boolean;
  loading: boolean;
}

export default Vue.extend({
  computed: {
    attendance() {
      return staffAttandanceEditModule.attendance;
    },
    attendedAt: {
      get(): string | null {
        if (
          staffAttandanceEditModule.attendance &&
          staffAttandanceEditModule.attendance.attendedAt
        ) {
          // return dayjs(attendanceEditModule.attendance.attendedAt).format('YYYY-MM-DDTHH:mm');
          return dayjs(staffAttandanceEditModule.attendance.attendedAt).format(
            "HH:mm"
          );
        }
        return null;
      },
      set(value: string) {
        if (this.date && value && this.attendance) {
          const _attendance = { ...this.attendance } as TimecardResponse;
          const date = dayjs(this.date + value).toISOString();
          _attendance.attendedAt = date;
          staffAttandanceEditModule.setAttendance(_attendance);
          // attendanceEditModule.setAttendedAtValue(date);
        }
      },
    },
    leftAt: {
      get(): string | null {
        if (
          staffAttandanceEditModule.attendance &&
          staffAttandanceEditModule.attendance.leftAt
        ) {
          // return dayjs(attendanceEditModule.attendance.leftAt).format('YYYY-MM-DDTHH:mm');
          return dayjs(staffAttandanceEditModule.attendance.leftAt).format(
            "HH:mm"
          );
        }
        return null;
      },
      set(value: string) {
        if (this.date && value && this.attendance) {
          const _attendance = { ...this.attendance } as TimecardResponse;
          const date = dayjs(this.date + value).toISOString();
          _attendance.leftAt = date;
          staffAttandanceEditModule.setAttendance(_attendance);
        }
      },
    },
    date() {
      return this.$route.params.date;
    },
    dateString() {
      if (this.$route.params.date) {
        return dayjs(this.$route.params.date).format("YYYY年MM月DD日");
      }
      return null;
    },
    breadcrumbsItems: function () {
      return [
        { text: "勤怠管理", href: "/staffAttendance/list" },
        { text: "出勤・退勤編集", href: "", disabled: true },
      ];
    },
  },

  data: (): DataType => {
    return {
      isSaving: false,
      loading: false,
    };
  },

  methods: {
    async getAttendance() {
      const userId = this.$route.params.userId;
      const date = this.$route.params.date;
      if (userId && date) {
        await staffAttandanceEditModule.getAttendance({
          userId,
          date,
        });
      }
      // this.loading = true;
      // await attendanceEditModule.getAttendance();
      // this.loading = false;
    },
    async save() {
      this.isSaving = true;
      const userId = this.$route.params.userId;
      const date = this.$route.params.date;
      if (userId && date && this.attendance) {
        await ApiTimecard.updateStaffAttendance({
          date,
          userId,
          attendedAt: this.attendance.attendedAt || undefined,
          leftAt: this.attendance.leftAt || undefined,
        })
          .then(() => {
            Swal.fire({ title: "更新しました", icon: "success" });
          })
          .catch(() => {
            alert(
              "保存できませんでした。売上情報を確定している場合などは、編集できません。"
            );
          });
      }
      this.isSaving = false;
    },
    async deleteAttendance() {
      this.isSaving = true;
      const userId = this.$route.params.userId;
      const date = this.$route.params.date;
      if (userId && date && this.attendance) {
        await ApiTimecard.updateStaffAttendance({
          date,
          userId,
          attendedAt: null,
          leftAt: null,
        })
          .then(() => {
            Swal.fire({ title: "更新しました", icon: "success" });
          })
          .catch(() => {
            alert(
              "保存できませんでした。売上情報を確定している場合などは、編集できません。"
            );
          });
      }
      this.getAttendance();
      this.isSaving = false;
    },
  },

  mounted() {
    const date = this.$route.params.date;
    const userId = this.$route.params.userId;

    if (date && userId) {
      try {
        this.getAttendance();
      } catch {
        return;
      }
    }
  },
});
