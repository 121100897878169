import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryClassResponse } from "chaild-api/lib";
import ApiClass from "@/api/ApiClass";

export interface ClassState {
  classes: NurseryClassResponse[];
  class: NurseryClassResponse | null;
}

@Module({ dynamic: true, store, name: "class", namespaced: true })
class ClassModule extends VuexModule implements ClassState {
  public classes: NurseryClassResponse[] = [];
  public class: NurseryClassResponse | null = null;

  @Action({ rawError: true })
  public async listClass(input: { nurseryId: number; year?: number | string }) {
    const response = await ApiClass.listClass(input);
    this.setClasses(response);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async getClass(input: { nurseryId: number; classId: string }) {
    const response = await ApiClass.getClass(input);
    if (response) {
      this.setClass(response);
      return response;
    }
  }

  @Mutation
  public setClasses(value: NurseryClassResponse[]) {
    this.classes = value;
  }

  @Mutation
  public setClass(value: NurseryClassResponse) {
    this.class = value;
  }
}

export const classModule = getModule(ClassModule);
