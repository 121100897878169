import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiChildren from "@/api/ApiChildren";
import ApiParent, { ParentInfo } from "@/api/ApiParent";
import { ChildResponse, ParentInfoData } from "chaild-api/lib";

export interface DataToUpdate {
  parentId: number;
  data: ParentInfoData[];
}

export interface ParentDocumentState {
  childId: number | null;
  doc: ParentInfo[];
  child: ChildResponse | null;

  dataToUpdate: DataToUpdate[];
}

@Module({ dynamic: true, store, name: "parent-document", namespaced: true })
class ParentDocumentModule extends VuexModule implements ParentDocumentState {
  public doc: ParentInfo[] = [];
  public child: ChildResponse | null = null;
  public childId: number | null = null;
  public dataToUpdate: DataToUpdate[] = [];

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Action
  public async getParentInfo() {
    if (this.childId) {
      const response = await ApiParent.getParentInfo(this.childId);
      if (response) {
        this.setDoc(response);
      }
    }
  }

  @Action
  public async getChild() {
    if (this.childId) {
      const response = await ApiChildren.getChild(this.childId);
      if (response) {
        this.setChild(response);
      }
    }
  }

  @Action
  public async saveUpdates() {
    if (this.childId && this.dataToUpdate.length > 0) {
      // this.dataToUpdate.forEach((data) => {})
      const responses = await Promise.all(
        this.dataToUpdate.map((d) =>
          ApiParent.updateParentInfo(this.childId!, d)
        )
      );
      // 成功したものだけを上書きする処理
    }
  }

  @Mutation
  public setDoc(doc: ParentInfo[]) {
    this.doc = doc;
  }

  @Mutation
  public setChild(child: ChildResponse) {
    this.child = child;
  }

  @Mutation
  public updateInfoValue(input: { parentId: number; value: ParentInfoData }) {
    if (this.doc.length > 0) {
      this.doc = this.doc.map((d) => {
        if (d.parent.parentId === input.parentId) {
          const newValue = { ...d };
          newValue.parentInfo = newValue.parentInfo.map((info) => {
            if (info.key === input.value.value) {
              return input.value;
            }
            return info;
          });
          return newValue;
        }
        return d;
      });
    }
  }

  @Mutation
  updateDataToUpdate(input: { parentId: number; value: ParentInfoData }) {
    let shouldCreate = true;
    const newDataToUpdate = this.dataToUpdate.map((dataToUpdate) => {
      if (dataToUpdate.parentId === input.parentId) {
        shouldCreate = false;

        const newData = { ...dataToUpdate };
        let shouldPush = true;
        newData.data = newData.data.map((d) => {
          if (d.key === input.value.key) {
            shouldPush = false;
            return input.value;
          }
          return d;
        });

        if (shouldPush) {
          newData.data.push(input.value);
        }
        return newData;
      }

      return dataToUpdate;
    });

    if (shouldCreate) {
      newDataToUpdate.push({
        parentId: input.parentId,
        data: [input.value],
      });
    }

    this.dataToUpdate = newDataToUpdate;
  }

  @Mutation
  clearLocalData() {
    this.doc = [];
    this.childId = null;
    this.child = null;

    this.dataToUpdate = [];
  }
}

export const parentDocumentModule = getModule(ParentDocumentModule);
