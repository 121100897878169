import { render, staticRenderFns } from "./ScrollablePanels2.vue?vue&type=template&id=5c23158c&scoped=true"
import script from "./ScrollablePanels2.vue?vue&type=script&lang=ts"
export * from "./ScrollablePanels2.vue?vue&type=script&lang=ts"
import style0 from "./ScrollablePanels2.vue?vue&type=style&index=0&id=5c23158c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c23158c",
  null
  
)

export default component.exports