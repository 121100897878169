import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { DiaryResponse, NurseryAttendanceResponse } from "chaild-api/lib";
import ApiChildDiary from "@/api/ApiChildDiary";
import ApiAttendance from "@/api/ApiAttendance";
import LocalDataService from "@/service/LocalDataService";

export interface ChildDiaryState {
  diaries: DiaryResponse[];
  limit: number;
  page: number;
  totalItemsCount: number;

  attendances: NurseryAttendanceResponse[];
}

@Module({ dynamic: true, store, name: "child-diary", namespaced: true })
class ChildDiaryModule extends VuexModule implements ChildDiaryState {
  public diaries: DiaryResponse[] = [];

  public limit = 30;
  public page = 1;
  public totalItemsCount = 0;

  public attendances: NurseryAttendanceResponse[] = [];

  @Action
  public async search({
    classId,
    date,
    nurseryId,
  }: {
    classId?: number;
    date?: string;
    nurseryId: number;
  }) {
    const response = await ApiChildDiary.searchChildDiary({
      classId,
      date,
      limit: this.limit,
      skip: (this.page - 1) * this.limit,
      nurseryId,
    });
    this.setDiaries(response.diaries);
    this.setTotalItemsCount(response.totalItemsCount);
  }

  @Mutation
  public setDiaries(diaries: DiaryResponse[]) {
    this.diaries = diaries;
  }

  @Action
  public async listAttendances({
    classId,
    date,
  }: {
    classId: number;
    date: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiAttendance.searchAttendance({
        date,
        nurseryId,
        classId,
      });

      if (response) {
        const attendances = response.attendances as NurseryAttendanceResponse[];
        this.setAttendances(attendances);
      }
    }
  }

  @Mutation
  public setAttendances(attendances: NurseryAttendanceResponse[]) {
    this.attendances = attendances;
  }

  @Mutation
  public setPage(value: number) {
    this.page = value;
  }

  @Mutation
  public setTotalItemsCount(value: number) {
    this.totalItemsCount = value;
  }
}

export const childDiaryModule = getModule(ChildDiaryModule);
