
import Vue from "vue";

export default Vue.extend({
  name: "AgePicker",

  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
  },

  methods: {
    inputAge(value: number | null) {
      this.$emit("input", value);
    },
  },

  data() {
    return {
      items: [
        { label: "すべて", value: null },
        { label: "0歳", value: 0 },
        { label: "1歳", value: 1 },
        { label: "2歳", value: 2 },
        { label: "3歳", value: 3 },
        { label: "4歳", value: 4 },
        { label: "5歳以上", value: 5 },
      ],
    };
  },
});
