import ApiClient from '@/api/ApiClient';
import { InvitationPdfResponse } from 'chaild-api/lib';

export default class ApiEvent {
  // 案内状
  public static async getInvitationLink(childId: number) {
    const response = await ApiClient.get(`/children/${childId}/invitation-pdf`, null);
    if (response) {
      return response as InvitationPdfResponse;
    }
    return null;
  }
}