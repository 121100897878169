export const genderItems = [
  { text: "すべて", value: "" },
  { text: "男性", value: "male" },
  { text: "女性", value: "female" },
];

export const ageItems = [
  { text: "すべて", value: "" },
  { text: "0歳", value: 0 },
  { text: "1歳", value: 1 },
  { text: "2歳", value: 2 },
  { text: "3歳", value: 3 },
  { text: "4歳", value: 4 },
  { text: "5歳以上", value: 5 },
];

export const statusItems = [
  { text: "すべて", value: "" },
  { text: "出席予定", value: "attending" },
  { text: "欠席予定", value: "isAbsent" },
  { text: "在園中", value: "inSchool" },
  { text: "降園済み", value: "wentHome" },
  { text: "欠席連絡", value: "absenceReported" },
  { text: "欠席連絡のキャンセル", value: "absenceCancellationReported" },
];

export const staffStatusItems = [
  { text: "欠勤予定", value: "isAbsent" },
  { text: "出勤予定", value: "attending" },
  { text: "出勤済み", value: "inSchool" },
  { text: "退勤済み", value: "wentHome" },
];
