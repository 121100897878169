
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    initialScrollX: {
      type: Number,
      default: 0
    },
  },
  mounted() {
    const centerPanel = this.$refs.panelCenter as HTMLDivElement

    if (centerPanel) {
      centerPanel.scrollLeft = this.initialScrollX
    }
  }
})
