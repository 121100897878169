
import Vue from "vue";
import MemoruAlbumCard from "@/components/atoms/MemoruAlbumCard.vue";
import { memoruModule } from "@/store/dataModules/memoruModule";
import LocalDataService from "@/service/LocalDataService";
import {
  MultimediaAlbumListResponse,
  NurseryClassResponse,
} from "chaild-api/lib";
import ApiMemoru from "@/api/ApiMemoru";
import { snackbarModule } from "@/store/viewModules/snackbarModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import dayjs from "dayjs";
import { classModule } from "@/store/dataModules/classModule";
import { isPublishedItems } from "@/constants/memoru";

export default Vue.extend({
  name: "MemoruAlbum",

  mixins: [ValidationMixin],

  components: {
    MemoruAlbumCard,
  },

  async mounted() {
    await this.listAlbums();
    await this.listClasses();
  },

  computed: {
    albums: function (): MultimediaAlbumListResponse | null {
      return memoruModule.albums;
    },
    classes(): NurseryClassResponse[] {
      if (this.createAlbumInput.year) {
        return classModule.classes.filter((c) => {
          return String(c.year) == this.createAlbumInput.year;
        });
      } else {
        return classModule.classes;
      }
    },
    page: {
      get(): number {
        if (this.searchFilters.skip == 0) {
          return 1;
        }
        return Math.ceil(
          this.searchFilters.skip / (this.searchFilters.limit - 1)
        );
      },
      set(value: number) {
        this.searchFilters.skip = (value - 1) * this.searchFilters.limit;
        this.listAlbums();
      },
    },
  },

  data() {
    return {
      loading: false,
      searchFilters: {
        year: "",
        nurseryClassIds: [],
        purchaseCountMin: "",
        isPublished: undefined as boolean | undefined,
        limit: 20,
        skip: 0,
      },
      isPublishedItems: isPublishedItems,
      createAlbumDialog: false,
      createAlbumForm: false,
      createAlbumInput: {
        title: "",
        year: "",
        nurseryClassIds: [],
        comment: "",
        availableAfter: "",
        availableBefore: "" as string | undefined,
      },
    };
  },

  methods: {
    async listAlbums() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await memoruModule.listAlbums({
          ...this.searchFilters,
          nurseryId,
        });
      }
      this.loading = false;
    },
    async createAlbum() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        // format as ISO8601
        this.createAlbumInput.availableAfter = this.createAlbumInput
          .availableAfter
          ? dayjs(this.createAlbumInput.availableAfter).toISOString()
          : this.createAlbumInput.availableAfter;
        this.createAlbumInput.availableBefore = this.createAlbumInput
          .availableBefore
          ? dayjs(this.createAlbumInput.availableBefore).toISOString()
          : undefined;

        const response = await ApiMemoru.createAlbum({
          nurseryId,
          ...this.createAlbumInput,
        }).catch(() => (this.loading = false));
        if (response) {
          snackbarModule.snackOn({
            message: "アルバムを作成しました",
          });
          this.createAlbumDialog = false;
          await this.listAlbums();
        }
        this.loading = false;
      }

      this.loading = false;
    },
    async listClasses() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId,
        });
      }
    },
  },
});
