
import Vue from "vue";
import LocalDataService from "@/service/LocalDataService";
import { batchStreamLogModule } from "@/store/dataModules/batchStreamLogModule";
import { BatchStreamLogListResponse } from "chaild-api/lib";
import { categoryItems, statusItems } from "@/constants/batchStreamLog";
import dayjs from "dayjs";

export default Vue.extend({
  name: "BatchStreamLogList",

  async mounted() {
    await this.listFiles();
  },

  data() {
    return {
      loading: false,
      searchFilters: {
        action: "export",
        category: "",
        skip: 0,
        limit: 20,
        status: "",
      },
      categoryItems: categoryItems,
      statusItems: statusItems,
    };
  },

  computed: {
    list: function (): BatchStreamLogListResponse | null {
      return batchStreamLogModule.list;
    },
    page: {
      get(): number {
        if (this.searchFilters.skip == 0) {
          return 1;
        }
        return Math.ceil(
          this.searchFilters.skip / (this.searchFilters.limit - 1)
        );
      },
      set(value: number) {
        this.searchFilters.skip = (value - 1) * this.searchFilters.limit;
        this.listFiles();
      },
    },
  },

  methods: {
    async listFiles() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await batchStreamLogModule
          .listFiles({
            nurseryId,
            ...this.searchFilters,
          })
          .catch();
      }
      this.loading = false;
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD HH:mm");
    },
  },
});
