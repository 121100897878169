
import Vue from 'vue'
import PageTitle from '@/components/atoms/PageTitle.vue'
import DatePager from '@/components/atoms/DatePager.vue';

export default Vue.extend({
  name: 'NapCheck',

  components: {
    PageTitle,
    DatePager,
  },

  data() {
    return {
      selectedHour: 12 as number,
      minutesList: ['00','05','10','15','20','25','30','35','40','45','50','55'],
    }
  },
})
