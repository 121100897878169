import {
  PreSignedUrlResponse,
  TimecardListResponse,
  TimecardResponse,
  TimecardStaffListResponse,
} from "chaild-api/lib";
import ApiClient from "./ApiClient";

export default class ApiTimecard {
  static async getStaffList({ nurseryId }: { nurseryId: number }) {
    return (await ApiClient.get(`/timecard/staffs`, {
      nurseryId,
    })) as TimecardStaffListResponse;
  }

  static async getAttendanceList({
    date,
    nurseryId,
    classId,
  }: {
    date: string;
    nurseryId: number;
    classId?: number | string;
  }) {
    return (await ApiClient.get(`/timecard/${date}`, {
      nurseryId,
      classId,
    })) as TimecardListResponse;
  }

  static async getAttendance({
    date,
    userId,
  }: {
    date: string;
    userId: number | string;
  }) {
    return (await ApiClient.get(
      `/timecard/${date}/${userId}`,
      null
    )) as TimecardResponse;
  }

  static async updateStaffAttendance({
    date,
    userId,
    attendedAt,
    leftAt,
  }: {
    date: string;
    userId: number | string;
    attendedAt?: string | null;
    leftAt?: string | null;
  }) {
    return (await ApiClient.put(`/timecard/${date}/${userId}`, {
      attendedAt,
      leftAt,
    })) as TimecardListResponse;
  }

  static async exportAttendance({
    date,
    nurseryId,
    fileType,
  }: {
    date: string;
    nurseryId: number;
    fileType: string;
  }) {
    return (await ApiClient.post(`/timecard/${date}/export`, {
      nurseryId,
      fileType,
    })) as PreSignedUrlResponse;
  }
}
