import ApiClient from "@/api/ApiClient";

export default class ApiNurseryHoursDiary {
  public static async listNurseryHoursDiary(input: {
    nurseryId: string;
    limit: number;
    skip: number;
    from?: string; // yyyy-mm-dd
    to?: string;
  }) {
    const response = await ApiClient.get(
      `/nursery/${input.nurseryId}/nursery-hours-diary/search`,
      input
    );
    return response;
  }

  public static async getNurseryHoursDiary(input: {
    nurseryId: string;
    date: string; // yyyy-mm-dd
  }) {
    const response = await ApiClient.get(
      `/nursery/${input.nurseryId}/nursery-hours-diary`,
      input
    );
    return response;
  }

  public static async createNurseryHoursDiary(input: {
    nurseryId: number;
    date: string;
    activity: string;
    remarks: string;
    message: string;
    status: "draft" | "applied";
    appliedBy?: number;
    staff?: number[];
  }) {
    const response = await ApiClient.post(
      `/nursery/${input.nurseryId}/nursery-hours-diary`,
      input
    );
    return response;
  }

  public static async updateNurseryHoursDiary(input: {
    nurseryId: number;
    nurseryHoursDiaryId: string;
    date: string;
    activity: string;
    remarks: string;
    message: string;
    status: "draft" | "applied";
    appliedBy?: number;
    staff?: number[];
  }) {
    const response = await ApiClient.put(
      `/nursery/${input.nurseryId}/nursery-hours-diary/${input.nurseryHoursDiaryId}`,
      input
    );
    return response;
  }

  public static async updateNurseryHoursDiaryApprovalStatus(input: {
    nurseryId: number;
    nurseryHoursDiaryId: string;
    approvalStatus: "approved" | "rejected";
  }) {
    const response = await ApiClient.put(
      `/nursery/${input.nurseryId}/nursery-hours-diary/${input.nurseryHoursDiaryId}/approval`,
      input
    );
    return response;
  }
}
