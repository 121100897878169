
import { MultimediaResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "MemoruContentCard",

  props: {
    content: {
      type: Object as () => MultimediaResponse,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
    getMovieSizeText(seconds: number): string {
      if (!seconds) {
        return "0:00";
      }
      return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)}`;
    },
    onClickCard() {
      this.$emit("click");
    },
  },
});
