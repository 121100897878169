import ApiClient from '@/api/ApiClient';

export default class ApiFee {

  public static async listFee(input: { childId: string }) {
    const response = await ApiClient.get(`/fee/children/${input.childId}`, null);
    return response;
  }

  public static async searchFee(input: {
    nurseryId: number;
    age?: string;
    classId?: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    limit?: number;
    skip?: number;
  }) {
    const response = await ApiClient.get('/fee/search', input);
    return response;
  }

  public static async createFee(input: {
    childId: string;
    certificationNumber: string;
    periodStart: string; // YYYY-MM-DD
    periodEnd?: string | null; // YYYY-MM-DD
    rank?: string;
    certifiedCategory: string;
    childcareCategory: string;
    fee: string;
    isFoodCostExempted: boolean;
    prefecture?: string;
    city?: string;
    localCode?: string;
  }) {
    if (input && input.periodEnd == "") {
      input.periodEnd = null;
    }
    const response = await ApiClient.post(`/fee/children/${input.childId}`, input);
    return response;
  }

  public static async updateFee(input: {
    childId: string;
    nurseryFeeId: string;
    certificationNumber: string;
    periodStart: string; // YYYY-MM-DD
    periodEnd?: string | null; // YYYY-MM-DD
    rank?: string;
    certifiedCategory: string;
    childcareCategory: string;
    fee: string;
    isFoodCostExempted: boolean;
    prefecture?: string;
    city?: string;
    localCode?: string;
  }) {
    if (input && input.periodEnd == "") {
      input.periodEnd = null;
    }
    const response = await ApiClient.put(`/fee/${input.nurseryFeeId}/children/${input.childId}`, input);
    return response;
  }

  public static async deleteFee(input: {
    childId: string;
    nurseryFeeId: string;
  }) {
    const response = await ApiClient.delete(`/fee/${input.nurseryFeeId}/children/${input.childId}`, null);
    return response;
  }
}