import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  MemoruPurchaseHistoryListResponse,
  MemoruSalesListResponse,
  MultimediaAlbumListResponse,
  MultimediaAlbumResponse,
  MultimediaListResponse,
} from "chaild-api/lib";
import ApiMemoru from "@/api/ApiMemoru";

interface MemoruState {
  albums: MultimediaAlbumListResponse | null;
  album: MultimediaAlbumResponse | null;
  contents: MultimediaListResponse | null;
  purchaseHistory: MemoruPurchaseHistoryListResponse | null;
  sales: MemoruSalesListResponse | null;
}

@Module({ dynamic: true, store, name: "memoru", namespaced: true })
class MemoruModule extends VuexModule implements MemoruState {
  public albums: MultimediaAlbumListResponse | null = null;
  public album: MultimediaAlbumResponse | null = null;
  public contents: MultimediaListResponse | null = null;
  public purchaseHistory: MemoruPurchaseHistoryListResponse | null = null;
  public sales: MemoruSalesListResponse | null = null;

  @Action({ rawError: true })
  public async listAlbums(input: {
    nurseryId: number;
    isPublished?: boolean;
    limit?: number;
    skip?: number;
    nurseryClassIds?: number[];
    purchaseCountMin?: string;
    year?: string;
  }) {
    const response = await ApiMemoru.listAlbums(input).catch();
    if (response) {
      this.setAlbums(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async getAlbum(input: { albumId: string }) {
    const response = await ApiMemoru.getAlbum({
      albumId: input.albumId,
    }).catch();
    if (response) {
      this.setAlbum(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async listContents(input: {
    albumId: string;
    category?: "photo" | "movie" | string;
    dateFrom?: string;
    dateTo?: string;
    purchasedCountMin?: string;
    limit?: number;
    skip?: number;
  }) {
    const response = await ApiMemoru.listContents(input).catch();
    if (response) {
      this.setContents(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async searchHistory(input: {
    nurseryId: number;
    classId?: string;
    from?: string; // YYYY-MM-DD
    to?: string; // YYYY-MM-DD
    mediaFormat?: "data" | "print" | string;
    serialNumber?: string;
    limit?: number;
    skip?: number;
  }) {
    const response = await ApiMemoru.searchHistory(input).catch();
    if (response) {
      this.setPurchaseHistory(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async listSales(input: {
    monthFrom: string;
    monthTo: string;
    nurseryId: number;
  }) {
    const response = await ApiMemoru.listSales(input).catch();
    if (response) {
      this.setSales(response);
    }
    return response;
  }

  @Mutation
  public setContents(value: MultimediaListResponse) {
    this.contents = value;
  }

  @Mutation
  public setAlbums(value: MultimediaAlbumListResponse) {
    this.albums = value;
  }

  @Mutation
  public setAlbum(value: MultimediaAlbumResponse) {
    this.album = value;
  }

  @Mutation
  public setPurchaseHistory(value: MemoruPurchaseHistoryListResponse) {
    this.purchaseHistory = value;
  }

  @Mutation
  public setSales(value: MemoruSalesListResponse) {
    this.sales = value;
  }
}

export const memoruModule = getModule(MemoruModule);
