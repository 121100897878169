
import ApiGuidance from "@/api/ApiGuidance";
import { GuidancePlanTypeItems } from "@/constants/guidance";
import LocalDataService from "@/service/LocalDataService";
import { NurseryGuidancePlanFormMastListResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";
export default Vue.extend({
  name: "GuidancePlanFormMastList",

  async mounted() {
    await this.listMast();
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: "テンプレート名称", value: "name" },
        { text: "種類", value: "type" },
        { text: "作成日", value: "createdAt" },
        { text: "複製", value: "duplicate" },
      ],
      searchFilters: {
        limit: 10000,
        skip: 0,
      },
      planItems: GuidancePlanTypeItems,
      mastResponse: null as null | NurseryGuidancePlanFormMastListResponse,
    };
  },

  methods: {
    async listMast() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.listPlanFormMast({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.mastResponse = response;
        }
      }
      this.loading = false;
    },
    async duplicateForm(formMastId: string) {
      const answer = confirm("複製を実行しますか？");
      const nurseryId = LocalDataService.getNurseryId();
      if (answer && nurseryId) {
        this.loading = true;
        const response = await ApiGuidance.duplicatePlanFormMast({
          nurseryId,
          formMastId,
        }).catch(() => (this.loading = false));
        if (response) {
          alert(
            "複製が完了しました。\n帳票設定画面に遷移して確認してください。"
          );
          await this.listMast();
        }
      }
      this.loading = false;
    },
    getPlanText(value: string): string {
      const item = this.planItems.find((p) => p.value == value);
      return item?.text || "";
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
