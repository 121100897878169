import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiStaff from "@/api/ApiStaff";
import {
  ChildDevelopmentClassesResponse,
  ChildDevelopmentClassResponse,
  ImportExportStatusResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import ApiChildDevelopment from "@/api/ApiChildDevelopment";
import ApiImportExportStatus from "@/api/ApiImportExportStatus";

export type ImportStatusLocal = {
  statusId: string;
  status: ImportExportStatusResponse;
};

export interface ChildDevelopmentsState {
  classes: ChildDevelopmentClassResponse[];
  statuses: ImportStatusLocal[];
}

@Module({ dynamic: true, store, name: "child-developments", namespaced: true })
class ChildDevelopmentsModule
  extends VuexModule
  implements ChildDevelopmentsState
{
  classes: ChildDevelopmentClassResponse[] = [];
  statuses: ImportStatusLocal[] = [];

  @Action
  async listClasses({ year }: { year: number }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildDevelopment.listChildDevelopments({
        nurseryId,
        businessYear: year,
      });
      if (response) {
        this.setClasses(response);
      }
    }
  }

  @Action
  async updateRecords({ year }: { year: number }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildDevelopment.updateAnalytics({
        nurseryId,
        businessYear: year,
      });
      if (response) {
        this.setClasses(response);
      }
    }
  }

  @Mutation
  setClasses(classes: ChildDevelopmentClassResponse[]) {
    this.classes = classes;
  }

  @Action
  async listImportStatus({
    statusId,
    category,
  }: {
    statusId: string;
    category: string;
  }) {
    const response = await ApiImportExportStatus.getStatus({
      statusId,
      category,
    });
    if (response) {
      this.updateLocalStatus(response);
    }
    return response as ImportExportStatusResponse;
  }

  @Mutation
  updateLocalStatus({
    status,
    statusId,
  }: {
    status: ImportExportStatusResponse;
    statusId: string;
  }) {
    let isFound = false;

    const statuses = this.statuses.map((s) => {
      if (s.statusId === statusId) {
        isFound = true;
        return {
          status,
          statusId,
        };
      }
      return s;
    });
    if (!isFound) {
      statuses.push({
        statusId,
        status,
      });
    }

    this.statuses = statuses;
  }
}

export const childDevelopmentsModule = getModule(ChildDevelopmentsModule);
