
import { NurseryClassResponse } from "chaild-api/lib";
import Vue, { PropType } from "vue";

export interface ClassSelectOption {
  classId: number | "all" | null;
  className: string;
}

export default Vue.extend({
  name: "ClassPicker",

  props: {
    value: {
      type: [Number, String],
      default: "all",
    },
    classes: {
      type: Array as PropType<NurseryClassResponse[]>,
      default: () => [],
    },
    search: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    items(): ClassSelectOption[] {
      if (this.search) {
        return [
          // { classId: null, className: 'すべて' },
          { classId: "all", className: "すべて" },
          ...this.classes.map((c) => {
            return {
              classId: c.classId,
              className: `${c.year}年度 ${c.className}`,
            };
          }),
        ];
      }
      return [
        ...this.classes.map((c) => {
          return {
            classId: c.classId,
            className: `${c.year}年度 ${c.className}`,
          };
        }),
      ];
    },
  },

  methods: {
    updateValue(value: number | "all") {
      this.$emit("input", value);
    },
  },
});
