
import Vue from "vue";

import { childAccountModule } from "@/store/dataModules/childAccountModule";
import {
  ChildcareTypeType,
  GenderType,
} from "chaild-api/src/model/mysql/entity/childEntity";
import LocalDataService from "@/service/LocalDataService";
import HourMinuteInput from "@/components/molecules/HourMinuteInput.vue";
import { classModule } from "@/store/dataModules/classModule";
import {
  NurseryChildResponse,
  NurseryClassResponse,
  NurseryGroupListResponse,
  NurseryHoursPatternResponse,
} from "chaild-api/lib";
import { nurseryHoursModule } from "@/store/dataModules/nurseryHoursModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import { groupModule } from "@/store/dataModules/groupModule";

interface DataType {
  isUpdating: boolean;
}

export default Vue.extend({
  mixins: [ValidationMixin],

  //【WS】園児予定のデフォルト登録を曜日ごとに登録できるように:対応でコメントアウト
  // components: {
  //   HourMinuteInput,
  // },

  data: (): DataType => {
    return {
      isUpdating: false,
    };
  },

  computed: {
    child(): NurseryChildResponse | null {
      return childAccountModule.child;
    },
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    classOptions(): any[] {
      return this.classes.map((c) => {
        return {
          classId: c.classId,
          className: `(${c.year}) ${c.className}`,
        };
      });
    },
    groups: function (): any[] {
      return groupModule.groups.map((g) => {
        return {
          groupId: g.groupId,
          name: `(${g.year}) ${g.name}`,
        };
      });
    },
    childType: {
      get(): string | null {
        if (this.child) {
          return this.child.childcareType;
        }
        return "";
      },
      set(type: ChildcareTypeType) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.childcareType = type;
          childAccountModule.setChild(newValue);
        }
      },
    },
    childTypeOptions() {
      return [
        { value: "normal", label: "一般保育" },
        { value: "temporary", label: "一時保育" },
      ];
    },
    classIds: {
      get(): number[] {
        if (this.child) {
          return this.child.nurseryClasses.map((c) => c.classId);
        }
        return [];
      },
      set(value: number[]) {
        if (this.child) {
          const newValue = { ...this.child };
          const classes: NurseryClassResponse[] = [...this.classes];
          const classObjects = value
            .map((id) => {
              const find = classes.find((c) => c.classId === id);
              if (find) {
                return find;
              }
              return null;
            })
            .filter((obj) => obj !== null) as NurseryClassResponse[];
          newValue.nurseryClasses = classObjects;
          childAccountModule.setChild(newValue);
        }
      },
    },
    groupIds: {
      get(): NurseryGroupListResponse {
        return this.child?.nurseryGroups || [];
      },
      set(value: NurseryGroupListResponse) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.nurseryGroups = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    firstName: {
      get(): string {
        if (this.child) {
          return this.child.firstName;
        }
        return "";
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.firstName = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    firstNameKana: {
      get(): string | null {
        if (this.child) {
          return this.child.firstNameKana;
        }
        return "";
      },
      set(value: string | null) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.firstNameKana = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    lastName: {
      get(): string {
        if (this.child) {
          return this.child.lastName;
        }
        return "";
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.lastName = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    lastNameKana: {
      get(): string | null {
        if (this.child) {
          return this.child.lastNameKana;
        }
        return "";
      },
      set(value: string | null) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.lastNameKana = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    code1: {
      get(): string | null {
        if (this.child) {
          return this.child.optionalChildCode1;
        }
        return "";
      },
      set(value: string | null) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.optionalChildCode1 = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    code2: {
      get(): string | null {
        if (this.child) {
          return this.child.optionalChildCode2;
        }
        return "";
      },
      set(value: string | null) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.optionalChildCode2 = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    dateOfBirth: {
      get(): string | null {
        if (this.child) {
          return this.child.dateOfBirth;
        }
        return "";
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.dateOfBirth = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    genderOptions() {
      return [
        { value: "male", label: "男性" },
        { value: "female", label: "女性" },
      ];
    },
    gender: {
      get(): string | null {
        if (this.child) {
          return this.child.gender;
        }
        return "";
      },
      set(value: GenderType) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.gender = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    admissionDate: {
      get(): string | null {
        if (this.child) {
          return this.child.admissionDate;
        }
        return "";
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.admissionDate = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    graduationDate: {
      get(): string | null {
        if (this.child) {
          return this.child.graduationDate;
        }
        return "";
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.graduationDate = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    moveOrLeave: {
      get(): string | null {
        if (this.child) {
          return this.child.quitDate;
        }
        return "";
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.quitDate = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    moveOrLeaveReason: {
      get(): string | null {
        if (this.child) {
          return this.child.quitReason;
        }
        return null;
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.quitReason = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    notaBene: {
      get(): string | null {
        if (this.child) {
          return this.child.note;
        }
        return null;
      },
      set(value: string) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.note = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    hoursPattern: {
      get(): NurseryHoursPatternResponse | null {
        if (this.child) {
          return this.child.hoursPattern;
        }
        return null;
      },
      set(value: NurseryHoursPatternResponse) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.hoursPattern = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    additionalStaffNum: {
      get(): number | null {
        if (this.child) {
          return this.child.additionalStaffNum;
        }
        return null;
      },
      set(value: number) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.additionalStaffNum = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    maxSubsidyAmount: {
      get(): number | null {
        if (this.child) {
          return this.child.maxSubsidyAmount;
        }
        return null;
      },
      set(value: number) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.maxSubsidyAmount = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    defaultStartMin: {
      get(): number | null {
        if (this.child) {
          return this.child.defaultStartMin;
        }
        return null;
      },
      set(value: number) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.defaultStartMin = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    defaultEndMin: {
      get(): number | null {
        if (this.child) {
          return this.child.defaultEndMin;
        }
        return null;
      },
      set(value: number) {
        if (this.child) {
          const newValue = { ...this.child };
          newValue.defaultEndMin = value;
          childAccountModule.setChild(newValue);
        }
      },
    },
    patternList: function (): NurseryHoursPatternResponse[] {
      return nurseryHoursModule.patternList;
    },
  },

  methods: {
    async update() {
      this.isUpdating = true;
      await childAccountModule.updateChild().then(() => {
        const childId = this.$route.params.childId as string;
        this.$router.push(`/children/account/${childId}`);
      });
      this.isUpdating = false;
    },
    async listNurseryHoursPattern() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await nurseryHoursModule.listPattern({
          nurseryId,
        });
      }
    },
    async listGroups() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await groupModule.listGroups({
          nurseryId,
        });
      }
    },
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await classModule.listClass({
        nurseryId: nurseryId,
      });
    }

    const childId = this.$route.params.childId as string;
    if (childId) {
      try {
        const parsed = parseInt(childId);
        childAccountModule.setChildId(parsed);
        childAccountModule.getChild();
        // childAccountModule.listAllergicFoods();
      } catch {
        return;
      }
    }

    await this.listNurseryHoursPattern();
    await this.listGroups();
  },
});
