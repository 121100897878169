import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiGrowthRecord, { ChildGrowthRecordRequest, GrowthPercentile } from '@/api/ApiGrowthRecord';
import { NurseryGrowthDetailResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';
import CommonUtil from '@/utils/CommonUtil'

export interface GrowthRepuqestWithMonth {
  month: number;
  request: ChildGrowthRecordRequest;
}

@Module({ dynamic: true, store, name: 'child-growth-records', namespaced: true })
class ChildGrowthRecordsModule extends VuexModule {
  public growthRecord: NurseryGrowthDetailResponse | null = null;
  public year: string = dayjs().format('YYYY');
  public childId: number | null = null;
  public childGrowthRecordRequests: GrowthRepuqestWithMonth[] = [];

  public percentileMaster: GrowthPercentile[] = [];

  get yearInt() {
    return parseInt(this.year)
  }

  @Mutation
  public initYear() {
    const yearMonth = dayjs();
    const month = yearMonth.month();
    
    if (month < 4) {
      this.year = `${yearMonth.year() - 1}`
    } else {
      this.year = `${yearMonth.year()}`
    }
  }

  @Action
  public async getGrowthRecord() {
    if (this.childId) {
      const getResponse = await ApiGrowthRecord.getChildGrowthRecords({ 
        childId: this.childId,
        year: this.yearInt,
      });
      if (getResponse) {
        this.setGrowthRecord(getResponse);
      }
    }
  }

  @Action
  public async saveRecords() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await Promise.all(this.childGrowthRecordRequests.map((request) => {
        return ApiGrowthRecord.saveRecords({
          year: this.yearInt,
          month: request.month,
          nurseryId,
          records: [request.request],
        });
      }))
      this.clearGrowthRecordRequests();
    }
  }


  @Mutation
  public setGrowthRecord(record: NurseryGrowthDetailResponse) {
    this.growthRecord = record;
  }

  @Mutation
  public setYear(year: string) {
    this.year = year;
  }

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Mutation
  public updateChildGrowthRecordRequests(request: GrowthRepuqestWithMonth) {
    let shouldPush = true;
    this.childGrowthRecordRequests = this.childGrowthRecordRequests.map((r) => {
      if (
        r.month === request.month
        && r.request.childId === request.request.childId
      ) {
        shouldPush = false;
        return request;
      }
      return r;
    });

    if (shouldPush) {
      this.childGrowthRecordRequests.push(request);
    }
  }

  @Mutation
  public clearGrowthRecordRequests() {
    this.childGrowthRecordRequests = [];
  }

  @Action
  public async getPercentile() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const getResponse = await ApiGrowthRecord.getPercentiles({ 
        nurseryId
      });
      if (getResponse) {
        this.setPercentileMaster(getResponse);
      }
    }
  }

  @Mutation
  public setPercentileMaster(percentiles: GrowthPercentile[]) {
    this.percentileMaster = percentiles;
  }
}

export const childGrowthRecordsModule = getModule(ChildGrowthRecordsModule);