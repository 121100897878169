
import Vue from "vue";

import ChildDevelopmentChildContainer from "@/containers/child-development/ChildDevelopmentChildContainer.vue";

export default Vue.extend({
  components: {
    ChildDevelopmentChildContainer,
  },
});
