
import Vue from "vue";

import { cashbookModule } from "@/store/dataModules/cashbookModule";
import LocalDataService from "@/service/LocalDataService";
import ValidationMixin from "@/mixins/ValidationMixin";
import {
  AccountTitleResponse,
  CashbookDetailsResponse,
  CashbookResponse,
} from "chaild-api/lib";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { meModule } from "@/store/dataModules/meModule";
import ApiResource from "@/api/ApiResource";

export default Vue.extend({
  name: "CashBook",

  mixins: [ValidationMixin],

  async mounted() {
    await this.listCashbook();
    await this.listAccountTitle();
  },

  data() {
    return {
      html: "" as any,
      isLoading: false,
      isFormValid: true,
      accountTitleDialog: false,
      taxationOptions: [
        { text: "", value: "" },
        { text: "通常課税", value: "normal" },
        { text: "課税軽減", value: "reduced" },
        { text: "非課税", value: "none" },
      ],
      finalizeItems: [
        { text: "入力中", value: false },
        { text: "確定", value: true },
      ],
      monthItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      isFinalized: false,
      accountTitleName: "",
      searchFilter: {
        year: dayjs().year(),
        month: dayjs().month() + 1,
      },
      file: null as File | null,
      attachments: [] as string[],
    };
  },

  computed: {
    yearMonth: function (): string {
      let _month = String(this.searchFilter.month);
      if (this.searchFilter.month < 10) {
        _month = "0" + this.searchFilter.month;
      }
      return String(this.searchFilter.year) + "-" + _month;
    },
    // cashbook: function(): CashbookResponse | null {
    // return cashbookModule.cashbook;
    // },
    cashbook: {
      get(): CashbookResponse | null {
        return cashbookModule.cashbook;
      },
      set(value: CashbookResponse) {
        cashbookModule.setCashbook(value);
      },
    },
    details: function (): CashbookDetailsResponse[] {
      if (cashbookModule.cashbook) {
        return cashbookModule.cashbook.details;
      } else {
        return [];
      }
    },
    accountTitleList: function (): AccountTitleResponse[] {
      return cashbookModule.accountTitleList;
    },
    dayItems: function (): string[] {
      const items: string[] = [];
      for (let i = 1; i < 32; i++) {
        if (i < 10) {
          items.push("0" + String(i));
        } else {
          items.push(String(i));
        }
      }
      return items;
    },
  },

  methods: {
    async clickSave() {
      if (this.checkAuthority()) {
        // 確定中から入力中に戻す場合
        if (this.cashbook?.finalizedAt && !this.isFinalized) {
          await this.updateCashbook();
          await this.updateCashbookDetail();
        } else {
          await this.updateCashbookDetail();
          await this.updateCashbook();
        }
        await this.listCashbook();
      } else {
        alert("施設長のみ入力中/確定の変更が可能です");
      }
    },
    async listCashbook() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await cashbookModule.listCashbook({
          nurseryId: nurseryId,
          yearMonth: this.yearMonth,
        });
        // 確定中
        if (
          cashbookModule.cashbook &&
          cashbookModule.cashbook.finalizedAt != null
        ) {
          this.isFinalized = true;
        } else {
          this.isFinalized = false;
        }

        // attachments
        if (this.cashbook && this.cashbook.attachments) {
          this.attachments = this.cashbook.attachments.map((a) => a.objectKey);
        }
      }
      this.isLoading = false;
    },
    async listAccountTitle() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await cashbookModule.listAccountTitle({
          nurseryId,
        });
      }
      this.isLoading = false;
    },
    async createAccountTitle() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await cashbookModule.createAccountTitle({
          nurseryId: nurseryId,
          name: this.accountTitleName,
        });
        await this.listAccountTitle();
      }
      this.accountTitleDialog = false;
    },
    async addDetailRow() {
      this.isLoading = true;
      await this.updateCashbookDetail();
      // carriedAmount
      let _carriedAmount: number | null = null;
      if (
        cashbookModule &&
        cashbookModule.cashbook &&
        cashbookModule.cashbook.carriedAmount != null
      ) {
        _carriedAmount = cashbookModule.cashbook.carriedAmount;
      }

      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await cashbookModule
          .createCashbookDetail({
            nurseryId,
            yearMonth: this.yearMonth,
            details: [{ day: "01" }],
          })
          .catch(() => {
            this.isLoading = false;
          });
        await this.listCashbook();

        // carriedAmount
        if (_carriedAmount && cashbookModule.cashbook) {
          cashbookModule.cashbook.carriedAmount = _carriedAmount;
        }
      }
      this.isLoading = false;
    },
    async updateCashbook() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.cashbook && this.cashbook.carriedAmount != null) {
        const response = await cashbookModule
          .updateCashbook({
            nurseryId,
            yearMonth: this.yearMonth,
            isFinalized: this.isFinalized,
            carriedAmount: this.cashbook.carriedAmount,
            attachments: this.attachments,
          })
          .catch(() => (this.isLoading = false));
        if (response) {
          Swal.fire({
            toast: true,
            icon: "success",
            title: "保存しました",
            position: "top-right",
          });
        } else {
          // Swal.fire({
          //   icon: 'error',
          //   title: '保存に失敗しました',
          //   text: '施設長のみ入力中/確定の変更が可能です'
          // });
        }
      }
      this.isLoading = false;
    },
    async updateCashbookDetail() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (
        nurseryId &&
        cashbookModule.cashbook &&
        cashbookModule.cashbook.details
      ) {
        await cashbookModule
          .updateCashbookDetail({
            nurseryId,
            yearMonth: this.yearMonth,
            details: cashbookModule.cashbook.details.map((d) => {
              return {
                day: d.date ? d.date.split("-")[2] : "01",
                detailId: d.detailId,
                description: d.description || undefined,
                supplier: d.supplier || undefined,
                incomeAmount: d.incomeAmount || undefined,
                outgoAmount: d.outgoAmount || undefined,
                taxation: d.taxation || undefined,
                note: d.note || undefined,
                titleId: (d as any).titleId || undefined,
              };
            }),
          })
          .catch(() => (this.isLoading = false));
        // await this.listCashbook();
      }
      this.isLoading = false;
    },
    async deleteRow(input: { detailId: number }) {
      this.isLoading = true;
      const date = this.cashbook?.yearMonth;
      const nurseryId = LocalDataService.getNurseryId();
      const tempArray: number[] = [];
      if (nurseryId && input.detailId && date) {
        tempArray.push(input.detailId);
        const response = await cashbookModule.deleteCashbookDetails({
          nurseryId,
          yearMonth: date,
          detailIds: tempArray,
        });
        if (response) {
          Swal.fire({
            title: "削除しました",
            toast: true,
          });
          this.listCashbook();
        }
      }
      this.isLoading = false;
    },
    getDayfromDate(date: string): string {
      if (date) {
        return dayjs(date).format("DD");
      } else {
        return "01";
      }
    },
    getDatefromDay(day: string): string {
      if (day) {
        return `${this.searchFilter.year}-${this.searchFilter.month}-${day}`;
      } else {
        return "";
      }
    },
    getIncomebyAccountTitle(titleId: number): number {
      let total = 0;
      if (this.cashbook) {
        for (const amount of this.cashbook.details) {
          if (amount.title && amount.title.titleId == titleId) {
            total += amount.incomeAmount;
          }
        }
      }
      return total;
    },
    getOutgobyAccountTitle(titleId: number): number {
      let total = 0;
      if (this.cashbook) {
        for (const amount of this.cashbook.details) {
          if (amount.title && amount.title.titleId == titleId) {
            total += amount.outgoAmount;
          }
        }
      }
      return total;
    },
    checkAuthority(): boolean {
      const nurseryId = LocalDataService.getNurseryId();
      if (!this.isFinalized) {
        if (
          cashbookModule.cashbook &&
          cashbookModule.cashbook.finalizedAt != null &&
          cashbookModule.cashbook.finalizedAt != undefined &&
          meModule.me &&
          nurseryId
        ) {
          const nursery = meModule.me.nurserySchools.find((u) => {
            if (nurseryId == u.nurseryId) {
              return u;
            }
          });
          if (nursery && nursery.isDirector) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    },
    async addAttachmentFile() {
      this.isLoading = true;

      this.attachments = [];
      const objectKey = await this.getFileObjectKey();
      if (objectKey && this.cashbook) {
        // 既存の添付ファイルをarrayにpush
        for (const attachment of this.cashbook.attachments) {
          this.attachments.push(attachment.objectKey);
        }
        // 新規ファイルをarrayにpush
        this.attachments.push(objectKey);

        if (this.checkAuthority()) {
          await this.updateCashbook();
          await this.listCashbook();
        }
      }
      this.file = null;
      this.isLoading = false;
    },
    async getFileObjectKey(): Promise<string> {
      const nurseryId = LocalDataService.getNurseryId();
      if (this.file && nurseryId) {
        const presignedResponse = await ApiResource.getPresignedUrlForCashbook({
          nurseryId: String(nurseryId),
          filename: this.file.name,
        }).catch();
        if (presignedResponse) {
          const uploadResponse = await ApiResource.uploadFile({
            url: presignedResponse.uploadUrl,
            file: this.file,
          });
          if (uploadResponse) {
            return presignedResponse.objectKey as string;
          }
        }
      }
      return "";
    },
    async deleteAccountTitle(titleId: string) {
      if (titleId) {
        const answer = confirm("削除しますか？");
        if (answer) {
          this.isLoading = true;
          const response = await cashbookModule
            .deleteAccountTitle({
              titleId,
            })
            .catch(() => (this.isLoading = false));
          if (response) {
            alert("削除しました");
            await this.listAccountTitle();
          }
          this.isLoading = false;
        }
      }
    },
  },
});
