
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import DatePicker from "@/components/atoms/DatePicker.vue";
// import StaffPicker from '@/components/atoms/StaffPicker.vue'
import MailTableContainer from "@/containers/MailTableContainer.vue";
import { chatModule } from "@/store/dataModules/chatModule";
import LocalDataService from "@/service/LocalDataService";
import { GroupChatResponse, StaffInfo } from "chaild-api/lib";
import { staffModule } from "@/store/dataModules/staffModule";
import ChatDetailDialog from "@/components/molecules/ChatDetailDialog.vue";
import dayjs from "dayjs";
import { NurseryUserResponse } from "chaild-api/src/component/nursery/user/types";
import { nurseryUserModule } from "@/store/dataModules/nurseryUserModule";
import ApiChat from "@/api/ApiChat";

interface DataType {
  tab: "delivered" | "inDraft" | "inProgress";
  dialog: boolean;
  loading: boolean;
  chatDetail: GroupChatResponse | null;
  startedAtFilterValue: string;
  endedAtFilterValue: string;
  titleFilterValue: string;
  userIdFilterValue: number | "all";
  limit: number;
  page: number;
}

export default Vue.extend({
  name: "Inbox",

  components: {
    PageTitle,
    DatePicker,
    MailTableContainer,
    ChatDetailDialog,
  },

  async mounted() {
    this.loading = true;
    this.startedAtFilterValue = dayjs().format("YYYY-MM-DD");
    await this.listGroupChats();
    await this.listStaffs();
    this.loading = false;
  },

  watch: {
    filterValues: {
      handler() {
        this.listGroupChats();
      },
    },
    tab: {
      handler(val) {
        this.startedAtFilterValue = "";
        this.endedAtFilterValue = "";
        this.titleFilterValue = "";
        this.userIdFilterValue = "all";
        this.listGroupChats();
      },
    },
    page: {
      handler() {
        this.listGroupChats();
      },
    },
  },

  data: (): DataType => {
    return {
      tab: "delivered",
      dialog: false,
      loading: false,
      chatDetail: null,
      startedAtFilterValue: "",
      endedAtFilterValue: "",
      titleFilterValue: "",
      userIdFilterValue: "all",
      limit: 10,
      page: 1,
    };
  },

  computed: {
    groupChats: function (): GroupChatResponse[] {
      return chatModule.groupChats;
    },
    drafts: function (): GroupChatResponse[] {
      return chatModule.drafts;
    },
    filterValues() {
      return {
        startedAt: this.startedAtFilterValue,
        endedAt: this.endedAtFilterValue,
        title: this.titleFilterValue,
        userId: this.userIdFilterValue == "all" ? "" : this.userIdFilterValue,
      };
    },
    totalItemsCount: function (): number {
      return chatModule.totalItemsCount;
    },
    staffs: function (): NurseryUserResponse[] {
      return nurseryUserModule.staffs;
    },
  },

  methods: {
    async listGroupChats() {
      this.loading = true;

      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await chatModule.listGroupChats({
          nurseryId: nurseryId,
          status: this.tab,
          ...this.filterValues,
          skip: (this.page - 1) * this.limit,
          limit: this.limit,
        });
      }

      this.loading = false;
    },
    async listStaffs() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await nurseryUserModule.listStaffs();
      }
    },
    clickDetail(item: GroupChatResponse) {
      this.chatDetail = item;
      this.dialog = true;
    },
    async deleteGroupChat(item: GroupChatResponse) {
      const answer = confirm("削除しますか？");
      if (answer) {
        const response = await ApiChat.deleteGroupChat({
          groupChatIds: String(item.groupChatId),
        }).catch(() => (this.loading = false));
        if (response) {
          alert("削除しました");
          await this.listGroupChats();
        }
        this.loading = false;
      }
    },
  },
});
