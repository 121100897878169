
import ApiGroup from "@/api/ApiGroup";
import LocalDataService from "@/service/LocalDataService";
import { groupModule } from "@/store/dataModules/groupModule";
import { snackbarModule } from "@/store/viewModules/snackbarModule";
import CommonUtil from "@/utils/CommonUtil";
import { NurseryGroupListResponse, NurseryGroupResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "GroupSetting",

  async mounted() {
    await this.listGroups();
  },

  watch: {
    searchFilters: {
      deep: true,
      handler() {
        this.listGroups();
      },
    },
  },

  data() {
    return {
      loading: false,
      dialog: false,
      isEdit: false,
      searchFilters: {
        year: CommonUtil.getFiscalYear(),
      },
    };
  },

  computed: {
    groups: function (): NurseryGroupListResponse | null {
      return groupModule.groups;
    },
    group: function (): NurseryGroupResponse | null {
      return groupModule.group;
    },
  },

  methods: {
    async listGroups() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await groupModule.listGroups({
          nurseryId,
          ...this.searchFilters,
        });
      }
    },
    clickItem(value: NurseryGroupResponse) {
      this.isEdit = true;
      groupModule.setGroup(value);
      this.dialog = true;
    },
    clickCreateBtn() {
      this.isEdit = false;
      groupModule.setGroup({
        year: Number(this.searchFilters.year),
        groupId: 0,
        name: "",
        createdAt: "",
        updatedAt: null,
      });
      this.dialog = true;
    },
    async createGroup() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && groupModule.group) {
        this.loading = true;
        const response = await ApiGroup.createGroup({
          nurseryId,
          year: String(groupModule.group.year),
          name: groupModule.group.name,
        }).catch(() => (this.loading = false));
        if (response) {
          this.dialog = false;
          snackbarModule.snackOn({
            message: "グループを作成しました",
          });
          await this.listGroups();
        }
        this.loading = false;
      }
    },
    async updateGroup() {
      if (groupModule.group) {
        this.loading = true;
        const response = await ApiGroup.updateGroup({
          groupId: groupModule.group.groupId,
          year: String(groupModule.group.year),
          name: groupModule.group.name,
        }).catch(() => (this.loading = false));
        if (response) {
          this.dialog = false;
          snackbarModule.snackOn({
            message: "保存しました",
          });
          await this.listGroups();
        }
        this.loading = false;
      }
    },
    async deleteGroup() {
      if (groupModule.group) {
        const answer = confirm("グループを削除しますか？");
        if (answer) {
          this.loading = true;
          const response = await ApiGroup.deleteGroup({
            groupId: groupModule.group.groupId,
          }).catch(() => (this.loading = false));
          if (response) {
            this.dialog = false;
            snackbarModule.snackOn({
              message: "削除しました",
            });
            await this.listGroups();
          }
          this.loading = false;
        }
      }
    },
    formatDate(value: string) {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
