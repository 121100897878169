import ApiClient from '@/api/ApiClient';
import { DiaryResponse } from 'chaild-api/lib';

export default class ApiChildReport {
  // 園児の日誌
  public static async searchChildDiary(input: {
    classId?: number;
    date?: string;
    limit?: number;
    skip?: number;
    nurseryId?: number;
  }) {
    const response = await ApiClient.get('/children/diary/search', input)
    if (response && response.diaries) {
      return response;
    }
    return null;
  }

  public static async findChildDiary(childId: number, date: string): Promise<DiaryResponse | null> {
    const response = await ApiClient.get(`/children/${childId}/diary?date=${date}`, null);
    if (response) {
      return response as DiaryResponse;
    }
    return null;
  }

}