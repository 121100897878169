
import Vue from "vue";
import DatePager from "@/components/atoms/DatePager.vue";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import {
  NurseryChildResponse,
  NurseryClassResponse,
  SearchNurseryAttendanceResponse,
} from "chaild-api/lib";
import { ageItems, genderItems, statusItems } from "@/constants/attendance";
import { attendanceModule } from "@/store/dataModules/attendanceModule";
import dayjs from "dayjs";
import CommonUtil from "@/utils/CommonUtil";

export default Vue.extend({
  name: "AttendanceList",

  components: {
    DatePager,
  },

  watch: {
    searchFilters: {
      deep: true,
      async handler() {
        await this.searchAttendance();
      },
    },
  },

  async mounted() {
    await this.searchAttendance();
    await this.listClasses();
  },

  data() {
    return {
      loading: false,
      searchFilters: {
        date: dayjs().format("YYYY-MM-DD"),
        age: "",
        classId: "",
        gender: "",
        status: "" as string,
        limit: 1000,
        skip: 0,
      },
      genderItems: genderItems,
      ageItems: ageItems,
      statusItems: statusItems,
    };
  },

  computed: {
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
    attendances: function (): SearchNurseryAttendanceResponse | null {
      return attendanceModule.attendances;
    },
  },

  methods: {
    async listClasses() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule
          .listClass({
            nurseryId,
          })
          .catch();
      }
      this.loading = false;
    },
    async searchAttendance() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await attendanceModule
          .searchAttendance({
            nurseryId,
            ...this.searchFilters,
          })
          .catch();
      }
      this.loading = false;
    },
    formatDate(date: string | null | undefined) {
      if (date) {
        return dayjs(date).format("HH:mm");
      }
      return null;
    },
    getClassName(child: NurseryChildResponse) {
      if (child.nurseryClasses) {
        const year = CommonUtil.getFiscalYear(this.searchFilters.date);
        const currentClass = child.nurseryClasses.find(
          (c) => String(c.year) == year
        );
        if (currentClass) {
          return currentClass.className;
        }
      }
      return "不明なクラス";
    },
    getAgeBasedonBirthdate(birthdate: string): number {
      const thisYear = dayjs();
      return thisYear.diff(birthdate, "year");
    },
    getStatusText(value: string): string {
      if (!value) {
        return "";
      }
      return statusItems.find((s) => s.value == value)?.text || "";
    },
  },
});
