
import dayjs from "dayjs";
import Vue from "vue";
import ApiNurseryRepot from "@/api/ApiNurseryReport";

import DatePicker from "@/components/atoms/DatePicker.vue";
import LocalDataService from "@/service/LocalDataService";
import { nurseryUserModule } from "@/store/dataModules/nurseryUserModule";

interface DataType {
  date: string;
  comment: string;
  event: string;
  earlyLeaver: string;
  appliedBy: number | null;

  isFetching: boolean;
  existingError: boolean;
}

// "comment": "string",
// "event": "string",
// "earlyLeaver": "string",
// "status": "draft"

export default Vue.extend({
  components: {
    DatePicker,
  },

  data: (): DataType => {
    return {
      date: dayjs().format("YYYY-MM-DD"),
      comment: "",
      event: "",
      earlyLeaver: "",
      appliedBy: null,

      isFetching: false,

      existingError: false,
    };
  },

  computed: {
    formattedDate() {
      return dayjs(this.date).format("YYYY年MM月DD日");
    },
    staffOptions() {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`,
        };
      });
    },
  },

  watch: {
    date: {
      handler() {
        this.checkExsitance();
      },
      immediate: true,
    },
  },

  methods: {
    async createAsDraft() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.isFetching = true;
        const response = await ApiNurseryRepot.createSchoolDiary({
          date: this.date,
          nurseryId,
          comment: this.comment,
          event: this.event,
          earlyLeaver: this.earlyLeaver,
          status: "draft",
        });
        if (response) {
          this.$router.go(-1);
        }
        this.isFetching = false;
      }
    },
    async createAndApply() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.appliedBy) {
        this.isFetching = true;
        const response = await ApiNurseryRepot.createSchoolDiary({
          date: this.date,
          nurseryId,
          comment: this.comment,
          event: this.event,
          earlyLeaver: this.earlyLeaver,
          appliedBy: this.appliedBy,
          status: "applied",
        });
        if (response) {
          this.$router.go(-1);
        }
        this.isFetching = false;
      }
    },
    async checkExsitance() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiNurseryRepot.getNurseryReport({
          nurseryId,
          date: this.date,
        });
        if (response) {
          this.existingError = true;
        } else {
          this.existingError = false;
        }
      }
    },
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      nurseryUserModule.listStaffs();
    }
  },
});
