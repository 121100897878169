
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'ClassList',

  props: {
    items: {
      default: [],
    }
  },

  methods: {
    clicked(classId: string) {
      this.$emit('click', classId);
    },
  },
})
