import {
  ChildInfoData,
  ChildInfoResponse,
  ChildResponse,
  NurseryChildResponse,
} from "chaild-api/lib";
import ApiClient from "./ApiClient";

export interface ChildrenWithKana {
  hiraganaId: number;
  column: number;
  row: number;
  value: string;
  children: Array<ChildForAttendance>;
}

export interface ChildForAttendance {
  childId: number;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
}

export default class ApiChildren {
  public static async listChildrenKana(input: { nurseryId: number }) {
    const response = await ApiClient.get(
      `/nursery/${input.nurseryId}/children/hiragana`,
      null
    );
    return response as ChildrenWithKana[];
  }

  public static async listChildren(input: {
    childCode?: string;
    classId?: number;
    groupId?: number;
    familyId?: number;
    firstName?: string;
    lastName?: string;
    nurseryId: number;
    limit?: number;
    age?: number;
    gender?: string;
    skip?: number;
  }) {
    const response = await ApiClient.get(`/children`, input);
    if (response && response.children) {
      return response.children as NurseryChildResponse[];
    }
    return [];
  }

  public static async getChildInfo(childId: number) {
    const response = await ApiClient.get(`/children-info/${childId}`, null);
    if (response) {
      return response as ChildInfoResponse;
    }
    return null;
  }

  public static async updateChildInfo(childId: number, data: ChildInfoData[]) {
    const answers = data.map((d) => {
      return {
        key: d.key,
        value: d.value,
      };
    });
    const response = await ApiClient.post(`/children-info/${childId}`, {
      answers,
    });
    if (response) {
      return response as ChildInfoResponse;
    }
    return null;
  }

  public static async getChild(childId: number) {
    const response = await ApiClient.get(`/children/${childId}`, null);
    if (response) {
      return response as NurseryChildResponse;
    }
    return null;
  }

  public static async updateChild(input: {
    childId: number;
    nurseryId: number;
    childcareType?: string;
    firstName?: string;
    firstNameKana?: string;
    lastName?: string;
    lastNameKana?: string;
    gender?: string;
    dateOfBirth?: string;
    optionalChildCode1?: string;
    optionalChildCode2?: string;
    admissionDate?: string;
    graduationDate: string | null;
    quitDate: string | null;
    quitReason?: string;
    note?: string;
    classIds: number[];
    groupIds: number[];
    hoursPatternId?: string;
    additionalStaffNum?: number;
    defaultStartMin?: number | null;
    defaultEndMin?: number | null;
    maxSubsidyAmount?: number | null;
  }) {
    const response = await ApiClient.put(`/children/${input.childId}`, {
      nurseryId: input.nurseryId,
      childcareType: input.childcareType,
      firstName: input.firstName,
      firstNameKana: input.firstNameKana,
      lastName: input.lastName,
      lastNameKana: input.lastNameKana,
      gender: input.gender,
      dateOfBirth: input.dateOfBirth,
      optionalChildCode1: input.optionalChildCode1,
      optionalChildCode2: input.optionalChildCode2,
      admissionDate: input.admissionDate,
      graduationDate: input.graduationDate,
      quitDate: input.quitDate,
      quitReason: input.quitReason,
      note: input.note,
      classIds: input.classIds,
      groupIds: input.groupIds,
      hoursPatternId: input.hoursPatternId,
      additionalStaffNum: input.additionalStaffNum,
      defaultStartMin: input.defaultStartMin,
      defaultEndMin: input.defaultEndMin,
      maxSubsidyAmount: input.maxSubsidyAmount,
    });
    if (response) {
      return response as NurseryChildResponse;
    }
    return null;
  }

  public static async createChild(input: {
    nurseryId: number;
    childcareType?: string;
    firstName?: string;
    firstNameKana?: string;
    lastName?: string;
    lastNameKana?: string;
    gender?: string;
    dateOfBirth?: string;
    optionalChildCode1?: string;
    optionalChildCode2?: string;
    admissionDate?: string;
    graduationDate?: string;
    quitDate?: string;
    quitReason?: string;
    note?: string;
    allergicFoodIds?: number[];
    hoursPatternId?: string;
  }) {
    const response = await ApiClient.post(`/children`, {
      nurseryId: input.nurseryId,
      childcareType: input.childcareType,
      firstName: input.firstName,
      firstNameKana: input.firstNameKana,
      lastName: input.lastName,
      lastNameKana: input.lastNameKana,
      gender: input.gender,
      dateOfBirth: input.dateOfBirth,
      optionalChildCode1: input.optionalChildCode1,
      optionalChildCode2: input.optionalChildCode2,
      admissionDate: input.admissionDate,
      graduationDate: input.graduationDate,
      quitDate: input.quitDate,
      quitReason: input.quitReason,
      note: input.note,
      allergicFoodIds: input.allergicFoodIds,
      hoursPatternId: input.hoursPatternId,
    });
    if (response) {
      return response as NurseryChildResponse;
    }
    return null;
  }

  public static async getInvitationPdf(input: {
    nurseryId: number;
    childIds: number[];
    type?: "home" | "memoru";
  }) {
    const response = await ApiClient.post(
      `/children/invitation-pdf?nurseryId=${input.nurseryId}`,
      input
    );
    return response;
  }

  public static async importChildrenCsv(input: {
    nurseryId: number;
    filePath: string;
  }) {
    return await ApiClient.post(
      `/nursery/${input.nurseryId}/children/import`,
      input
    );
  }

  public static async exportChildrenCsv(input: {
    nurseryId: number;
    childrenIds: number[];
  }) {
    return await ApiClient.post(
      `/nursery/${input.nurseryId}/children/export`,
      input
    );
  }

  public static async getAuthMethods(input: { childId: number }) {
    return await ApiClient.get(`/children/${input.childId}/auth-methods`, null);
  }

  public static async updateClassChildren(input: {
    nurseryId: number;
    classId: number;
    childrenIds: number[];
  }) {
    return await ApiClient.put(
      `/nursery/${input.nurseryId}/children/class`,
      input
    );
  }

  static async deleteChild(input: { childId: number }) {
    return await ApiClient.delete(`/children/${input.childId}`, null);
  }
}
