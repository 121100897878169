import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  NurseryNapDetailResponse,
  NurseryNapSensorLatestResponse,
} from "chaild-api/lib";
import ApiSensor from "@/api/ApiSensor";
import LocalDataService from "@/service/LocalDataService";
import Pusher from "pusher-js";
import ApiNap from "@/api/ApiNap";
import dayjs from "dayjs";

export interface NapSensorState {
  pusher: Pusher | null;
  napId: number | null;
  nap: NurseryNapDetailResponse | null;
  staffId: number | null;

  latest: NurseryNapSensorLatestResponse | null;

  isAlertOn: boolean;

  intervalCheckTimes: string[];
  alertingChildrenIds: number[];
  alertTimerId: number | null;
}

@Module({ dynamic: true, store, name: "nap-sensor", namespaced: true })
class NapSensorModule extends VuexModule implements NapSensorState {
  public pusher: Pusher | null = null;
  public napId: number | null = null;
  public nap: NurseryNapDetailResponse | null = null;
  public staffId: number | null = null;
  public latest: NurseryNapSensorLatestResponse | null = null;

  public isAlertOn = true;

  public intervalCheckTimes: string[] = [];
  public alertingChildrenIds: number[] = [];
  public alertTimerId: number | null = null;

  @Action
  public async getNap() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.napId) {
      const response = await ApiNap.getNap({
        nurseryId,
        napId: this.napId,
      });

      if (response) {
        this.setNap(response);
      }
    }
  }

  @Mutation
  public setNap(nap: NurseryNapDetailResponse) {
    this.nap = nap;
  }

  @Action
  public async getLatest() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.napId) {
      const response = await ApiSensor.getLatestSensorData(
        this.napId,
        nurseryId
      );
      if (response) {
        this.setLatestData(response);
      }
    }
  }

  @Mutation
  public setLatestData(data: NurseryNapSensorLatestResponse | null) {
    this.latest = data;
  }

  @Mutation
  public updateLatestData(commingData: NurseryNapSensorLatestResponse) {
    if (this.latest) {
      const newData: NurseryNapSensorLatestResponse = { ...this.latest };
      if (newData.data) {
        newData.data = newData.data.map((d) => {
          const find = commingData.data.find(
            (cdd) => cdd.childId === d.childId
          );
          if (find) {
            return find;
          }
          return d;
        });
      }

      this.latest = newData;

      const alerts = newData.data.filter((d) => d.isAlert && d.isSleeping);
      this.alertingChildrenIds = alerts.map((d) => d.childId);
    }
  }

  @Action
  public ringAlert() {
    const music = new Audio("/assets/ccs_alert.mp3");
    music.play();
  }

  @Action
  public ringAlertSilent() {
    const music = new Audio("/assets/silence.mp3");
    music.play();
  }

  @Action
  public async connectPusher() {
    Pusher.logToConsole = true;
    if (this.napId && this.staffId) {
      if (this.pusher) {
        this.pusher.disconnect();
      }

      const authorizer = (ch, options) => {
        return {
          authorize: (socketId, callback) => {
            ApiSensor.authorizePusher(this.napId!, socketId, this.staffId!)
              .then((res) => {
                this.subscribeChannle(res?.channelName);
                return res;
              })
              .then((data) => {
                callback(null, data);
              })
              .catch((err) => {
                callback(new Error(`Error calling auth endpoint: ${err}`), {
                  auth: "",
                });
              });
          },
        };
      };

      this.setPusher(authorizer);

      if (this.pusher) {
        const channel = this.pusher.subscribe(`presence-${this.napId}`);
      }
    }
  }

  @Action
  public async subscribeChannle(channelName: string | undefined) {
    if (this.pusher && channelName) {
      const channel = this.pusher.subscribe(channelName);

      const self = this;

      channel.bind(
        "sensorEventCreated",
        function (data: NurseryNapSensorLatestResponse) {
          self.updateLatestData(data);
        }
      );

      channel.bind(
        "sensorAlerted",
        function (data: NurseryNapSensorLatestResponse) {
          self.updateLatestData(data);
        }
      );

      channel.bind(
        "sensorLogUploaded",
        function (data: NurseryNapSensorLatestResponse) {
          self.updateLatestData(data);
        }
      );
    }
  }

  @Mutation
  public setPusher(authorizer) {
    this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: "ap3",
      authorizer,
    });
  }

  @Mutation
  public setNapId(napId: number) {
    this.napId = napId;
  }

  @Mutation
  public setStaffId(staffId: number) {
    this.staffId = staffId;
  }

  @Action
  public disconnect() {
    if (this.pusher) {
      this.pusher.disconnect();
    }
  }

  @Mutation
  public setIsAlertOn(isAlertOn: boolean) {
    this.isAlertOn = isAlertOn;
  }

  @Action
  public async confirmIntervalCheck(recorderId: number) {
    if (this.napId) {
      await Promise.all(
        this.intervalCheckTimes.map((timeCode) =>
          ApiSensor.confirmIntervalCheck(this.napId!, timeCode, recorderId)
        )
      );
      this.clearIntervalCheckTime();
    }
  }

  @Action
  public async confirmOldestIntervalCheck(recorderId: number) {
    if (this.napId && this.intervalCheckTimes.length > 0) {
      const timeCode = this.intervalCheckTimes[0];
      await ApiSensor.confirmIntervalCheck(this.napId!, timeCode, recorderId);
      this.removeIntervalCheckTime(timeCode);
    }
  }

  @Mutation
  public addIntervalCheckTime(time: string) {
    this.intervalCheckTimes.push(time);
  }

  @Mutation
  public clearIntervalCheckTime() {
    this.intervalCheckTimes = [];
  }

  @Mutation
  public removeIntervalCheckTime(timeCode: string) {
    this.intervalCheckTimes = this.intervalCheckTimes.filter(
      (t) => t !== timeCode
    );
  }

  @Action
  public async updateChildNapStatus(payload: {
    isSleep: boolean;
    childId: number;
  }) {
    if (this.napId) {
      const timestamp = dayjs().toISOString();
      await ApiNap.updateChildNapStatus({
        childId: payload.childId,
        napId: this.napId,
        status: payload.isSleep ? "sleepIn" : "getUp",
        time: timestamp,
      });

      if (!payload.isSleep) {
        this.removeAlertingChildId(payload.childId);
      }
    }
  }

  @Mutation
  public addAlertingChildId(childId: number) {
    this.alertingChildrenIds.push(childId);
  }

  @Mutation
  public removeAlertingChildId(childId: number) {
    this.alertingChildrenIds = this.alertingChildrenIds.filter(
      (id) => id !== childId
    );
  }
}

export const napSensorModule = getModule(NapSensorModule);
