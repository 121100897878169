
import { ChildInfoData } from "chaild-api/lib";
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    elements: {
      type: Array as PropType<ChildInfoData[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },

  methods: {
    inputValue(value: any, element: ChildInfoData) {
      const newEl = { ...element };
      newEl.value = value;
      this.$emit("input", newEl);
    },
  },
});
