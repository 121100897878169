import { render, staticRenderFns } from "./SchoolServiceUsages.vue?vue&type=template&id=90c51066&scoped=true"
import script from "./SchoolServiceUsages.vue?vue&type=script&lang=ts"
export * from "./SchoolServiceUsages.vue?vue&type=script&lang=ts"
import style0 from "./SchoolServiceUsages.vue?vue&type=style&index=0&id=90c51066&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c51066",
  null
  
)

export default component.exports