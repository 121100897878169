
import Vue from "vue";
import LocalDataService from "@/service/LocalDataService";
import { cashbookModule } from "@/store/dataModules/cashbookModule";
import { AccountTitleResponse, CashbookResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import { taxationOptions } from "@/constants/cashbook";
import { meModule } from "@/store/dataModules/meModule";
import ApiCashBook from "@/api/ApiCashBook";

export default Vue.extend({
  name: "CashBookTable",

  async mounted() {
    if (this.$route.params.yearMonth) {
      this.yearMonth = this.$route.params.yearMonth;
      await this.getMe();
      await this.listCashbook();
      await this.listAccountTitle();

      await this.getPresignedUrl();
    }
  },

  data() {
    return {
      loading: false,
      yearMonth: "",
      taxationOptions: taxationOptions,
    };
  },

  computed: {
    cashbook: function (): CashbookResponse | null {
      return cashbookModule.cashbook;
    },
    accountTitles: function (): AccountTitleResponse[] {
      return cashbookModule.accountTitleList;
    },
    nurseryName: function (): string {
      const nurseryId = LocalDataService.getNurseryId();
      if (meModule.me && nurseryId) {
        return (
          meModule.me.nurserySchools.find(
            (n) => String(n.nurseryId) == String(nurseryId)
          )?.nurseryName || "園名"
        );
      } else {
        return "園名";
      }
    },
  },

  methods: {
    async listCashbook() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await cashbookModule
          .listCashbook({
            nurseryId,
            yearMonth: this.yearMonth,
          })
          .catch();
      }
      this.loading = false;
    },
    async listAccountTitle() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await cashbookModule.listAccountTitle({
          nurseryId,
        });
      }
      this.loading = false;
    },
    async getMe() {
      await meModule.fetchMe();
    },
    async getPresignedUrl() {
      // get base64
      const rawHtml = document.documentElement.outerHTML;
      const additionalStyle = `<style type="text/css">@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
        .font {font-family: "Noto Sans JP", sans-serif, system-ui !important;}
        body {font-family: "Noto Sans JP", sans-serif, system-ui !important;}
        div, table {font-family: "Noto Sans JP", sans-serif, system-ui !important;}
        </style>`;
      const headTagIndex = rawHtml.indexOf("<head>");
      const styleInserted =
        rawHtml.slice(0, headTagIndex + "<head>".length) +
        additionalStyle +
        rawHtml.slice(headTagIndex + "<head>".length, rawHtml.length);

      const encoded = window.btoa(unescape(encodeURIComponent(styleInserted)));
      // post
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.yearMonth) {
        const response = await ApiCashBook.getPresignedUrl({
          nurseryId,
          yearMonth: this.yearMonth,
          base64Html: encoded,
        }).catch();
        if (response) {
          window.open(response.preSignedDownloadUrl, "_blank");
        }
      }
    },
    getIncomebyAccountTitle(titleId: number): number {
      let total = 0;
      if (this.cashbook) {
        for (const amount of this.cashbook.details) {
          if (amount.title && amount.title.titleId == titleId) {
            total += amount.incomeAmount;
          }
        }
      }
      return total;
    },
    getOutgobyAccountTitle(titleId: number): number {
      let total = 0;
      if (this.cashbook) {
        for (const amount of this.cashbook.details) {
          if (amount.title && amount.title.titleId == titleId) {
            total += amount.outgoAmount;
          }
        }
      }
      return total;
    },
    formatMonthDay(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("MM-DD");
    },
  },
});
