import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { BatchStreamLogListResponse } from "chaild-api/lib";
import ApiBatchStreamLog, { ListFilesRequest } from "@/api/ApiBatchStreamLog";

export interface BatchStreamLogState {
  list: BatchStreamLogListResponse | null;
}

@Module({ dynamic: true, store, name: "batch-stream-log", namespaced: true })
class BatchStreamLogModule extends VuexModule implements BatchStreamLogState {
  public list: BatchStreamLogListResponse | null = null;

  @Action({ rawError: true })
  public async listFiles(input: ListFilesRequest) {
    const response = await ApiBatchStreamLog.listFiles(input);
    if (response) {
      this.setList(response);
    }
    return response;
  }

  @Mutation
  public setList(value: BatchStreamLogListResponse) {
    this.list = value;
  }
}

export const batchStreamLogModule = getModule(BatchStreamLogModule);
