import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiChildren, { ChildrenWithKana } from "@/api/ApiChildren";

export interface ChildrenState {
  kanaList: ChildrenWithKana[];
}

@Module({ dynamic: true, store, name: "children", namespaced: true })
class ChildrenModule extends VuexModule implements ChildrenState {
  public kanaList: ChildrenWithKana[] = [];

  @Action({ rawError: true })
  public async listKana(input: { nurseryId: number }) {
    const response = await ApiChildren.listChildrenKana(input);
    if (response) {
      this.setKanaList(response);
      return response;
    }
  }

  @Mutation
  public setKanaList(value: ChildrenWithKana[]) {
    this.kanaList = value;
  }
}

export const childrenModule = getModule(ChildrenModule);
