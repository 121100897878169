
import { meModule } from "@/store/dataModules/meModule";
import { MeNurserySchoolResponse, MeResponse } from "chaild-api/lib";
import Vue from "vue";
import { navMenuItems } from "@/constants/navigation";

export default Vue.extend({
  name: "NavigationDrawer",

  data() {
    return {
      navMenuItems: navMenuItems,
    };
  },

  computed: {
    shouldHideDrawer() {
      if (
        this.$route.path === "/attendance/management" ||
        this.$route.path === "/staffAttendance/management" ||
        this.$route.path === "/maintenance" ||
        (this.$route.path.startsWith("/nap/") &&
          this.$route.path.endsWith("detail")) ||
        (this.$route.path.startsWith("/nap/") &&
          this.$route.path.endsWith("sensor")) ||
        this.$route.name == "CashBookTable"
      ) {
        return true;
      }
      return false;
    },
    me: function (): MeResponse | null {
      return meModule.me;
    },
    nurseryId: function (): number | null {
      return meModule.nurseryId;
    },
    currentSchool: function (): MeNurserySchoolResponse | null {
      if (this.me && this.nurseryId) {
        const currentSchool = this.me.nurserySchools.find((s) => {
          return s.nurseryId == this.nurseryId;
        });
        return currentSchool ?? null;
      } else {
        return null;
      }
    },
    isAdmin: function (): boolean {
      return !!this.currentSchool?.isAdmin;
    },
    username: function (): string | null {
      if (this.currentSchool) {
        return `${this.currentSchool?.lastName || ""} ${
          this.currentSchool?.firstName || ""
        }`;
      } else {
        return null;
      }
    },
  },

  methods: {
    openNewTab(value: string) {
      window.open(value, "_blank");
    },
    shouldShowMenuHeader(menu: string): boolean {
      if (this.currentSchool) {
        const features = (
          this.currentSchool as any
        )?.applicationPlan?.nurseryWeb?.featureGroups.find(
          (f) => f.name == menu
        );
        const enabledMenus = features.features.filter((f) => f.isEnabled);
        return !(enabledMenus.length == 0);
      } else {
        return false;
      }
    },
  },
});
