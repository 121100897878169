import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { UniqueServiceMastResponse } from "chaild-api/lib";
import ApiSchoolService from "@/api/ApiSchoolService";
import LocalDataService from "@/service/LocalDataService";

@Module({ dynamic: true, store, name: "school-service", namespaced: true })
class SchoolServiceModule extends VuexModule {
  public schoolServices: UniqueServiceMastResponse[] = [];

  @Action
  public async listSchoolServices() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiSchoolService.listSchoolServices({ nurseryId });
      if (response) {
        this.setSchoolServices(response);
      }
    }
  }

  @Mutation
  public setSchoolServices(services: UniqueServiceMastResponse[]) {
    this.schoolServices = services;
  }

  @Action
  public async createSchoolService({
    uniqueServiceName,
    serviceType,
    paymentMonthType,
    price,
    maximumPrice,
    accountType,
  }: {
    uniqueServiceName: string;
    serviceType: string;
    paymentMonthType: number;
    price: number;
    maximumPrice?: number;
    accountType: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiSchoolService.createSchoolService({
        nurseryId,
        uniqueServiceName,
        serviceType,
        paymentMonthType,
        price,
        maximumPrice,
        accountType,
      });

      if (response) {
        return true; // 成功
      }
    }
    return false; // 失敗
  }

  @Action
  public async updateSchoolService(service: UniqueServiceMastResponse) {
    const response = await ApiSchoolService.updateSchoolService({
      uniqueServiceId: service.uniqueServiceId,
      uniqueServiceName: service.uniqueServiceName,
    });
    return response;
  }

  @Action
  public async deleteService(service: UniqueServiceMastResponse) {
    const response = await ApiSchoolService.deleteSchoolService({
      uniqueServiceId: service.uniqueServiceId,
    });
    return response;
  }
}

export const schoolServiceModule = getModule(SchoolServiceModule);
