
/* eslint-disable */
import Vue from 'vue';
import { classModule } from '@/store/dataModules/classModule';
import { pasoriSensorModule } from '@/store/viewModules/pasori/pasoriSensorModule'
import ClassPicker from '@/components/atoms/ClassPicker.vue';
import { NurseryChildResponse, NurseryClassResponse } from 'chaild-api/lib';
import { PasoriDevice } from '@/api/ApiDevice';
import {
  NFCPortLib,
  Configuration,
  DetectionOption,
  array_tohexs
} from '@/lib/NFCPortLib'


interface DataType {
  isFetching: boolean;
  showQuit: boolean;
  classId: number | null;
  selectedChild: NurseryChildResponse | null;
  detectedIdm: string | null;
  isRegistering: boolean;
}

interface ChildWithDevices {
  child: NurseryChildResponse;
  devices: PasoriDevice[];
}

export default Vue.extend({
  components: {
    ClassPicker
  },

  data: (): DataType => {
    return {
      isFetching: false,
      showQuit: false,
      classId: null,
      selectedChild: null,
      detectedIdm: null,
      isRegistering: false,
    }
  },

  computed: {
    classes(): NurseryClassResponse[] {
      return classModule.classes
    },
    childrenTableHeaders: function() {
      return [
        { text: '園児氏名', value: 'name', },
        { text: 'クラス名', value: 'class', },
        { text: 'UID', value: 'sensors', },
        { text: '', value: 'edit', },
      ];
    },
    children() {
      if (!this.showQuit) {
        return pasoriSensorModule.children.filter((c) => c.quitDate === null)
      }
      return pasoriSensorModule.children
    },
    sensors() {
      return pasoriSensorModule.sensors
    },
    childrenWithDevices(): ChildWithDevices[] {
      return this.children.map((c) => {
        const devices = pasoriSensorModule.sensors.filter((device) => device.childId === c.childId)
        return {
          child: c,
          devices: devices
        }
      })
    }
  },

  methods: {
    getClassName(child: NurseryChildResponse) {
      if (child.nurseryClasses) {
        const latest = child.nurseryClasses.length > 0 ? child.nurseryClasses[0] : null;
        if (latest) {
          return latest.className
        }
      }
      return '-';
    },
    async selectClassName(classId: number) {
      this.isFetching = true;
      this.classId = classId;
      await Promise.all([
        pasoriSensorModule.listChildren({ classId }),
        pasoriSensorModule.listSensors({ classId })
      ])
      this.isFetching = false;
    },
    async read() {
      const lib = new NFCPortLib()
      const nfcConfig = new Configuration(500, 500, true, true);

      try {
        await lib.init(nfcConfig);
        await lib.open();
      } catch {
        alert('読み取りデバイスに接続できませんでした。ドライバーが正しくインストールされていない可能性があります。')
      }
      
      try {
        /* detectCard(FeliCa Card) */
        const detectOption = new DetectionOption(new Uint8Array([0xff, 0xff]), 0, true, false, null);
        const card = await lib.detectCard('iso14443-3A', detectOption)
          .then(ret => {
            return ret;
          }, (error) => {
            // detectTitle.innerText = 'Card is not detected';
            console.log(error)
            // throw (error);
          });

        this.detectedIdm = array_tohexs(card.uid)

      } catch {
        alert('センサーの読み取りに失敗しました。センサーが正しくセットされているか確認してください。')
      }

      /* close() */
      await lib.close();
    },
    async register() {
      if (
        this.selectedChild
        && this.detectedIdm
        && this.classId
      ) {
      this.isRegistering = true;

      await pasoriSensorModule.registerSensor({
        sid: this.detectedIdm,
        childId: this.selectedChild.childId,
        classId: this.classId
      })
      
      this.selectedChild = null;
      this.detectedIdm = null;
      this.isRegistering = false;
      }
    },
    async remove(childId: number, sid: string) {
      if (
        this.classId
      ) {
        this.isFetching = true;
        await pasoriSensorModule.removeSensor({
          childId,
          sid,
          classId: this.classId
        })
        this.isFetching = false
      }
    }
  },
  // async mounted() {

  // }
})
