
import Vue from "vue";
import KanaList from "@/components/atoms/KanaList.vue";
import LocalDataService from "@/service/LocalDataService";
import { childrenModule } from "@/store/dataModules/childrenModule";
import { attendanceModule } from "@/store/dataModules/attendanceModule";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import {
  ChildResponse,
  NurseryAttendanceResponse,
  NurseryChildResponse,
} from "chaild-api/lib";
import { ChildForAttendance } from "@/api/ApiChildren";

interface DataType {
  dialog: boolean;
  selectedChild: ChildResponse | null;
  selectedKana: string | null;

  isUpdating: boolean;
  loading: boolean;
}

export default Vue.extend({
  name: "AttendanceManagement",

  components: {
    KanaList,
  },

  async mounted() {
    attendanceModule.setDate(dayjs().format("YYYY-MM-DD"));
    await this.listDailyAttendances();
    await this.fetchKanaList();
  },

  // 押したひらがなに応じて変化させる

  data: (): DataType => {
    return {
      dialog: false,
      selectedChild: null,
      selectedKana: null,

      isUpdating: false,
      loading: false,
    };
  },

  computed: {
    childrenList(): ChildForAttendance[] {
      if (this.selectedKana) {
        const target = childrenModule.kanaList.filter(
          (childrenWithKana) => childrenWithKana.value === this.selectedKana
        );
        return target.flatMap((childrenWithKana) => childrenWithKana.children);
      }

      return [];

      // return childrenModule.kanaList.flatMap((childrenWithKana) => childrenWithKana.children);
    },
    kanaList: function () {
      const items = childrenModule.kanaList.map((item) => {
        if ((item as any).children.length > 0) {
          return (item as any).value;
        }
      });
      return items.filter((item) => item);
    },
    selectedChildAttendance(): NurseryAttendanceResponse | null {
      if (this.selectedChild) {
        const find = attendanceModule.todaysAttendances.find(
          (at) => at.child.childId === this.selectedChild?.childId
        );
        if (find) {
          return find;
        }
      }
      return null;
    },
    selectedChildsSiblingAttendance(): NurseryAttendanceResponse[] {
      const result: NurseryAttendanceResponse[] = [];
      if (
        this.selectedChild &&
        this.selectedChildAttendance &&
        attendanceModule.todaysAttendances
      ) {
        const siblingChildIds =
          this.selectedChildAttendance.child.siblingChildIds;
        // Todo: 以下消す
        // const siblingChildIds = [258888588688,278888588888]

        for (const id of siblingChildIds) {
          const _attendance = attendanceModule.todaysAttendances.find((at) => {
            if (at.child.childId == id) {
              return at;
            }
          });
          if (_attendance) {
            result.push(_attendance);
          }
        }
      }
      return result;
    },
    shouldAttendButtonEnabled(): boolean {
      if (this.isUpdating) {
        return false;
      }
      if (
        this.selectedChildAttendance &&
        this.selectedChildAttendance.attendedAt
      ) {
        return false;
      }
      return true;
    },
    shouldLeftButtonEnabled(): boolean {
      if (this.isUpdating) {
        return false;
      }
      if (this.selectedChildAttendance && this.selectedChildAttendance.leftAt) {
        return false;
      }
      return true;
    },
    getAttendedTime: function () {
      return function (childId?: number): string {
        if (childId) {
          const attendance = attendanceModule.todaysAttendances.find(
            (at) => at.child.childId == childId
          );
          if (attendance && attendance.attendedAt) {
            return dayjs(attendance.attendedAt).format("HH:mm");
          }
        }
        return "";
      };
    },
    getLeftTime: function () {
      return function (childId?: number): string {
        if (childId) {
          const attendance = attendanceModule.todaysAttendances.find(
            (at) => at.child.childId == childId
          );
          if (attendance && attendance.leftAt) {
            return dayjs(attendance.leftAt).format("HH:mm");
          }
        }
        return "";
      };
    },
  },

  methods: {
    async fetchKanaList() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await childrenModule.listKana({
          nurseryId: nurseryId,
        });
      }
      this.loading = false;
    },
    async listDailyAttendances() {
      await attendanceModule.listTodaysAttendances();
    },
    clickKana(value: string) {
      this.selectedKana = value;
    },
    clickChild(value: NurseryChildResponse) {
      this.selectedChild = value;
      this.dialog = true;
    },
    async attend(childId: string) {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && childId) {
        this.isUpdating = true;
        const response = await attendanceModule
          .createAttendance({
            childId: childId,
            nurseryId: nurseryId,
            attendedAt: this.getNow(),
          })
          .catch();
        if (response) {
          Swal.fire({
            title: "登園しました",
            icon: "success",
            toast: true,
            position: "top-end",
          });
          window.setTimeout(() => {
            this.dialog = false;
          }, 5000);
          await this.listDailyAttendances();
        }
        this.selectedKana = null;
        this.isUpdating = false;
      }
    },
    async left(childId: string) {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && childId) {
        this.isUpdating = true;
        const response = await attendanceModule
          .createAttendance({
            childId: childId,
            nurseryId: nurseryId,
            leftAt: this.getNow(),
          })
          .catch();
        if (response) {
          Swal.fire({
            title: "降園しました",
            icon: "success",
            toast: true,
            position: "top-end",
          });
          window.setTimeout(() => {
            this.dialog = false;
          }, 5000);
          await this.listDailyAttendances();
        }
        this.selectedKana = null;
        this.isUpdating = false;
      }
    },
    getNow(): string {
      return dayjs().format();
    },
    isAttended(childId: number): boolean {
      const result = attendanceModule.todaysAttendances.find((at) => {
        if (at.child.childId == childId && at.attendedAt != null) {
          return at;
        }
      });
      return result != null ? true : false;
    },
    isLeft(childId: number): boolean {
      const result = attendanceModule.todaysAttendances.find((at) => {
        if (at.child.childId == childId && at.leftAt != null) {
          return at;
        }
      });
      return result != null ? true : false;
    },
  },
});
