
import Vue from "vue";
import { multimediaCategoryType } from "@/constants/memoru";
import { memoruModule } from "@/store/dataModules/memoruModule";
import LocalDataService from "@/service/LocalDataService";
import { MultimediaAlbumListResponse } from "chaild-api/lib";
import ValidationMixin from "@/mixins/ValidationMixin";
import { snackbarModule } from "@/store/viewModules/snackbarModule";
import ApiMemoru from "@/api/ApiMemoru";
import ApiResource from "@/api/ApiResource";

export default Vue.extend({
  name: "MemoruUpload",

  mixins: [ValidationMixin],

  async mounted() {
    // TODO: paramsのalbumIdを見て初期値としてset
    await this.listAlbums();
  },

  data() {
    return {
      loading: false,
      isFormValid: false,
      multimediaCategoryType: multimediaCategoryType,
      input: {
        albumId: "",
        date: "",
        category: "photo" as "photo" | "movie",
      },
      files: [] as File[],
    };
  },

  computed: {
    albums: function (): MultimediaAlbumListResponse | null {
      return memoruModule.albums;
    },
  },

  methods: {
    async listAlbums() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await memoruModule.listAlbums({
          nurseryId,
          skip: 0,
          limit: 20000,
        });
      }
    },
    async clickUpload() {
      this.loading = true;
      for (const file of this.files) {
        // get presigned url
        const presignedResponse = await ApiMemoru.getPresignedUrl({
          ...this.input,
          filename: file.name,
        }).catch(() => (this.loading = false));

        if (presignedResponse) {
          const uploadResponse = await ApiResource.uploadFile({
            url: presignedResponse.preSignedUploadUrl,
            file: file,
          }).catch(() => (this.loading = false));
        }
      }
      snackbarModule.snackOn({
        message: "アップロードを開始しました",
      });
      this.loading = false;
      this.$router.push({ path: "/memoru/top" });
    },
    onDrop(values: any) {
      values.preventDefault();
      values.stopPropagation();
      const _files = values.dataTransfer.files;
      for (const file of _files) {
        this.files.push(file);
      }
    },
  },
});
