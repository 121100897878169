import ApiClient from '@/api/ApiClient';
import {
  MeResponse,
} from 'chaild-api/lib/index';
import {
  PostMeRequest,
} from '@/model/Request';

export default class ApiMe {

  public static async fetchMe(): Promise<MeResponse> {
    const response = await ApiClient.get(`/me`, null);
    return response;
  }

  public static async postMe(
    request: PostMeRequest,
  ): Promise<MeResponse> {
    const response = await ApiClient.post(`/me`, request);
    return response;
  }
  
  public static async postWithNoBody() {
    const response = await ApiClient.post('/me', null);
    return response;
  }

  public static async putMe(input: { defaultNurseryId: number }) {
    const response = await ApiClient.put(`/me`, input);
    return response;
  }
}