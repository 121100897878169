
import Vue from "vue";

// import PageTitle from '@/components/atoms/PageTitle.vue';
import ChildDevelopmentClassContainer from "@/containers/child-development/ChildDevelopmentClassContainer.vue";

export default Vue.extend({
  components: {
    ChildDevelopmentClassContainer,
  },
});
