import ApiClient from '@/api/ApiClient';

export default class ApiDiarySetting {

  public static async listSensorDevices({
    nurseryId,
    classId,
  }: {
    nurseryId: number;
    classId: number;
  }) {
    const response = await ApiClient.get(`/device/sensor`, { nurseryId, classId });
    return response as {
      sensors: PasoriDevice[];
      count: number;
    };
  }


  public static async listKeyholderDevices({
    nurseryId,
    classId,
  }: {
    nurseryId: number;
    classId: number;
  }) {
    const response = await ApiClient.get(`/device/keyholder`, { nurseryId, classId });
    return response as {
      keyholders: PasoriDevice[];
      count: number;
    };
  }

  public static async registerSensorDevice({
    sid,
    childId
  }: {
    sid: string;
    childId: number;
  }) {
    const response = await ApiClient.post(`/device/sensor/regist`, { sid, childId });
    return response;
  }

  public static async removeSensorDevice({
    sid,
    childId
  }: {
    sid: string;
    childId: number;
  }) {
    const response = await ApiClient.post(`/device/sensor/release`, { sid, childId });
    return response;
  }

  public static async registerKeyholderDevice({
    sid,
    childId
  }: {
    sid: string;
    childId: number;
  }) {
    const response = await ApiClient.post(`/device/keyholder/regist`, { sid, childId });
    return response;
  }

  public static async removeKeyholderDevice({
    sid,
    childId
  }: {
    sid: string;
    childId: number;
  }) {
    const response = await ApiClient.post(`/device/keyholder/release`, { sid, childId });
    return response;
  }
}

export interface PasoriDevice {
  sid: string;
  childId: number;
}