
import Vue from "vue";

import { childAccountCreateModule } from "@/store/dataModules/childAccountCreateModule";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import {
  NurseryClassResponse,
  NurseryHoursPatternResponse,
} from "chaild-api/lib";
import ValidationMixin from "@/mixins/ValidationMixin";
import { nurseryHoursModule } from "@/store/dataModules/nurseryHoursModule";

interface DataType {
  childcareType: string | null;
  firstName: string | null;
  firstNameKana: string | null;
  lastName: string | null;
  lastNameKana: string | null;
  gender: string | null;
  dateOfBirth: string | null;
  optionalChildCode1: string | null;
  optionalChildCode2: string | null;
  admissionDate: string | null;
  graduationDate: string | null;
  moveOrLeave: boolean | null;
  reason: string | null;
  notaBene: string | null;
  hoursPatternId: string | null;

  isCreating: boolean;
  isFormValid: boolean;
}

export default Vue.extend({
  mixins: [ValidationMixin],

  computed: {
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    classOptions(): any[] {
      return this.classes.map((c) => {
        return {
          classId: c.classId,
          className: `${c.year}年度 ${c.className}`,
        };
      });
    },
    childTypeOptions() {
      return [
        { value: "normal", label: "一般保育" },
        { value: "temporary", label: "一時保育" },
      ];
    },
    genderOptions() {
      return [
        { value: "male", label: "男性" },
        { value: "female", label: "女性" },
      ];
    },
    patternList: function (): NurseryHoursPatternResponse[] {
      return nurseryHoursModule.patternList;
    },
  },

  data: (): DataType => {
    return {
      childcareType: null,
      firstName: null,
      firstNameKana: null,
      lastName: null,
      lastNameKana: null,
      gender: null,
      dateOfBirth: null,
      optionalChildCode1: null,
      optionalChildCode2: null,
      admissionDate: null,
      graduationDate: null,
      moveOrLeave: null,
      reason: null,
      notaBene: null,
      hoursPatternId: null,

      isCreating: false,
      isFormValid: false,
    };
  },

  methods: {
    async create() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.isCreating = true;

        try {
          const created = await childAccountCreateModule
            .createChild({
              nurseryId,
              childcareType: this.childcareType || undefined,
              firstName: this.firstName || undefined,
              firstNameKana: this.firstNameKana || undefined,
              lastName: this.lastName || undefined,
              lastNameKana: this.lastNameKana || undefined,
              gender: this.gender || undefined,
              dateOfBirth: this.dateOfBirth || undefined,
              optionalChildCode1: this.optionalChildCode1 || undefined,
              optionalChildCode2: this.optionalChildCode2 || undefined,
              admissionDate: this.admissionDate || undefined,
              graduationDate: this.graduationDate || undefined,
              moveOrLeave: this.moveOrLeave || false,
              reason: this.reason || undefined,
              notaBene: this.notaBene || undefined,
              hoursPatternId: this.hoursPatternId || undefined,
              allergicFoodIds: [],
            })
            .catch((e) => {
              console.warn(e);
            });
          if (created) {
            this.$router.push(`/children`);
          }
        } catch {
          this.isCreating = false;
        }

        this.isCreating = false;
      }
    },
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await classModule.listClass({
        nurseryId: nurseryId,
      });
      await nurseryHoursModule.listPattern({
        nurseryId,
      });
    }
  },
});
