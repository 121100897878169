export const parentTypeItems = [
  { text: "母", value: "mother" },
  { text: "父", value: "father" },
  { text: "祖母", value: "grandma" },
  { text: "祖父", value: "grandpa" },
  { text: "叔母", value: "aunt" },
  { text: "叔父", value: "uncle" },
  { text: "姉妹", value: "sister" },
  { text: "兄弟", value: "brother" },
  { text: "その他", value: "other" },
];
