import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { SchoolDiaryResponse } from "chaild-api/lib";
// import ApiSchoolDairy from '@/api/ApiSchoolDairy';
import ApiNurseryReport from "@/api/ApiNurseryReport";
import LocalDataService from "@/service/LocalDataService";

export interface SchoolDairyState {
  diaries: SchoolDiaryResponse[];

  limit: number;
  page: number;
  totalItemsCount: number;
}

@Module({ dynamic: true, store, name: "school-diary", namespaced: true })
class SchoolDairyModule extends VuexModule implements SchoolDairyState {
  public diaries: SchoolDiaryResponse[] = [];

  public limit = 10;
  public page = 1;
  public totalItemsCount = 0;

  @Action
  public async listSchoolDiaries() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiNurseryReport.listNurseryReports({
        nurseryId: nurseryId,
        limit: this.limit,
        skip: (this.page - 1) * this.limit,
      });

      if (response) {
        this.setDiaries(response.diaries);
        this.setTotalItemsCount(response.totalItemsCount);
      }
      // limitきいてないせつ
    }
  }

  @Mutation
  public setDiaries(diaries: SchoolDiaryResponse[]) {
    this.diaries = diaries;
  }

  @Mutation
  public setLimit(value: number) {
    this.limit = value;
  }

  @Mutation
  public setPage(value: number) {
    this.page = value;
  }

  @Mutation
  public setTotalItemsCount(value: number) {
    this.totalItemsCount = value;
  }
}

export const schoolDairyModule = getModule(SchoolDairyModule);
