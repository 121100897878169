
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import LocalDataService from "@/service/LocalDataService";
import { staffModule } from "@/store/dataModules/staffModule";
import { staffEditModule } from "@/store/dataModules/staffEditModule";
import Swal from "sweetalert2";

export default Vue.extend({
  name: "StaffDetail",

  components: {
    PageTitle,
  },

  mounted() {
    this.getStaff();
  },

  data() {
    return {
      retireSelectValues: [
        { text: "", value: "" },
        { text: "異動", value: "transfer" },
        { text: "退職", value: "retire" },
        { text: "死亡", value: "die" },
      ],
    };
  },

  computed: {
    userNurseryId: function () {
      return this.$route.params.id;
    },
    staffDetail: function () {
      return staffModule.staffDetail;
    },
  },

  methods: {
    async getStaff() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await staffModule.getStaff({
          nurseryId: String(nurseryId),
          userNurseryId: this.userNurseryId,
        });
      }
    },
    async deleteStaff() {
      Swal.fire({
        title: "警告",
        text: "スタッフを削除しますか？",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "red",
        cancelButtonColor: "grey",
        confirmButtonText: "削除",
        cancelButtonText: "キャンセル",
      }).then((result) => {
        if (result.isConfirmed) {
          const nurseryId = LocalDataService.getNurseryId();
          if (nurseryId) {
            staffEditModule
              .deleteStaff({
                nurseryId: String(nurseryId),
                userNurseryId: this.userNurseryId,
              })
              .then((response) => {
                if (response) {
                  Swal.fire({ title: "削除しました", icon: "success" });
                  this.$router.push({ path: "/setting/staff" });
                } else {
                  Swal.fire({ title: "失敗しました", icon: "error" });
                }
              });
          }
        }
      });
    },
    editStaff() {
      this.$router.push({ path: `/setting/staff/${this.userNurseryId}/edit` });
    },
    getRetireReasonText(value: string): string {
      return this.retireSelectValues.find((r) => r.value == value)?.text || "";
    },
  },
});
