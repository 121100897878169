
import Vue from "vue";

import { attendanceEditModule } from "@/store/dataModules/attendanceEditModule";
import dayjs from "dayjs";
import ApiAttendance from "@/api/ApiAttendance";
import LocalDataService from "@/service/LocalDataService";

interface DataType {
  isSaving: boolean;
  loading: boolean;
}

export default Vue.extend({
  computed: {
    attendance() {
      return attendanceEditModule.attendance;
    },
    attendedAt: {
      get(): string | null {
        if (
          attendanceEditModule.attendance &&
          attendanceEditModule.attendance.attendedAt
        ) {
          return dayjs(attendanceEditModule.attendance.attendedAt).format(
            "HH:mm"
          );
        }
        return null;
      },
      set(value: string) {
        if (this.date && value) {
          const date = dayjs(this.date + value).toISOString();
          attendanceEditModule.setAttendedAtValue(date);
        }
      },
    },
    leftAt: {
      get(): string | null {
        if (
          attendanceEditModule.attendance &&
          attendanceEditModule.attendance.leftAt
        ) {
          return dayjs(attendanceEditModule.attendance.leftAt).format("HH:mm");
        }
        return null;
      },
      set(value: string) {
        if (this.date && value) {
          const date = dayjs(this.date + value).toISOString();
          attendanceEditModule.setLeftAtValue(date);
        }
      },
    },
    date() {
      return this.$route.params.date;
    },
    breadcrumbsItems: function () {
      return [
        { text: "出欠管理", href: "/attendance/list" },
        { text: "登園・降園編集", href: "", disabled: true },
      ];
    },
  },

  data: (): DataType => {
    return {
      isSaving: false,
      loading: false,
    };
  },

  methods: {
    async getAttendance() {
      this.loading = true;
      await attendanceEditModule.getAttendance();
      this.loading = false;
    },
    async save() {
      this.isSaving = true;
      if (attendanceEditModule.attendance) {
        if (attendanceEditModule.attendance.attendanceId == -1) {
          // create
          await attendanceEditModule.createAttendance();
        } else {
          // update
          await attendanceEditModule.updateAttendance().catch(() => {
            alert(
              "保存できませんでした。売上情報を確定している場合などは、編集できません。"
            );
          });
        }
      }
      this.isSaving = false;
    },
    async deleteAttendance() {
      const answer = confirm("登園・降園記録を削除しますか？");
      this.isSaving = true;
      if (answer) {
        const nurseryId = LocalDataService.getNurseryId();
        if (
          this.attendance?.attendanceId &&
          this.attendance.child.childId &&
          nurseryId
        ) {
          const response = await ApiAttendance.deleteAttendance({
            attendanceId: this.attendance.attendanceId,
            childId: this.attendance.child.childId,
            nurseryId,
          }).catch((e) => console.warn(e));
          if (response) {
            alert("削除しました");
            this.$router.push({ path: "/attendance/list" });
          }
        }
      }
      this.isSaving = false;
    },
  },

  mounted() {
    const date = this.$route.params.date;
    const childId = this.$route.params.childId;

    if (date && childId) {
      try {
        const parsed = parseInt(childId);
        attendanceEditModule.setChildId(parsed);
        attendanceEditModule.setDate(date);

        this.getAttendance();
      } catch {
        return;
      }
    }
  },
});
