import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiGrowthRecord from '@/api/ApiGrowthRecord';
import { NurseryGrowthDetailResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';
import CommonUtil from '@/utils/CommonUtil'

@Module({ dynamic: true, store, name: 'growth-records', namespaced: true })
class GrowthRecordsModule extends VuexModule {
  public growthRecords: NurseryGrowthDetailResponse[] = [];
  public year: string = dayjs().format('YYYY');

  get yearInt() {
    return parseInt(CommonUtil.getFiscalYear(`${this.year}`))
  }

  @Action
  public async listGrowthRecords(year?: number | string) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const listResponse = await ApiGrowthRecord.listGrowthRecords({ 
        nurseryId,
        year: year || this.yearInt,
      });
      if (listResponse) {
        this.setGrowthRecords(listResponse);
      }
    }
  }


  @Mutation
  public setGrowthRecords(records: NurseryGrowthDetailResponse[]) {
    this.growthRecords = records;
  }

  @Mutation
  public setYear(year: string) {
    this.year = year;
  }
}

export const growthRecordsModule = getModule(GrowthRecordsModule);