import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryNapResponse } from "chaild-api/lib";
import ApiNap from "@/api/ApiNap";
import LocalDataService from "@/service/LocalDataService";

export interface NapListState {
  naps: NurseryNapResponse[];

  from: string | null;
  to: string | null;
  classId: number | null;
  age: number | null;
}

@Module({ dynamic: true, store, name: "nap-list", namespaced: true })
class NapListModule extends VuexModule implements NapListState {
  public naps: NurseryNapResponse[] = [];

  public from: string | null = null;
  public to: string | null = null;
  public classId: number | null = null;
  public age: number | null = null;

  @Action
  public async listNaps() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const searchParams: {
        nurseryId: number;
        age?: number;
        classId?: number;
        from?: string;
        to?: string;
      } = { nurseryId };

      if (this.from) {
        searchParams.from = this.from;
      }
      if (this.to) {
        searchParams.to = this.to;
      }
      if (this.classId) {
        searchParams.classId = this.classId;
      }
      if (this.age) {
        searchParams.age = this.age;
      }

      const response = await ApiNap.listNaps(searchParams);

      if (response) {
        this.setNaps(response);
      }
    }
  }

  @Mutation
  public setNaps(naps: NurseryNapResponse[]) {
    this.naps = naps;
  }

  @Mutation
  public setFrom(from: string | null) {
    this.from = from;
  }

  @Mutation
  public setTo(to: string | null) {
    this.to = to;
  }

  @Mutation
  public setClassId(classId: number | null) {
    this.classId = classId;
  }

  @Mutation
  public setAge(age: number | null) {
    this.age = age;
  }
}

export const napListModule = getModule(NapListModule);
