
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
    }
  },
  computed: {
    paletteStyle() {
      return `background-color: ${this.value};`
    }
  },
  methods: {
    onColorInput(value: any) {
      if (value.hex) {
        this.$emit('input', value.hex as string)
      }
    }
  }
})
