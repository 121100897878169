/**
 * 指導計画
 */
import ApiClient from "./ApiClient";
import {
  GuidancePlanCategoryType,
  GuidancePlanItemCategoryType,
} from "chaild-api/lib";

export default class ApiGuidance {
  /**
   * Guidance Plan
   */
  static async listPlan(input: {
    nurseryId: number;
    limit?: number;
    skip?: number;
    type?: GuidancePlanCategoryType;
    year?: number | string;
    month?: number | string;
    week?: number | string;
  }) {
    return await ApiClient.get("/guidance-plan", input);
  }

  static async createPlan(input: {
    nurseryId: number;
    name: string;
    planFormId: number | string;
    parentPlanId: number | string;
    year: number;
    month?: number;
    week?: number;
    classId?: number | string;
  }) {
    return await ApiClient.post(
      `/guidance-plan?nurseryId=${input.nurseryId}`,
      input
    );
  }

  static async getPlan(input: { planId: number | string; nurseryId: number }) {
    return await ApiClient.get(`/guidance-plan/${input.planId}`, input);
  }

  static async updatePlan(input: {
    planId: number;
    nurseryId: number;
    itemList: {
      id: number;
      body?: string | null;
      childTextList?: {
        childId: number;
        body?: string | null;
      }[];
    }[];
  }) {
    return await ApiClient.put(
      `/guidance-plan/${input.planId}?nurseryId=${input.nurseryId}`,
      {
        itemList: input.itemList,
      }
    );
  }

  static async deletePlan(input: { nurseryId: number; planId: number }) {
    return await ApiClient.delete(
      `/guidance-plan/${input.planId}?nurseryId=${input.nurseryId}`,
      null
    );
  }

  static async updatePlanApproval(input: {
    planId: number;
    nurseryId: number;
    applicationId?: number;
    chiefId?: number | null;
    directorId?: number | null;
  }) {
    return await ApiClient.put(
      `/guidance-plan/${input.planId}/approval?nurseryId=${input.nurseryId}`,
      input
    );
  }

  static async exportPlan(input: { planId: string }) {
    return await ApiClient.post(`/guidance-plan/${input.planId}/pdf`, null);
  }

  /**
   * Guidance Plan Form
   */
  static async listPlanForm(input: {
    nurseryId: number;
    limit?: number;
    skip?: number;
    type?: GuidancePlanCategoryType;
  }) {
    return await ApiClient.get(`/guidance-plan-form`, input);
  }

  static async createPlanForm(input: {
    nurseryId: number;
    name: string;
    type: GuidancePlanCategoryType;
    // classIdList: number[];
    itemList: any[];
  }) {
    return await ApiClient.post(
      `/guidance-plan-form?nurseryId=${input.nurseryId}`,
      input
    );
  }

  static async updatePlanForm(input: {
    nurseryId: number;
    formId: number;
    name: string;
    type: GuidancePlanCategoryType;
    itemList: any[];
    // classIdList: number[];
  }) {
    return await ApiClient.put(
      `/guidance-plan-form/${input.formId}?nurseryId=${input.nurseryId}`,
      input
    );
  }

  static async deletePlanForm(input: { nurseryId: number; formId: number }) {
    return await ApiClient.delete(
      `/guidance-plan-form/${input.formId}?nurseryId=${input.nurseryId}`,
      null
    );
  }

  /**
   * Guidance Plan Form Item
   */
  static async listPlanFormItem(input: {
    nurseryId: number;
    limit?: number;
    skip?: number;
  }) {
    return await ApiClient.get(`/guidance-plan-form-item`, input);
  }

  static async createPlanFormItem(input: {
    nurseryId: number;
    name: string;
    type: GuidancePlanItemCategoryType;
    option?: {
      header: {
        cols: any[];
        rows: any[];
      };
    };
    classIdList?: number[];
  }) {
    return await ApiClient.post(
      `/guidance-plan-form-item?nurseryId=${input.nurseryId}`,
      input
    );
  }

  static async updatePlanFormItem(input: {
    itemId: number;
    nurseryId: number;
    name: string;
    type: GuidancePlanItemCategoryType;
    option?: {
      header: {
        cols: any[];
        rows: any[];
      };
    };
    classIdList?: number[];
  }) {
    return await ApiClient.put(
      `/guidance-plan-form-item/${input.itemId}?nurseryId=${input.nurseryId}`,
      input
    );
  }

  static async deletePlanFormItem(input: {
    itemId: number;
    nurseryId: number;
  }) {
    return await ApiClient.delete(
      `/guidance-plan-form-item/${input.itemId}?nurseryId=${input.nurseryId}`,
      null
    );
  }

  /**
   * Guidance Plan Form Mast
   */
  static async listPlanFormMast(input: {
    nurseryId: number;
    limit?: number;
    skip?: number;
    type?: GuidancePlanCategoryType;
  }) {
    return await ApiClient.get(`/guidance-plan-form-mast`, input);
  }

  static async duplicatePlanFormMast(input: {
    formMastId: string;
    nurseryId: number;
  }) {
    return await ApiClient.post(
      `/guidance-plan-form-mast/${input.formMastId}/duplicate?nurseryId=${input.nurseryId}`,
      null
    );
  }
}
