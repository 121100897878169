
import Vue from "vue";
import MemoruAlbum from "@/views/memoru/MemoruAlbum.vue";
import MemoruPurchaseHistory from "@/views/memoru/MemoruPurchaseHistory.vue";
import MemoruSales from "@/views/memoru/MemoruSales.vue";

export default Vue.extend({
  name: "MemoruTop",

  components: {
    MemoruAlbum,
    MemoruPurchaseHistory,
    MemoruSales,
  },

  data() {
    return {
      tab: "album",
    };
  },
});
