import ApiClient from "@/api/ApiClient";

export default class ApiAdmin {
  public static async updateNursery(input: {
    nurseryId: string;
    nurseryName: string;
    nurseryCode: string;
    localAuthorityCode: string;
    postalCode: string;
    prefecture: string;
    address: string;
    building: string;
    phoneNumber: string;
    faxNumber: string;
    representativeEmail: string;
    companyInfo: {
      companyName: string;
      postalCode: string;
      prefecture: string;
      address: string;
      building: string;
      phoneNumber: string;
      faxNumber: string;
      representativeFirstName: string;
      representativeLastName: string;
      representativeFirstNameKana: string;
      representativeLastNameKana: string;
    };
  }) {
    const response = await ApiClient.put(`/nursery/${input.nurseryId}`, {
      nurseryName: input.nurseryName || undefined,
      nurseryCode: input.nurseryCode || undefined,
      localAuthorityCode: input.localAuthorityCode || undefined,
      postalCode: input.postalCode || undefined,
      prefecture: input.prefecture || undefined,
      address: input.address || undefined,
      building: input.building || undefined,
      phoneNumber: input.phoneNumber || undefined,
      faxNumber: input.faxNumber || undefined,
      representativeEmail: input.representativeEmail || undefined,
      companyInfo:
        {
          companyName: input.companyInfo.companyName || undefined,
          postalCode: input.companyInfo.postalCode || undefined,
          prefecture: input.companyInfo.prefecture || undefined,
          address: input.companyInfo.address || undefined,
          building: input.companyInfo.building || undefined,
          phoneNumber: input.companyInfo.phoneNumber || undefined,
          faxNumber: input.companyInfo.faxNumber || undefined,
          representativeFirstName:
            input.companyInfo.representativeFirstName || undefined,
          representativeLastName:
            input.companyInfo.representativeLastName || undefined,
          representativeFirstNameKana:
            input.companyInfo.representativeFirstNameKana || undefined,
          representativeLastNameKana:
            input.companyInfo.representativeLastNameKana || undefined,
        } || undefined,
    });
    if (response) {
      return response;
    }
  }
}
