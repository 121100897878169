import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiMeal from '@/api/ApiMeal';
import { UniqueServiceRegistrationResponse, MealPlanResponse, MealCategoryResponse, MealAllergenResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';

// perUse: 'perUse', // 回数
// perUseMonth: 'useMonth', // 利用月額
// monthly: 'monthly' // 固定月額

@Module({ dynamic: true, store, name: 'food-menu-test', namespaced: true })
class FoodMenuTestModule extends VuexModule {
  public testingMeal: MealPlanResponse | null = null;

  @Mutation
  public setTestingMeal(meal: MealPlanResponse | null) {
    this.testingMeal = meal;
  }

  @Action
  public async createTest ({
    planId,
    flavor,
    texture,
    freshness,
    arrangement,
    color,
    quantity,
    hasNoForeignMatter,
    hasProperHeat,
    hasNoOdor,
    hasProperTaste,
    note,
    testedBy,
    testedAt,
  }: {
    planId: number;
    flavor: number;
    texture: number;
    freshness: number;
    arrangement: number;
    color: number;
    quantity: number;
    hasNoForeignMatter: boolean;
    hasProperHeat: boolean;
    hasNoOdor: boolean;
    hasProperTaste: boolean;
    note: string;
    testedBy: number;
    testedAt: string;
  }) {
    const response = await ApiMeal.createMealTest({
      planId,
      flavor,
      texture,
      freshness,
      arrangement,
      color,
      quantity,
      hasNoForeignMatter,
      hasProperHeat,
      hasNoOdor,
      hasProperTaste,
      note,
      testedBy,
      testedAt,
    });
    return response;
  }

  @Action
  public async updateTest ({
    testId,
    flavor,
    texture,
    freshness,
    arrangement,
    color,
    quantity,
    hasNoForeignMatter,
    hasProperHeat,
    hasNoOdor,
    hasProperTaste,
    note,
    testedBy,
    testedAt,
  }: {
    testId: number;
    flavor: number;
    texture: number;
    freshness: number;
    arrangement: number;
    color: number;
    quantity: number;
    hasNoForeignMatter: boolean;
    hasProperHeat: boolean;
    hasNoOdor: boolean;
    hasProperTaste: boolean;
    note: string;
    testedBy: number;
    testedAt: string;
  }) {
    const response = await ApiMeal.updateMealTest({
      testId,
      flavor,
      texture,
      freshness,
      arrangement,
      color,
      quantity,
      hasNoForeignMatter,
      hasProperHeat,
      hasNoOdor,
      hasProperTaste,
      note,
      testedBy,
      testedAt,
    });
    return response;
  }

  @Action
  public async deleteTest({ testId }: { testId: number }) {
    const response = await ApiMeal.deleteMealTest({ testId });
    return response;
  }
}

export const foodMenuTestModule = getModule(FoodMenuTestModule);