
import Vue from "vue";
import { PostMeRequest } from "@/model/Request";
import { meModule } from "@/store/dataModules/meModule";
import PageTitle from "@/components/atoms/PageTitle.vue";

interface DataType {
  postMeRequest: PostMeRequest;
}

export default Vue.extend({
  name: "SignUp",

  components: {
    PageTitle,
  },

  data: () => ({
    postMeRequest: {
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
    },
  }),
  methods: {
    async postMe() {
      const response = await meModule.postMe(this.postMeRequest);
      if (response) {
        this.$router.push({ path: "/attendance/list" });
      }
    },
  },
});
