
import Vue from 'vue';
import draggable from "vuedraggable";
import ColorPicker from '@/components/molecules/ColorPicker.vue';
import {
  workDescriptionModule
} from '@/store/viewModules/work-schedule/workDescriptionModule';
import { ShiftTimeTableRequest } from '@/api/ApiShift'
import { WorkDescriptionSettingResponse } from 'chaild-api/lib';

interface DataType {
  isFetching: boolean;
  isCreateDialogOpen: boolean;
  newWorkTitle: string;
  newWorkShorten: string;
  newWorkColor: string;
  newWorkIsChildcare: boolean;
  isCreatingNewWork: boolean;

  isUpdatingWork: boolean;
  editingWork: WorkDescriptionSettingResponse | null;

  isDeletingWork: boolean;
  deletingWork: WorkDescriptionSettingResponse | null;
}

export default Vue.extend({
  components: {
    ColorPicker,
    draggable,
  },
  data: (): DataType => {
    return {
      isFetching: false,
      isCreateDialogOpen: false,
      newWorkTitle: '',
      newWorkShorten: '',
      newWorkColor: '#029ec0',
      newWorkIsChildcare: false,
      isCreatingNewWork: false,

      isUpdatingWork: false,
      editingWork: null,

      isDeletingWork: false,
      deletingWork: null,
    };
  },
  computed: {
    isNewPatternValid() {
      return (
        this.newWorkTitle.length > 0
        && this.newWorkShorten.length > 0
      )
    },
    workDescriptions: {
      get(): WorkDescriptionSettingResponse[] {
        return workDescriptionModule.workDescriptions
      },
      async set(value: WorkDescriptionSettingResponse[]) {
        if(value) {
          const WorkDescriptionsIds = value.map((item) => item)
          workDescriptionModule.setWorkDescriptions(WorkDescriptionsIds)
          
          this.isFetching = true
          await workDescriptionModule.sortWorkDescriptions()
          this.isFetching = false

          this.isFetching = true
          await workDescriptionModule.listWorkDescriptions()
          this.isFetching = false
        }
      }
    },
    isEditDialogOpen: {
      get() {
        return this.editingWork !== null
      },
      set(value: boolean) {
        if (!value) {
          this.editingWork = null
        }
      }
    },
    isDeleteDialogOpen: {
      get() {
        return this.deletingWork !== null
      },
      set(value: boolean) {
        if (!value) {
          this.deletingWork = null
        }
      }
    }
  },
  methods: {
    async createWorkDescription() {
      if (this.isNewPatternValid) {
        this.isCreatingNewWork = true
        await workDescriptionModule.createWorkDescription({
          name: this.newWorkTitle,
          abbreviation: this.newWorkShorten,
          color: this.newWorkColor,
          isChildcare: this.newWorkIsChildcare
        })
        this.isCreatingNewWork = false

        this.isFetching = true
          await workDescriptionModule.sortWorkDescriptions()
          await workDescriptionModule.listWorkDescriptions()
          this.isFetching = false

        this.isCreateDialogOpen = false
      }
    },
    async updateWork() {
      if (this.editingWork) {
        this.isUpdatingWork = true
        await workDescriptionModule.updateWorkDescription({
          workDescriptionId: this.editingWork.workDescriptionId,
          name: this.editingWork.name,
          abbreviation: this.editingWork.abbreviation,
          color: this.editingWork.color,
          isChildcare: this.editingWork.isChildcare
        })
        this.isUpdatingWork = false
        this.editingWork = null
      }
    },
    async deleteWork() {
      if (this.deletingWork) {
        this.isDeletingWork = true
        await workDescriptionModule.deleteWorkDescription(this.deletingWork.workDescriptionId)
        this.isDeletingWork = false
        this.isFetching = true
          await workDescriptionModule.sortWorkDescriptions()
          this.isFetching = false

          this.isFetching = true
          await workDescriptionModule.listWorkDescriptions()
          this.isFetching = false
        this.deletingWork = null
      }
    }
  },
  async mounted() {
    this.isFetching = true
    await workDescriptionModule.listWorkDescriptions()
    this.isFetching = false
    
  }
})
