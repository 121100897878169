import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiStaff from "@/api/ApiStaff";
import { NurseryChildResponse, NurseryClassResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import ApiChildDevelopment from "@/api/ApiChildDevelopment";
import ApiChildren from "@/api/ApiChildren";
import ApiClass from "@/api/ApiClass";

export interface ChildDevelopmentClassState {
  children: NurseryChildResponse[];
  nurseryClass: NurseryClassResponse | null;
}

@Module({
  dynamic: true,
  store,
  name: "child-development-class",
  namespaced: true,
})
class ChildDevelopmentClassModule
  extends VuexModule
  implements ChildDevelopmentClassState
{
  children: NurseryChildResponse[] = [];
  nurseryClass: NurseryClassResponse | null = null;

  @Action
  async listChildren({ classId }: { classId: number }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildren.listChildren({
        nurseryId,
        classId,
      });
      if (response) {
        this.setChildren(response);
      }
    }
  }

  @Mutation
  setChildren(children: NurseryChildResponse[]) {
    this.children = children;
  }

  @Action
  async getClass({ classId }: { classId: number }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiClass.getClass({
        nurseryId,
        classId: String(classId),
      });
      if (response) {
        this.setClass(response);
      }
    }
  }

  @Mutation
  setClass(nurseryClass: NurseryClassResponse | null) {
    this.nurseryClass = nurseryClass;
  }
}

export const childDevelopmentClassModule = getModule(
  ChildDevelopmentClassModule
);
