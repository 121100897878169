
import Vue from 'vue';
import dayjs from 'dayjs';
import { NurseryUserClassResponse, NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';
import { staffEditModule } from '@/store/dataModules/staffEditModule';

export default Vue.extend({
  name: 'StaffTableContaier',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        { text: '氏名', value: 'name', },
        { text: '氏名（カナ）', value: 'name-kana', },
        { text: '性別', value: 'gender', },
        { text: '配属（予定）日', value: 'assignDate', },
        { text: '退職（予定）日', value: 'retireDate', },
        { text: '担任クラス', value: 'classIdsInCharge', },
        { text: '編集', value: 'edit', },
      ],
    }
  },

  methods: {
    formatDate(date: string) {
      return dayjs(date).format('YYYY/MM/DD HH:mm')
    },
    edit(value: NurseryUserResponse) {
      this.$emit('click', value);
    },
    getClassNames(classes: NurseryUserClassResponse[]): string {
      if (classes && classes.length > 0) {
        const names = classes.map((c) => {
          if (c.className) {
            return c.className;
          }
        })
        return names.join();
      } else {
        return '';
      }
    },
    clickClassStaff(item: NurseryUserResponse) {
      staffEditModule.setUserId(String(item.userId));
      // this.getStaff();
      // this.classStaffDialog = true;
    },
    // async getStaff() {
    //   const nurseryId = LocalDataService.getNurseryId();
    //   if (nurseryId) {
    //     await staffModule.getStaff({
    //       nurseryId: nurseryId,
    //       userId: staffEditModule.userId,
    //     });
    //   }
    // },
  }
})
