import ApiClient2 from "./ApiClient2";

export default class ApiMemoru {
  /**
   * Album
   */
  static async listAlbums(input: {
    nurseryId: number;
    isPublished?: boolean;
    limit?: number;
    skip?: number;
    nurseryClassIds?: number[];
    purchaseCountMin?: string;
    year?: string;
  }) {
    return await ApiClient2.get("/album", input, "memoru");
  }

  static async getAlbum(input: { albumId: string }) {
    return await ApiClient2.get(`/album/${input.albumId}`, null, "memoru");
  }

  static async createAlbum(input: {
    nurseryId: number;
    year: string;
    title: string;
    nurseryClassIds: number[];
    comment: string;
    availableAfter: string; //ISO8601
    availableBefore?: string; //ISO8601
  }) {
    return await ApiClient2.post("/album", input, "memoru");
  }

  static async updateAlbum(input: {
    albumId: number | string;
    nurseryId: number;
    year: number | string;
    title: string;
    nurseryClassIds: number[];
    comment: string;
    availableAfter: string; //ISO8601
    availableBefore?: string; //ISO8601
  }) {
    return await ApiClient2.put(`/album/${input.albumId}`, input, "memoru");
  }

  static async updateAlbums(input: {
    albumIds: number[];
    availableBefore?: string | null;
  }) {
    return await ApiClient2.put(`/album`, input, "memoru");
  }

  static async getPresignedUrl(input: {
    albumId: string;
    date: string;
    category: "photo" | "movie";
    filename: string; // 拡張子有り
  }) {
    return await ApiClient2.post(
      `/album/${input.albumId}/multimedia`,
      input,
      "memoru"
    );
  }

  /**
   * Content
   */
  static async listContents(input: {
    albumId: string;
    category?: "photo" | "movie" | string;
    dateFrom?: string;
    dateTo?: string;
    purchasedCountMin?: string;
    limit?: number;
    skip?: number;
  }) {
    return await ApiClient2.get(
      `/album/${input.albumId}/multimedia`,
      input,
      "memoru"
    );
  }

  static async getContent(input: { multimediaId: number }) {
    return await ApiClient2.get(
      `/multimedia/${input.multimediaId}`,
      null,
      "memoru"
    );
  }

  static async deleteContent(input: { multimediaId: number }) {
    return await ApiClient2.delete(
      `/multimedia/${input.multimediaId}`,
      null,
      "memoru"
    );
  }

  /**
   * PurchaseHistory
   */
  static async searchHistory(input: {
    nurseryId: number;
    classId?: string;
    from?: string; // YYYY-MM-DD
    to?: string; // YYYY-MM-DD
    mediaFormat?: "data" | "print" | string;
    serialNumber?: string;
    limit?: number;
    skip?: number;
  }) {
    return await ApiClient2.get("/purchase-history", input, "memoru");
  }

  /**
   * Sales
   */
  static async listSales(input: {
    monthFrom: string;
    monthTo: string;
    nurseryId: number;
  }) {
    return await ApiClient2.get("/sales", input, "memoru");
  }
}
