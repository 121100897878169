import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { SchoolDiaryResponse } from "chaild-api/lib";
import ApiNurseryReport from "@/api/ApiNurseryReport";

export interface NurseryReportState {
  report: SchoolDiaryResponse | null;
}

@Module({ dynamic: true, store, name: "nursery-report", namespaced: true })
class NurseryReportModule extends VuexModule implements NurseryReportState {
  public report: SchoolDiaryResponse | null = null;

  @Action({ rawError: true })
  public async getNurseryReport(input: { nurseryId: number; date?: string }) {
    const response = await ApiNurseryReport.getNurseryReport(input);
    this.setReport(response);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateNurseryReportApproval(input: {
    nurseryId: number;
    schoolDiaryId: string;
    approvalStatus: "approved" | "rejected";
  }) {
    // const response = await ApiNurseryReport.updateNurseryReportApproval(input);
    // if (response) {
    //   return response;
    // }
  }

  @Mutation
  public setReport(value: SchoolDiaryResponse) {
    this.report = value;
  }
}

export const nurseryReportModule = getModule(NurseryReportModule);
