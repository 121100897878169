import ApiClient from '@/api/ApiClient';
import { NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';

export default class ApiNurseryUser {
  // 園の日誌
  public static async listStaffs(input: {
    nurseryId: number;
  }) {
    const response = await ApiClient.get(`/nursery/${input.nurseryId}/user`, null);
    if (response && response.users) {
      return response.users as NurseryUserResponse[];
    }
    return [];
  }
}