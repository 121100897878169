import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiChildren from "@/api/ApiChildren";
import {
  ChildInfoData,
  ChildInfoResponse,
  ChildResponse,
} from "chaild-api/lib";

export interface ChildDocumentState {
  childId: number | null;
  doc: ChildInfoResponse | null;
  child: ChildResponse | null;

  dataToUpdate: ChildInfoData[];
}

@Module({ dynamic: true, store, name: "children-document", namespaced: true })
class ChildDocumentModule extends VuexModule implements ChildDocumentState {
  public doc: ChildInfoResponse | null = null;
  public child: ChildResponse | null = null;
  public childId: number | null = null;
  public dataToUpdate: ChildInfoData[] = [];

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Action({ rawError: true })
  public async getChildInfo() {
    if (this.childId) {
      const response = await ApiChildren.getChildInfo(this.childId);
      if (response) {
        this.setDoc(response);
      }
    }
  }

  @Action({ rawError: true })
  public async getChild() {
    if (this.childId) {
      const response = await ApiChildren.getChild(this.childId);
      if (response) {
        this.setChild(response);
      }
    }
  }

  @Action({ rawError: true })
  public async saveUpdates() {
    if (this.childId && this.dataToUpdate.length > 0) {
      const response = await ApiChildren.updateChildInfo(
        this.childId,
        this.dataToUpdate
      );
      if (response) {
        this.setDoc(response);
      }
    }
  }

  @Mutation
  public setDoc(doc: ChildInfoResponse) {
    this.doc = doc;
  }

  @Mutation
  public setChild(child: ChildResponse) {
    this.child = child;
  }

  @Mutation
  public updateBasicInfo(value: ChildInfoData) {
    if (this.doc) {
      this.doc.basicInfo = this.doc.basicInfo.map((info) => {
        if (info.key == value.key) {
          return value;
        }
        return info;
      });
    }
  }

  @Mutation
  public updateLifestyleInfo(value: ChildInfoData) {
    if (this.doc) {
      this.doc.lifestyleInfo = this.doc.lifestyleInfo.map((info) => {
        if (info.key == value.key) {
          return value;
        }
        return info;
      });
    }
  }

  @Mutation
  public updateHealthInfo(value: ChildInfoData) {
    if (this.doc) {
      this.doc.healthInfo = this.doc.healthInfo.map((info) => {
        if (info.key == value.key) {
          return value;
        }
        return info;
      });
    }
  }

  @Mutation
  updateDataToUpdate(value: ChildInfoData) {
    let shouldPush = true;
    this.dataToUpdate = this.dataToUpdate.map((d) => {
      if (d.key === value.key) {
        shouldPush = false;
        return value;
      }
      return d;
    });

    if (shouldPush) {
      this.dataToUpdate.push(value);
    }
  }

  @Mutation
  clearLocalData() {
    this.dataToUpdate = [];
    this.child = null;
    this.childId = null;
    this.doc = null;
  }
}

export const childDocumentModule = getModule(ChildDocumentModule);
