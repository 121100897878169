
import Vue from 'vue';

import dayjs from 'dayjs';
import { foodMenusModule } from '@/store/viewModules/food-menu/foodMenusModule';
import { MealPlanResponse, NurseryClassResponse } from 'chaild-api/lib';

import PageTitle from '@/components/atoms/PageTitle.vue';
import MonthPager from '@/components/atoms/MonthPager.vue';
import FoodMenuImportContainer from '@/containers/food-menu/FoodMenuImportContainer.vue';
import FoodMenuCreateContainer from '@/containers/food-menu/FoodMenuCreateContainer.vue';
import FoodMenuTestContainer from '@/containers/food-menu/FoodMenuTestContainer.vue';
import FoodMenuEditContainer from '@/containers/food-menu/FoodMenuEditContainer.vue';
import { foodMenuTestModule } from '@/store/viewModules/food-menu/foodMenuTestModule';

interface DateWithMenus {
  date: string;
  formatted: string;
  menus: MealPlanResponse[];
}

interface DataType {
  yearMonth: string;

  isFetching: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
    MonthPager,
    FoodMenuCreateContainer,
    FoodMenuTestContainer,
    FoodMenuEditContainer,
    FoodMenuImportContainer,
  },

  data: (): DataType => ({
    yearMonth: dayjs().format('YYYY-MM'),
    
    isFetching: false,
  }),

  computed: {
    dates() {
      const date = dayjs(this.yearMonth).startOf('months');
      const daysInMonth = date.daysInMonth();
      return [...Array(daysInMonth)].map((emp, index) => {
        return date.add(index, 'day');
      });
    },
    testItems() {
      return [
        {
          category: '給食（離乳食）',
          menu: 'ご飯, 味噌汁, ひじき, オレンジ',
          classes: 'ひつじ組, きりん組, うさぎ組, ぞう組',
        }
      ]
    },
    importStatuses() {
      return foodMenusModule.importStatuses;
    },
    menus() {
      return foodMenusModule.menus;
    },
    datesWithMenus(): DateWithMenus[] {
      return this.dates.map((date) => {
        const thisdayMenus = this.menus.filter((menu) => menu.date === date.format('YYYY-MM-DD'));
        return {
          date: date.format('YYYY-MM-DD'),
          formatted: date.format('MM/DD (ddd)'),
          menus: thisdayMenus,
        };
      });
    },
    menuCategories() {
      return foodMenusModule.menuCategories;
    },
    allergens() {
      return foodMenusModule.allergens;
    },
    todayLabel() {
      return dayjs().format('MM/DD (ddd)')
    },
    todayCode() {
      return dayjs().format('YYYY-MM-DD')
    },
    todaysMenu(): DateWithMenus | null {
      const menu = this.datesWithMenus.find((dwm) => dwm.date === dayjs().format('YYYY-MM-DD'));
      return menu || null;
    }
  },

  methods: {
    async listMenus() {
      this.isFetching = true;
      await foodMenusModule.listMenus({
        yearMonth: this.yearMonth
      });
      this.isFetching = false;
    },
    async listImportStatus() {
      const day = dayjs(this.yearMonth);
      let year = day.year();
      const month = day.month() + 1;

      if (month < 4) {
        year = year - 1;
      }

      foodMenusModule.listImportStatus({
        year,
        month,
      })
    },
    listMenuCategories() {
      foodMenusModule.listMealCategories();
    },
    listAllergens() {
      foodMenusModule.listAllergens();
    },
    setCreating(date: string) {
      foodMenusModule.setCreatingDate(dayjs(date).format('YYYY-MM-DD'));
    },
    formatClassNames(classes: NurseryClassResponse[]) {
      return classes.map((c) => c.className).join(',');
    },
    openTestDialog(menu: MealPlanResponse) {
      foodMenuTestModule.setTestingMeal(menu);
    },
    openEditDialog(menu: MealPlanResponse) {
      foodMenusModule.setEditingMenu(menu);
    },
    openImportDialog() {
      foodMenusModule.setImportYearMonth(this.yearMonth);
      foodMenusModule.setIsImportDataDialogOpen(true);
    },
    async deleteMenu(menu: MealPlanResponse) {
      if (window.confirm('献立を削除しますか？')) {
        this.isFetching = true;
        await foodMenusModule.deleteMenu(menu.planId);
        await foodMenusModule.listMenus({ yearMonth: this.yearMonth });
        this.isFetching = false;
      }
    }
  },

  watch: {
    yearMonth: {
      handler: function() {
        this.listMenus();
        this.listImportStatus();
      },
    }
  },

  mounted() {
    this.listMenus();
    this.listImportStatus();
    this.listMenuCategories();
    this.listAllergens();
  }
});
