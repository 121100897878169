
import Vue from "vue";
import { ParentInfo } from "@/api/ApiParent";
import { parentDocumentModule } from "@/store/dataModules/parentDocumentModule";
import { ParentInfoData, ParentResponse } from "chaild-api/lib";

import ChildInfoPage from "@/components/organisms/ChildInfoPage.vue";

interface DataType {
  isSaving: boolean;
  loading: boolean;
}

export default Vue.extend({
  components: {
    ChildInfoPage,
  },

  computed: {
    doc() {
      return parentDocumentModule.doc;
    },
    parentInfo(): ParentInfo[] {
      if (this.doc) {
        return this.doc;
      }
      return [];
    },
    child() {
      return parentDocumentModule.child;
    },
  },

  data: (): DataType => {
    return {
      isSaving: false,
      loading: false,
    };
  },

  methods: {
    updateInfo(value: ParentInfoData, p: ParentInfo) {
      parentDocumentModule.updateInfoValue({
        parentId: p.parent.parentId,
        value: value,
      });
      parentDocumentModule.updateDataToUpdate({
        parentId: p.parent.parentId,
        value: value,
      });
    },
    async save() {
      this.isSaving = true;
      await parentDocumentModule.saveUpdates();
      this.isSaving = false;
    },
  },

  async mounted() {
    this.loading = true;
    const childId = this.$route.params.childId as string;
    if (childId) {
      try {
        const parsed = parseInt(childId);
        parentDocumentModule.clearLocalData();
        parentDocumentModule.setChildId(parsed);
        await parentDocumentModule.getParentInfo();
        await parentDocumentModule.getChild();
      } catch {
        return;
      }
    }

    this.loading = false;
  },
});
