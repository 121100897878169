import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import { NurseryChildResponse } from 'chaild-api/lib';
import ApiChildren from '@/api/ApiChildren';
import ApiDevice, { PasoriDevice } from '@/api/ApiDevice';
import LocalDataService from '@/service/LocalDataService';

@Module({ dynamic: true, store, name: 'pasori-vevo', namespaced: true })
class PasoriVevoModule extends VuexModule {
  public children: NurseryChildResponse[] = [];
  public keyholders: PasoriDevice[] = [];

  @Action
  public async listChildren({
    classId,
  }: {
    classId: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId()
    if (nurseryId) {
      const response = await ApiChildren.listChildren({
        nurseryId,
        classId
      });
      this.setChildren(response);
    }
  }

  @Mutation
  public setChildren(children: NurseryChildResponse[]) {
    this.children = children;
  }

  @Action
  public async listKeyholders({
    classId,
  }: {
    classId: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId()
    if (nurseryId) {
      const response = await ApiDevice.listKeyholderDevices({
        nurseryId,
        classId,
      });
      if (response) {
        this.setKeyholders(response.keyholders)
      }
    }
  }

  @Mutation
  public setKeyholders(keyholders: PasoriDevice[]) {
    this.keyholders = keyholders;
  }

  @Action
  public async registerKeyholder({
    sid,
    childId,
    classId
  }: {
    sid: string;
    childId: number;
    classId: number;
  }){
    const response = await ApiDevice.registerKeyholderDevice({
      sid,
      childId,
    });
    this.listKeyholders({ classId })
  }

  @Action
  public async removeKeyholder({
    sid,
    childId,
    classId
  }: {
    sid: string;
    childId: number;
    classId: number;
  }){
    const response = await ApiDevice.removeKeyholderDevice({
      sid,
      childId,
    });
    this.listKeyholders({ classId })
  }
}

export const pasoriVevoModule = getModule(PasoriVevoModule);
