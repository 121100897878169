
import Vue from 'vue';

import PageTitle from '@/components/atoms/PageTitle.vue';
import ChildGrowthRecordRow from '@/components/organisms/growth-record/ChildGrowthRecordRow.vue';
import dayjs from 'dayjs';
import { GChart } from 'vue-google-charts'


import { childGrowthRecordsModule, GrowthRepuqestWithMonth } from '@/store/viewModules/growth-record/childGrowthRecordsModule';
import { NurseryGrowthResponse, NurseryGrowthDetailResponse } from 'chaild-api/lib';

interface RecordsWithMonth {
  month: number;
  record: NurseryGrowthResponse | null;
}

interface DataType {
  isSaving: boolean;
  colorPrimary: string;
  colorChartGray: string;
  colorChartArea: string;
}

export default Vue.extend({
  components: {
    PageTitle,
    ChildGrowthRecordRow,
    GChart,
  },

  data: (): DataType => ({
    isSaving: false,
    colorPrimary: '#029ec0',
    colorChartGray: '#ccc',
    colorChartArea: '#67c4d9'
  }),

  computed: {
    year: {
      get() {
        return childGrowthRecordsModule.yearInt;
      },
      set(value: number) {
        if (value > 2000 && value < 2100) {
          childGrowthRecordsModule.setYear(`${value}`)
          this.fetchGrowthRecord()
        }
      }
    },
    growthRecord() {
      return childGrowthRecordsModule.growthRecord;
    },
    recordsWithMonth(): RecordsWithMonth[] {
      const numbers = [...Array(12)].map((emp, i) => (i + 4) % 12);
      return numbers.map((m) => {
        const month = m === 0 ? 12 : m;
        if (this.growthRecord) {
          const find = this.growthRecord.records.find((r) => r.month === month);
          return {
            month,
            record: find || null,
          }
        }
        return {
          month,
          record: null,
        }
      });
    },
    childName(): string {
      if (this.growthRecord) {
        return `${this.growthRecord.child.lastName} ${this.growthRecord.child.firstName}`;
      }
      return '読み込み中...';
    },
    recordRequests() {
      return childGrowthRecordsModule.childGrowthRecordRequests;
    },
    heightChartData() {
      if (!this.growthRecord) {
        return [];
      }

      const growthRecord = {...this.growthRecord};
      const numbers = [...Array(12)].map((emp, i) => (i + 4) % 12);
      // const chartData: Array<Array<string | number | null>> = [['月', 'パーセンタイル値 3%', 'パーセンタイル値 10%', 'パーセンタイル値 25%', 'パーセンタイル値 50%', 'パーセンタイル値 75%', 'パーセンタイル値 90%', 'パーセンタイル値 97%', '身長']];
      const chartData: Array<Array<string | number | null>> = [['月', 'パーセンタイル値 3%', 'パーセンタイル値 97%', '身長']];
      
      numbers.map((m) => {
        const month = m === 0 ? 12 : m;
        const col: Array<string | number | null> = [`${month}月`]
        const find = growthRecord.records.find((r) => r.month === month);
        if (find) {
          const year = month < 4 ? (this.year) + 1 : (this.year);
          const percenTiles = this.getHeightPercentile(growthRecord, year, month);
          if (percenTiles['3'] && percenTiles['97']) {
            col.push(percenTiles['3'])
            col.push(percenTiles['97'] - percenTiles['3'])
          } else {
            col.push(null)
            col.push(null)
          }

          const value = find ? find.height : null;
          col.push(value);
          chartData.push(col);
        } else {
          col.push(null)
          col.push(null)
          col.push(null)
          chartData.push(col);
        }
      });

      return chartData;
    },
    weightChartData() {
      if (!this.growthRecord) {
        return [];
      }

      const growthRecord = {...this.growthRecord};
      const numbers = [...Array(12)].map((emp, i) => (i + 4) % 12);
      const chartData: Array<Array<string | number | null>> = [['月', 'パーセンタイル値 3%', 'パーセンタイル値 97%', '体重']];
      
      numbers.map((m) => {
        const month = m === 0 ? 12 : m;
        const col: Array<string | number | null> = [`${month}月`]
        const find = growthRecord.records.find((r) => r.month === month);
        if (find) {
          const year = month < 4 ? (this.year) + 1 : (this.year);
          const percenTiles = this.getWeightPercentile(growthRecord, year, month);
          if (percenTiles['3'] && percenTiles['97']) {
            col.push(percenTiles['3'])
            col.push(percenTiles['97'] - percenTiles['3'])
          } else {
            col.push(null)
            col.push(null)
          }

          const value = find ? find.weight : null;
          col.push(value);
          chartData.push(col);
        } else {
          col.push(null)
          col.push(null)
          col.push(null)
          chartData.push(col);
        }
      });

      return chartData;
    }
  },

  methods: {
    async fetchGrowthRecord() {
      this.isSaving = true
      await childGrowthRecordsModule.getGrowthRecord()
      this.isSaving = false
    },
    inputHeight(value: number, record: RecordsWithMonth) {
      if (this.growthRecord) {
        const find = this.recordRequests.find((r) => r.month === record.month);
        const newRecord: GrowthRepuqestWithMonth = find ? {...find} : { 
          month: record.month,
          request: {
            childId: this.growthRecord.child.childId,
          }
        };
        newRecord.request.height = value;
        childGrowthRecordsModule.updateChildGrowthRecordRequests(newRecord);
      }
    },
    inputWeight(value: number, record: RecordsWithMonth) {
      if (this.growthRecord) {
        const find = this.recordRequests.find((r) => r.month === record.month);
        const newRecord: GrowthRepuqestWithMonth = find ? {...find} : { 
          month: record.month,
          request: {
            childId: this.growthRecord.child.childId,
          }
        };
        newRecord.request.weight = value;
        childGrowthRecordsModule.updateChildGrowthRecordRequests(newRecord);
      }
    },
    inputRemarks(value: string, record: RecordsWithMonth) {
      if (this.growthRecord) {
        const find = this.recordRequests.find((r) => r.month === record.month);
        const newRecord: GrowthRepuqestWithMonth = find ? {...find} : { 
          month: record.month,
          request: {
            childId: this.growthRecord.child.childId,
          }
        };
        newRecord.request.remarks = value;
        childGrowthRecordsModule.updateChildGrowthRecordRequests(newRecord);
      }
    },
    async saveRecords() {
      this.isSaving = true;
      await childGrowthRecordsModule.saveRecords();
      await childGrowthRecordsModule.getGrowthRecord();
      this.isSaving = false;
    },
    getHeightPercentile(record: NurseryGrowthDetailResponse | null, year, month): {
        3: null | number;
        10: null | number;
        25: null | number;
        50: null | number;
        75: null | number;
        90: null | number;
        97: null | number;
      } {
      if (record) {
        const birthDay = dayjs(record.child.dateOfBirth);
        const months = dayjs(`${year}-${month}`).diff(birthDay, 'month');
        const gender = record.child.gender;
        const percentileValues = childGrowthRecordsModule.percentileMaster.filter((pm) => {
          if (
            pm.sex === gender
            && pm.month === months
            && pm.valueType === 'height'
          ) {
            return true;
          }
          return false;
        })
        if (percentileValues.length === 7) {
          percentileValues.sort((pv1, pv2) => {
            return pv1.percentile - pv2.percentile
          })
          return {
            3: percentileValues[0].value,
            10: percentileValues[1].value,
            25: percentileValues[2].value,
            50: percentileValues[3].value,
            75: percentileValues[4].value,
            90: percentileValues[5].value,
            97: percentileValues[6].value,
          }
        }
      }
      return {
        3: null,
        10: null,
        25: null,
        50: null,
        75: null,
        90: null,
        97: null,
      }
    },
    getWeightPercentile(record: NurseryGrowthDetailResponse | null, year, month): {
        3: null | number;
        10: null | number;
        25: null | number;
        50: null | number;
        75: null | number;
        90: null | number;
        97: null | number;
      } {
      if (record) {
        const birthDay = dayjs(record.child.dateOfBirth);
        const months = dayjs(`${year}-${month}`).diff(birthDay, 'month');
        const gender = record.child.gender;
        const percentileValues = childGrowthRecordsModule.percentileMaster.filter((pm) => {
          if (
            pm.sex === gender
            && pm.month === months
            && pm.valueType === 'weight'
          ) {
            return true;
          }
          return false;
        })
        if (percentileValues.length === 7) {
          percentileValues.sort((pv1, pv2) => {
            return pv1.percentile - pv2.percentile
          })
          return {
            3: percentileValues[0].value,
            10: percentileValues[1].value,
            25: percentileValues[2].value,
            50: percentileValues[3].value,
            75: percentileValues[4].value,
            90: percentileValues[5].value,
            97: percentileValues[6].value,
          }
        }
      }
      return {
        3: null,
        10: null,
        25: null,
        50: null,
        75: null,
        90: null,
        97: null,
      }
    },
  },

  mounted() {
    const childId = this.$route.params.childId;
    if (childId) {
      try {
        const childIdInt = parseInt(childId, 10);
        childGrowthRecordsModule.initYear();
        childGrowthRecordsModule.setChildId(childIdInt);
        childGrowthRecordsModule.getGrowthRecord();
        childGrowthRecordsModule.getPercentile();
      } catch {
        return;
      }
    }
  }
});
