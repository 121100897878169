import ApiClient from '@/api/ApiClient';

export default class ApiImportExportStatus {

  public static async getStatus(input: {
    statusId: string;
    category?: string;
  }) {
    const response = await ApiClient.post(`/i-e-status/${input.statusId}`, {
      category: input.category,
    });
    return response;
  }

  public static async getMultipleStatus(input: {
    integrationId: string;
  }) {
    return await ApiClient.post(`/i-e-status/integration/${input.integrationId}`, null);
  }
}