import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { SchoolDiaryResponse } from "chaild-api/lib";
import ApiNurseryReport from "@/api/ApiNurseryReport";
import LocalDataService from "@/service/LocalDataService";

export interface SchoolDairyEditState {
  diary: SchoolDiaryResponse | null;
  date: string | null;

  appliedBy: number | null;
}

@Module({ dynamic: true, store, name: "school-diary-edit", namespaced: true })
class SchoolDairyEditModule extends VuexModule implements SchoolDairyEditState {
  public diary: SchoolDiaryResponse | null = null;
  public date: string | null = null;
  public appliedBy: number | null = null;

  @Action
  public async getSchoolDiary() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.date) {
      const response = await ApiNurseryReport.getNurseryReport({
        nurseryId,
        date: this.date,
      });

      this.setDiary(response);
      return response;
    }
  }

  @Action
  public async saveSchoolDiary(status: "draft" | "applied") {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.diary) {
      const response = await ApiNurseryReport.updateSchoolDiary({
        nurseryId,
        schoolDiaryId: this.diary.schoolDiaryId,
        comment: this.diary.comment || undefined,
        event: this.diary.event || undefined,
        earlyLeaver: this.diary.earlyLeaver || undefined,
        appliedBy:
          status === "applied" && this.appliedBy ? this.appliedBy : undefined,
        status,
      });
      this.setDiary(response);
    }
  }

  @Action({ rawError: true })
  public async approveSchoolDiary() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.diary) {
      const response = await ApiNurseryReport.updateNurseryReportApproval({
        nurseryId,
        schoolDiaryId: this.diary.schoolDiaryId,
        approvalStatus: "approved",
      });
      this.setDiary(response);
    }
  }

  @Action({ rawError: true })
  public async rejectSchoolDiary() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.diary) {
      const response = await ApiNurseryReport.updateNurseryReportApproval({
        nurseryId,
        schoolDiaryId: this.diary.schoolDiaryId,
        approvalStatus: "rejected",
      });
      this.setDiary(response);
    }
  }

  @Mutation
  public setDiary(diary: SchoolDiaryResponse) {
    if (diary.status === "applied") {
      this.appliedBy = diary.createdBy.userNurseryId;
    } else {
      this.appliedBy = null;
    }
    this.diary = diary;
  }

  @Mutation
  public setDate(date: string | null) {
    this.date = date;
  }

  @Mutation
  public setAppliedBy(userId: number | null) {
    this.appliedBy = userId;
  }
}

export const schoolDairyEditModule = getModule(SchoolDairyEditModule);
