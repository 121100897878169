import Vue from "vue";

export default Vue.extend({
  // dialog open/close methods
  name: "dialog-mixin",

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    open: {
      get(): boolean {
        return this.isOpen;
      },
      set(value: boolean) {
        this.$emit("is-open", value);
      },
    },
  },
  methods: {
    close() {
      this.$emit("is-open", false);
    },
  },
});
