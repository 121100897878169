import ApiClient from '@/api/ApiClient';
import { NurseryChildResponse, NurseryNapDetailResponse, NurseryNapIndividualResponse, NurseryNapIndividualOverview, NurseryNapResponse, NurseryNapAirConditionRequest } from 'chaild-api/lib';

export interface NapMonitoringData {
  child: NurseryChildResponse;
  overviews: NurseryNapIndividualOverview[];
}

export default class ApiNap {
  public static async listNaps(input: {
    nurseryId: number;
    age?: number;
    classId?: number;
    from?: string;
    to?: string;
  }) {
    const response = await ApiClient.get(`/nap`, input);
    if (response) {
      return response as NurseryNapResponse[];
    }
    return [];
  }

  public static async getNap(input: {
    nurseryId: number;
    napId: number;
  }) {
    const response = await ApiClient.get(`/nap/${input.napId}?nurseryId=${input.nurseryId}`, null);
    if (response) {
      return response as NurseryNapDetailResponse;
    }
    return null;
  }

  public static async updateNap(input: {
    nurseryId: number;
    napId: number;
    comment?: string;
    recorderId?: number;
    airConditions?: NurseryNapAirConditionRequest[];
    weather?: string;
  }) {
    const response = await ApiClient.put(`/nap/${input.napId}?nurseryId=${input.nurseryId}`, {
      comment: input.comment,
      recorderId: input.recorderId,
      weather: input.weather,
      airConditions: input.airConditions,
    });
    if (response) {
      return response as NurseryNapDetailResponse;
    }
    return null;
  }

  public static async updateNapStatus(input: {
    nurseryId: number;
    napId: number;
    status: string;
  }) {
    const response = await ApiClient.put(`/nap/${input.napId}/status?nurseryId=${input.nurseryId}`, {
      status: input.status
    });
    if (response) {
      return response as NurseryNapDetailResponse;
    }
    return null;
  }

  public static async listNapData(input: {
    nurseryId: number;
    napId: number;
  }) {
    const response = await ApiClient.get(`/nap/${input.napId}/children?nurseryId=${input.nurseryId}`, null);
    if (response && response.response) {
      return response.response as NapMonitoringData[];
    }
    return [];
  }

  public static async createNap(input: {
    nurseryId: number;
    date: string;
    recorderId: number;
    classIds: number[];
    ages: number[];
    interval: number;
    childIds: number[];
  }) {
    const response = await ApiClient.post(`/nap?nurseryId=${input.nurseryId}`, {
      date: input.date,
      recorderId: input.recorderId,
      class: {
        all: false,
        classIds: input.classIds,
      },
      age: {
        all: input.ages.length === 0 ? true : false,
        ages: input.ages,
      },
      interval: input.interval,
      childIds: input.childIds
    });
    if (response) {
      return response as NurseryNapDetailResponse;
    }
    return null;
  }

  public static async createNapTemplate(input: {
    nurseryId: number;
    templateTitle: string;
    recorderId: number;
    classIds: number[];
    ages: number[];
    interval: number;
    childIds: number[];
  }) {
    const response = await ApiClient.post(`/nap/template?nurseryId=${input.nurseryId}`, {
      recorderId: input.recorderId,
      templateTitle: input.templateTitle,
      class: {
        all: false,
        classIds: input.classIds,
      },
      age: {
        all: input.ages.length === 0 ? true : false,
        ages: input.ages,
      },
      interval: input.interval,
      childIds: input.childIds
    });
    if (response) {
      return response as NurseryNapDetailResponse;
    }
    return null;
  }

  public static async deleteNapTemplate(input: {
    nurseryId: number;
    napTemplateId: number;
  }) {
    const response = await ApiClient.delete(`/nap/template/${input.napTemplateId}`, {
      nurseryId: input.nurseryId
    });
    if (response) {
      return response as any;
    }
    return null;
  }  

  public static async createNapData(input: {
    nurseryId: number;
    childId: number;
    napId: number;
    time: string;
    params: {
      staffId: number;
      posturalChanged?: string;
      status?: string;
      additionalNote?: string[];
      textComment?: string;
    };
  }) {
    const response = await ApiClient.post(`/nap/${input.napId}/children/${input.childId}/${input.time}?nurseryId=${input.nurseryId}`, input.params);
    if (response) {
      return response as NurseryNapIndividualResponse;
    }
    return null;
  }

  public static async editNapData(input: {
    nurseryId: number;
    childId: number;
    napId: number;
    time: string;
    params: {
      staffId: number;
      posturalChanged?: string;
      status?: string;
      additionalNote?: string[];
      textComment?: string;
    };
  }) {
    const response = await ApiClient.put(`/nap/${input.napId}/children/${input.childId}/${input.time}?nurseryId=${input.nurseryId}`, input.params);
    if (response) {
      return response as NurseryNapIndividualResponse;
    }
    return null;
  }

  public static async updateChildNapStatus(input: {
    childId: number;
    napId: number;
    time: string;
    status: string;
  }) {
    const response = await ApiClient.put(`/nap/${input.napId}/children/${input.childId}`, {
      timestamp: input.time,
      status: input.status,
    });
    if (response) {
      return response as NurseryNapIndividualResponse;
    }
    return null;
  }

  public static async deleteNapData(input: {
    napId: number;
    childId: number;
    time: string;
    nurseryId: number;
  }) {
    const time = input.time.replace(':', '');
    const response = await ApiClient.delete(`/nap/${input.napId}/children/${input.childId}/${time}`, {
      nurseryId: input.nurseryId,
    });
    if (response) {
      return response as any;
    }
    return null;
  }

  public static async deleteNap(input: {
    napId: number;
    nurseryId: number;
  }) {
    const response = await ApiClient.delete(`/nap/${input.napId}`, {
      nurseryId: input.nurseryId,
    });
    if (response) {
      return response as any;
    }
    return null;
  }
}