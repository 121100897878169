
import Vue from "vue";
import MemoruContentCard from "@/components/atoms/MemoruContentCard.vue";
import { memoruModule } from "@/store/dataModules/memoruModule";
import {
  MultimediaAlbumResponse,
  MultimediaListResponse,
  MultimediaResponse,
  NurseryClassResponse,
} from "chaild-api/lib";
import { multimediaCategoryType } from "@/constants/memoru";
import dayjs from "dayjs";
import ApiMemoru from "@/api/ApiMemoru";
import ValidationMixin from "@/mixins/ValidationMixin";
import { classModule } from "@/store/dataModules/classModule";
import LocalDataService from "@/service/LocalDataService";
import { snackbarModule } from "@/store/viewModules/snackbarModule";

export default Vue.extend({
  name: "MemoruContent",

  mixins: [ValidationMixin],

  components: {
    MemoruContentCard,
  },

  async mounted() {
    await this.listContents();
    await this.getAlbum();
    await this.listClasses();
  },

  data() {
    return {
      multimediaCategoryType: multimediaCategoryType,
      loading: false,
      updateDialog: false,
      detailDialog: false,
      searchFilters: {
        category: "",
        dateFrom: "",
        dateTo: "",
        purchasedCountMin: "",
        limit: 20,
        skip: 0,
      },
      updateAlbumInput: {
        title: "",
        year: "" as string | number,
        nurseryClassIds: [] as any[],
        comment: "",
        availableAfter: "",
        availableBefore: "" as string | undefined,
      },
      selectedContent: null as null | MultimediaResponse,
    };
  },

  computed: {
    albumId: function (): string {
      return this.$route.params.albumId;
    },
    contents: function (): MultimediaListResponse | null {
      return memoruModule.contents;
    },
    album: function (): MultimediaAlbumResponse | null {
      return memoruModule.album;
    },
    available: function (): boolean {
      if (this.album) {
        if (
          dayjs().isAfter(this.album.availableAfter) &&
          (!this.album.availableBefore ||
            dayjs().isBefore(this.album.availableBefore))
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    page: {
      get(): number {
        if (this.searchFilters.skip == 0) {
          return 1;
        }
        return this.searchFilters.skip / this.searchFilters.limit;
      },
      set(value: number) {
        this.searchFilters.skip = (value - 1) * this.searchFilters.limit;
        this.listContents();
        this.searchFilters.skip = value * this.searchFilters.limit;
      },
    },
    classes(): NurseryClassResponse[] {
      if (this.updateAlbumInput.year) {
        return classModule.classes.filter((c) => {
          return String(c.year) == this.updateAlbumInput.year;
        });
      } else {
        return classModule.classes;
      }
    },
  },

  methods: {
    async listContents() {
      this.loading = true;
      await memoruModule.listContents({
        ...this.searchFilters,
        albumId: this.albumId,
      });
      this.loading = false;
    },
    async getAlbum() {
      this.loading = true;
      if (this.albumId) {
        await memoruModule
          .getAlbum({
            albumId: this.albumId,
          })
          .catch();
        if (this.album) {
          this.updateAlbumInput = {
            title: this.album.title,
            year: this.album.year,
            nurseryClassIds:
              this.album.nurseryClasses.map((c) => c.classId) || [],
            comment: this.album.comment || "",
            availableAfter: this.formatDate(this.album.availableAfter),
            availableBefore: this.formatDate(this.album.availableBefore || ""),
          };
        }
      }
      this.loading = false;
    },
    async updateAlbum() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiMemoru.updateAlbum({
          nurseryId,
          albumId: this.albumId,
          ...this.updateAlbumInput,
        }).catch(() => (this.loading = false));
        if (response) {
          snackbarModule.snackOn({
            message: "アルバムを更新しました",
          });
          this.updateDialog = false;
          await this.listContents();
          await this.getAlbum();
        }
      }
      this.loading = false;
    },
    async deleteContent() {
      const answer = confirm("削除しますか？");
      if (answer && this.selectedContent) {
        this.loading = true;
        const response = await ApiMemoru.deleteContent({
          multimediaId: this.selectedContent.multimediaId,
        }).catch(() => (this.loading = false));
        if (response) {
          this.detailDialog = false;
          alert("削除しました");
          await this.listContents();
        }
        this.loading = false;
      }
    },
    async listClasses() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId,
        });
      }
    },
    async onClickContent(value: MultimediaResponse) {
      if (value.multimediaId) {
        this.loading = true;
        const response = await ApiMemoru.getContent({
          multimediaId: value.multimediaId,
        }).catch(() => (this.loading = false));
        this.selectedContent = response;
        this.detailDialog = true;
        this.loading = false;
      }
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
});
