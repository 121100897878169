import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface SnackbarState {
  isShow: boolean;
  message: string;
  color: string;
}


@Module({ dynamic: true, store, name: 'snackbar', namespaced: true })
class SnackbarModule extends VuexModule implements SnackbarState {
  isShow = false;
  message = '成功しました';
  color = 'success';

  @Action
  public snackOn({ message, color }: { message: string; color?: string }) {
    if (color != undefined) { this.setColor(color); }
    this.setMessage(message);
    this.setIsShow(true);
  }

  @Mutation
  public setIsShow(value: boolean) {
    this.isShow = value;
  }

  @Mutation
  public setMessage(value: string) {
    this.message = value;
  }

  @Mutation
  public setColor(value: string) {
    this.color = value;
  }
}

export const snackbarModule = getModule(SnackbarModule);