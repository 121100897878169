
import Vue from "vue";
import { NurserySystemSettingsResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import ApiSystemSetting from "@/api/ApiSystemSetting";

export default Vue.extend({
  name: "SystemSetting",

  async mounted() {
    await this.getSystem();
  },

  data() {
    return {
      loading: false,
      systemResponse: null as null | NurserySystemSettingsResponse,
      views: {
        titleCols: 4,
        textCols: 8,
      },
    };
  },

  methods: {
    async getSystem() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiSystemSetting.getSetting({
          nurseryId,
        }).catch(() => (this.loading = false));
        if (response) {
          this.systemResponse = response;
        }
      }
      this.loading = false;
    },
    async updateSystem() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.systemResponse) {
        const response = await ApiSystemSetting.updateSetting({
          nurseryId,
          ...this.systemResponse,
        }).catch(() => (this.loading = false));
        if (response) {
          alert("更新しました");
          this.getSystem();
        }
      }
      this.loading = false;
    },
  },
});
