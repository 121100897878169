
import Vue from 'vue'
import KanaList from '@/components/atoms/KanaList.vue';
import LocalDataService from '@/service/LocalDataService';
import { childrenModule } from '@/store/dataModules/childrenModule';
import { attendanceModule } from '@/store/dataModules/attendanceModule';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { ChildResponse, NurseryAttendanceResponse, NurseryChildResponse, TimecardResponse } from 'chaild-api/lib';
import { ChildForAttendance } from '@/api/ApiChildren';
import { staffModule } from '@/store/dataModules/staffModule';
import { staffAttandanceManagementModule } from '@/store/viewModules/timecard/staffAttendaceModule'
import { NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';
import ApiTimecard from "@/api/ApiTimecard";

interface DataType {
  dialog: boolean;
  selectedUser: NurseryUserResponse | null;
  selectedKana: string | null;

  isUpdating: boolean;
  loading: boolean;
}

export default Vue.extend({
  name: 'StaffAttendanceManagement',

  components: {
    KanaList,
  },

  async mounted() {
    // attendanceModule.setDate(dayjs().format('YYYY-MM-DD'));
    // await this.listDailyAttendances();
    this.listDailyAttendances();
    await this.fetchStaffList();
  },

  // 押したひらがなに応じて変化させる

  data: (): DataType => {
    return {
      dialog: false,
      selectedUser: null,
      selectedKana: null,

      isUpdating: false,
      loading: false,
    }
  },

  computed: {
    childrenList(): ChildForAttendance[] {
      if (this.selectedKana) {
        const target = childrenModule.kanaList.filter((childrenWithKana) => childrenWithKana.value === this.selectedKana);
        return target.flatMap((childrenWithKana) => childrenWithKana.children);
      }

      return [];

      // return childrenModule.kanaList.flatMap((childrenWithKana) => childrenWithKana.children);
    },
    staffList() {
      if (this.selectedKana) {
        const target = staffAttandanceManagementModule.staffList.filter((staff) => staff.value === this.selectedKana);
        return target.flatMap((staff) => staff.nurseryUser);
      }

      return [];
    },
    kanaList: function() {
      const items = staffAttandanceManagementModule.staffList.map((item) => {
        if (item.nurseryUser) {
          return (item as any).value;
        }
      });
      return items.filter((item) => item)
    },
    selectedUserAttendance(): TimecardResponse | null {
      if (this.selectedUser) {
        const find = staffAttandanceManagementModule.attendances.find((at) => at.nurseryUser.userId === this.selectedUser?.userId);
        if (find) {
          return find;
        }
      }
      return null;
    },
    shouldAttendButtonEnabled(): boolean {
      if (this.isUpdating) {
        return false;
      }
      if (this.selectedUserAttendance && this.selectedUserAttendance.attendedAt) {
        return false;
      }
      return true;
    },
    shouldLeftButtonEnabled(): boolean {
      if (this.isUpdating) {
        return false;
      }
      if (this.selectedUserAttendance && this.selectedUserAttendance.leftAt) {
        return false;
      }
      return true;
    },
    attendedTime(): string | null {
      if (this.selectedUserAttendance && this.selectedUserAttendance.attendedAt) {
        return dayjs(this.selectedUserAttendance.attendedAt).format('HH:mm')
      }
      return null;
    },
    leftTime(): string | null {
      if (this.selectedUserAttendance && this.selectedUserAttendance.leftAt) {
        return dayjs(this.selectedUserAttendance.leftAt).format('HH:mm')
      }
      return null;
    },
  },

  methods: {
    async fetchStaffList() {
      this.loading = true;
      await staffAttandanceManagementModule.getStaffList();
      this.loading = false;
    },
    async listDailyAttendances() {
      await staffAttandanceManagementModule.getAttendances();
    },
    clickKana(value: string) {
      this.selectedKana = value;
    },
    clickStaff(value: NurseryUserResponse) {
      this.selectedUser = value;
      this.dialog = true;
    },
    async attend() {

      if (this.selectedUser) {
        console.log('attend')
        const userId = this.selectedUser.userId;

        const today = dayjs().format('YYYY-MM-DD');
        const now = dayjs().toISOString();

        try {
          this.isUpdating = true;
          const response = await ApiTimecard.updateStaffAttendance({
            userId,
            date: today,
            attendedAt: now
          })

          if (response) {
            await Promise.all([
              this.fetchStaffList(),
              this.listDailyAttendances()
            ]);
            Swal.fire({ title: '出勤しました。', icon: 'success', toast: true, position: 'top-end'});
          } else {
            throw(undefined)
          }
        } catch {
          Swal.fire({ title: 'エラーが発生しました。', icon: 'error', toast: true, position: 'top-end'});
        }

        this.isUpdating = false;
      }
    },
    async left() {
      const today = dayjs().format('YYYY-MM-DD');
      const now = dayjs().toISOString();

      if (this.selectedUser && this.selectedUserAttendance && this.selectedUserAttendance.attendedAt) {
        const userId = this.selectedUser.userId;
        try {
          this.isUpdating = true;
          const response = await ApiTimecard.updateStaffAttendance({
            userId,
            date: today,
            attendedAt: this.selectedUserAttendance.attendedAt,
            leftAt: now
          })

          if (response) {
            await Promise.all([
              this.fetchStaffList(),
              this.listDailyAttendances()
            ]);
            Swal.fire({ title: '退勤しました。', icon: 'success', toast: true, position: 'top-end'});
          } else {
            throw(undefined)
          }
        } catch {
          Swal.fire({ title: 'エラーが発生しました。', icon: 'error', toast: true, position: 'top-end'});
        }

        this.isUpdating = false;
      }
    },
  },
})
