
import Vue from "vue";
export default Vue.extend({
  name: "PageTitle",

  props: {
    value: {
      type: String,
      default: "",
    },
  },
});
