
import Vue from "vue";

import { childAccountModule } from "@/store/dataModules/childAccountModule";
import ApiChildren from "@/api/ApiChildren";
import { parentTypeItems } from "@/constants/children";

export default Vue.extend({
  async mounted() {
    const childId = this.$route.params.childId as string;
    if (childId) {
      const parsed = parseInt(childId);
      childAccountModule.setChildId(parsed);
      await childAccountModule.getChild();
    }

    await this.getAuthMethods();
  },

  data() {
    return {
      isDownloading: false,
      authMethodsResponse: [],
      parentTypeItems: parentTypeItems,
      loading: false,
    };
  },

  computed: {
    child() {
      return childAccountModule.child;
    },
    childType(): string {
      if (this.child) {
        if (this.child.childcareType === "normal") {
          return "一般保育";
        } else if (this.child.childcareType === "temporary") {
          return "一時保育";
        }
      }
      return "";
    },
    classNames(): string {
      if (this.child) {
        return this.child.nurseryClasses
          .map((nc) => `${nc.year}年度 ${nc.className}`)
          .join(", ");
      }
      return "";
    },
    groupNames(): string {
      if (this.child) {
        return this.child.nurseryGroups.map((g) => g.name).join(", ");
      }
      return "";
    },
  },

  methods: {
    async invitationDownload() {
      this.isDownloading = true;
      const link = await childAccountModule.getInvitationLink();
      this.isDownloading = false;
      if (link) {
        window.open(link, "_blank");
      }
    },
    async getAuthMethods() {
      if (this.child && this.child.childId) {
        const response = await ApiChildren.getAuthMethods({
          childId: this.child.childId,
        });
        if (response) {
          this.authMethodsResponse = response.authMethods;
        }
      }
    },
    async deleteChild() {
      const alertText =
        "\
注意！\n\
この操作は、園児情報をすべて削除します。\n\
今まで蓄積したデータを一回の操作で丸ごと失う危険がありますので、\n\
操作の間違いには、十分にご注意ください。\n\n\
〔 園児情報削除時の注意点 〕 \n\
• 操作は取消できません。\n\
• 現在の園児情報は、この操作によりすべて消去されます。卒園もしくは退園の場合は「卒園日（予定）」を入力して登録してください。\n\
• それでも園児を削除する場合は、下記の削除ボタンを押下してください。\n\
      ";
      const answer = confirm(alertText);
      if (answer) {
        if (this.child && this.child.childId) {
          this.loading = true;
          const response = await ApiChildren.deleteChild({
            childId: this.child.childId,
          }).catch(() => (this.loading = false));
          if (response) {
            alert("削除しました");
            this.$router.push({ path: "/children" });
          }
          this.loading = false;
        }
      }
    },
    getParentTypeText(value: string): string {
      if (!value) {
        return "";
      }
      return parentTypeItems.find((p) => p.value == value)?.text || "";
    },
    timeIntToHourMin(timeInt: number) {
      const hour = Math.floor(timeInt / 60);
      const min = Math.floor(timeInt % 60);
      const minStr = min === 0 ? "00" : String(min);
      return `${hour}:${minStr}`;
    },
  },
});
