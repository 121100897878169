
import Vue from "vue";

import PageTitle from "@/components/atoms/PageTitle.vue";
import MonthPager from "@/components/atoms/MonthPager.vue";
import dayjs from "dayjs";

import { schoolServiceMonthlyUsageModule } from "@/store/viewModules/school-service/schoolServiceMonthlyUsageModule";
import {
  NurseryChildResponse,
  UniqueServiceMastResponse,
  UniqueServiceRegistrationResponse,
} from "chaild-api/lib";

interface DataType {
  yearMonth: string;

  isFetching: boolean;
}

interface ChildWithUsages {
  child: NurseryChildResponse;
  services: ServiceWithUsages[];
}

interface ServiceWithUsages {
  service: UniqueServiceMastResponse;
  usages: DateWithUsage[];
  isMonthly: boolean;
  isMonthlyUsed: boolean;
}

interface DateWithUsage {
  date: string;
  dateLabel: string;
  hasUsed: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
    MonthPager,
  },

  data: (): DataType => ({
    yearMonth: dayjs().format("YYYY-MM-DD"),

    isFetching: false,
  }),

  computed: {
    dates(): string[] {
      const date = dayjs(this.yearMonth).startOf("months");
      const daysInMonth = date.daysInMonth();
      return [...Array(daysInMonth)].map((emp, index) => {
        return date.add(index, "day").format("YYYY-MM-DD");
        // return date.add(index, 'day').format('MM/DD (ddd)');
      });
    },
    dateLabels(): string[] {
      return this.dates.map((d) => dayjs(d).format("MM/DD (ddd)"));
    },
    tableStyle(): string {
      const tableWidth = 180 + 80 * (this.dates.length + 1);
      return `width: ${tableWidth}px;`;
    },
    usages() {
      return schoolServiceMonthlyUsageModule.usages;
    },
    services() {
      return schoolServiceMonthlyUsageModule.services;
    },
    childrenWithUsages(): ChildWithUsages[] {
      return this.usages.map((usage) => {
        const services: ServiceWithUsages[] = this.services.map((service) => {
          const findService = usage.uniqueServices.find(
            (s) => s.uniqueServiceId === service.uniqueServiceId
          );

          const registrations = findService ? findService.registrations : [];

          const datesWithUsages: DateWithUsage[] = this.dates.map((date) => {
            const findUsage = registrations.find((r) => r.date === date);
            const hasUsed = findUsage ? true : false;
            return {
              date,
              dateLabel: dayjs(date).format("MM/DD (ddd)"),
              hasUsed,
            };
          });

          const isMonthlyUsed = findService
            ? findService.registrations.length > 0
            : false;

          return {
            service,
            usages: datesWithUsages,
            isMonthly: service.serviceType !== "perUse",
            isMonthlyUsed,
          };
        });
        return {
          child: usage.child,
          services,
        };
      });
    },
  },

  methods: {
    async listUsages() {
      this.isFetching = true;
      await schoolServiceMonthlyUsageModule.listUsages({
        yearMonth: this.yearMonth,
      });
      this.isFetching = false;
    },
  },

  mounted() {
    if (this.$route.params.classId) {
      const classId = parseInt(this.$route.params.classId, 10);
      schoolServiceMonthlyUsageModule.setClassId(classId);
      this.listUsages();
      schoolServiceMonthlyUsageModule.listSchoolServices();
    }
  },
});
