import ApiClient from "@/api/ApiClient";

export default class ApiCashBook {
  // 勘定項目
  public static async listAccountTitle(input: { nurseryId: number }) {
    const response = await ApiClient.get("/cashbook/account-title", input);
    return response;
  }

  public static async createAccountTitle(input: {
    name: string;
    nurseryId: number;
  }) {
    const response = await ApiClient.post("/cashbook/account-title", input);
    return response;
  }

  public static async deleteAccountTitle(input: { titleId: string }) {
    const response = await ApiClient.delete(
      `/cashbook/account-title/${input.titleId}`,
      null
    );
    return response;
  }

  public static async updateAccountTitle(input: {
    titleId: number;
    name: string;
  }) {
    const response = await ApiClient.put(
      `/cashbook/account-title/${input.titleId}`,
      {
        name: input.name,
      }
    );
    return response;
  }

  // 小口現金出納帳（全体）
  public static async listCashBook(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
  }) {
    const response = await ApiClient.get(
      `/cashbook/${input.nurseryId}/${input.yearMonth}`,
      null
    );
    return response;
  }

  public static async updateCashBook(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    isFinalized: boolean;
    carriedAmount: number;
    attachments: string[];
  }) {
    const response = await ApiClient.put(
      `/cashbook/${input.nurseryId}/${input.yearMonth}`,
      input
    );
    return response;
  }

  // 小口現金出納帳（詳細）
  public static async deleteCashBookDetail(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    detailIds: number[];
  }) {
    const response = await ApiClient.delete(
      `/cashbook/${input.nurseryId}/${input.yearMonth}/details?detailIds=${input.detailIds[0]}`,
      null
    );
    return response;
  }

  public static async createCashbookDetail(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    details: {
      day: string; // DD
      titleId?: number;
      description?: string;
      supplier?: string;
      incomeAmount?: number;
      outgoAmount?: number;
      taxation?: string;
      note?: string;
    }[];
  }) {
    const response = await ApiClient.post(
      `/cashbook/${input.nurseryId}/${input.yearMonth}/details`,
      {
        details: input.details,
      }
    );
    return response;
  }

  public static async updateCashbookDetail(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    details: {
      day: string; // DD
      detailId: number;
      titleId?: number;
      description?: string;
      supplier?: string;
      incomeAmount?: number;
      outgoAmount?: number;
      taxation?: string;
      note?: string;
    }[];
  }) {
    const response = await ApiClient.put(
      `/cashbook/${input.nurseryId}/${input.yearMonth}/details`,
      {
        details: input.details,
      }
    );
    return response;
  }

  static async getPresignedUrl(input: {
    nurseryId: number;
    yearMonth: string; // YYYY-MM
    base64Html: string;
  }) {
    return await ApiClient.post(
      `/cashbook/${input.nurseryId}/${input.yearMonth}/details/pdf`,
      input
    );
  }
}
