import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiShift, { ShiftTimeTableRequest } from "@/api/ApiShift";
import { ShiftPatternSettingResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";

export interface ShiftPatternState {
  shiftPatterns: ShiftPatternSettingResponse[];
}

export const WorkDescriptions: Record<string, string> = {
  workDescription: "作業内容",
  break: "休憩",
  holiday: "お休み（有給外）",
  paidHoliday: "有給休暇",
  publicHoliday: "公休",
  subsidyChildcare: "配慮が必要な園児の保育",
};

export const createTimeTableRequest = (): ShiftTimeTableRequest => ({
  startMin: 0,
  endMin: 0,
  comment: "",
  category: "",
  workDescriptionId: undefined,
  name: "",
  color: "",
  abbreviation: "",
});

@Module({ dynamic: true, store, name: "shift-pattern", namespaced: true })
class ShiftPatternModule extends VuexModule implements ShiftPatternState {
  public shiftPatterns: ShiftPatternSettingResponse[] = [];

  @Action
  public async listShiftPatterns() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiShift.listShiftPattern(nurseryId);

      if (response) {
        this.setShiftPatterns(response);
      }
    }
  }

  @Action
  public async createShiftPattern({
    name,
    abbreviation,
    color,
    timetables,
  }: {
    name: string;
    abbreviation: string;
    color: string;
    timetables: ShiftTimeTableRequest[];
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiShift.createShiftPattern({
        nurseryId,
        name,
        abbreviation,
        color,
        timetables,
      });

      if (response) {
        this.addShiftPatterns([response]);
      }
    }
  }

  @Action
  public async updateShiftPattern({
    shiftPatternId,
    name,
    abbreviation,
    color,
    timetables,
  }: {
    shiftPatternId: number;
    name: string;
    abbreviation: string;
    color: string | null;
    timetables: ShiftTimeTableRequest[];
  }) {
    const response = await ApiShift.updateShiftPattern({
      shiftPatternId,
      name,
      abbreviation,
      color,
      timetables,
    });

    if (response) {
      this.replaceShiftPattern(response);
    }
  }

  @Action
  public async deleteShiftPattern(shiftPatternId: number) {
    const response = await ApiShift.deleteShiftPattern(shiftPatternId);
    if (response && response.message && response.message === "ok") {
      this.removeShiftPattern(shiftPatternId);
    }
  }

  @Mutation
  public setShiftPatterns(patterns: ShiftPatternSettingResponse[]) {
    this.shiftPatterns = patterns;
  }

  // 作業内容順番更新
  @Mutation
  public async sortShiftPatterns() {
    const nurseryId = LocalDataService.getNurseryId();
    const shiftPatternIds = this.shiftPatterns.map((item) => item.shiftPatternId)
    if(nurseryId) {
      const response = await ApiShift.sortShiftPattern({
        nurseryId,
        shiftPatternIds,
      });
      if (response) {
        this.shiftPatterns = response
      }
    }
  }

  @Mutation
  public addShiftPatterns(patterns: ShiftPatternSettingResponse[]) {
    this.shiftPatterns = this.shiftPatterns.concat(patterns);
  }

  @Mutation
  public replaceShiftPattern(pattern: ShiftPatternSettingResponse) {
    this.shiftPatterns = this.shiftPatterns.map((s) => {
      if (s.shiftPatternId === pattern.shiftPatternId) {
        return pattern;
      }
      return s;
    });
  }

  @Mutation
  public removeShiftPattern(shiftPatternId: number) {
    this.shiftPatterns = this.shiftPatterns.filter(
      (s) => s.shiftPatternId !== shiftPatternId
    );
  }
}

export const shiftPatternModule = getModule(ShiftPatternModule);
