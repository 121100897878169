
import { napDetailModule } from '@/store/dataModules/napDetailModule';
import { NurseryNapAirConditionRequest, NurseryNapDetailResponse } from 'chaild-api/lib';
import { NurseryNapAirConditionEntity } from 'chaild-api/src/model/mysql/entity/nurseryNapAirConditionEntity';
import dayjs from 'dayjs';
import Vue from 'vue';

interface DataType {
  isAirConditionModalOpen: boolean;
  newAirConditionTime: number | null;
  newAirConditionTemper: number | null;
  newAirConditionHumi: number | null;

  isCreating: boolean;
  isDeleting: boolean;
}

export default Vue.extend({
  
  data: (): DataType => {
    return {
      isAirConditionModalOpen: false,
      newAirConditionTime: null,
      newAirConditionTemper: null,
      newAirConditionHumi: null,

      isCreating: false,
      isDeleting: false,
    };
  },

  computed: {
    weatherOptions() {
      return [
        {
          label: '晴れ',
          value: 'sunny',
        },
        {
          label: '曇り',
          value: 'cloudy',
        },
        {
          label: '雨',
          value: 'rainy',
        },
      ]
    },
    weather: {
      get() {
        if (napDetailModule.nap) {
          return napDetailModule.nap.weather;
        }
        return null;
      },
      set(value: string) {
        if (napDetailModule.nap) {
          const newNap = {...napDetailModule.nap};
          newNap.weather = value;
          napDetailModule.setNap(newNap);
          napDetailModule.updateNap();
        }
      }
    },
    airConditions: {
      get() {
        if (napDetailModule.nap) {
          const airConditions = [...napDetailModule.nap.airConditions];
          return airConditions.sort((ac1, ac2) => ac1.time - ac2.time);
        }
        return [];
      },
    },
    airConditionCreateButtonValid() {
      return (
        this.newAirConditionTime !== null
        && this.newAirConditionTemper !== null
        && this.newAirConditionHumi !== null
      );
    }
  },

  methods: {
    async addAirCondition() {
      if (
        this.newAirConditionTime !== null
        && this.newAirConditionTemper !== null
        && this.newAirConditionHumi !== null
      ) {

        const time = (typeof this.newAirConditionTime === 'string')
          ? parseInt(this.newAirConditionTime)
          : this.newAirConditionTime;
        
        const temperature = (typeof this.newAirConditionTemper === 'string')
          ? parseFloat(this.newAirConditionTemper)
          : this.newAirConditionTemper;

        const humidity = (typeof this.newAirConditionHumi === 'string')
          ? parseFloat(this.newAirConditionHumi)
          : this.newAirConditionHumi;

        const airCondition: NurseryNapAirConditionRequest = {
          time,
          temperature,
          humidity,
        };

        const newNap = {...napDetailModule.nap} as NurseryNapDetailResponse;

        const airConditions: NurseryNapAirConditionRequest[] | undefined = newNap.airConditions
          ? [
            ...newNap.airConditions.map((ac) => ({
              time: ac.time,
              temperature: ac.temperature,
              humidity: ac.humidity,
            })),
            airCondition
          ]
          : [airCondition];

        this.isCreating = true;
        await napDetailModule.updateNap(airConditions);
        this.isCreating = false;

        this.newAirConditionTime = null;
        this.newAirConditionTemper = null;
        this.newAirConditionHumi = null;
        this.isAirConditionModalOpen = false;
      }
    },
    async deleteAirCondition(id: number) {
      if (napDetailModule.nap) {
        const airConditions = [...napDetailModule.nap.airConditions];
        const filtered = airConditions.filter((ac) => ac.id !== id);

        this.isDeleting = true;
        await napDetailModule.updateNap(filtered);
        this.isDeleting = false;
      }
    },
    openModal() {
      const hour = dayjs().hour();
      this.newAirConditionTime = hour;
      this.isAirConditionModalOpen = true;
    }
  }
});
