
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import ClassDialogContainer from "@/containers/ClassDialogContainer.vue";
import { classEditModule } from "@/store/dataModules/classEditModule";
import { classModule } from "@/store/dataModules/classModule";
import LocalDataService from "@/service/LocalDataService";
import { NurseryClassResponse, NurseryChildResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import ApiChildren from "../../api/ApiChildren";
import ApiClass from "@/api/ApiClass";
import ValidationMixin from "@/mixins/ValidationMixin";

export default Vue.extend({
  name: "ClassSetting",

  mixins: [ValidationMixin],

  components: {
    PageTitle,
    ClassDialogContainer,
  },

  async mounted() {
    await this.listClass();
    await this.getAllChildren();
  },

  data() {
    return {
      loading: false,
      createDialog: false,
      updateDialog: false,
      childrenDialog: false,
      duplicateDialog: false,
      yearFilterValue: "",
      selectedClassId: 0,
      headers: [
        { text: "年度", value: "year" },
        { text: "クラス名", value: "className" },
        { text: "年齢", value: "childrenAge" },
        { text: "作成日", value: "createdAt" },
        { text: "園児", value: "children" },
        { text: "編集", value: "edit" },
        { text: "複製", value: "duplicate" },
      ],
      childrenList: [] as NurseryChildResponse[],
      allChildrenList: [] as NurseryChildResponse[],
      duplicateInput: {
        classId: "" as string | number,
        to: {
          className: "",
          childrenAge: 0,
          year: 2021 as number,
        },
      },
      duplicateFormValid: false,
      childrenTableHeaders: [
        { text: "園児", value: "children" },
        { text: "性別", value: "gender" },
      ],
    };
  },

  watch: {
    createDialog: {
      handler(val: boolean) {
        if (!val) {
          this.clearValues();
          this.listClass();
        }
      },
    },
    updateDialog: {
      handler(val: boolean) {
        if (!val) {
          this.clearValues();
          this.listClass();
        }
      },
    },
  },

  computed: {
    classList: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
  },

  methods: {
    async listClass() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId: nurseryId,
          year: this.yearFilterValue,
        });
      }
      this.loading = false;
    },
    async searchChildren(classId: number) {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.selectedClassId = classId;
        const response = await ApiChildren.listChildren({
          nurseryId,
          classId,
        }).catch();
        if (response) {
          this.childrenList = response;
          this.childrenDialog = true;
        } else {
          this.childrenList = [];
        }
      }
      this.loading = false;
    },
    async getAllChildren() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiChildren.listChildren({
          nurseryId,
        }).catch();
        if (response) {
          this.allChildrenList = response;
        } else {
          this.allChildrenList = [];
        }
      }
      this.loading = false;
    },
    async duplicateClass() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiClass.duplicateClass({
          nurseryId,
          ...this.duplicateInput,
        }).catch(() => (this.loading = false));
        if (response) {
          alert("クラスを複製しました");
          this.duplicateDialog = false;
          await this.listClass();
        }
      }
      this.loading = false;
    },
    async updateClassChildren() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiChildren.updateClassChildren({
          nurseryId,
          classId: this.selectedClassId,
          childrenIds: this.childrenList.map((c) => c.childId),
        }).catch(() => (this.loading = false));
        if (response) {
          this.childrenDialog = false;
          alert("保存しました");
        }
      }
      this.loading = false;
    },
    clickClass(value: NurseryClassResponse) {
      if (value) {
        classEditModule.setClassId(value.classId as any);
        classEditModule.setClassName(value.className);
        classEditModule.setYear((value as any).year);
        classEditModule.setChildrenAge(String(value.childrenAge));
        this.updateDialog = true;
      }
    },
    clickDuplicate(value: NurseryClassResponse) {
      this.duplicateDialog = true;
      this.duplicateInput.classId = value.classId;
      this.duplicateInput.to.className = `${value.className}のコピー`;
      this.duplicateInput.to.childrenAge = value.childrenAge;
      this.duplicateInput.to.year = value.year || 2021;
    },
    clearValues() {
      classEditModule.setClassName("");
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
