import dayjs from 'dayjs';

export default class CommonUtil {

  public static getToday(): string {
    return dayjs().format('YYYY-MM-DD');
  }

  public static getFiscalYear(value?: string): string {
    if (Number(dayjs(value).format('M')) < 4) {
      return dayjs(value).subtract(1, 'year').format('YYYY');
    } else {
      return dayjs(value).format('YYYY');
    }
  }
}