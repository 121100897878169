import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryDiarySettingResponse } from "chaild-api/lib";
import ApiDiarySetting from "@/api/ApiDiarySetting";

export interface DiarySettingState {
  diarySetting: NurseryDiarySettingResponse | null;
}

@Module({ dynamic: true, store, name: "diary-setting", namespaced: true })
class DiarySettingModule extends VuexModule implements DiarySettingState {
  public diarySetting: NurseryDiarySettingResponse | null = null;

  @Action({ rawError: true })
  public async listDiarySetting(input: { nurseryId: number }) {
    const response = await ApiDiarySetting.listDiarySettings(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      this.setDiarySetting(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateDiarySetting(input: {
    nurseryId: number;
    classDiaryConfirmation: {
      targetClassIds: number[];
      targetAges: number[];
    };
    schoolDiaryConfirmation: {
      targetClassIds: number[];
    };
  }) {
    const response = await ApiDiarySetting.updateDiarySettings(input).catch(
      (e) => console.warn(e)
    );
    if (response) {
      return response;
    }
  }

  @Mutation
  public setDiarySetting(value: NurseryDiarySettingResponse) {
    this.diarySetting = value;
  }
}

export const diarySettingModule = getModule(DiarySettingModule);
