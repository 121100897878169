
import Vue from "vue";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ja";

export default Vue.extend({
  name: "DatePager",

  props: {
    value: {
      type: String,
      required: false,
    },
  },

  mounted() {
    dayjs.locale("ja");
  },

  watch: {
    date: {
      immediate: true,
      handler(val) {
        this.$emit("change", dayjs(val).format("YYYY-MM-DD"));
      },
    },
  },

  data() {
    return {
      date: dayjs() as Dayjs,
      formatString: "YYYY年MM月DD日 (ddd)",
      open: false,
    };
  },

  computed: {
    formattedDate(): string {
      if (this.value) {
        return dayjs(this.value).format(this.formatString);
      }
      return dayjs(this.date).format(this.formatString);
    },
  },

  methods: {
    add() {
      if (this.value) {
        const newValue = dayjs(this.value).add(1, "day").format("YYYY-MM-DD");
        this.$emit("input", newValue);
        return;
      }
      this.date = dayjs(this.date).add(1, "day");
    },
    subtract() {
      if (this.value) {
        const newValue = dayjs(this.value)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        this.$emit("input", newValue);
        return;
      }
      this.date = dayjs(this.date).subtract(1, "day");
    },
    updateValue(date: string) {
      const newDate = dayjs(date).format("YYYY-MM-DD");
      this.$emit("input", newDate);
    },
  },
});
