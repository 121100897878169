
import Vue from 'vue';

import LocalDataService from '@/service/LocalDataService';
import { classModule } from '@/store/dataModules/classModule';

import { napCreateModule } from '@/store/dataModules/napCreateModule';
import { NurseryChildResponse, NurseryClassResponse, NurseryNapTemplateResponse } from 'chaild-api/lib';

interface DataType {
  isFetchingChildren: boolean;
  isCreating: boolean;

  willCreateTemplate: boolean;
  templateTitle: string;

  isFetchingTemplate: boolean;

  templateToDelete: NurseryNapTemplateResponse | null;
  isDeletingTemplate: boolean;

  isFetchingClasses: boolean;
}

export default Vue.extend({

  data: (): DataType => {
    return {
      isFetchingChildren: false,
      isCreating: false,

      willCreateTemplate: false,
      templateTitle: '',
      isFetchingTemplate: false,

      templateToDelete: null,
      isDeletingTemplate: false,
      isFetchingClasses: false
    }
  },

  computed: {
    napTemplates() {
      return napCreateModule.napTemplates;
    },
    staffs() {
      return napCreateModule.staffs;
    },
    classes(): NurseryClassResponse[] {
      return napCreateModule.classes;
    },
    classOptions(): Array<{ text: string; value: string | number }> {
      return this.classes.map((c) => {
        return {
          text: `${c.year}年度 ${c.className}`,
          value: c.classId
        }
      });
    },
    selectedChildren() {
      return napCreateModule.selectedChildren;
    },
    childrenOptions() {
      return napCreateModule.childrenOptions;
    },
    staffOptions() {
      return napCreateModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`
        }
      })
    },
    ageOptions() {
      return [
        { age: 0, label: '0歳' },
        { age: 1, label: '1歳' },
        { age: 2, label: '2歳' },
        { age: 3, label: '3歳' },
        { age: 4, label: '4歳' },
        { age: 5, label: '5歳以上' },
      ]
    },
    intervalOptions() {
      return [
        { value: 0, label: '5分' },
        { value: 1, label: '10分' },
        { value: 2, label: '15分' },
        { value: 3, label: '30分' },
        { value: 4, label: '60分' },
      ]
    },
    isCreateValid(): boolean {
      if (this.willCreateTemplate) {
        return (
          this.recorderId !== null &&
          this.selectedChildren.length > 0 &&
          this.templateTitle.trim().length > 0
        );  
      }
      return (
        this.recorderId !== null &&
        this.selectedChildren.length > 0
      );
    },
    date: {
      get() {
        return napCreateModule.date;
      },
      set(value: string) {
        napCreateModule.setDate(value);
        this.fetchClasses()
      }
    },
    inteval: {
      get() {
        return napCreateModule.interval;
      },
      set(value: number) {
        napCreateModule.setInterval(value);
      }
    },
    ages: {
      get() {
        return napCreateModule.ages;
      },
      set(value: number[]) {
        napCreateModule.setAges(value);
      }
    },
    recorderId: {
      get() {
        return napCreateModule.recorderId;
      },
      set(value: number) {
        napCreateModule.setRecorderId(value);
      }
    },
    classIds: {
      get() {
        return napCreateModule.classIds;
      },
      set(value: number[]) {
        const oldValue = [...napCreateModule.classIds];
        napCreateModule.setClassIds(value);

        if (value.length > oldValue.length) {
          const newValues = value.filter((id) => {
            return oldValue.indexOf(id) < 0;
          });

          this.addChildrenWithClassIds(newValues);
        } else {
          const removeValues = oldValue.filter((id) => {
            return value.indexOf(id) < 0;
          });

          this.removeChildrenWithClassId(removeValues);
        }
      }
    }
  },
  
  methods: {
    async listTemplates() {
      this.isFetchingTemplate = true;
      await napCreateModule.listNapTemplates();
      this.isFetchingTemplate = false;
    },
    listStaffs() {
      napCreateModule.listStaffs();
    },
    selectTemplate(t: NurseryNapTemplateResponse) {
      const recorderId = t.recorderId;
      const classIds = t.class.classes.map((c) => c.classId);
      const ages = t.age.ages;
      // TODO: Age関係?
      
      napCreateModule.setRecorderId(recorderId);
      napCreateModule.setClassIds(classIds);
      napCreateModule.setSelectedChildren([]);
      napCreateModule.setAges(ages);

      this.addChildrenWithClassIds(classIds);

      // napCreateModule.setInterval(t.)
    },
    openDeleteModal(t: NurseryNapTemplateResponse | null) {
      this.templateToDelete = t;
    },
    async deleteTemplate() {
      if (this.templateToDelete) {
        this.isDeletingTemplate = true;
        await napCreateModule.deleteNapTemplate(this.templateToDelete.napTemplateId);
        await napCreateModule.listNapTemplates();
        this.templateToDelete = null;
        this.isDeletingTemplate = false;
      }
    },
    async addChildrenWithClassIds(ids: number[]) {
      this.isFetchingChildren = true;
      await Promise.all(ids.map((id) => napCreateModule.addChildrenWithClassIds(id)));
      this.isFetchingChildren = false;
    },
    async removeChildrenWithClassId(ids: number[]) {
      this.isFetchingChildren = true;
      await Promise.all(ids.map((id) => napCreateModule.removeChildrenWithClassId(id)));
      this.isFetchingChildren = false;
    },
    selectChild(child: NurseryChildResponse) {
      napCreateModule.addSelectedChildren(child);
    },
    removeChild(child: NurseryChildResponse) {
      napCreateModule.removeChild(child.childId);
    },
    async create() {
      if (this.willCreateTemplate) {
        this.isCreating = true;
        await this.createTemplate();
      }
      
      this.isCreating = true;
      const response = await napCreateModule.createNap();
      this.isCreating = false;
      if (response && response.napId) {
        this.$router.push(`/nap/${response.napId}/detail`);
      } else {
        alert('エラー: 午睡チェックの作成に失敗しました');
      }
    },
    async createTemplate() {
      await napCreateModule.createNapTemplate(this.templateTitle);
    },
    async fetchClasses() {
      this.isFetchingClasses = true
      await napCreateModule.listClass()
      this.isFetchingClasses = false
    }
  },

  mounted() {
    this.listTemplates();
    this.listStaffs();
    this.fetchClasses()
  }
});
