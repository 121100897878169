
import Vue from "vue";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import ApiGuidance from "@/api/ApiGuidance";
import { GuidancePlanFormItemCategoryItems } from "@/constants/guidance";
import {
  GuidancePlanItemCategoryType,
  NurseryClassResponse,
  NurseryGuidancePlanFormItemListResponse,
  NurseryGuidancePlanFormItemResponse,
} from "chaild-api/lib";
import { classModule } from "@/store/dataModules/classModule";

export default Vue.extend({
  name: "GuidancePlanFormItemList",

  async mounted() {
    await this.listFormItem();
    await this.listClass();
  },

  data() {
    return {
      loading: false,
      categoryItems: GuidancePlanFormItemCategoryItems,
      headers: [
        { text: "項目名", value: "name" },
        { text: "種類", value: "type" },
        { text: "作成日", value: "createdAt" },
        { text: "編集", value: "edit" },
        { text: "削除", value: "delete" },
      ],
      searchFilters: {
        limit: 2000,
        skip: 0,
      },
      itemResponse: null as null | NurseryGuidancePlanFormItemListResponse,
      input: {
        name: "",
        type: "" as GuidancePlanItemCategoryType,
        classIdList: [] as number[],
        itemId: 0 as number,
      },
      headerInput: {
        cols: "",
        rows: "",
      },
      editDialog: false,
    };
  },

  computed: {
    isUpdate: function (): boolean {
      return !!this.input.itemId;
    },
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
  },

  methods: {
    async listFormItem() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.listPlanFormItem({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.itemResponse = response;
        }
      }
      this.loading = false;
    },
    async createFormItem() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.createPlanFormItem({
          nurseryId,
          ...this.input,
          option:
            this.input.type == "grid"
              ? {
                  header: {
                    cols: this.getHeaderArray(this.headerInput.cols),
                    rows: this.getHeaderArray(this.headerInput.rows),
                  },
                }
              : undefined,
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listFormItem();
        }
      }
      this.loading = false;
    },
    async updateFormItem() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.updatePlanFormItem({
          nurseryId,
          ...this.input,
          option:
            this.input.type == "grid"
              ? {
                  header: {
                    cols: this.getHeaderArray(this.headerInput.cols),
                    rows: this.getHeaderArray(this.headerInput.rows),
                  },
                }
              : undefined,
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listFormItem();
        }
      }
      this.loading = false;
    },
    async deleteFormItem(itemId: number) {
      const answer = confirm("削除しますか？");
      if (answer) {
        this.loading = true;
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId && itemId) {
          const response = await ApiGuidance.deletePlanFormItem({
            nurseryId,
            itemId,
          }).catch(() => (this.loading = false));
          if (response) {
            alert("削除しました");
            await this.listFormItem();
          }
        }
        this.loading = false;
      }
    },
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule
          .listClass({
            nurseryId,
          })
          .catch();
      }
    },
    openEditDialog(value?: NurseryGuidancePlanFormItemResponse) {
      if (value) {
        this.setInput(value);
      } else {
        this.clearInput();
      }
      this.editDialog = true;
    },
    getCategryText(value: string): string {
      const item = this.categoryItems.find((c) => c.value == value);
      return item?.text || "";
    },
    clearInput() {
      this.input = {
        name: "",
        type: "text",
        classIdList: [],
        itemId: 0,
      };
      this.headerInput = {
        cols: "",
        rows: "",
      };
    },
    setInput(value: NurseryGuidancePlanFormItemResponse) {
      this.input = {
        name: value.name,
        type: value.type,
        classIdList: value.classList?.map((c) => c.classId) || [],
        itemId: value.id,
      };
      if (value.type == "grid" && value.option) {
        this.headerInput = {
          cols: value.option.header.cols.join(","),
          rows: value.option.header.rows.join(","),
        };
      }
    },
    getHeaderArray(value: string): string[] {
      if (value) {
        return value.split(",");
      } else {
        return [];
      }
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
