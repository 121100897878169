
import Vue from 'vue'
import PageTitle from '@/components/atoms/PageTitle.vue'

export default Vue.extend({
  name: 'NapTemplateList',

  components: {
    PageTitle
  },
})
