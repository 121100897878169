
import Vue from "vue";

import { childDiaryDetailModule } from "@/store/dataModules/childDiaryDetailModule";
import dayjs from "dayjs";
import { attendanceEditModule } from "@/store/dataModules/attendanceEditModule";
import { NurseryAttendanceResponse } from "chaild-api/lib";

interface DataType {
  date: string | null;
  childId: number | null;
}

export default Vue.extend({
  data: (): DataType => {
    return {
      date: null,
      childId: null,
    };
  },

  computed: {
    report() {
      return childDiaryDetailModule.report;
    },
    diary() {
      return childDiaryDetailModule.diary;
    },
    attendance(): NurseryAttendanceResponse | null {
      return attendanceEditModule.attendance;
    },
  },

  methods: {
    formatMealStatus(status: string) {
      if (status === "finished") {
        return "完食";
      } else if (status === "refill") {
        return "おかわり";
      } else if (status === "unfinished") {
        return "少し残した";
      } else if (status === "half") {
        return "半分食べた";
      } else if (status === "little") {
        return "少し食べた";
      } else if (status === "notEat") {
        return "食べなかった";
      } else if (status === "notServed") {
        return "未提供";
      }
    },
    formatDefecation(defecation: string) {
      if (defecation === "hard") {
        return "かたい";
      } else if (defecation === "normal") {
        return "ふつう";
      } else if (defecation === "soft") {
        return "やわらかい";
      }
    },
    async getAttendance(input: { childId: string; date: string }) {
      attendanceEditModule.setChildId(Number(input.childId));
      attendanceEditModule.setDate(input.date);
      await attendanceEditModule.getAttendance();
    },
    formatDate(date: string | null | undefined) {
      if (date) {
        return dayjs(date).format("HH:mm");
      }
      return null;
    },
    formatTemperature(value: number): string {
      return value.toFixed(1);
    },
  },

  mounted() {
    const childId = this.$route.params.childId as string;
    const date = this.$route.params.date as string;

    if (childId && date) {
      try {
        const parsed = parseInt(childId);
        childDiaryDetailModule.setChildId(parsed);
        childDiaryDetailModule.setDate(date);

        childDiaryDetailModule.getDailyReport();
        childDiaryDetailModule.getDiary();

        this.getAttendance({
          childId: childId,
          date: date,
        });

        this.date = date;
        this.childId = parsed;
      } catch {
        return;
      }
    }
  },
});
