
import Vue from "vue";
import { chatEditModule } from "@/store/dataModules/chatEditModule";
import { classModule } from "@/store/dataModules/classModule";
import LocalDataService from "@/service/LocalDataService";
import ValidationMixin from "@/mixins/ValidationMixin";
import Swal from "sweetalert2";
import ApiResource from "@/api/ApiResource";
import {
  GroupChatTemplateResponse,
  NurseryChildResponse,
} from "chaild-api/lib";
import ApiChat from "@/api/ApiChat";
import { chatModule } from "@/store/dataModules/chatModule";
import { groupModule } from "@/store/dataModules/groupModule";

export default Vue.extend({
  name: "NewMessage",

  mixins: [ValidationMixin],

  async mounted() {
    await this.listClasses();
    await this.listGroups();
    await this.listTemplates();

    // 新規作成の場合
    if (!this.isDraft) {
      chatEditModule.setTitle("");
      chatEditModule.setBody("");
      chatEditModule.setClassIds([]);
      chatEditModule.setGroupIds([]);

      // reset validation
      (this.$refs.form as any).reset();
    }
  },

  data() {
    return {
      valid: false,
      file: [] as File[],
      loading: false,
      templateDialog: false,
      isFetchingChildren: false,
    };
  },

  computed: {
    body: {
      get() {
        return chatEditModule.body;
      },
      set(value: string) {
        chatEditModule.setBody(value);
      },
    },
    title: {
      get() {
        return chatEditModule.title;
      },
      set(value: string) {
        chatEditModule.setTitle(value);
      },
    },
    classIds: {
      get() {
        return chatEditModule.classIds;
      },
      set(value: number[]) {
        chatEditModule.setClassIds(value);
      },
    },
    groupIds: {
      get() {
        return chatEditModule.groupIds;
      },
      set(value: number[]) {
        chatEditModule.setGroupIds(value);
      },
    },
    childrenSelectionClassIds: {
      get() {
        return chatEditModule.childrenSelectionClassIds
      },
      set(ids: number[]) {
        const oldValue = [...this.childrenSelectionClassIds];
        chatEditModule.setChildrenSelectionClassIds(ids)

        if (ids.length > oldValue.length) {
          const newValues = ids.filter((id) => {
            return oldValue.indexOf(id) < 0;
          });

          this.addChildrenWithClassIds(newValues);
        } else {
          const removeValues = oldValue.filter((id) => {
            return ids.indexOf(id) < 0;
          });

          this.removeChildrenWithClassId(removeValues);
        }
      }
    },
    childrenSelectionGroupIds: {
      get() {
        return chatEditModule.childrenSelectionGroupIds
      },
      set(ids: number[]) {
        const oldValue = [...this.childrenSelectionGroupIds];
        chatEditModule.setChildrenSelectionGroupIds(ids)

        if (ids.length > oldValue.length) {
          const newValues = ids.filter((id) => {
            return oldValue.indexOf(id) < 0;
          });

          this.addChildrenWithGroupIds(newValues);
        } else {
          const removeValues = oldValue.filter((id) => {
            return ids.indexOf(id) < 0;
          });

          this.removeChildrenWithGroupdId(removeValues);
        }
      }
    },
    targetVariant: {
      get() {
        return chatEditModule.targetVariant;
      },
      set(value: "group" | "children") {
        chatEditModule.setTargetVariant(value);
      },
    },
    selectedChildren() {
      return chatEditModule.selectedChildren;
    },
    childrenOptions() {
      return chatEditModule.childrenOptions;
    },
    classList: function () {
      return classModule.classes.map((c) => {
        return {
          className: `${c.year}年度 ${c.className}`,
          classId: c.classId,
        };
      });
    },
    isDraft: function () {
      if (this.$route.query.id) {
        return true;
      } else {
        return false;
      }
    },
    templates: function (): GroupChatTemplateResponse[] {
      return chatModule.templates;
    },
    groups: function (): any[] {
      return groupModule.groups.map((g) => {
        return {
          name: `${g.year}年度 ${g.name}`,
          groupId: g.groupId,
        };
      });
    },
  },

  methods: {
    async send() {
      this.loading = true;
      const attachmentUrls = await this.getAttachmentUrls();
      chatEditModule.setAttachmentUrls(attachmentUrls);
      const response = await chatEditModule.sendGroupChat();
      if (response) {
        Swal.fire({ title: "送信しました", icon: "success" }).then(() => {
          this.$router.push({ path: "/mail/inbox" });
        });
      } else {
        Swal.fire({ title: "送信に失敗しました", icon: "error" });
      }
      this.loading = false;
    },
    async saveAsDraft() {
      this.loading = true;

      const groupChatId = this.$route.query.id;
      const response = await chatEditModule.saveAsDraft({
        groupChatId: groupChatId ? String(groupChatId) : undefined,
      });
      if (response) {
        this.$router.push({ path: "/mail/inbox" });
      }
      this.loading = false;
    },
    async listClasses() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId: nurseryId,
        });
      }
    },
    async getAttachmentUrls() {
      const resultUrls: string[] = [];
      const nurseryId = LocalDataService.getNurseryId();

      // 添付ファイルのurlを返す
      for (const _file of this.file) {
        if (nurseryId) {
          console.log("filename", _file);
          console.log("filename", _file, name);
          // get presigned url
          const presignedUrl = await ApiResource.getPresignedUrl({
            imageType: "chat",
            filename: _file.name,
            nurseryId: nurseryId,
          }).catch(() => (this.loading = false));

          if (presignedUrl) {
            // upload file & get url
            const uploadResponse = await ApiResource.uploadFile({
              url: presignedUrl.url,
              file: _file,
            }).catch(() => (this.loading = false));

            if (uploadResponse) {
              const url = presignedUrl.url.split("?")[0];
              resultUrls.push(url);
            }
          }
        }
      }
      return resultUrls;
    },
    async deleteChat() {
      this.loading = true;

      const groupChatId = this.$route.query.id;
      if (groupChatId) {
        // 下書きを削除
        const response = await ApiChat.deleteDraftGroupChat({
          groupChatIds: String(groupChatId),
        });
        if (response) {
          this.$router.push({ path: "/mail/inbox" });
        }
      } else {
        // 戻るだけ
        this.$router.push({ path: "/mail/inbox" });
      }
      this.loading = false;
    },
    async listTemplates() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await chatModule
          .listTemplates({
            nurseryId,
          })
          .catch();
      }
      this.loading = false;
    },
    async saveAsTemplate() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        if (this.targetVariant === "group") {
          const response = await ApiChat.createTemplate({
            nurseryId,
            title: chatEditModule.title,
            classIds: chatEditModule.classIds,
            groupIds: chatEditModule.groupIds,
            body: chatEditModule.body,
            attachmentUrls: [],
          }).catch();
          if (response) {
            alert("保存しました");
          }
        } else {
          const childIds = chatEditModule.selectedChildren.map(
            (c) => c.childId
          );
          const response = await ApiChat.createTemplate({
            nurseryId,
            title: chatEditModule.title,
            childIds,
            body: chatEditModule.body,
            attachmentUrls: [],
          }).catch();
          if (response) {
            alert("保存しました");
          }
        }
      }
      this.loading = false;
    },
    async applyTemplate(templateId: string) {
      this.loading = true;
      if (templateId) {
        const response = (await ApiChat.getTemplate({
          templateId,
        }).catch()) as GroupChatTemplateResponse;
        if (response) {
          if (response.toClasses.length > 0 || response.toGroups.length > 0) {
            const classIds = response.toClasses.map((r) => {
              return r.classId;
            });
            const groupIds = response.toGroups.map((g) => {
              return g.groupId;
            });
            chatEditModule.setTitle(response.title || "");
            chatEditModule.setBody(response.body);
            chatEditModule.setClassIds(classIds);
            chatEditModule.setGroupIds(groupIds);
            chatEditModule.setTargetVariant("group");
          } else {
            chatEditModule.setTitle(response.title || "");
            chatEditModule.setBody(response.body);
            chatEditModule.setSelectedChildren(response.toChildren);
            chatEditModule.setTargetVariant("children");
          }
        }
        this.templateDialog = false;
      }
      this.loading = false;
    },
    async deleteTemplate(templateId: string) {
      this.loading = true;
      if (templateId) {
        const response = await ApiChat.deleteTemplate({
          templateId,
        }).catch();
        if (response) {
          alert("テンプレートを削除しました");
          this.templateDialog = false;
        }
      }
      this.loading = false;
    },
    async listGroups() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.loading = true;
        await groupModule.listGroups({
          nurseryId,
        });
        this.loading = false;
      }
    },
    async addChildrenWithClassIds(ids: number[]) {
      this.isFetchingChildren = true;
      await Promise.all(
        ids.map((id) => chatEditModule.addChildrenWithClassIds(id))
      );
      this.isFetchingChildren = false;
    },
    async removeChildrenWithClassId(ids: number[]) {
      this.isFetchingChildren = true;
      await Promise.all(ids.map((id) => chatEditModule.removeChildrenWithClassId(id)));
      this.isFetchingChildren = false;
    },
    async addChildrenWithGroupIds(ids: number[]) {
      this.isFetchingChildren = true;
      await Promise.all(
        ids.map((id) => chatEditModule.addChildrenWithGroupId(id))
      );
      this.isFetchingChildren = false;
    },
    async removeChildrenWithGroupdId(ids: number[]) {
      this.isFetchingChildren = true;
      await Promise.all(ids.map((id) => chatEditModule.removeChildrenWithGroupId(id)));
      this.isFetchingChildren = false;
    },
    selectChild(child: NurseryChildResponse) {
      chatEditModule.addSelectedChildren(child);
    },
    removeChild(child: NurseryChildResponse) {
      chatEditModule.removeChild(child.childId);
    },
  },
});
