
import Vue from 'vue';

import { foodMenusModule } from '@/store/viewModules/food-menu/foodMenusModule';
import dayjs from 'dayjs';

import PageTitle from '@/components/atoms/PageTitle.vue';
import FoodCategoryCreateContainer from '@/containers/food-menu/FoodCategoryCreateContainer.vue';
import { NurseryClassResponse } from 'chaild-api/lib';

interface DataType {
  isCreating: boolean;

  categoryId: number | null;
  body: string;
  note: string;
  allergenIds: number[];
}

export default Vue.extend({
  components: {
    PageTitle,
    FoodCategoryCreateContainer,
  },

  data: (): DataType => ({
    isCreating: false,

    categoryId: null,
    body: '',
    note: '',
    allergenIds: [],
  }),

  computed: {
    menuCategories() {
      return foodMenusModule.menuCategories;
    },
    allergens() {
      return foodMenusModule.allergens;
    },
    creatingDate() {
      return foodMenusModule.creatingDate;
    },
    formattedDate(): string | null {
      if (this.creatingDate) {
        return dayjs(this.creatingDate).format('MM/DD (ddd)');
      }
      return null;
    },
    isDialogOpen() {
      if (this.creatingDate) {
        return true;
      }
      return false;
    },
    creationValid(): boolean {
      if (
        this.categoryId
        && this.body.trim().length > 0
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    async createMenu() {
      if (
        this.categoryId
      ) {
        this.isCreating = true;
        await foodMenusModule.createMenu({
          categoryId: this.categoryId,
          body: this.body,
          note: this.note,
          allergenIds: this.allergenIds,
        });
        this.isCreating = false;
        this.closeDialog();
      }
    },
    clear() {
      this.categoryId = null;
      this.body = '';
      this.note = '';
      this.allergenIds = [];
    },
    closeDialog() {
      this.clear();
      foodMenusModule.setCreatingDate(null);
    },
    openCreateCategoryDialog() {
      foodMenusModule.setIsCreateCategoryDialogOpen(true);
    },
    formatClassNames(classes: NurseryClassResponse[]) {
      return classes.map((c) => c.className).join(',');
    }
  }
});
