import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiClass from "@/api/ApiClass";
import dayjs from "dayjs";

export interface ClassEditState {
  classId: string;
  className: string;
  year: number;
  childrenAge: string;
}

/* eslint-disable */
@Module({ dynamic: true, store, name: "class-edit", namespaced: true })
class ClassEditModule extends VuexModule implements ClassEditState {
  public classId: string = "";
  public className: string = "";
  public childrenAge: string = "";
  public year: number = dayjs().year();

  @Action({ rawError: true })
  public async addClass(input: { nurseryId: number }) {
    const response = await ApiClass.addClass({
      nurseryId: input.nurseryId,
      className: this.className,
      childrenAge: this.childrenAge,
      year: this.year,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateClass(input: { nurseryId: number }) {
    const response = await ApiClass.updateClass({
      nurseryId: input.nurseryId,
      classId: this.classId,
      className: this.className,
      year: this.year,
      childrenAge: this.childrenAge,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteClass(input: { nurseryId: number }) {
    const response = await ApiClass.deleteClass({
      nurseryId: input.nurseryId,
      classId: this.classId,
    });
    if (response) {
      return response;
    }
  }

  @Mutation
  public setClassId(value: string) {
    this.classId = value;
  }

  @Mutation
  public setClassName(value: string) {
    this.className = value;
  }

  @Mutation
  public setChildrenAge(value: string) {
    this.childrenAge = value;
  }

  @Mutation
  public setYear(value: number) {
    this.year = value;
  }
}

export const classEditModule = getModule(ClassEditModule);
