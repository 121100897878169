import axios from "axios";
import ApiClient from "@/api/ApiClient";
import { PreSignedUrlResponse } from "chaild-api/lib";

export default class ApiResource {
  public static async getPresignedUrl(input: {
    imageType: string; // chat / profile
    filename: string;
    nurseryId: number;
  }) {
    const response = await ApiClient.post("/resource", input);
    return response;
  }

  public static async getPresignedUrlForCashbook(input: {
    date?: string; // YYYY-MM-DD
    nurseryId: string;
    filename: string;
  }) {
    const response = await ApiClient.post("/resource/cashbook", input);
    return response;
  }

  public static async uploadFile(input: { url: string; file: File }) {
    axios.defaults.headers.common["Content-Type"] = input.file.type;

    const response = await axios({
      method: "PUT",
      url: input.url,
      headers: {
        "Content-Type": input.file.type,
      },
      data: input.file,
    });

    if (response) {
      return response;
    }
  }

  public static async getDownloadUrl({
    nurseryId,
    path,
  }: {
    nurseryId: string | number;
    path: string;
  }) {
    const response = await ApiClient.post(`/resource/${nurseryId}/download`, {
      objectKey: path,
    });
    return response as PreSignedUrlResponse;
  }
}
