import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { GroupChatResponse, GroupChatTemplateResponse } from "chaild-api/lib";
import ApiChat from "@/api/ApiChat";

export interface ChatState {
  groupChats: GroupChatResponse[];
  drafts: GroupChatResponse[];
  totalItemsCount: number;
  templates: GroupChatTemplateResponse[];
}

@Module({ dynamic: true, store, name: "chat", namespaced: true })
class ChatModule extends VuexModule implements ChatState {
  public groupChats: GroupChatResponse[] = [];
  public drafts: GroupChatResponse[] = [];
  public totalItemsCount = 0;
  public templates: GroupChatTemplateResponse[] = [];

  @Action({ rawError: true })
  public async listGroupChats(input: {
    nurseryId: number;
    limit?: number;
    skip?: number;
    startedAt?: string;
    endedAt?: string;
    title?: string;
    userId?: number | string;
    status?: "inDraft" | "delivered" | "inProgress";
  }) {
    const response = await ApiChat.listGroupChat(input);
    if (input.status == "inDraft") {
      if (response) {
        this.setDrafts(response.groupChats);
        this.setTotalItemsCount(response.totalItemsCount);
        return response;
      }
    } else {
      if (response) {
        this.setGroupChats(response.groupChats);
        this.setTotalItemsCount(response.totalItemsCount);
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async listGroupChatDrafts(input: { nurseryId: number }) {
    const response = await ApiChat.listGroupChatDraft(input);
    if (response) {
      this.setDrafts(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async listTemplates(input: { nurseryId: number }) {
    const response = await ApiChat.listTemplates(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      this.setTemplates(response);
      return response;
    }
  }

  @Mutation
  public setGroupChats(value: GroupChatResponse[]) {
    this.groupChats = value;
  }

  @Mutation
  public setDrafts(value: GroupChatResponse[]) {
    this.drafts = value;
  }

  @Mutation
  public setTotalItemsCount(value: number) {
    this.totalItemsCount = value;
  }

  @Mutation
  public setTemplates(value: GroupChatTemplateResponse[]) {
    this.templates = value;
  }
}

export const chatModule = getModule(ChatModule);
