
import Vue from 'vue';

import { napDetailModule, NapDetailData, poseOptions } from '@/store/dataModules/napDetailModule';
import { NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';
import { NurseryChildResponse, NurseryNapDetailResponse, NurseryNapIndividualOverview } from 'chaild-api/lib';
import dayjs from 'dayjs';

import NapDetailDataCreateContainer from '@/containers/NapDetailDataCreateContainer.vue';
import NapDetailDataEditContainer from '@/containers/NapDetailDataEditContainer.vue';
import NapDetailAirConditionContainer from '@/containers/NapDetailAirConditionContainer.vue';

import ApiNap, { NapMonitoringData } from '@/api/ApiNap';
import LocalDataService from '@/service/LocalDataService';
import { nurseryUserModule } from '@/store/dataModules/nurseryUserModule';
import CommonUtil from '@/utils/CommonUtil';

interface DataType {
  selectedHour: number;
  isUpdating: boolean;

  isDeleteModalOpen: boolean;
  isDeleting: boolean;
}

interface StaffOption {
  userId: number;
  name: string;
}

export default Vue.extend({
  components: {
    NapDetailDataCreateContainer,
    NapDetailDataEditContainer,
    NapDetailAirConditionContainer,
  },

  data: (): DataType => {
    return {
      selectedHour: 0,
      isUpdating: false,

      isDeleteModalOpen: false,
      isDeleting: false,
    };
  },

  computed: {
    nap(): NurseryNapDetailResponse | null {
      return napDetailModule.nap;
    },
    napStatus(): string | null {
      if (this.nap) {
        return this.nap.status;
      }
      return null;
    },
    napData() {
      return napDetailModule.napData;
    },
    staffs() {
      return napDetailModule.staffs;
    },
    children(): NurseryChildResponse[] {
      if (this.nap) {
        return this.nap.children;
      }
      return [];
    },
    staffOptions() {
      return napDetailModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`
        }
      });
    },
    subTitle(): string {
      if (this.nap) {
        const dateLabel = dayjs(this.nap.date).format('YYYY年MM月DD日 (ddd)');
        const classLabel = this.nap.class.all ?
          'すべての年齢' :
          this.nap.class.classes.map((c) => c.className).join(', ');
        const ageLabel = this.nap.age.all ?
          'すべての年齢' :
          this.nap.age.ages.map((a) => a === 5 ? '5歳以上' : `${a}歳`).join(', ');
        return `${dateLabel} ${classLabel} / ${ageLabel}`;
      }
      return '';
    },
    hours(): number[] {
      return [...Array(24)].map((e, i) => {
        return i
      });
    },
    hoursAm(): number[] {
      return [...Array(12)].map((e, i) => {
        return i
      });
    },
    hoursPm(): number[] {
      return [...Array(12)].map((e, i) => {
        return i + 12
      });
    },
    dataCols(): string[] {
      if (this.nap) {
        const start = dayjs(this.nap.createdAt).hour(this.selectedHour).startOf('hour');
        return [...Array(12)].map((e, i) => {
          const time = dayjs(start).add(5 * i, 'minutes').format('HH:mm');
          return time;
        });
      }
      return [];
    },
    comment: {
      get(): string | null {
        if (this.nap) {
          return this.nap.comment;
        }
        return null;
      },
      set(value: string) {
        if (this.nap) {
          const newNap: NurseryNapDetailResponse = {...this.nap};
          newNap.comment = value;
          napDetailModule.setNap(newNap);
        }
      }
    },
    recorder: {
      get(): number | null {
        if (napDetailModule.nap) {
          return napDetailModule.nap.recorderId;
        }
        return null;
      },
      set(value: number) {
        if (this.nap) {
          const newNap: NurseryNapDetailResponse = {...this.nap};
          newNap.recorderId = value;
          napDetailModule.setNap(newNap);
          napDetailModule.updateNap();
        }
      }
    },
  },

  methods: {
    getCurrentClassName(child: NurseryChildResponse) {
      if (this.nap) {
        const date = dayjs(this.nap.date);
        const regulator = date.month() < 4 ? -1 : 0; // ホントは < 3
        // const thisYear = parseInt(dayjs(this.nap.date).format('YYYY')) + regulator;
        const thisYear = parseInt(CommonUtil.getFiscalYear(this.nap.date))
        const find = child.nurseryClasses.find((c) => c.year === thisYear);
        if (find) {
          return find.className;
        }
      }
      return '';
    },
    getLatestStatus(overview: NurseryNapIndividualOverview[]) {
      if (overview.length === 0) {
        return 'none';
      }
      const sorted = [...overview].sort((o1, o2) => {
        const time1 = dayjs(o1.time);
        const time2 = dayjs(o2.time);
        return time2.diff(time1);
      });
      return sorted[0].status;
    },
    getLatestStatusLabel(overview: NurseryNapIndividualOverview[]) {
      const status = this.getLatestStatus(overview);
      if (status === 'none') {
        return '未';
      }

      if (status === 'getUp') {
        return '起';
      }

      return '寝';
    },
    cellClickCreate(childId: number, time: string) {
      if (this.nap) {
        const napData: NapDetailData = {
          childId: childId,
          time: time,
          staffId: this.nap?.recorderId,
          posturalChanged: null,
          status: null,
          additionalNote: [],
          textComment: null,
        };
        napDetailModule.setCreatingNapDetailData(napData);
      }
    },
    cellClickEdit(data: NapMonitoringData, hour: string) {
      const napData = this.getNapData(data, hour);
      if (this.nap && napData) {
        const editData: NapDetailData = {
          childId: data.child.childId,
          time: hour,
          staffId: napData.staff.userId,
          posturalChanged: napData.posturalChanged || null,
          status: napData.status,
          additionalNote: napData.additionalNote || [],
          textComment: '',
        };

        napDetailModule.setEditingNapDetailData(editData);
      }
    },
    getNapData(data: NapMonitoringData, hour: string) {
      const key = hour.replace(':', '');
      const find = data.overviews.find((ov) => ov.time === key);
      if (find) {
        return find;
      }
      return null;
    },
    getNapDataStatus(data: NapMonitoringData, hour: string) {
      const thisData = this.getNapData(data, hour);
      if (thisData) {
        const returnValue: {
          label?: string;
          bg?: string;
          color?: string;
          isIcon?: boolean;
          iconName?: string;
        } = {
          label: '',
        };

        if (thisData.status) {
          switch (thisData.status) {
            case 'sleepIn':
              returnValue.label = '<div class="data-cell-status">入眠</div>';
              returnValue.bg = '#029ec0';
              returnValue.isIcon = false;
              returnValue.color = 'white';
              break;
            case 'faceLeft':
              returnValue.iconName = 'mdi-arrow-left';
              returnValue.isIcon = true;
              returnValue.bg = '#02C07C';
              returnValue.color = 'white';
              break;
            case 'faceRight':
              returnValue.iconName = 'mdi-arrow-right';
              returnValue.bg = '#02C07C';
              returnValue.isIcon = true;
              returnValue.color = 'white';
              break;
            case 'faceDown':
              returnValue.iconName = 'mdi-arrow-down';
              returnValue.isIcon = true;
              returnValue.bg = '#02C07C';
              returnValue.color = 'white';
              break;
            case 'faceUp':
              returnValue.iconName = 'mdi-arrow-up';
              returnValue.isIcon = true;
              returnValue.bg = '#02C07C';
              returnValue.color = 'white';
              break;
            case 'getUp':
              returnValue.label = '<div class="data-cell-status">起床</div>';
              returnValue.bg = '#EE6190';
              returnValue.color = 'white';
              break;
            default:
              break;
          }
        }

        if (thisData.posturalChanged) {
          switch (thisData.posturalChanged) {
            case 'leftToUp':
              returnValue.label = returnValue.label + '<div class="data-cell-pose">左→仰</div>'
              break;
            case 'rightToUp':
              returnValue.label = returnValue.label + '<div class="data-cell-pose">右→仰</div>'
              break;
            case 'downToUp':
              returnValue.label = returnValue.label + '<div class="data-cell-pose">伏→仰</div>'
              break;
            default:
              break;
          }
        }

        returnValue.label = returnValue.label + `<div class="data-cell-staff">${thisData.staff.lastName}</div>`

        return returnValue;
      }
    },
    async saveNap() {
      if (this.nap) {
        this.isUpdating = true;
        await napDetailModule.updateNap();
        this.isUpdating = false;
      }
    },
    async updateNapStatus(status: string) {
      if (this.nap) {
        this.isUpdating = true;
        await napDetailModule.updateNapStatus(status);
        this.isUpdating = false;
      }
    },
    async deleteNap() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.nap) {
        this.isDeleting = true;
        const response = await ApiNap.deleteNap({
          napId: this.nap.napId,
          nurseryId,
        });
        if (response) {
          this.$router.push('/nap/list')
        } else {
          this.isDeleting = false;
          alert('削除に失敗しました');
        }
      }
    },
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      nurseryUserModule.listStaffs();
    }
    const napId = this.$route.params.napId;
    if (napId) {
      
      try {
        const parsed = parseInt(napId);
        await Promise.all([
          napDetailModule.setNapId(parsed),
          napDetailModule.getNap(),
          napDetailModule.listStaffs(),
          napDetailModule.listNapData(),
        ]);

        if (this.nap) {
          const date = dayjs();
          this.selectedHour = date.hour();
        }

      } catch {
        return;
      }
    }
  }
});
