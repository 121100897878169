
import Vue from 'vue';

import {
  napDetailModule,
  NapDetailData,
  poseOptions,
  PoseType,
  NapDataNote,
  additionalNoteOptions,
  NapDataStatus,
  napDataStatusOptions
} from '@/store/dataModules/napDetailModule';

import { NurseryChildResponse, NurseryNapIndividualOverview } from 'chaild-api/lib';
import { NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';
import dayjs from 'dayjs';
import { nurseryUserModule } from '@/store/dataModules/nurseryUserModule';

interface DataType {
  isDeleting: boolean;
  isSaving: boolean;
}

export default Vue.extend({
  computed: {
    nap() {
      return napDetailModule.nap;
    },
    editingData() {
      return napDetailModule.editingNapDetailData;
    },
    shoudlShowDialog() {
      if (this.editingData) {
        return true;
      }
      return false;
    },
    targetChild(): NurseryChildResponse | null {
      if (this.nap && this.editingData) {
        const child = this.nap.children.find((c) => c.childId === this.editingData!.childId);
        if (child) {
          return child;
        }
      }
      return null;
    },
    staffOptions() {
      return napDetailModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`
        }
      });
    },
    staff: {
      get(): number | null {
        if (this.editingData) {
          return this.editingData.staffId;
        }
        return null;
      },
      set(value: number) {
        if (this.editingData) {
          const data = {...this.editingData};
          data.staffId = value;
          napDetailModule.setEditingNapDetailData(data);
        }
      }
    },
    recorder: {
      get(): NurseryUserResponse | null {
        if (this.nap) {
          const find = nurseryUserModule.staffs.find((staff) => staff.userId === this.nap?.recorderId);
          if (find) {
            return find;
          }
        }
        return null;
      },
    },
    poseOptions() {
      return [
        { value: null, label: '変更なし' },
        { value: poseOptions.leftToUp, label: '左向きから仰向けに' },
        { value: poseOptions.downToUp, label: 'うつ伏せから仰向けに' },
        { value: poseOptions.rightToUp, label: '右向きから仰向けに' },
      ];
    },
    pose: {
      get(): PoseType | null {
        if (this.editingData) {
          return this.editingData.posturalChanged
        }
        return null;
      },
      set(value: PoseType) {
        if (this.editingData) {
          const data = {...this.editingData};
          data.posturalChanged = value;
          napDetailModule.setEditingNapDetailData(data);
        }
      }
    },
    // 状態(hug:抱っこ, sleepIn:就寝, getUp:起床, faceUp:仰向け, faceDown:うつ伏せ, faceRight:右向き, faceLeft:左向き)
    statusOptions() {
      if (this.editingData) {
        const find = napDetailModule.napData.find((data) => data.child.childId === this.editingData?.childId);
        if (find) {
          const divTimeStr = this.editingData?.time as string
          const divTime = parseInt(divTimeStr.replace(':', '')); 
          const overviews = find.overviews.filter((ov) => {
            const int1 = parseInt(ov.time);
            return int1 < divTime;
          });

          const latestStatus = this.getLatestStatus(overviews);
          if (latestStatus === 'getUp') {
            return [
              { value: napDataStatusOptions.sleepIn, label: '就寝', color: 'primary' },
              { value: napDataStatusOptions.hug, label: '抱っこ寝', color: 'primary' },
            ];
          }
        }
      }

      return [
        { value: napDataStatusOptions.getUp, label: '起床', color: '#EE6190' },
        { value: napDataStatusOptions.sleepIn, label: '就寝', color: 'primary' },
        { value: napDataStatusOptions.hug, label: '抱っこ寝', color: 'primary' },
        { value: napDataStatusOptions.faceUp, label: 'mdi-arrow-up', color: '#02C07C', isArrow: true },
        { value: napDataStatusOptions.faceDown, label: 'mdi-arrow-down', color: '#02C07C', isArrow: true },
        { value: napDataStatusOptions.faceRight, label: 'mdi-arrow-right', color: '#02C07C', isArrow: true },
        { value: napDataStatusOptions.faceLeft, label: 'mdi-arrow-left', color: '#02C07C', isArrow: true },
      ];
    },
    status: {
      get(): NapDataStatus | null {
        if (this.editingData) {
          return this.editingData.status
        }
        return null;
      },
      set(value: NapDataStatus) {
        if (this.editingData) {
          const data = {...this.editingData};
          data.status = value;
          napDetailModule.setEditingNapDetailData(data);
        }
      }
    },
    // 備考(くしゃみ:sneeze, 咳:cough, 熱:fever, 発汗:sweat, しゃっくり:hiccup, 鼻水:runnyNose, 呼吸の乱れ:breathDisorder,嘔吐:vomiting)
    noteOptions() {
      return [
        { value: additionalNoteOptions.sneeze, label: 'くしゃみ' },
        { value: additionalNoteOptions.cough, label: '咳' },
        { value: additionalNoteOptions.fever, label: '発熱' },
        { value: additionalNoteOptions.sweat, label: '発汗' },
        { value: additionalNoteOptions.hiccup, label: 'しゃっくり' },
        { value: additionalNoteOptions.runnyNose, label: '鼻水' },
        { value: additionalNoteOptions.breathDisorder, label: '呼吸の乱れ' },
        { value: additionalNoteOptions.vomiting, label: '嘔吐' },
      ];
    },
    additionalNote: {
      get(): NapDataNote[] {
        if (this.editingData) {
          return this.editingData.additionalNote
        }
        return [];
      },
      set(value: NapDataNote[]) {
        if (this.editingData) {
          const data = {...this.editingData};
          data.additionalNote = value;
          napDetailModule.setEditingNapDetailData(data);
        }
      }
    },
    textComment: {
      get(): string | null {
        if (this.editingData) {
          return this.editingData.textComment;
        }
        return null;
      },
      set(value: string | null) {
        if (this.editingData) {
          const data = {...this.editingData};
          data.textComment = value;
          napDetailModule.setEditingNapDetailData(data);
        }
      }
    },
  },

  data: (): DataType => {
    return {
      isDeleting: false,
      isSaving: false,
    }
  },

  methods: {
    close() {
      napDetailModule.setEditingNapDetailData(null);
    },
    setStatus(value: NapDataStatus) {
      if (this.editingData) {
        const data = {...this.editingData};
        data.status = value;
        napDetailModule.setEditingNapDetailData(data);
      }
    },
    getLatestStatus(overview: NurseryNapIndividualOverview[]) {
      if (overview.length === 0) {
        return 'none';
      }
      const sorted = [...overview].sort((o1, o2) => {
        const time1 = dayjs(o1.time);
        const time2 = dayjs(o2.time);
        return time2.diff(time1);
      });
      return sorted[0].status;
    },
    async save() {
      // napDetailModule.createData();
      this.isSaving = true;
      await napDetailModule.editData();
      this.isSaving = false;
    },
    async deleteData() {
      this.isDeleting = true;
      await napDetailModule.deleteData();
      this.isDeleting = false;
    }
  },
})
