
import Vue from 'vue'

export default Vue.extend({
  name: 'maintenance',

  computed: {
    maintenanceMessage(): string {
      const message = process.env.VUE_APP_MAINTENANCE_MESSAGE;
      return message ? message : '';
    },
  }
})
