import ApiClient from '@/api/ApiClient';

export default class ApiDiarySetting {

  public static async listDiarySettings(input: {
    nurseryId: number;
  }) {
    const response = await ApiClient.get(`/nursery/${input.nurseryId}/diary-setting`, null);
    return response;
  }

  public static async updateDiarySettings(input: {
    nurseryId: number;
    classDiaryConfirmation: {
      targetClassIds: number[];
      targetAges: number[];
    };
    schoolDiaryConfirmation: {
      targetClassIds: number[];
    };
  }) {
    const response = await ApiClient.put(`/nursery/${input.nurseryId}/diary-setting`, input);
    return response;
  }
}