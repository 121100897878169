import ApiClient from "@/api/ApiClient";
import {
  MealAllergenResponse,
  MealCategoryResponse,
  MealPlanResponse,
} from "chaild-api/lib";

export interface ImportMealPlanStatus {
  filePath: string;
  year: number;
  month: number;
  status: string;
  filename: string;
  createdAt: string;
  updatedAt: string;
}

export default class ApiMeal {
  // ==========
  // Allergen
  // ==========
  public static async listAllergen({ nurseryId }: { nurseryId: number }) {
    try {
      const response = await ApiClient.get(`/meal/allergen`, {
        nurseryId,
      });
      return response as MealAllergenResponse[];
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async createAllergen({
    nurseryId,
    name,
  }: {
    nurseryId: number;
    name: string;
  }) {
    const response = await ApiClient.post(`/meal/allergen`, {
      nurseryId,
      name,
    });
    // return response as AllergicFoodBulkResponse;
  }

  public static async updateAllergen({
    allergenId,
    name,
  }: {
    allergenId: number;
    name: string;
  }) {
    const response = await ApiClient.post(`/meal/allergen/${allergenId}`, {
      name,
    });
    // return response as AllergicFoodBulkResponse;
  }

  public static async deleteAllergen({ allergenId }: { allergenId: number }) {
    const response = await ApiClient.delete(
      `/meal/allergen/${allergenId}`,
      null
    );
    // return response as AllergicFoodBulkResponse;
  }

  // ==========
  // Meal Category
  // ==========
  public static async listMealCategories({ nurseryId }: { nurseryId: number }) {
    try {
      const response = await ApiClient.get(`/meal/category`, {
        nurseryId,
      });
      return response as MealCategoryResponse[];
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async createMealCategory({
    nurseryId,
    name,
    targetClassIds,
  }: {
    nurseryId: number;
    name: string;
    targetClassIds: number[];
  }) {
    try {
      const response = await ApiClient.post(`/meal/category`, {
        nurseryId,
        name,
        targetClassIds,
      });
      return response as MealCategoryResponse;
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async updateMealCategories({
    categories,
  }: {
    categories: Array<{
      categoryId: number;
      name: string;
      targetClassIds: number[];
    }>;
  }) {
    const response = await ApiClient.put(`/meal/category`, {
      categories,
    });
    // return response as AllergicFoodBulkResponse;
  }

  public static async deleteMealCategories({
    categoryIds,
  }: {
    categoryIds: number[];
  }) {
    const response = await ApiClient.delete(`/meal/category`, {
      categoryIds,
    });
    // return response as AllergicFoodBulkResponse;
  }

  // ==========
  // Meal Plan
  // ==========
  public static async listMealPlans({
    nurseryId,
    yearMonth,
  }: {
    nurseryId: number;
    yearMonth: string;
  }) {
    try {
      const response = await ApiClient.get(`/meal/plan`, {
        nurseryId,
        yearMonth,
      });
      return response as MealPlanResponse[];
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async createMealPlan({
    nurseryId,
    date,
    categoryId,
    body,
    note,
    allergenIds,
  }: {
    nurseryId: number;
    date: string;
    categoryId: number;
    body: string;
    note: string;
    allergenIds: number[];
  }) {
    try {
      const response = await ApiClient.post(`/meal/plan`, {
        nurseryId,
        date,
        categoryId,
        body,
        note,
        allergenIds,
      });
      return response as MealPlanResponse;
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async getMealPlan({ planId }: { planId: number }) {
    const response = await ApiClient.get(`/meal/plan/${planId}`, null);
    // return response as AllergicFoodBulkResponse;
  }

  public static async updateMealPlan({
    planId,
    date,
    categoryId,
    body,
    note,
    allergenIds,
  }: {
    planId: number;
    date: string;
    categoryId: number;
    body: string;
    note: string;
    allergenIds: number[];
  }) {
    try {
      const response = await ApiClient.put(`/meal/plan/${planId}`, {
        date,
        categoryId,
        body,
        note,
        allergenIds,
      });
      return response as MealPlanResponse;
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async deleteMealPlan({ planId }: { planId: number }) {
    try {
      const response = await ApiClient.delete(`/meal/plan/${planId}`, {});
      return response as { message: string };
    } catch {
      return null;
    }
    return null;
  }

  // ==========
  // Meal Test
  // ==========
  public static async createMealTest({
    planId,
    flavor,
    texture,
    freshness,
    arrangement,
    color,
    quantity,
    hasNoForeignMatter,
    hasProperHeat,
    hasNoOdor,
    hasProperTaste,
    note,
    testedBy,
    testedAt,
  }: {
    planId: number;
    flavor: number;
    texture: number;
    freshness: number;
    arrangement: number;
    color: number;
    quantity: number;
    hasNoForeignMatter: boolean;
    hasProperHeat: boolean;
    hasNoOdor: boolean;
    hasProperTaste: boolean;
    note: string;
    testedBy: number;
    testedAt: string;
  }) {
    try {
      const response = await ApiClient.post(`/meal/test`, {
        planId,
        flavor,
        texture,
        freshness,
        arrangement,
        color,
        quantity,
        hasNoForeignMatter,
        hasProperHeat,
        hasNoOdor,
        hasProperTaste,
        note,
        testedBy,
        testedAt,
      });
      return response as MealPlanResponse;
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async updateMealTest({
    testId,
    flavor,
    texture,
    freshness,
    arrangement,
    color,
    quantity,
    hasNoForeignMatter,
    hasProperHeat,
    hasNoOdor,
    hasProperTaste,
    note,
    testedBy,
    testedAt,
  }: {
    testId: number;
    flavor: number;
    texture: number;
    freshness: number;
    arrangement: number;
    color: number;
    quantity: number;
    hasNoForeignMatter: boolean;
    hasProperHeat: boolean;
    hasNoOdor: boolean;
    hasProperTaste: boolean;
    note: string;
    testedBy: number;
    testedAt: string;
  }) {
    try {
      const response = await ApiClient.put(`/meal/test/${testId}`, {
        flavor,
        texture,
        freshness,
        arrangement,
        color,
        quantity,
        hasNoForeignMatter,
        hasProperHeat,
        hasNoOdor,
        hasProperTaste,
        note,
        testedBy,
        testedAt,
      });
      return response as MealPlanResponse;
    } catch {
      return null;
    }
    // return response as AllergicFoodBulkResponse;
  }

  public static async deleteMealTest({ testId }: { testId: number }) {
    try {
      const response = await ApiClient.delete(`/meal/test/${testId}`, {});
      return response as { message: string };
    } catch {
      return null;
    }
  }

  public static async getUploadUrl({
    nurseryId,
    year,
    month,
    filename,
  }: {
    nurseryId: number;
    year: number;
    month: number;
    filename: string;
  }) {
    try {
      const response = await ApiClient.postAdmin(`/meal/plan/upload`, {
        nurseryId,
        year,
        month,
        filename,
      });
      return response as {
        url: string;
        filePath: string;
      };
    } catch {
      return null;
    }
  }

  public static async listImportStatuses({
    nurseryId,
    skip,
    limit,
    year,
    month,
  }: {
    nurseryId: number;
    skip: number;
    limit: number;
    year: number;
    month: number;
  }) {
    const correctedYear = month <= 3 ? year + 1 : year;
    try {
      const response = await ApiClient.getAdmin(`/meal/plan/upload`, {
        nurseryId,
        year: correctedYear,
        month,
        skip,
        limit,
      });
      return response as {
        items: ImportMealPlanStatus[];
        totalItemsCount: number;
      };
    } catch {
      return null;
    }
  }
}
