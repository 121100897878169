var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('page-title',{attrs:{"value":`${_vm.childName} の成長記録`}}),_c('div',{staticClass:"my-4",staticStyle:{"width":"200px"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","suffix":"年度","type":"number"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),(_vm.recordRequests.length > 0)?_c('div',{staticClass:"py-2"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.isSaving},on:{"click":_vm.saveRecords}},[_vm._v("保存する")])],1):_vm._e(),(_vm.isSaving)?_c('div',{staticClass:"my-2"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{staticClass:"children-table"},[_vm._m(0),_vm._l((_vm.recordsWithMonth),function(r,i){return _c('child-growth-record-row',{key:`row-${i}`,attrs:{"record":r.record,"month":r.month},on:{"inputHeight":function($event){return _vm.inputHeight($event, r)},"inputWeight":function($event){return _vm.inputWeight($event, r)},"inputRemarks":function($event){return _vm.inputRemarks($event, r)}}})})],2),(_vm.growthRecord && _vm.growthRecord.records.length > 0)?_c('div',[_c('div',[_c('g-chart',{attrs:{"type":"ComboChart","data":_vm.heightChartData,"options":{
          colors:['transparent', _vm.colorChartArea, _vm.colorPrimary],
          seriesType: 'area',
          isStacked: true,
          series: {
            0: { lineWidth: 0, enableInteractivity: false, visibleInLegend: false },
            1: { lineWidth: 0, enableInteractivity: false, labelInLegend: 'パーセンタイル値 3% ~ 97%の範囲'},
            2: { lineWidth: 2, pointSize: 6, type: 'line' },
          },
          height: 700
        }}})],1),_c('div',[_c('g-chart',{attrs:{"type":"ComboChart","data":_vm.weightChartData,"options":{
          colors:['transparent', _vm.colorChartArea, _vm.colorPrimary],
          seriesType: 'area',
          isStacked: true,
          series: {
            0: { lineWidth: 0, enableInteractivity: false, visibleInLegend: false },
            1: { lineWidth: 0, enableInteractivity: false, labelInLegend: 'パーセンタイル値 3% ~ 97%の範囲'},
            2: { lineWidth: 2, pointSize: 6, type: 'line' },
          },
          height: 700
        }}})],1)]):_c('div',[_c('v-card',{staticClass:"no-chart-card",attrs:{"outlined":""}},[_vm._v(" 成長記録のデータを入力すると、グラフが表示されます。 ")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"children-table__headers"},[_c('div',{staticClass:"children-table__header col-month"}),_c('div',{staticClass:"children-table__header col-height"},[_vm._v("身長 (cm)")]),_c('div',{staticClass:"children-table__header col-weight"},[_vm._v("体重 (kg)")]),_c('div',{staticClass:"children-table__header col-other"},[_vm._v("備考")])])
}]

export { render, staticRenderFns }