
import Vue from 'vue';
import draggable from "vuedraggable";
import ColorPicker from '@/components/molecules/ColorPicker.vue';
import HourMinuteInput from '@/components/molecules/HourMinuteInput.vue'
import {
  shiftPatternModule,
  WorkDescriptions,
  createTimeTableRequest,
} from '@/store/viewModules/work-schedule/shiftPatternModule';
import {
  workDescriptionModule
} from '@/store/viewModules/work-schedule/workDescriptionModule';
import ApiShift, { ShiftTimeTableRequest, ShiftPatternUpdateRequest } from '@/api/ApiShift'
import { ShiftPatternSettingResponse, ShiftTimetableObject, ShiftTimetableSettingObject } from 'chaild-api/lib';
import ValidationMixin from '@/mixins/ValidationMixin';

interface DataType {
  isFetching: boolean;
  isCreateDialogOpen: boolean;
  newPatternTitle: string;
  newPatternShorten: string;
  newPatternColor: string;
  newPatternTimeTables: ShiftTimeTableRequest[];
  isCreatingNewPattern: boolean;
  hours: number[];
  minutes: number[];

  isUpdatingPattern: boolean;
  editingPattern: ShiftPatternUpdateRequest | null;

  isDeletingPattern: boolean;
  deletingPattern: ShiftPatternSettingResponse | null;
}

const hours = [...Array(12)].map((emp, i) => i)
const minutes = [...Array(4)].map((emp, i) => i * 15)
const descriptionsMap = Object.keys(WorkDescriptions).map((key) => {
  return {
    text: WorkDescriptions[key],
    value: key
  }
})

export default Vue.extend({
  components: {
    ColorPicker,
    HourMinuteInput,
    draggable,
  },
  mixins: [ValidationMixin],
  data: (): DataType => {
    return {
      isFetching: false,
      isCreateDialogOpen: false,
      newPatternTitle: '',
      newPatternShorten: '',
      newPatternColor: '#029ec0',
      newPatternTimeTables: [],
      isCreatingNewPattern: false,
      hours,
      minutes,

      isUpdatingPattern: false,
      editingPattern: null,

      isDeletingPattern: false,
      deletingPattern: null,
    };
  },
  computed: {
    isNewPatternValid() {
      return (
        this.newPatternTitle.length > 0
        && this.newPatternShorten.length > 0
      )
    },
    shiftPatterns: {
      get(): ShiftPatternSettingResponse[] {
        return shiftPatternModule.shiftPatterns
      },
      async set(value: ShiftPatternSettingResponse[]) {
        if(value) {
          const shiftPatternIds = value.map((item) => item)
          shiftPatternModule.setShiftPatterns(shiftPatternIds)
          
          this.isFetching = true
          await shiftPatternModule.sortShiftPatterns()
          await shiftPatternModule.listShiftPatterns()
          this.isFetching = false
        }
      }
    },
    workDescriptions() {
      return workDescriptionModule.workDescriptions
    },
    workDescriptionOptions(): Record<string, any>[] {
      return [
        ...this.workDescriptions.map((wd) => ({
          text: wd.name,
          value: wd.workDescriptionId
        })),
      ]
    },
    descriptions(): Record<string, any>[] {
      return [
        ...descriptionsMap
      ]
    },
    isEditDialogOpen: {
      get() {
        return this.editingPattern !== null
      },
      set(value: boolean) {
        if (!value) {
          this.editingPattern = null
        }
      }
    },
    isDeleteDialogOpen: {
      get() {
        return this.deletingPattern !== null
      },
      set(value: boolean) {
        if (!value) {
          this.deletingPattern = null
        }
      }
    },
  },
  methods: {
    createNewTimeTable() {
      const timetable = createTimeTableRequest();
      this.newPatternTimeTables.push(timetable)
    },
    removeTimetable(index: number) {
      this.newPatternTimeTables.splice(index, 1)
    },
    setEditingPattern(pattern: ShiftPatternSettingResponse) {
      this.editingPattern = {
        shiftPatternId: pattern.shiftPatternId,
        name: pattern.name,
        abbreviation: pattern.abbreviation,
        color: pattern.color,
        timetables: pattern.timetables.map((tt) => ({
          startMin: tt.startMin,
          endMin: tt.endMin,
          comment: tt.comment,
          workDescriptionId: tt.workDescription?.workDescriptionId,
          category: tt.category,
          name: tt.name,
          abbreviation: tt.abbreviation,
          color: tt.color
        }))
      }
    },
    async createShiftPattern() {
      if (this.isNewPatternValid) {
        this.isCreatingNewPattern = true
        const timetables = this.newPatternTimeTables.map((tt) => {
          if (tt.workDescriptionId) {
            const find = this.workDescriptions.find((wd) => wd.workDescriptionId === tt.workDescriptionId)
            if (find) {
              tt.name = find.name
              tt.abbreviation = find.abbreviation
              tt.color = find.color
            }
          }
          return tt
        })
        await shiftPatternModule.createShiftPattern({
          name: this.newPatternTitle,
          abbreviation: this.newPatternShorten,
          color: this.newPatternColor,
          timetables: timetables
        })
        this.isCreatingNewPattern = false

        this.isFetching = true
          await shiftPatternModule.sortShiftPatterns()
          this.isFetching = false

          this.isFetching = true
          await shiftPatternModule.listShiftPatterns()
          this.isFetching = false

        this.isCreateDialogOpen = false
        this.newPatternColor = '#029ec0'
        this.newPatternTitle = ''
        this.newPatternShorten = ''
        this.newPatternTimeTables = []
      }
    },
    async updateShiftPattern() {
      if (this.editingPattern) {
        this.isUpdatingPattern = true
        await shiftPatternModule.updateShiftPattern(this.editingPattern)
        this.isUpdatingPattern = false
        this.editingPattern = null
      }
    },
    insertTimetableToEditing() {
      if (this.editingPattern) {
        const timetable = createTimeTableRequest();
        (this.editingPattern.timetables as Array<ShiftTimetableSettingObject | ShiftTimeTableRequest>).push(timetable)
      }
    },
    removeTimetableFromEditing(index: number) {
      if (this.editingPattern) {
        this.editingPattern.timetables.splice(index, 1)
      }
    },
    async deletePattern() {
      if (this.deletingPattern) {
        this.isDeletingPattern = true
        await shiftPatternModule.deleteShiftPattern(this.deletingPattern.shiftPatternId)
        this.isDeletingPattern = false
        this.isFetching = true
          await shiftPatternModule.sortShiftPatterns()
          this.isFetching = false

          this.isFetching = true
          await shiftPatternModule.listShiftPatterns()
          this.isFetching = false
        this.deletingPattern = null
      }
    },
    selectWorkDescriptionInEdit(workDescriptionId: number, index: number) {
      if (this.editingPattern) {
        this.editingPattern.timetables = this.editingPattern.timetables.map((tt, i) => {
          if (i === index) {
            const find = this.workDescriptions.find((wd) => wd.workDescriptionId === workDescriptionId)
            if (find) {
              tt.name = find.name
              tt.abbreviation = find.abbreviation
              tt.color = find.color
            }
          }
          return tt
        })
      }
    }
  },
  async mounted() {
    this.isFetching = true
    await Promise.all([
      workDescriptionModule.listWorkDescriptions(),
      shiftPatternModule.listShiftPatterns()
    ])
    this.isFetching = false
  }
})
