
import Vue from "vue";
export default Vue.extend({
  name: "KanaList",

  props: {
    kanaArray: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      listA: ["わ", "ら", "や", "ま", "は", "な", "た", "さ", "か", "あ"],
      listI: ["-", "り", "-", "み", "ひ", "に", "ち", "し", "き", "い"],
      listU: ["を", "る", "ゆ", "む", "ふ", "ぬ", "つ", "す", "く", "う"],
      listE: ["-", "れ", "-", "め", "へ", "ね", "て", "せ", "け", "え"],
      listO: ["ん", "ろ", "よ", "も", "ほ", "の", "と", "そ", "こ", "お"],
    };
  },

  methods: {
    hasChild(kana: string): boolean {
      return this.kanaArray.includes(kana);
    },
    clicked(value: string) {
      this.$emit("click", value);
    },
  },
});
