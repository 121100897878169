import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiSchoolService from '@/api/ApiSchoolService';
import { NurseryGrowthDetailResponse, UniqueServiceMastResponse, UniqueServiceRegistrationDetailResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';

// perUse: 'perUse', // 回数
// perUseMonth: 'useMonth', // 利用月額
// monthly: 'monthly' // 固定月額


@Module({ dynamic: true, store, name: 'school-service-usage', namespaced: true })
class SchoolServiceUsageModule extends VuexModule {
  public usages: UniqueServiceRegistrationDetailResponse[] = [];
  public monthlyUsages: UniqueServiceRegistrationDetailResponse[] = [];
  public services: UniqueServiceMastResponse[] = [];

  @Action
  public async listUsages({
    date,
  }: {
    date: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiSchoolService.listSchoolServiceUsageWithDate({
        nurseryId,
        date,
        serviceType: 'perUse',
      });
  
      if (response) {
        this.setUsages(response.data);
      }
    }
  }

  @Mutation
  public setUsages(usages: UniqueServiceRegistrationDetailResponse[]) {
    this.usages = usages;
  }

  @Action
  public async listMonthlyUsages({
    date,
  }: {
    date: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const [monthlyResponse, useMonthResponse] = await Promise.all([
        ApiSchoolService.listSchoolServiceUsageWithDate({
          nurseryId,
          date,
          serviceType: 'monthly',
        }),
        ApiSchoolService.listSchoolServiceUsageWithDate({
          nurseryId,
          date,
          serviceType: 'useMonth',
        }),
      ]);

      const unique = [...(monthlyResponse?.data || [])].map((data) => {
        const other = [...(useMonthResponse?.data || [])].find((od) => {
          if (od.child.childId === data.child.childId) {
            return true;
          }
          return false;
        });

        if (other) {
          data.uniqueServices = [
            ...data.uniqueServices,
            ...other.uniqueServices,
          ];
        }
        return data;
      });

      this.setMonthlyUsages(unique);
    }
  }

  @Mutation
  public setMonthlyUsages(usages: UniqueServiceRegistrationDetailResponse[]) {
    this.monthlyUsages = usages;
  }

  @Action
  public async registerUsage({
    childId,
    uniqueServiceId,
    date,
  }: {
    childId: number;
    uniqueServiceId: number | string;
    date: string;
  }) {
    try {
      const response = await ApiSchoolService.registerSchoolServiceUsage({ childId, uniqueServiceId, date });
      if (response) {
        this.replaceUsage(response);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  @Action
  public async deleteUsage({
    childId,
    registrationId
  }: {
    childId: number;
    registrationId: number | string;
  }) {
    const response = await ApiSchoolService.deleteSchoolServiceUsage({ childId, registrationId });
    if (response) {
      this.replaceUsage(response);
    }
  }

  @Mutation
  public replaceUsage(usage: UniqueServiceRegistrationDetailResponse) {
    this.usages = this.usages.map((u) => {
      if (u.child.childId === usage.child.childId) {
        return usage;
      }
      return u;
    });

    this.monthlyUsages = this.monthlyUsages.map((u) => {
      if (u.child.childId === usage.child.childId) {
        return usage;
      }
      return u;
    });
  }
}

export const schoolServiceUsageModule = getModule(SchoolServiceUsageModule);