
import Vue from 'vue'
import PageTitle from '@/components/atoms/PageTitle.vue'
import ClassPicker from '@/components/atoms/ClassPicker.vue'
import AgePicker from '@/components/atoms/AgePicker.vue'
import DatePicker from '@/components/atoms/DatePicker.vue'

import LocalDataService from '@/service/LocalDataService'
import { classModule } from '@/store/dataModules/classModule'
import { NurseryClassResponse, NurseryNapResponse } from 'chaild-api/lib'

import { napListModule } from '@/store/dataModules/napListModule';
import dayjs from 'dayjs'

interface DataType {
  isSearching: boolean;
}

export default Vue.extend({
  name: 'NapList',

  // todo: 午睡チェックの右上おしたら、新規作成を挟む（そこでテンプレートも読み込める）
  components: {
    PageTitle,
    ClassPicker,
    AgePicker,
    DatePicker,
  },

  data: (): DataType => {
    return {
      isSearching: false,
    };
  },

  computed: {
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    napList(): NurseryNapResponse[] {
      return napListModule.naps;
    },
    from: {
      get(): string | null {
        return napListModule.from;
      },
      set(date: string) {
        napListModule.setFrom(date);
      },
    },
    to: {
      get(): string | null {
        return napListModule.to;
      },
      set(date: string) {
        napListModule.setTo(date);
      },
    },
    classId: {
      get(): number | null {
        return napListModule.classId;
      },
      set(value: number | null) {
        napListModule.setClassId(value);
      }
    },
    age: {
      get(): number | null {
        return napListModule.age;
      },
      set(value: number | null) {
        napListModule.setAge(value);
      }
    },
  },

  methods: {
    async search() {
      this.isSearching = true;
      await napListModule.listNaps();
      this.isSearching = false;
    },
    formatTarget(nap: NurseryNapResponse) {
      const classLabel = nap.class.all ?
        'すべての年齢' :
        nap.class.classes.map((c) => c.className).join(', ');
      const ageLabel = nap.age.all ?
        'すべての年齢' :
        nap.age.ages.map((a) => a === 5 ? '5歳以上' : `${a}歳`).join(', ');
      return `${classLabel} / ${ageLabel}`;
    },
    formatInterval(value: number) {
      switch (value) {
        case 0:
          return '5分';
        case 1:
          return '10分';
        case 2:
          return '15分';
        case 3:
          return '30分';
        case 4:
          return '60分';
        default:
          return '';
      }
    },
    formartStatus(value: string) {
      switch (value) {
        case 'inProgress':
          return '午睡中';
        case 'finished':
          return '午睡終了';
        case 'applied':
          return '申請済み';
        default:
          return '';
      }
    },
    formartDate(value: string | null | undefined) {
      if (value) {
        return dayjs(value).format('MM/DD HH:mm');
      }
      return '';
    },
    openNewTab(value: string) {
      window.open(value, '_blank');
    },
  },

  mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      classModule.listClass({
        nurseryId: nurseryId,
      });

      this.search();
    }
  }
})
