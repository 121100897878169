import Vue from "vue";

export const CharType = {
  zenkaku: "zenkaku", // 全角文字（ひらがな・カタカナ・漢字 etc.）
  hiragana: "hiragana", // 全角ひらがな
  katakana: "katakana", // 全角カタカナ
  alphanumeric: "alphanumeric", // 半角英数字（大文字・小文字）
  numeric: "numeric", // 半角数字
  alpha: "alpha", // 半角英字（大文字・小文字）
  upper: "upper", // 半角英字（大文字のみ）
  lower: "lower", // 半角英字（小文字のみ）
} as const;
export type CharType = typeof CharType[keyof typeof CharType];
function checkCharType(input: string, charType: CharType): boolean {
  switch (charType) {
    // 全角文字（ひらがな・カタカナ・漢字 etc.）
    case "zenkaku":
      // eslint-disable-next-line no-control-regex
      return !!input.match(/^[^\x01-\x7E\xA1-\xDF]+$/);
    // 全角ひらがな
    case "hiragana":
      return !!input.match(/^[\u3041-\u3096]+$/);
    // 全角カタカナ
    case "katakana":
      return !!input.match(/^[\u30a1-\u30fc\u3000\u0020]+$/);
    // 半角英数字（大文字・小文字）
    case "alphanumeric":
      return !!input.match(/^[0-9a-zA-Z]+$/);
    // 半角数字
    case "numeric":
      return !!input.match(/^[0-9]+$/);
    // 半角英字（大文字・小文字）
    case "alpha":
      return !!input.match(/^[a-zA-Z]+$/);
    // 半角英字（大文字のみ）
    case "upper":
      return !!input.match(/^[A-Z]+$/);
    // 半角英字（小文字のみ）
    case "lower":
      return !!input.match(/^[a-z]+$/);
  }
  return false;
}

export default Vue.extend({
  name: "validation-mixin",

  methods: {
    required(text: string): string | boolean {
      return !!text || "必須";
    },
    arrayRequired(array: any[]): boolean {
      return array.length > 0;
    },
    isPdf(file?: File): string | boolean {
      if (!file) {
        return true;
      }
      return file?.type == "application/pdf" || "PDFファイルのみ選択可能";
    },
    isEmail(text: string): boolean {
      if (!text) {
        return true;
      }
      return (
        text.match(
          "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
        ) !== null
      );
    },
    isZip(text: string): boolean {
      if (!text) {
        return true;
      }
      return text.match("[0-9]{3}-[0-9]{4}") !== null;
    },
    isTel(text: string): boolean {
      if (!text) {
        return true;
      }
      return text.match(/0\d{1,4}-\d{1,4}-\d{4}/) !== null;
    },
    isKatakana(text: string): boolean {
      if (!text) {
        return true;
      }
      return text.match(/^[\u30a1-\u30fc\u0020\u3000]+$/) !== null;
    },
    isNaturalNumber(num: number): boolean {
      if (num == undefined || num == null) {
        return true;
      }
      if (num > 0) {
        return true;
      } else {
        return false;
      }
    },
    isNotMinus(num: number): boolean {
      if (num == undefined || num == null) {
        return true;
      }
      if (num >= 0) {
        return true;
      } else {
        return false;
      }
    },
    isHour(num: number): boolean {
      if (num >= 0 && num < 24) {
        return true;
      } else {
        return false;
      }
    },
    isMinute(num: number): boolean {
      if (num >= 0 && num < 60) {
        return true;
      } else {
        return false;
      }
    },
    isSafeInteger(num: number): boolean {
      return Number.isInteger(Number(num)) && Number.isSafeInteger(Number(num));
    },
    isAbbreviationValid(target: unknown) {
      if (typeof target !== "string" || target.length < 1) {
        // 空文字はエラー
        return "文字数が短すぎます";
      }
      let zenkakuCount = 0;
      let hankakuCount = 0;
      for (const char of target) {
        if (checkCharType(char, "zenkaku")) {
          zenkakuCount++;
        } else {
          hankakuCount++;
        }
      }
      if (hankakuCount === 0) {
        // 全角のみの指定
        if (zenkakuCount > 2) {
          return "文字数が長すぎます";
        }
      } else if (zenkakuCount === 0) {
        // 半角のみの指定
        if (hankakuCount > 4) {
          return "文字数が長すぎます";
        }
      } else {
        // 全角と半角の混在指定
        if (zenkakuCount > 1 || hankakuCount > 2) {
          return "文字数が長すぎます";
        }
      }
      return true;
    },
  },
});
