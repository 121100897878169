
import Vue from 'vue';

import PageTitle from '@/components/atoms/PageTitle.vue';
import { schoolServiceModule } from '@/store/dataModules/schoolServiceModule';

interface DataType {
  isDialogOpen: boolean;
  isCreating: boolean;

  serviceName: string;
  serviceType: string | null;
  paymentMonthType: string | null;
  unitPrice: number | null;
  maxPrice: number | null;
  accountType: string | null;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    isDialogOpen: false,
    isCreating: false,

    serviceName: '',
    serviceType: null,
    paymentMonthType: null,
    unitPrice: null,
    maxPrice: null,
    accountType: null,
  }),

  computed: {
    serviceTypes() {
      return [
        {
          value: 'perUse',
          label: '回数',
        },
        {
          value: 'useMonth',
          label: '利用月額',
        },
        {
          value: 'monthly',
          label: '固定月額',
        },
      ]
    },
    paymentTypes() {
      return [
        {
          value: '0',
          label: '延長料金と同月請求',
        },
        {
          value: '1',
          label: '延長料金の翌月請求',
        },
        {
          value: '2',
          label: '延長料金の翌々月請求',
        },
      ]
    },
    accountTypes() {
      return [
        {
          value: 'food',
          label: '主食・副食費',
        },
        {
          value: 'other',
          label: 'その他',
        },
      ]
    },
    isInputValid() {
      if (
        this.serviceName.trim().length === 0
        || !this.unitPrice
        || !this.accountType
        || !this.serviceType
        || !this.paymentMonthType
      ) {
        return false;
      }

      return true;
    }
  },

  methods: {
    cancel() {
      this.isDialogOpen = false;

      this.serviceName = '';
      this.serviceType = null;
      this.paymentMonthType = null;
      this.unitPrice = null;
      this.maxPrice = null;
    },
    async create() {
      if (
        this.serviceType
        && this.unitPrice
        && this.accountType
        && this.paymentMonthType
      ) {
        this.isCreating = true;

        const paymentMonthType = parseInt(this.paymentMonthType, 10);
        
        await schoolServiceModule.createSchoolService({
          uniqueServiceName: this.serviceName,
          serviceType: this.serviceType,
          paymentMonthType: paymentMonthType,
          price: this.unitPrice,
          maximumPrice: this.maxPrice || undefined,
          accountType: this.accountType,
        });
        await schoolServiceModule.listSchoolServices();
        
        this.isCreating = false;

        this.cancel();
      }
    }
  },
})
