import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiGrowthRecord from '@/api/ApiGrowthRecord';
import { NurseryGrowthDetailResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';

export interface ChildGrowthRecordRequest {
  childId: number;
  height?: string | number;
  weight?: string | number;
  remarks?: string;
}

@Module({ dynamic: true, store, name: 'class-growth-record', namespaced: true })
class ClassGrowthRecordModule extends VuexModule {
  public growthRecords: NurseryGrowthDetailResponse[] = [];
  public childGrowthRecordRequests: ChildGrowthRecordRequest[] = [];
  public classId: number | null = null;
  public yearMonth: string = dayjs().format('YYYY-MM');

  get year() {
    const yearMonth = dayjs(this.yearMonth);
    const year = yearMonth.year();
    return year;
  }

  get month() {
    const yearMonth = dayjs(this.yearMonth);
    const month = yearMonth.month() + 1;
    return month;
  }

  @Action
  public async listGrowthRecords() {
    const nurseryId = LocalDataService.getNurseryId();
    if (this.classId && nurseryId) {
      const listResponse = await ApiGrowthRecord.listGrowthRecords({ 
        nurseryId, classId: this.classId,
        year: this.year,
        month: this.month,
      });
      if (listResponse) {
        this.setGrowthRecords(listResponse);
      }
    }
  }

  @Action
  public async saveRecords() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const year = this.month < 4 ? this.year - 1 : this.year;
      await ApiGrowthRecord.saveRecords({
        year: year,
        month: this.month,
        nurseryId,
        records: this.childGrowthRecordRequests
      });
      this.clearGrowthRecordRequests();
    }
  }

  @Mutation
  public setClassId(classId: number) {
    this.classId = classId;
  }

  @Mutation
  public setYearMonth(yearMonth: string) {
    this.yearMonth = yearMonth;
  }

  @Mutation
  public setGrowthRecords(records: NurseryGrowthDetailResponse[]) {
    this.growthRecords = records;
  }

  @Mutation
  public updateChildGrowthRecordRequests(request: ChildGrowthRecordRequest) {
    let shouldPush = true;
    this.childGrowthRecordRequests = this.childGrowthRecordRequests.map((r) => {
      if (r.childId === request.childId) {
        shouldPush = false;
        return request;
      }
      return r;
    });

    if (shouldPush) {
      this.childGrowthRecordRequests.push(request);
    }
  }

  @Mutation
  public clearGrowthRecordRequests() {
    this.childGrowthRecordRequests = [];
  }
}

export const classGrowthRecordModule = getModule(ClassGrowthRecordModule);