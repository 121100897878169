import ApiClient from '@/api/ApiClient';

export default class ApiClass {
  // クラス
  public static async listClass(input: { nurseryId: number; year?: number | string }) {
    const response = await ApiClient.get(`/nursery/${input.nurseryId}/classes`, {
      year: input.year
    });
    return response;
  }

  public static async addClass(input: {
    nurseryId: number;
    className: string;
    childrenAge: string;
    year: number;
  }) {
    const response = await ApiClient.post(`/nursery/${input.nurseryId}/classes`, {
      className: input.className,
      childrenAge: input.childrenAge,
      year: input.year,
    });
    return response;
  }

  public static async getClass(input: {
    nurseryId: number;
    classId: string;
  }) {
    const response = await ApiClient.get(`/nursery/${input.nurseryId}/classes/${input.classId}`, null);
    return response;
  }

  public static async updateClass(input: {
    nurseryId: number;
    classId: string;
    className: string;
    year: number;
    childrenAge: string;
  }) {
    const response = await ApiClient.put(`/nursery/${input.nurseryId}/classes/${input.classId}`, {
      className: input.className,
      year: input.year,
      childrenAge: input.childrenAge,
    });
    return response;
  }

  public static async deleteClass(input: {
    nurseryId: number;
    classId: string;
  }) {
    const response = await ApiClient.delete(`/nursery/${input.nurseryId}/classes/${input.classId}`, null);
    return response;
  }

  public static async duplicateClass(input: {
    nurseryId: number;
    classId: number | string;
    to: {
      className: string;
      childrenAge: number;
      year: number;
    };
  }) {
    return await ApiClient.post(`/nursery/${input.nurseryId}/classes/copy`, input);
  }
}