
import Vue from "vue";
import {
  invoiceModule,
  InvoiceStatus,
} from "@/store/dataModules/invoiceModule";
import LocalDataService from "@/service/LocalDataService";
import PageTitle from "@/components/atoms/PageTitle.vue";
import { NurseryHoursFeeInvoiceResponse } from "chaild-api/lib";
import ApiInvoice from "@/api/ApiInvoice";

export const Hour = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
] as const;
export type HourType = (typeof Hour)[number];

export const Minute = [
  ...Hour,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
] as const;
export type MinuteType = (typeof Minute)[number];

export default Vue.extend({
  name: "InvoiceDetail",

  components: {
    PageTitle,
  },

  async mounted() {
    await this.getInvoice();
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    childId: function (): string {
      return String(this.$route.query.childId);
    },
    year: function (): string {
      return String(this.$route.query.year);
    },
    month: function (): string {
      return String(this.$route.query.month);
    },
    invoiceDetail: function (): NurseryHoursFeeInvoiceResponse | null {
      return invoiceModule.invoiceDetail;
    },
    invoiceStatus: function (): InvoiceStatus | null {
      return invoiceModule.status;
    },
  },

  methods: {
    async getInvoice() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.childId && this.year && this.month) {
        await invoiceModule.getInvoice({
          nurseryId,
          childId: Number(this.childId),
          year: Number(this.year),
          month: Number(this.month),
        });
      }
      this.isLoading = false;
    },
    updateAttendance(date: string) {
      if (date && this.childId) {
        this.$router.push({ path: `/attendance/${this.childId}/${date}/edit` });
      } else {
        this.$router.push({ path: "/attendance/list" });
      }
    },
    convertMinuteToHourMinute(minute: number): string {
      if (minute) {
        const calcMin = minute % 60;
        const calcHour = (minute - calcMin) / 60;
        return `${calcHour}:${calcMin < 10 ? `0${calcMin}` : calcMin}`;
      } else {
        return "";
      }
    },
    async downloadCsv() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const invoiceResponse = await ApiInvoice.downloadInvoiceDetail({
          nurseryId,
          childId: Number(this.childId),
          year: Number(this.year),
          month: Number(this.month),
        }).catch(() => (this.isLoading = false));

        if (invoiceResponse) {
          window.open(invoiceResponse.url, "_blank");
        } else {
          alert("CSVダウンロードに失敗しました");
        }
      }
      this.isLoading = false;
    },
    async downloadInvoice() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const invoiceResponse = await ApiInvoice.downloadInvoice({
          nurseryId,
          childId: Number(this.childId),
          year: Number(this.year),
          month: Number(this.month),
        }).catch(() => (this.isLoading = false));

        if (invoiceResponse) {
          window.open(invoiceResponse.url, "_blank");
        } else {
          alert("ダウンロードに失敗しました");
        }
      }
      this.isLoading = false;
    },
  },
});
