
import Vue from "vue";

import { schoolDairyEditModule } from "@/store/dataModules/schoolDiaryEditModule";
import { meModule } from "@/store/dataModules/meModule";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import { classModule } from "@/store/dataModules/classModule";
import {
  NurseryChildResponse,
  NurseryDiarySettingResponse,
} from "chaild-api/lib";
import { nurseryUserModule } from "@/store/dataModules/nurseryUserModule";
import { diarySettingModule } from "@/store/dataModules/diarySettingModule";
import CommonUtil from "@/utils/CommonUtil";

interface DataType {
  isUpdating: boolean;
  loading: boolean;
  alertSetting: {
    shouldAlert: boolean;
    classes: string[];
  };
}

export default Vue.extend({
  computed: {
    diary() {
      return schoolDairyEditModule.diary;
    },
    classes() {
      return classModule.classes;
    },
    dateLabel(): string {
      if (this.diary) {
        return dayjs(this.diary.date).format("YYYY年MM月DD日");
      }
      return "";
    },
    applicationStatus(): string {
      if (this.diary) {
        return this.diary.status;
      }
      return "";
    },
    comment: {
      get() {
        if (schoolDairyEditModule.diary) {
          return schoolDairyEditModule.diary.comment;
        }
        return null;
      },
      set(value: string) {
        if (schoolDairyEditModule.diary) {
          const newValue = { ...schoolDairyEditModule.diary };
          newValue.comment = value;
          schoolDairyEditModule.setDiary(newValue);
        }
      },
    },
    event: {
      get() {
        if (schoolDairyEditModule.diary) {
          return schoolDairyEditModule.diary.event;
        }
        return null;
      },
      set(value: string) {
        if (schoolDairyEditModule.diary) {
          const newValue = { ...schoolDairyEditModule.diary };
          newValue.event = value;
          schoolDairyEditModule.setDiary(newValue);
        }
      },
    },
    earlyLeaver: {
      get() {
        if (schoolDairyEditModule.diary) {
          return schoolDairyEditModule.diary.earlyLeaver;
        }
        return null;
      },
      set(value: string) {
        if (schoolDairyEditModule.diary) {
          const newValue = { ...schoolDairyEditModule.diary };
          newValue.earlyLeaver = value;
          schoolDairyEditModule.setDiary(newValue);
        }
      },
    },
    staffOptions() {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`,
        };
      });
    },
    me() {
      return meModule.me;
    },
    isDirector() {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (currentAccount && currentAccount.isDirector) {
          return true;
        }
      }
      return false;
    },
    isChief() {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (currentAccount && currentAccount.isChief) {
          return true;
        }
      }
      return false;
    },
    isApproved(): boolean {
      if (this.diary) {
        // return this.diary.approvedBy.director !== -1;
        return (
          this.diary.approvedBy.director != null &&
          this.diary.approvedBy.director != -1
        );
      }
      return false;
    },
    isApprovedByChief(): boolean {
      if (this.diary) {
        // return this.diary.approvedBy.chief !== -1;
        return (
          this.diary.approvedBy.chief != null &&
          this.diary.approvedBy.chief != -1
        );
      }
      return false;
    },
    isApprovedByMe(): boolean {
      if (this.diary && meModule.me) {
        if (
          this.diary.approvedBy &&
          this.diary.approvedBy.director &&
          this.diary.approvedBy.director != -1
        ) {
          const directorNuseryId = this.diary.approvedBy.director.nurseryId;
          const directorUserId = this.diary.approvedBy.director.userNurseryId;
          const findAccount = meModule.me.nurserySchools.find(
            (ac) => ac.nurseryId === directorNuseryId
          );
          if (findAccount && findAccount.userId === directorUserId) {
            return true;
          }
        }

        if (
          this.diary.approvedBy &&
          this.diary.approvedBy.chief &&
          this.diary.approvedBy.chief != -1
        ) {
          const chiefNuseryId = this.diary.approvedBy.chief.nurseryId;
          const chiefUserId = this.diary.approvedBy.chief.userNurseryId;
          const findAccount = meModule.me.nurserySchools.find(
            (ac) => ac.nurseryId === chiefNuseryId
          );
          if (findAccount && findAccount.userId === chiefUserId) {
            return true;
          }
        }

        return false;
      }
      return false;
    },
    isDraft(): boolean {
      if (this.diary) {
        return this.diary.status === "draft";
      }
      return false;
    },
    isApplied(): boolean {
      if (this.diary) {
        return this.diary.status === "applied";
      }
      return false;
    },
    cheifApproveStamp(): string {
      if (this.diary) {
        if (this.diary.approvedBy.chief && this.diary.approvedBy.chief != -1) {
          return `${this.diary.approvedBy.chief.lastName} ${this.diary.approvedBy.chief.firstName}`;
        }
      }
      return "-";
    },
    directorApproveStamp(): string {
      if (this.diary) {
        if (
          this.diary.approvedBy.director &&
          this.diary.approvedBy.director != -1
        ) {
          return `${this.diary.approvedBy.director.lastName} ${this.diary.approvedBy.director.firstName}`;
        }
      }
      return "-";
    },
    recorderStamp(): string {
      if (this.diary) {
        if (this.diary.createdBy) {
          return `${this.diary.createdBy.lastName} ${this.diary.createdBy.firstName}`;
        }
      }
      return "下書き";
    },
    appliedBy: {
      get() {
        return schoolDairyEditModule.appliedBy;
      },
      set(value: number | null) {
        schoolDairyEditModule.setAppliedBy(value);
      },
    },
    diarySetting: function (): NurseryDiarySettingResponse | null {
      return diarySettingModule.diarySetting;
    },
  },

  methods: {
    async saveAsDraft() {
      this.isUpdating = true;
      await schoolDairyEditModule.saveSchoolDiary("draft");
      this.isUpdating = false;
    },
    async saveAndApply() {
      this.isUpdating = true;
      await schoolDairyEditModule.saveSchoolDiary("applied");
      this.isUpdating = false;
    },
    async approve() {
      this.isUpdating = true;
      if (this.alertSetting.shouldAlert) {
        const answer = confirm(
          `未作成のクラス日誌があります。承認してよろしいでしょうか？\n\n${this.alertSetting.classes.join(
            "\n"
          )}`
        );
        if (answer) {
          await schoolDairyEditModule.approveSchoolDiary();
        }
      } else {
        await schoolDairyEditModule.approveSchoolDiary();
      }
      this.isUpdating = false;
    },
    async reject() {
      this.isUpdating = true;
      await schoolDairyEditModule.rejectSchoolDiary();
      this.isUpdating = false;
    },
    getClassName(classId: number) {
      // return '' + this.classes.length;
      const find = this.classes.find((c) => c.classId === classId);
      if (find) {
        return find.className;
      }
      return "";
    },
    getCurrentClass(child: NurseryChildResponse) {
      // const currentYear = dayjs().year();
      if (this.diary && this.diary.date) {
        const currentYear = CommonUtil.getFiscalYear(this.diary.date);
        const find = child.nurseryClasses.find(
          (c) => String(c.year) == currentYear
        );
        if (find) {
          return find.className;
        }
        return "";
      }
    },
  },

  data: (): DataType => {
    return {
      isUpdating: false,
      loading: false,
      alertSetting: {
        shouldAlert: false,
        classes: [],
      },
    };
  },

  async mounted() {
    this.loading = true;

    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await classModule.listClass({
        nurseryId: nurseryId,
      });
      await nurseryUserModule.listStaffs();
      await diarySettingModule.listDiarySetting({ nurseryId });
    }

    const date = this.$route.params.date;
    if (date) {
      schoolDairyEditModule.setDate(date);
      const diaryResponse = await schoolDairyEditModule.getSchoolDiary();

      // alert setting
      if (diaryResponse && this.diarySetting) {
        this.diarySetting.schoolDiaryConfirmation.targetClasses.forEach((c) => {
          const isNotEmpty = diaryResponse.classDiaries.some(
            (d) => d.classId == c.classId
          );
          if (!isNotEmpty) {
            this.alertSetting.shouldAlert = true;
            this.alertSetting.classes.push(`${c.className}`);
          }
        });
      }
    }

    this.loading = false;
  },
});
