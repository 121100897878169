
import Vue from "vue";

import { schoolDairyModule } from "@/store/dataModules/schoolDiaryModule";
import dayjs from "dayjs";

interface DataType {
  isFetching: boolean;
  page: number;
}

export default Vue.extend({
  watch: {
    page: {
      handler(val) {
        schoolDairyModule.setPage(val);
        this.search();
      },
    },
  },

  computed: {
    diaries() {
      return [...schoolDairyModule.diaries].sort((d1, d2) => {
        const date1 = dayjs(d1.date);
        const date2 = dayjs(d2.date);
        return date2.diff(date1);
      });
    },
    totalItemsCount: function (): number {
      return schoolDairyModule.totalItemsCount;
    },
    limit: function (): number {
      return schoolDairyModule.limit;
    },
  },

  data: (): DataType => {
    return {
      isFetching: false,
      page: 1,
    };
  },

  methods: {
    async search() {
      this.isFetching = true;
      await schoolDairyModule.listSchoolDiaries();
      this.isFetching = false;
    },
  },

  mounted() {
    this.search();
  },
});
