
import Vue from "vue";
import { classModule } from "@/store/dataModules/classModule";
import LocalDataService from "@/service/LocalDataService";

import PasoriSensorContainer from "@/containers/pasori/PasoriSensorContainer.vue";
import PasoriVevoContainer from "@/containers/pasori/PasoriVevoContainer.vue";

interface DataType {
  tab: number;
}

export default Vue.extend({
  components: {
    PasoriSensorContainer,
    PasoriVevoContainer,
  },

  data: (): DataType => {
    return {
      tab: 0,
    };
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await classModule.listClass({
        nurseryId: nurseryId,
      });
    }
  },
});
