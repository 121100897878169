
import Vue from 'vue'

import MonthlyScheduleContainer from '@/containers/work-schedule/MonthlyScheduleContainer.vue'

export default Vue.extend({
  components: {
    MonthlyScheduleContainer,
  },
})

