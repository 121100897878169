
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import { NurseryClassResponse } from "chaild-api/lib";
import { diarySettingModule } from "@/store/dataModules/diarySettingModule";
import Vue from "vue";

export default Vue.extend({
  name: "DiarySetting",

  async mounted() {
    await this.listClasses();
    await this.listDiarySetting();
  },

  data() {
    return {
      isLoading: false,
      ageItems: [0, 1, 2, 3, 4, 5],
      input: {
        classDiaryConfirmation: {
          targetClassIds: [],
          targetAges: [],
        },
        schoolDiaryConfirmation: {
          targetClassIds: [],
        },
      },
    };
  },

  computed: {
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
  },

  methods: {
    async listClasses() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule
          .listClass({
            nurseryId,
          })
          .catch();
      }
      this.isLoading = false;
    },
    async listDiarySetting() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await diarySettingModule
          .listDiarySetting({
            nurseryId,
          })
          .catch();
        if (response) {
          this.input.classDiaryConfirmation.targetClassIds =
            response.classDiaryConfirmation.targetClasses.map((c) => {
              return c.classId;
            });
          this.input.classDiaryConfirmation.targetAges =
            response.classDiaryConfirmation.targetAges;
          this.input.schoolDiaryConfirmation.targetClassIds =
            response.schoolDiaryConfirmation.targetClasses.map((c) => {
              return c.classId;
            });
        }
      }
      this.isLoading = false;
    },
    async updateDiarySetting() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await diarySettingModule
          .updateDiarySetting({
            nurseryId,
            ...this.input,
          })
          .catch();
        if (response) {
          alert("保存しました");
          await this.listDiarySetting();
        }
      }
      this.isLoading = false;
    },
  },
});
