import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiShift, { DayScheduleUpdateRequest } from "@/api/ApiShift";
import {
  ShiftPatternMonthlyResponse,
  ShiftPatternResponse,
  ShiftUsersResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";

export interface MonthlyScheduleState {
  yearMonth: string;
  monthlySchedule: ShiftPatternMonthlyResponse | null;
  registerStaffIds: number[];
  removeStaffIds: number[];
  editingShiftPattern: DayScheduleUpdateRequest | null;
}

@Module({ dynamic: true, store, name: "monthly-schedule", namespaced: true })
class MonthlyScheduleModule extends VuexModule implements MonthlyScheduleState {
  public yearMonth: string = dayjs().format("YYYY-MM");
  public monthlySchedule: ShiftPatternMonthlyResponse | null = null;
  public shiftStaffs: ShiftUsersResponse["users"] = [];
  public sortStaffIds: number[] = [];
  public registerStaffIds: number[] = [];
  public removeStaffIds: number[] = [];
  public editingShiftPattern: DayScheduleUpdateRequest | null = null;

  @Action
  public async onYearMonthChange(yearMonth: string) {
    this.setYearMonth(yearMonth);
    this.setMonthlySchdule(null);
    await this.listMonthlySchedule();
  }

  @Mutation
  public setYearMonth(yearMonth: string) {
    this.yearMonth = yearMonth;
  }
  @Mutation
  public setShiftStaffs(shiftStaffs: ShiftUsersResponse["users"]) {
    this.shiftStaffs = shiftStaffs;
  }

  @Mutation
  public setSortStaffIds(sortStaffIds: number[]) {
    this.sortStaffIds = sortStaffIds;
  }

  @Action
  public async listShiftUsers() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiShift.listShiftUsers({
        nurseryId,
      });
      const users = response?.users || [];
      this.setShiftStaffs(users);
      this.setSortStaffIds(users.map((user) => user.userId));
    }
  }

  @Action
  public async sortShiftUsers() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiShift.sortShiftUsers({
        nurseryId,
        userIds: this.sortStaffIds,
      });
      const users = response?.users || [];
      this.setShiftStaffs(users);
      this.setSortStaffIds(users.map((user) => user.userId));
    }
  }

  @Action
  public async listMonthlySchedule() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));

      // if (monthInt < 4) {
      //   yearInt = yearInt - 1
      // }

      const response = await ApiShift.listMonthlySchedule({
        nurseryId,
        year: yearInt,
        month: monthInt,
      });

      if (response) {
        this.setMonthlySchdule(response);
      }
    }
  }

  /**
   * PDF出力
   */
  @Action({ rawError: true })
  public async dlShiftPattern() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));
      const response = await ApiShift.dlMonthlyShiftPattern({
        nurseryId,
        year: yearInt,
        month: monthInt,
      }).catch();
      if (response) {
        return response;
      }
    }
  }

  @Mutation
  public setMonthlySchdule(
    monthlySchedule: ShiftPatternMonthlyResponse | null
  ) {
    this.monthlySchedule = monthlySchedule;
  }

  // Register/Remove StaffIds
  @Mutation
  public setRegisterStaffIds(ids: number[]) {
    this.registerStaffIds = ids;
  }

  @Mutation
  public addRegisterStaffIds(ids: number[]) {
    this.registerStaffIds = this.registerStaffIds.concat(ids);
  }

  @Mutation
  public removeRegisterStaffId(id: number) {
    this.registerStaffIds = this.registerStaffIds.filter(
      (userId) => userId !== id
    );
  }

  @Mutation
  public emptyRegisterStaffIds() {
    this.registerStaffIds = [];
  }

  @Mutation
  public setRemoveStaffIds(ids: number[]) {
    this.removeStaffIds = ids;
  }

  @Mutation
  public addRemoveStaffIds(ids: number[]) {
    this.removeStaffIds = this.removeStaffIds.concat(ids);
  }

  @Mutation
  public removeRemoveStaffId(id: number) {
    this.removeStaffIds = this.removeStaffIds.filter((userId) => userId !== id);
  }

  @Mutation
  public emptyRemoveStaffIds() {
    this.removeStaffIds = [];
  }

  @Action
  public async registerStaffs() {
    if (this.registerStaffIds.length > 0) {
      const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));

      // if (monthInt < 4) {
      //   yearInt = yearInt - 1
      // }

      const response = await ApiShift.registerUsersToMonthlySchedule({
        userIds: this.registerStaffIds,
        year: yearInt,
        month: monthInt,
      });

      if (response) {
        this.setMonthlySchdule(response);
        this.emptyRegisterStaffIds();
      }
    }
  }

  @Action
  public async removeStaffs() {
    if (this.removeStaffIds.length > 0) {
      const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));

      // if (monthInt < 4) {
      //   yearInt = yearInt - 1
      // }

      const response = await ApiShift.removeUsersFromMonthlySchedule({
        userIds: Array.from(new Set(this.removeStaffIds)),
        year: yearInt,
        month: monthInt,
      });

      if (response) {
        this.setMonthlySchdule(response);
        this.emptyRemoveStaffIds();
      }
    }
  }

  @Action
  public async applyPattern({
    shiftPatternId,
    userId,
    days,
  }: {
    shiftPatternId: number;
    userId: number;
    days: number[];
  }) {
    const yearInt = parseInt(dayjs(this.yearMonth).format("YYYY"));
    const monthInt = parseInt(dayjs(this.yearMonth).format("MM"));

    // if (monthInt < 4) {
    //   yearInt = yearInt - 1
    // }

    const response = await ApiShift.applyMonthlyPattern({
      shiftPatternId,
      userId,
      days,
      year: yearInt,
      month: monthInt,
    });

    if (response) {
      this.setMonthlySchdule(response);
    }
  }

  // シフトパターン編集
  @Action
  public async getUserShiftPattern({
    userId,
    day,
  }: {
    userId: number;
    day: string | number;
  }) {
    const year = parseInt(dayjs(this.yearMonth).format("YYYY"));
    const month = parseInt(dayjs(this.yearMonth).format("MM"));

    const response = await ApiShift.getUserShiftPatternByDate({
      userId,
      year,
      month,
      day,
    });

    if (response) {
      this.setShiftPattern(response);
    }
  }

  @Action
  public async saveEditingPattern({
    userId,
    date,
  }: {
    userId: number;
    date: number;
  }) {
    if (this.editingShiftPattern) {
      const year = parseInt(dayjs(this.yearMonth).format("YYYY"));
      const month = parseInt(dayjs(this.yearMonth).format("MM"));

      const response = await ApiShift.updateUserShiftPatternByDate({
        year,
        month,
        day: date,
        userId,
        patternRequest: this.editingShiftPattern,
      });
    }
  }

  @Action
  public async deleteShiftPatternByDate({
    userId,
    date,
  }: {
    userId: number;
    date: number;
  }) {
    const year = parseInt(dayjs(this.yearMonth).format("YYYY"));
    const month = parseInt(dayjs(this.yearMonth).format("MM"));

    const response = await ApiShift.deleteUserShiftPatternByDate({
      year,
      month,
      day: date,
      userId,
    });
  }

  @Mutation
  public setShiftPattern(pattern: ShiftPatternResponse | null) {
    if (pattern) {
      this.editingShiftPattern = {
        name: pattern.name || "",
        abbreviation: pattern.abbreviation || "",
        color: pattern.color,
        timetables: pattern.timetables.map((tt) => ({
          operationType: "update",
          name: tt.name || "",
          abbreviation: tt.abbreviation || "",
          timetableId: tt.timetableId,
          startMin: tt.startMin,
          endMin: tt.endMin,
          comment: tt.comment,
          color: tt.color,
          workDescriptionId: tt.workDescription?.workDescriptionId,
          category: tt.category,
        })),
      };
    } else {
      this.editingShiftPattern = null;
    }
  }

  @Mutation
  public updateEditingPattern(pattern: DayScheduleUpdateRequest | null) {
    if (pattern) {
      this.editingShiftPattern = pattern;
    } else {
      this.editingShiftPattern = null;
    }
  }
}

export const monthlyScheduleModule = getModule(MonthlyScheduleModule);
