
import Vue from 'vue';

import { placementSettingModule } from '../../store/viewModules/work-schedule/placementSettingModule'
import HourMinuteInput from '@/components/molecules/HourMinuteInput.vue'
import { WorkSchedulePlacementSettingResponse } from 'chaild-api/lib';
import ApiWorkSchedule from '@/api/ApiWorkSchedule'
import LocalDataService from '@/service/LocalDataService';

interface DataType {
  placementSetting: WorkSchedulePlacementSettingResponse | null;
  isUpdating: boolean;
}

export default Vue.extend({
  components: {
    HourMinuteInput
  },
  data: (): DataType => ({
    placementSetting: null,
    isUpdating: false
  }),
  computed: {
  },
  methods: {
    async getPlacementSetting() {
      const nurseryId = LocalDataService.getNurseryId()

      if (nurseryId) {
        const response = await ApiWorkSchedule.getPlacementSetting({ nurseryId })
        if (response) {
          this.placementSetting = response
        }
      }
    },
    async updatePlacementSetting() {
      const nurseryId = LocalDataService.getNurseryId()

      if (nurseryId && this.placementSetting) {
        this.isUpdating = true
        const response = await ApiWorkSchedule.updatePlacementSetting({
          nurseryId,
          body: this.placementSetting
        })
        this.isUpdating = false

        if (response) {
          this.placementSetting = response
        }
      }
    }
  },
  mounted() {
    this.getPlacementSetting()
  }
})
