
import Vue from 'vue';

import { classGrowthRecordModule, ChildGrowthRecordRequest } from '@/store/viewModules/growth-record/classGrowthRecordModule';

import PageTitle from '@/components/atoms/PageTitle.vue';
import ClassGrowthRecordRow from '@/components/organisms/growth-record/ClassGrowthRecrodRow.vue';
import MonthPager from '@/components/atoms/MonthPager.vue';
import dayjs from 'dayjs';

import { NurseryGrowthDetailResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import { classModule } from '@/store/dataModules/classModule';

interface DataType {
  isSaving: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
    MonthPager,
    ClassGrowthRecordRow,
  },

  data: (): DataType => ({
    isSaving: false,
  }),

  computed: {
    yearMotnh: {
      get() {
        return classGrowthRecordModule.yearMonth;
      },
      set(value: string) {
        classGrowthRecordModule.setYearMonth(value);
        classGrowthRecordModule.listGrowthRecords();
      }
    },
    records() {
      return classGrowthRecordModule.growthRecords.filter((r) => {
        if (r.child.quitDate) {
          const now = dayjs();
          const quitDate = dayjs(r.child.quitDate);
          const diff = now.diff(quitDate);
          if (diff > 0) {
            return false;
          }
        } else {
          return true;
        }
      });
    },
    recordRequests() {
      return classGrowthRecordModule.childGrowthRecordRequests;
    },
    className() {
      if (classGrowthRecordModule.classId) {
        const find = classModule.classes.find((c) => c.classId === classGrowthRecordModule.classId);
        if (find) {
          return find.className;
        }
      }
      return '';
    }
  },

  methods: {
    inputHeight(value: number, record: NurseryGrowthDetailResponse) {
      const find = this.recordRequests.find((r) => r.childId === record.child.childId);
      const newRecord: ChildGrowthRecordRequest = find ? {...find} : { childId: record.child.childId };
      newRecord.height = value;

      classGrowthRecordModule.updateChildGrowthRecordRequests(newRecord);
    },
    inputWeight(value: number, record: NurseryGrowthDetailResponse) {
      const find = this.recordRequests.find((r) => r.childId === record.child.childId);
      const newRecord: ChildGrowthRecordRequest = find ? {...find} : { childId: record.child.childId };
      newRecord.weight = value;

      classGrowthRecordModule.updateChildGrowthRecordRequests(newRecord);
    },
    inputRemarks(value: string, record: NurseryGrowthDetailResponse) {
      const find = this.recordRequests.find((r) => r.childId === record.child.childId);
      const newRecord: ChildGrowthRecordRequest = find ? {...find} : { childId: record.child.childId };
      newRecord.remarks = value;

      classGrowthRecordModule.updateChildGrowthRecordRequests(newRecord);
    },
    async saveRecords() {
      this.isSaving = true;
      await classGrowthRecordModule.saveRecords();
      this.isSaving = false;
    }
  },

  mounted() {
    const classId = this.$route.params.classId as string;
    if (classId) {
      try {
        const classIdInt = parseInt(classId, 10);
        classGrowthRecordModule.setClassId(classIdInt);
        classGrowthRecordModule.listGrowthRecords();
      } catch {
        return;
      }
    }

    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      classModule.listClass({
        nurseryId: nurseryId,
      });
    }
  }
});
