import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import ApiMeal, { ImportMealPlanStatus } from '@/api/ApiMeal';
import { UniqueServiceRegistrationResponse, MealPlanResponse, MealCategoryResponse, MealAllergenResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';

// perUse: 'perUse', // 回数
// perUseMonth: 'useMonth', // 利用月額
// monthly: 'monthly' // 固定月額

@Module({ dynamic: true, store, name: 'food-menus', namespaced: true })
class FoodMenusModule extends VuexModule {
  public menus: MealPlanResponse[] = [];
  public menuCategories: MealCategoryResponse[] = [];
  public allergens: MealAllergenResponse[] = [];
  public importStatuses: ImportMealPlanStatus[] = [];

  public creatingDate: string | null = null;
  public editingMenu: MealPlanResponse | null = null;
  public isCreateCategoryDialogOpen = false;
  public isImportDialogOpen = false;
  public importYearMonth = '';

  @Action
  public async listMenus({
    yearMonth,
  }: {
    yearMonth: string;
  }) {
    const nurseryId = LocalDataService.getNurseryId();

    if (nurseryId) {
      const response = await ApiMeal.listMealPlans({
        nurseryId,
        yearMonth
      });
      if (response) {
        this.setMenus(response);
      }
    }
  }

  @Mutation
  public setMenus(menus: MealPlanResponse[]) {
    this.menus = menus;
  }

  @Mutation
  public addMenu(menu: MealPlanResponse) {
    this.menus.push(menu);
  }

  @Mutation
  public replaceMenu(menu: MealPlanResponse) {
    this.menus = this.menus.map((m) =>  {
      if (m.planId === menu.planId) {
        return menu;
      }
      return m;
    });
  }

  @Action
  public async listMealCategories() {
    const nurseryId = LocalDataService.getNurseryId();
    
    if (nurseryId) {
      const response = await ApiMeal.listMealCategories({ nurseryId });

      if (response) {
        this.setMealCategories(response);
      }
    }
  }

  @Mutation
  public setMealCategories(categories: MealCategoryResponse[]) {
    this.menuCategories = categories;
  }

  @Action
  public async listAllergens() {
    const nurseryId = LocalDataService.getNurseryId();
    
    if (nurseryId) {
      const response = await ApiMeal.listAllergen({ nurseryId });

      if (response) {
        this.setAllergens(response);
      }
    }
  }

  @Mutation
  public setAllergens(allergens: MealAllergenResponse[]) {
    this.allergens = allergens;
  }

  @Mutation
  public setCreatingDate(date: string | null) {
    this.creatingDate = date;
  }

  @Action
  public async createMenu({
    categoryId,
    body,
    note,
    allergenIds,
  }: {
    categoryId: number;
    body: string;
    note: string;
    allergenIds: number[];
  }) {
    const nurseryId = LocalDataService.getNurseryId();

    if (nurseryId && this.creatingDate) {
      const response = await ApiMeal.createMealPlan({
        nurseryId,
        date: dayjs(this.creatingDate).format('YYYY-MM-DD'),
        categoryId,
        body,
        note,
        allergenIds
      });

      if (response) {
        this.addMenu(response);
      }
    }
  }

  @Mutation
  public setIsCreateCategoryDialogOpen(isOpen: boolean) {
    this.isCreateCategoryDialogOpen = isOpen;
  }

  @Mutation
  public setIsImportDataDialogOpen(isOpen: boolean) {
    this.isImportDialogOpen = isOpen;
  }

  @Mutation
  public setImportYearMonth(yearMonth: string) {
    this.importYearMonth = yearMonth;
  }

  @Action
  public async createCategory({
    name,
    targetClassIds,
  }: {
    name: string;
    targetClassIds: number[];
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiMeal.createMealCategory({
        nurseryId,
        name,
        targetClassIds,
      });
      if (response) {
        this.addCategory(response);
      }
    }
  }

  @Mutation
  public addCategory(category: MealCategoryResponse) {
    this.menuCategories.push(category);
  }

  @Mutation
  public setEditingMenu(menu: MealPlanResponse | null) {
    this.editingMenu = menu;
  }

  @Action
  public async updateMenu() {
    if (this.editingMenu) {
      const response = await ApiMeal.updateMealPlan({
        planId: this.editingMenu.planId,
        date: this.editingMenu.date,
        categoryId: this.editingMenu.category.categoryId,
        body: this.editingMenu.body || ' ',
        note: this.editingMenu.note || ' ',
        allergenIds: this.editingMenu.allergens.map((a) => a.allergenId),
      });

      if (response) {
        this.replaceMenu(response);
      }
    }
  }

  @Action
  public async deleteMenu(planId: number) {
    const response = await ApiMeal.deleteMealPlan({ planId });
    return response;
  }

  @Action
  public async listImportStatus({
    year,
    month,
  }: {
    year: number;
    month: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiMeal.listImportStatuses({
        nurseryId,
        limit: 10,
        skip: 0,
        year,
        month,
      });
      if (response) {
        this.setImportStatus(response.items)
      }
    }
  }

  @Mutation
  public setImportStatus(status: ImportMealPlanStatus[]) {
    this.importStatuses = status;
  }
}

export const foodMenusModule = getModule(FoodMenusModule);