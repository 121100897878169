
import ValidationMixin from '@/mixins/ValidationMixin';
import Vue, { PropType } from 'vue'

interface Hourly {
  billingMinute: number;
  billingAmount: number;
  billingUpperLimitAmount: number;
}

interface Monthly {
  billingAmount: number;
}

export default Vue.extend({
  name: 'BillingInput',

  mixins: [ValidationMixin],

  props: {
    billingMethod: {
      type: String,
      default: 'hourly',
    },
    hourly: {
      type: Object as PropType<Hourly>,
      default: () => ({
        billingMinute: 10,
        billingAmount: 0,
        billingUpperLimitAmount: 0,
      }),
    },
    monthly: {
      type: Object as PropType<Monthly>,
      default: () => ({
        billingAmount: 0
      }),
    },
    hideMonthly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // billingMethodItems: [
      //   { text: '時間あたり', value: 'hourly', },
      //   { text: '月あたり', value: 'monthly', },
      //   { text: 'グループ定額', value: 'groupFixed', },
      // ],
      isFormValid: false,
    }
  },

  computed: {
    billingMethodItems: function() {
      if (this.hideMonthly) {
        return [
          { text: '時間あたり', value: 'hourly', },
          { text: 'グループ定額', value: 'groupFixed', },
        ];
      }
      return [
        { text: '時間あたり', value: 'hourly', },
        { text: '月あたり', value: 'monthly', },
        { text: 'グループ定額', value: 'groupFixed', },
      ];
    },
    inputBillingMethod: {
      get(): string {
        return this.billingMethod;
      },
      set(value: string) {
        this.$emit('billingMethod', value);
      }
    },
    inputHourly: {
      get(): Hourly {
        return this.hourly;
      },
      set(value: Hourly) {
        this.$emit('hourly', value);
      },
    },
    inputMonthly: {
      get(): Monthly {
        return this.monthly;
      },
      set(value: Monthly) {
        this.$emit('monthly', value);
      }
    },
  },

})
