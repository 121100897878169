export default class ErrorMessageUtil {
  // @formatter:off
  // prettier-ignore
  public static errors = [
    { code: 'E001', message: '入力必須の値などに過不足があります。ご確認ください。', },
    // { code: 'E003', message: 'ユーザーが存在しません', },
    // { code: 'E004', message: 'ユーザーが既に存在しています。', },
    { code: 'E005', message: '指定されたObjectTypeが不正です。', },
    { code: 'E006', message: '朝食の記録が存在しません。', },
    { code: 'E007', message: '朝食の記録が既に存在します。', },
    { code: 'E008', message: '夕食の記録が存在しません。', },
    //
    { code: 'E021', message: 'メッセージが見つかりません。', },
    { code: 'E023', message: 'クラスが見つかりません。', },
    { code: 'E024', message: '指定した園児が見つかりません。', },
    //
    { code: 'E050', message: 'ユーザーは指定した保育園に所属していません。', },
    //
    { code: 'E014', message: '保育日誌の記録が存在しません', },
    { code: 'E015', message: '保育日誌の記録が既に存在します。', },
    //
    { code: 'E051', message: '管理者ユーザーのみが許可された操作です。', },
    //
    { code: 'E090', message: '保護者が見つかりません。', },
    { code: 'E091', message: '同名のアレルギー食品が既に登録されています。', },
    { code: 'E092', message: '指定したアレルギー食品は指定した保育園の物ではありません。', },
    { code: 'E093', message: 'スタッフアカウント役職名がすでに存在しています。', },
    { code: 'E094', message: 'スタッフアカウント役職が存在しません。', },
    { code: 'E095', message: 'スタッフアカウント情報がすでに存在しています。', },
    { code: 'E096', message: 'スタッフアカウント情報が存在しません。', },
    { code: 'E097', message: 'このスタッフアカウント役職は現在使用されているため、削除できません。', },
    { code: 'E098', message: '開始日が終了日より後に設定されています。', },
    { code: 'E099', message: '指定された日時は過去の日付です。', },
    //
    { code: 'E100', message: 'Excelファイルの生成に失敗しました。恐れ入りますが、システム管理者にお問い合わせください。', },
    { code: 'E101', message: '午睡センサーが起動されていません。', },
    { code: 'E102', message: '午睡センサーの記録が見つかりません。', },
    { code: 'E103', message: '欠席登録が見つかりません。', },
    { code: 'E104', message: '他人が作成したチャットは閲覧のみ可能です。', },
    { code: 'E105', message: 'ご指定のメールアドレスは他のユーザーに利用されているため変更できません。', },
    { code: 'E106', message: '添付ファイルが見つかりません', },
    { code: 'E107', message: '認定・保育料の記録が存在しません。', },
    { code: 'E108', message: '認定期間の終了日を入力してください。', },
    { code: 'E109', message: '認定・保育料の期間指定が不正です。他の期間との重複がないか確認してください。', },
    //
    { code: 'E110', message: '対象の勘定科目が見つかりません。', },
    { code: 'E111', message: '勘定科目が小口現金出納帳で利用されているため削除できません。', },
    { code: 'E112', message: '小口現金出納帳の詳細レコードが見つかりません。', },
    { code: 'E113', message: '同名のアレルギー項目がすでに登録されています。', },
    { code: 'E114', message: 'アレルギー項目が見つかりません。', },
    { code: 'E115', message: '指定されたアレルギー項目は削除・更新することができません。', },
    { code: 'E116', message: '献立種別が見つかりません。', },
    { code: 'E117', message: '献立が見つかりません。', },
    { code: 'E118', message: '検食が見つかりません。', },
    { code: 'E119', message: '検食がすでに作成済みです。', },
    //
    { code: 'E120', message: '延長保育パターンが見つかりません。', },
    { code: 'E121', message: '時間枠に被りがあるため保存できません。', },
    { code: 'E122', message: '時間枠が見つかりません。', },
    { code: 'E123', message: '打刻設定が存在しません。', },
    { code: 'E124', message: '指定した延長保育日誌は指定した保育園のものではありません。', },
    { code: 'E125', message: '指定した園提供サービスはマスターに登録されていません。', },
    { code: 'E126', message: '指定した園提供サービスは既に登録されています。', },
    { code: 'E127', message: '指定した園提供サービスは利用登録されていません。', },
    { code: 'E128', message: '月額制の施設提供サービスの場合は、1日 日付を利用日として設定してください。', },
    { code: 'E129', message: '指定した施設提供サービスは利用されているため削除できません。', },
    //
    { code: 'E130', message: '施設提供サービスのserviceTypeの形式が不正です。', },
    { code: 'E131', message: '施設提供サービスのpaymentMonthTypeの形式が不正です。', },
    { code: 'E132', message: '施設提供サービスのaccountTypeの形式が不正です。', },
    { code: 'E133', message: 'ユーザーは指定した運営企業に所属していません。', },
    { code: 'E134', message: '運営会社が見つかりません。', },
    { code: 'E135', message: '指定された請求書が存在しません。', },
    { code: 'E136', message: '請求書のステータスが不正です。', },
    { code: 'E137', message: '請求書がまだ確定されていません。', },
    { code: 'E138', message: 'ファイルの読み込みに失敗しました。CSVファイルの形式を確認してください。', },
    { code: 'E139', message: 'CSVファイルの園児コードが不正です。', },
    //
    { code: 'E140', message: 'CSVファイルの売掛金の値が不正です。', },
    { code: 'E141', message: '売上情報のエクスポート状況が存在しません。', },
    { code: 'E142', message: '売掛金残高のインポート状況が存在しません。', },
    { code: 'E143', message: 'この月の売上情報が送信されているため, この登園・降園記録は編集できません。', },
    { code: 'E144', message: '確定ステータスの小口現金出納帳は変更できません。', },
    { code: 'E145', message: 'この月の売上情報が確定しているため, 対象期間を含む保育料・認定情報は編集できません。', },
    { code: 'E146', message: '指定されたその他サービス詳細が存在しません。', },
    { code: 'E147', message: '保育料・認定情報が登録されていない園児がいます。設定してから送信してください。', },
    { code: 'E148', message: '運営企業のサービス設定が存在しません。', },
    { code: 'E149', message: '権限不足のため小口現金出納帳の確定ステータスを解除することができません。', },
    //
    { code: 'E150', message: '下書きステータスではないため操作することができません。', },
    { code: 'E151', message: 'この月の売上情報が確定しているため, 対象期間を含む園提供サービスは編集できません。', },
    { code: 'E152', message: '園の振込口座の設定が見つかりません。', },
    { code: 'E153', message: '指定の園児は卒園もしくは退園しているため登録・編集をすることができません。', },
    { code: 'E154', message: '指定のディスカッションが見つかりません。', },
    // { code: 'E155', message: '', },
    // { code: 'E156', message: '', },
    // { code: 'E157', message: '', },
    // { code: 'E158', message: '', },
    { code: 'E159', message: 'PDFの生成に失敗しました。', },
    //
    { code: 'E160', message: 'インポート / エクスポートのステータスが存在しません。', },
    { code: 'E161', message: 'ダウンロード対象のアイテムがありません。', },
    { code: 'E162', message: '指定された配送先住所が存在しません。', },
    { code: 'E163', message: '指定されたアイテムはカートに存在しません。', },
    { code: 'E164', message: '指定のmemoru単価設定は存在しません。', },
    { code: 'E165', message: '指定されたアルバムは存在しません。', },
    { code: 'E166', message: '指定されたメディアは存在しません。', },
    { code: 'E167', message: 'デフォルト配送先は削除できません。', },
    { code: 'E168', message: 'デフォルト配送先は必須です。', },
    { code: 'E169', message: '指定された作業内容は存在しません。', },
    //
    { code: 'E170', message: '指定された作業内容は使用されています。', },
    { code: 'E171', message: '指定されたシフトパターンは存在しません。', },
    { code: 'E172', message: '指定されたシフトパターンが既に存在しています。', },
    { code: 'E173', message: '指定されたタイムテーブルは存在しません。', },
    { code: 'E174', message: 'タイムテーブルの時間が不正です。', },
    { code: 'E175', message: 'タイムテーブルの時間が重複しています。', },
    { code: 'E176', message: 'グループ設定が見つかりません。', },
    { code: 'E177', message: '請求書の計算が進行中です時間を置いて再度実行してください。', },
    { code: 'E178', message: '指定のファイル形式はサポートされていません。', },
    { code: 'E179', message: '指定されたメディアは既に購入済みです。', },
    //
    { code: 'E180', message: 'メールアドレスが認証されていません。', },
    { code: 'E181', message: '帳票マスタが存在しません。', },
    { code: 'E182', message: '帳票項目マスタが存在しません。', },
    { code: 'E183', message: 'この帳票項目マスタは削除できません。', },
    { code: 'E184', message: '帳票が存在しません。', },
    { code: 'E185', message: '帳票項目が存在しません。', },
    { code: 'E186', message: 'この帳票項目は削除できません。', },
    { code: 'E187', message: '指導計画が存在しません。', },
    { code: 'E188', message: 'この指導計画は削除できません。', },
    { code: 'E189', message: '承認状態の指導計画は編集できません。', },
    //
    { code: 'E190', message: 'この指導計画項目は編集できません。', },
    { code: 'E191', message: 'この指導計画項目はこの指導計画に紐づいていません。', },
    { code: 'E192', message: 'この指導計画項目にはこの園児別テキストは存在しません。', },
    { code: 'E193', message: '指導計画の承認状態の変更権限がありません。', },
    { code: 'E194', message: '指導計画の削除権限がありません。', },
    //
    { code: 'E235', message: '発達項目が見つかりません。', },
    { code: 'E236', message: '領域が見つかりません。', },
    { code: 'E237', message: '発達記録が見つかりません。', },
    { code: 'E238', message: 'おたよりが見つかりません。', },
    { code: 'E239', message: 'おたよりのデザインが見つかりません。', },
    //
    { code: 'E240', message: 'ファイルが見つかりません。', },
    //
  ];
  // @formatter:on

  public static getErrorMessage(code: string): string {
    if (!code) {
      return "";
    }
    const error = this.errors.find((e) => e.code == code);
    return error ? error.message : "";
  }
}
