/**
 * memoru
 */
export const multimediaCategoryType = [
  {
    text: "",
    value: "",
  },
  {
    text: "写真",
    value: "photo",
  },
  {
    text: "動画",
    value: "movie",
  },
];

export const mediaFormatType = [
  {
    text: "",
    value: "",
  },
  {
    text: "データ",
    value: "data",
  },
  {
    text: "プリント",
    value: "print",
  },
];

export const isPublishedItems = [
  { text: "", value: undefined },
  { text: "公開中", value: true },
  { text: "非公開中", value: false },
];
