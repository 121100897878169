import ApiClient from "@/api/ApiClient";

export default class ApiNurseryHours {
  public static async listPattern(input: { nurseryId: number }) {
    const response = await ApiClient.get("/nursery-hours", input);
    return response;
  }

  public static async getPatternDetail(input: { patternId: string }) {
    const response = await ApiClient.get(
      `/nursery-hours/${input.patternId}`,
      null
    );
    return response;
  }

  public static async setDefaultPattern(input: { patternId: string }) {
    const response = await ApiClient.put(
      `/nursery-hours/${input.patternId}`,
      input
    );
    return response;
  }

  // パターン: 月極
  public static async createPatternMonthly(input: {
    nurseryId: number;
    patternTitle: string;
    billingAmount: number;
    extendedChildcareStartingHour: number;
    extendedChildcareStartingMinute: number;
  }) {
    const response = await ApiClient.post("/nursery-hours/monthly", input);
    return response;
  }

  public static async updatePatternMonthly(input: {
    patternId: string;
    billingAmount: number;
    patternTitle: string;
  }) {
    const response = await ApiClient.put(
      `/nursery-hours/monthly/${input.patternId}`,
      input
    );
    return response;
  }

  // パターン: 変動時間枠
  public static async createPatternSpotFlexible(input: {
    nurseryId: number;
    patternTitle: string;
  }) {
    const response = await ApiClient.post(
      "/nursery-hours/spot-flexible",
      input
    );
    return response;
  }

  public static async updatePatternSpotFlexible(input: {
    patternId: string;
    patternTitle: string;
  }) {
    const response = await ApiClient.put(
      `/nursery-hours/spot-flexible/${input.patternId}`,
      input
    );
    return response;
  }

  // パターン: 固定時間枠（幼稚園）
  public static async createPatternSpotKindergarten(input: {
    nurseryId: number;
    patternTitle: string;
    startingHour: number;
    startingMinute: number;
    earlyHoursBillingFee: {
      billingMethod: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    };
  }) {
    const response = await ApiClient.post(
      "/nursery-hours/spot-kindergarten",
      input
    );
    return response;
  }

  public static async updatePatternSpotKindergarten(input: {
    patternId: string;
    nurseryId: number;
    patternTitle: string;
    startingHour: number;
    startingMinute: number;
    earlyHoursBillingFee: {
      billingMethod: string; // hourly, monthly
    };
    hourly?: {
      billingMinute?: number;
      billingAmount?: number;
      billingUpperLimitAmount?: number;
    };
    monthly?: {
      billingAmount?: number;
    };
  }) {
    const response = await ApiClient.put(
      `/nursery-hours/spot-kindergarten/${input.patternId}`,
      input
    );
    return response;
  }

  // パターン: 固定時間枠（保育園）
  public static async createPatternSpotNursery(input: {
    nurseryId: number;
    patternTitle: string;
    normalChildcareStartingHour: number;
    normalChildcareStartingMinute: number;
    shortenedChildcareStartingHour: number;
    shortenedChildcareStartingMinute: number;
    isGroupFixed: boolean; // trueの場合はhourly, monthlyの金額設定は無視される
    groupFixedBillingFee?: {
      normalChildcareBillingAmount: number;
      shortenedChildcareBillingAmount: number;
    };
    earlyHoursBillingFee?: {
      billingMethod?: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    };
    shortenedBillingFee?: {
      before: {
        billingMethod?: string; // hourly, monthly
        hourly?: {
          billingMinute?: number;
          billingAmount?: number;
          billingUpperLimitAmount?: number;
        };
        monthly?: {
          billingAmount?: number;
        };
      };
      after: {
        billingMethod?: string; // hourly, monthly
        hourly?: {
          billingMinute?: number;
          billingAmount?: number;
          billingUpperLimitAmount?: number;
        };
        monthly?: {
          billingAmount?: number;
        };
      };
    };
  }) {
    const response = await ApiClient.post("/nursery-hours/spot-nursery", input);
    return response;
  }

  public static async updatePatternSpotNursery(input: {
    patternId: string;
    patternTitle: string;
    nurseryId: number;
    normalChildcareStartingHour: number;
    normalChildcareStartingMinute: number;
    shortenedChildcareStartingHour: number;
    shortenedChildcareStartingMinute: number;
    isGroupFixed: boolean; // trueの場合はhourly, monthlyの金額設定は無視される
    groupFixedBillingFee?: {
      normalChildcareBillingAmount: number;
      shortenedChildcareBillingAmount: number;
    };
    earlyHoursBillingFee?: {
      billingMethod?: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    };
    shortenedBillingFee?: {
      before: {
        billingMethod?: string; // hourly, monthly
        hourly?: {
          billingMinute?: number;
          billingAmount?: number;
          billingUpperLimitAmount?: number;
        };
        monthly?: {
          billingAmount?: number;
        };
      };
      after: {
        billingMethod?: string; // hourly, monthly
        hourly?: {
          billingMinute?: number;
          billingAmount?: number;
          billingUpperLimitAmount?: number;
        };
        monthly?: {
          billingAmount?: number;
        };
      };
    };
  }) {
    const response = await ApiClient.put(
      `/nursery-hours/spot-nursery/${input.patternId}`,
      input
    );
    return response;
  }

  // パターン: 朝夕通し時間枠
  public static async createPatternSpotCombination(input: {
    nurseryId: number;
    patternTitle: string;
    normalChildcareStartingHour: number;
    normalChildcareStartingMinute: number;
    shortenedChildcareStartingHour: number;
    shortenedChildcareStartingMinute: number;
  }) {
    const response = await ApiClient.post(
      "/nursery-hours/spot-combination",
      input
    );
    return response;
  }

  public static async updatePatternSpotCombination(input: {
    patternId: string;
    patternTitle: string;
    normalChildcareStartingHour: number;
    normalChildcareStartingMinute: number;
    shortenedChildcareStartingHour: number;
    shortenedChildcareStartingMinute: number;
  }) {
    const response = await ApiClient.put(
      `/nursery-hours/spot-combination/${input.patternId}`,
      input
    );
    return response;
  }

  // 時間枠
  public static async createSpans(input: {
    patternId: string;
    spans: {
      startingHour?: number; // 0-23
      startingMinute?: number; // 0-59
      endingHour?: number;
      endingMinute?: number;
      billingMethod?: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    }[];
    spanCategory?: string; // 朝夕通し時間枠の場合は必須
  }) {
    const response = await ApiClient.post(
      `/nursery-hours/span/${input.patternId}`,
      input
    );
    return response;
  }

  public static async updateSpans(input: {
    patternId: string;
    spans: {
      startingHour?: number; // 0-23
      startingMinute?: number; // 0-59
      endingHour?: number;
      endingMinute?: number;
      billingMethod?: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    }[];
    spanCategory?: string; // 朝夕通し時間枠の場合は必須
  }) {
    const response = await ApiClient.put(
      `/nursery-hours/span/${input.patternId}`,
      input
    );
    return response;
  }
}
