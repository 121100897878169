
import Vue from "vue";
import DatePager from "@/components/atoms/DatePager.vue";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import { NurseryClassResponse, TimecardListResponse } from "chaild-api/lib";
import {
  ageItems,
  genderItems,
  statusItems,
  staffStatusItems,
} from "@/constants/attendance";
import dayjs from "dayjs";
import { staffAttandanceListModule } from "@/store/viewModules/timecard/staffAttendanceListModule";
import ApiTimecard from "@/api/ApiTimecard";

export default Vue.extend({
  name: "StaffAttendanceList",

  components: {
    DatePager,
  },

  watch: {
    searchFilters: {
      deep: true,
      async handler() {
        await this.searchAttendance();
      },
    },
  },

  async mounted() {
    this.searchAttendance();
    this.listClasses();
  },

  data() {
    return {
      loading: false,
      searchFilters: {
        date: dayjs().format("YYYY-MM-DD"),
        age: "",
        classId: "",
        gender: "",
        status: "" as string,
        limit: 1000,
        skip: 0,
      },
      genderItems: genderItems,
      ageItems: ageItems,
      statusItems: statusItems,
    };
  },

  computed: {
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
    attendances: function (): TimecardListResponse | null {
      return staffAttandanceListModule.attendances;
    },
  },

  methods: {
    async listClasses() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule
          .listClass({
            nurseryId,
          })
          .catch();
      }
      this.loading = false;
    },
    async searchAttendance() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await staffAttandanceListModule.getAttendances({
          date: this.searchFilters.date,
          classId: this.searchFilters.classId || undefined,
        });
      }
      this.loading = false;
    },
    formatDate(date: string | null | undefined) {
      if (date) {
        return dayjs(date).format("HH:mm");
      }
      return null;
    },
    getClassName(classes: NurseryClassResponse[]) {
      return classes.map((c) => c.className).join(", ");
    },
    getAgeBasedonBirthdate(birthdate: string): number {
      const thisYear = dayjs();
      return thisYear.diff(birthdate, "year");
    },
    getStatusText(value: string): string {
      if (!value) {
        return "";
      }
      return staffStatusItems.find((s) => s.value == value)?.text || "";
    },
    async exportDaily() {
      const nurseryId = LocalDataService.getNurseryId();
      if (this.searchFilters.date && nurseryId) {
        this.loading = true;
        const response = await ApiTimecard.exportAttendance({
          date: this.searchFilters.date,
          nurseryId,
          fileType: "dailyCsv",
        });
        this.loading = false;

        if (response) {
          window.open(response.preSignedDownloadUrl, "_blank");
        }
      }
    },
    async exportMonthly() {
      const nurseryId = LocalDataService.getNurseryId();
      if (this.searchFilters.date && nurseryId) {
        this.loading = true;
        const response = await ApiTimecard.exportAttendance({
          date: this.searchFilters.date,
          nurseryId,
          fileType: "monthlyCsv",
        });
        this.loading = false;

        if (response) {
          window.open(response.preSignedDownloadUrl, "_blank");
        }
      }
    },
  },
});
