
import DialogMixin from '@/mixins/DialogMixin'
import { chatEditModule } from '@/store/dataModules/chatEditModule'
import { GroupChatResponse, NurseryGroupListResponse } from 'chaild-api/lib'
import { NurseryUserClassResponse } from 'chaild-api/src/component/nursery/user/types'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'ChatDetailDialog',

  mixins: [DialogMixin],

  props: {
    chat: {
      type: Object as PropType<GroupChatResponse>,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getClassNames(classes: NurseryUserClassResponse[]): string {
      if (classes && classes.length > 0) {
        const names = classes.map((c) => {
          if (c.className) {
            return c.className;
          }
        })
        return names.join();
      } else {
        return '';
      }
    },
    getGroupNames(groups: NurseryGroupListResponse): string {
      if (groups && groups.length > 0) {
        const names = groups.map((g) => {
          if (g.name) {
            return g.name;
          }
        });
        return names.join();
      } else {
        return '';
      }
    },
    editMessage() {
      if (this.chat && this.isDraft) {
        chatEditModule.setTitle(this.chat.title || "");
        chatEditModule.setBody(this.chat.body || "");
        chatEditModule.setClassIds(this.chat.to.classes.map((c) => { return c.classId }));
        this.$router.push({ path: '/mail/new', query: { id: String(this.chat.groupChatId) }});
      }
    },
  },
})
