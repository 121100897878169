
import Vue from "vue";

import PageTitle from "@/components/atoms/PageTitle.vue";
import ChildDevelopmentsContainer from "@/containers/child-development/ChildDevelopmentsContainer.vue";

export default Vue.extend({
  components: {
    PageTitle,
    ChildDevelopmentsContainer,
  },
});
