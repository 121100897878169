import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiEvent from "@/api/ApiEvent";

export interface EventEditState {
  eventId: string;
  eventName: string;
  classIds: number[];
  toDate: string;
  fromDate: string;
  fromTime: string;
  toTime: string;
}

/* eslint-disable */
@Module({ dynamic: true, store, name: "event-edit", namespaced: true })
class EventEditModule extends VuexModule implements EventEditState {
  public eventId: string = "";
  public eventName: string = "";
  public classIds: number[] = [];
  public fromDate: string = "";
  public toDate: string = "";
  public fromTime: string = "";
  public toTime: string = "";

  @Action({ rawError: true })
  public async addEvent(input: { nurseryId: number }) {
    let times = {};
    if (this.fromTime && this.toTime) {
      times = {
        fromTime: this.fromTime,
        toTime: this.toTime,
      };
    }
    const response = await ApiEvent.addEvent({
      nurseryId: input.nurseryId,
      eventName: this.eventName,
      classIds: this.classIds,
      fromDate: this.fromDate,
      toDate: this.toDate,
      ...times,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateEvent(input: { nurseryId: number }) {
    let times = {};
    if (this.fromTime && this.toTime) {
      times = {
        fromTime: this.fromTime,
        toTime: this.toTime,
      };
    }
    const response = await ApiEvent.updateEvent({
      nurseryId: input.nurseryId,
      eventId: this.eventId,
      eventName: this.eventName,
      classIds: this.classIds,
      date: this.fromDate,
      ...times,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteEvent(input: { nurseryId: number }) {
    const response = await ApiEvent.deleteEvent({
      nurseryId: input.nurseryId,
      eventId: this.eventId,
    });
    if (response) {
      return response;
    }
  }

  @Mutation
  public setEventId(value: string) {
    this.eventId = value;
  }

  @Mutation
  public setEventName(value: string) {
    this.eventName = value;
  }

  @Mutation
  public setClassIds(value: number[]) {
    this.classIds = value;
  }

  @Mutation
  public setFromDate(value: string) {
    this.fromDate = value;
  }

  @Mutation
  public setToDate(value: string) {
    this.toDate = value;
  }

  @Mutation
  public setFromTime(value: string) {
    this.fromTime = value;
  }

  @Mutation
  public setToTime(value: string) {
    this.toTime = value;
  }

  @Mutation
  public clearValues() {
    this.eventName = "";
    this.classIds = [];
    this.fromDate = "";
    this.toDate = "";
    this.fromTime = "";
    this.toTime = "";
  }
}

export const eventEditModule = getModule(EventEditModule);
