import ApiClient from "./ApiClient";

export default class ApiSystemSetting {
  static async getSetting(input: { nurseryId: number }) {
    return await ApiClient.get(`/nursery/${input.nurseryId}/settings`, null);
  }

  static async updateSetting(input: {
    nurseryId: number;
    chat: {
      isEnabled: boolean;
    };
  }) {
    return await ApiClient.put(`/nursery/${input.nurseryId}/settings`, input);
  }
}
