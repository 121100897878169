
import Vue from "vue";
import ApiGuidance from "@/api/ApiGuidance";
import {
  GuidancePlanFormItemCategoryItems,
  GuidancePlanTypeItems,
} from "@/constants/guidance";
import LocalDataService from "@/service/LocalDataService";
import {
  GuidancePlanCategoryType,
  NurseryGuidancePlanFormDetail,
  NurseryGuidancePlanFormItemListResponse,
  NurseryGuidancePlanFormListResponse,
} from "chaild-api/lib";
import dayjs from "dayjs";

type ItemList = {
  itemId: string;
  order?: number; // orderは送信時に算出する
};

export default Vue.extend({
  name: "GuidancePlanFormList",

  async mounted() {
    await this.listForm();
    await this.listFormItem();
  },

  data() {
    return {
      loading: false,
      editDialog: false,
      planItems: GuidancePlanTypeItems,
      categoryItems: GuidancePlanFormItemCategoryItems,
      headers: [
        { text: "テンプレート名称", value: "name" },
        { text: "種類", value: "type" },
        { text: "作成日", value: "createdAt" },
        { text: "編集", value: "edit" },
        { text: "複製", value: "duplicate" },
        { text: "削除", value: "delete" },
      ],
      searchFilters: {
        limit: 2000,
        skip: 0,
        type: "" as GuidancePlanCategoryType,
      },
      input: {
        name: "",
        type: "" as GuidancePlanCategoryType,
        itemList: [] as ItemList[],
        formId: 0,
      },
      /**
       * 帳票
       */
      formResponse: null as null | NurseryGuidancePlanFormListResponse,
      /**
       * 帳票項目
       */
      itemResponse: null as null | NurseryGuidancePlanFormItemListResponse,
    };
  },

  computed: {
    isUpdate: function (): boolean {
      return !!this.input.formId;
    },
    planFormItems: function (): any[] {
      if (this.itemResponse) {
        return this.itemResponse.data.map((i) => {
          return {
            text: i.name,
            value: i.id,
          };
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    async listForm() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.listPlanForm({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.formResponse = response;
        }
      }
      this.loading = false;
    },
    async createForm() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        /**
         * orderを計算
         */
        this.input.itemList = this.calcItemListOrder();
        const response = await ApiGuidance.createPlanForm({
          nurseryId,
          ...this.input,
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listForm();
        }
      }
      this.loading = false;
    },
    async updateForm() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        /**
         * orderを計算
         */
        this.input.itemList = this.calcItemListOrder();
        const response = await ApiGuidance.updatePlanForm({
          nurseryId,
          ...this.input,
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listForm();
        }
      }
      this.loading = false;
    },
    async deleteForm(formId: number) {
      const answer = confirm("削除しますか");
      if (answer) {
        this.loading = true;
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId) {
          const response = await ApiGuidance.deletePlanForm({
            nurseryId,
            formId,
          }).catch(() => (this.loading = false));
          if (response) {
            alert("削除しました");
            await this.listForm();
          }
        }
      }
      this.loading = false;
    },
    async listFormItem() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.listPlanFormItem({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.itemResponse = response;
        }
      }
      this.loading = false;
    },
    openEditDialog(value?: NurseryGuidancePlanFormDetail) {
      if (value) {
        this.setInput(value);
      } else {
        this.clearInput();
      }
      this.editDialog = true;
    },
    clearInput() {
      this.input = {
        name: "",
        type: "" as GuidancePlanCategoryType,
        itemList: [] as ItemList[],
        formId: 0,
      };
    },
    setInput(value: NurseryGuidancePlanFormDetail) {
      this.input = {
        name: value.name,
        formId: value.id,
        type: value.type,
        itemList: value.itemList.map((item: any) => {
          return {
            itemId: item.item.id,
          };
        }),
      };
    },
    /**
     * ItemList.push
     */
    AddItemToItemList() {
      this.input.itemList.push({
        itemId: "",
      });
    },
    /**
     * itemList.delete
     */
    deleteItemInItemList(order: number) {
      this.input.itemList.splice(order, 1);
    },
    /**
     * calc order
     */
    calcItemListOrder(): ItemList[] {
      return this.input.itemList.map((item, index) => {
        return {
          itemId: item.itemId,
          order: index + 1,
        };
      });
    },
    getPlanText(value: string): string {
      const item = this.planItems.find((p) => p.value == value);
      return item?.text || "";
    },
    getCategryText(value: string): string {
      const item = this.categoryItems.find((c) => c.value == value);
      return item?.text || "";
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
