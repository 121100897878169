import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryAttendanceResponse } from "chaild-api/lib";
import ApiAttendance from "@/api/ApiAttendance";
import LocalDataService from "@/service/LocalDataService";

/* eslint-disable */
export interface AttendanceEditState {
  attendance: NurseryAttendanceResponse | null;

  childId: number | null;
  date: string | null;
}

@Module({ dynamic: true, store, name: "attendance-edit", namespaced: true })
class AttendanceEditModule extends VuexModule implements AttendanceEditState {
  public attendance: NurseryAttendanceResponse | null = null;

  public childId: number | null = null;
  public date: string | null = null;

  @Action({ rawError: true })
  public async getAttendance() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.childId && this.date) {
      const response = await ApiAttendance.getAttendance({
        childId: this.childId,
        nurseryId: nurseryId,
        date: this.date,
      });
      if (response) {
        this.setAttendance(response);
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async updateAttendance() {
    const nurseryId = LocalDataService.getNurseryId();
    if (
      nurseryId &&
      this.childId &&
      this.attendance &&
      (this.attendance.attendedAt || this.attendance.leftAt)
    ) {
      const response = await ApiAttendance.updateAttendance({
        nurseryId,
        childId: this.childId,
        attendanceId: this.attendance.attendanceId,
        attendedAt: this.attendance.attendedAt
          ? this.attendance.attendedAt
          : undefined,
        leftAt: this.attendance.leftAt ? this.attendance.leftAt : undefined,
      }).catch((e) => console.warn(e));

      if (response) {
        this.setAttendance(response);
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async createAttendance() {
    const nurseryId = LocalDataService.getNurseryId();
    if (
      nurseryId &&
      this.childId &&
      this.date &&
      this.attendance &&
      (this.attendance.attendedAt || this.attendance.leftAt)
    ) {
      const response = await ApiAttendance.createAttendance({
        nurseryId,
        childId: this.childId,
        attendedAt: this.attendance.attendedAt
          ? this.attendance.attendedAt
          : undefined,
        leftAt: this.attendance.leftAt ? this.attendance.leftAt : undefined,
        date: this.date,
      }).catch((e) => console.warn(e));

      if (response) {
        this.setAttendance(response);
        return response;
      }
    }
  }

  @Mutation
  public setAttendance(value: NurseryAttendanceResponse) {
    this.attendance = value;
  }

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Mutation
  public setDate(date: string | null) {
    this.date = date;
  }

  @Mutation
  public setAttendedAtValue(attendedAt: string) {
    if (this.attendance) {
      this.attendance.attendedAt = attendedAt;
    }
  }

  @Mutation
  public setLeftAtValue(leftAt: string) {
    if (this.attendance) {
      this.attendance.leftAt = leftAt;
    }
  }
}

export const attendanceEditModule = getModule(AttendanceEditModule);
