import ApiClient from '@/api/ApiClient';
import { DailyReportResponse } from 'chaild-api/lib';

export default class ApiChildReport {
  // 園児の日誌
  public static async fetchChildDiary(childId: number, date: string): Promise<DailyReportResponse | null> {
    const response = await ApiClient.get(`/children/${childId}/report?from=${date}&to=${date}`, null);
    if (response && response.reports && response.reports.length > 0) {
      const report = response.reports[0] as DailyReportResponse;
      return report;
    }
    return null;
  }
}