/**
 * おたより
 */

import ApiClient from "./ApiClient";

export default class ApiLetter {

  static async listTemplates(input: {
    isFullColor: boolean;
    month: number | string;
    nurseryId: number;
  }) {
    return await ApiClient.get(`/letter/template`, input);
  }

  static async listLetters(input: {
    nurseryId: number;
    from?: string;
    to?: string;
    limit?: number;
    skip?: number;
  }) {
    return await ApiClient.get(`/letter`, input);
  }

  static async createLetter(input: {
    nurseryId: number;
    date: string; // YYYY-MM-DD
    name: string;
    className?: string;
    designSlug: string;
    body: any;
    createdBy: string;
  }) {
    return await ApiClient.post(`/letter`, input);
  }

  static async updateLetter(input: {
    letterId: string;
    date: string; // YYYY-MM-DD
    name: string;
    className?: string;
    designSlug: string;
    body: any;
    createdBy: string;
  }) {
    return await ApiClient.put(`/letter/${input.letterId}`, input);
  }

  static async getLetter(input: {
    letterId: string;
  }) {
    return await ApiClient.get(`/letter/${input.letterId}`, null);
  }

  static async deleteLetter(input: {
    letterId: string;
  }) {
    return await ApiClient.delete(`/letter/${input.letterId}`, null);
  }

  static async duplicateLetter(input: {
    letterId: string;
  }) {
    return await ApiClient.post(`/letter/${input.letterId}/copy`, null);
  }

  static async exportLetter(input: {
    letterId: string;
  }) {
    return await ApiClient.post(`/letter/${input.letterId}/pdf`, null);
  }

}