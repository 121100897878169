import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiNurseryReport from "@/api/ApiNurseryReport";

/* eslint-disable */
export interface NurseryReportEditState {
  date: string;
  aim: string;
  activityAm: string;
  activityPm: string;
  comment: string;
  event: string;
  absentee: string;
  status: "draft" | "applied";
}

@Module({ dynamic: true, store, name: "nursery-report-edit", namespaced: true })
class NurseryReportEditModule
  extends VuexModule
  implements NurseryReportEditState
{
  public date: string = "";
  public aim: string = "";
  public activityAm: string = "";
  public activityPm: string = "";
  public comment: string = "";
  public event: string = "";
  public absentee: string = "";
  public status: "draft" | "applied" = "draft";

  @Action({ rawError: true })
  public async createReport(input: { nurseryId: number }) {
    const response = await ApiNurseryReport.createNurseryReport({
      nurseryId: input.nurseryId,
      date: this.date,
      aim: this.aim,
      activityAm: this.activityAm,
      activityPm: this.activityPm,
      comment: this.comment,
      event: this.event,
      absentee: this.absentee,
      status: this.status,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateReport(input: {
    nurseryId: number;
    schoolDiaryId: number;
  }) {
    const response = await ApiNurseryReport.updateNurseryReport({
      nurseryId: input.nurseryId,
      schoolDiaryId: input.schoolDiaryId,
      date: this.date,
      aim: this.aim,
      activityAm: this.activityAm,
      activityPm: this.activityPm,
      comment: this.comment,
      event: this.event,
      absentee: this.absentee,
      status: this.status,
    });
    if (response) {
      return response;
    }
  }

  @Mutation
  public setDate(value: string) {
    this.date = value;
  }

  @Mutation
  public setAim(value: string) {
    this.aim = value;
  }

  @Mutation
  public setActivityAm(value: string) {
    this.activityAm = value;
  }

  @Mutation
  public setActivityPm(value: string) {
    this.activityPm = value;
  }

  @Mutation
  public setComment(value: string) {
    this.comment = value;
  }

  @Mutation
  public setEvent(value: string) {
    this.event = value;
  }

  @Mutation
  public setAbsentee(value: string) {
    this.absentee = value;
  }

  @Mutation
  public setStatus(value: "draft" | "applied") {
    this.status = value;
  }
}

export const nurseryReportEditModule = getModule(NurseryReportEditModule);
