/**
 * サイドメニューの項目
 */

type NavMenu = {
  name: string;
  features: {
    slug: string;
    path?: string;
    href?: string;
    target?: string;
  }[];
};

export const navMenuItems: NavMenu[] = [
  {
    name: "出欠管理",
    features: [
      { slug: "nursery-web-attendance-list", path: "/attendance/list" },
      {
        slug: "nursery-web-attendance-management",
        path: "/attendance/management",
        target: "_blank",
      },
    ],
  },
  {
    name: "午睡",
    features: [{ slug: "nursery-web-nap-list", path: "/nap/list" }],
  },
  {
    name: "日誌",
    features: [
      { slug: "nursery-web-report-diary", path: "/dailyreport/child" },
      { slug: "nursery-web-report-class-diary", path: "/class-diaries" },
      { slug: "nursery-web-report-nursery-diary", path: "/school-diary" },
      {
        slug: "nursery-web-report-nursery-hours-diary",
        path: "/nursery-hours-diary/list",
      },
      { slug: "nursery-web-report-diary-alert", path: "/diary-setting" },
    ],
  },
  {
    name: "献立・検食",
    features: [{ slug: "nursery-web-meal-plan", path: "/food-menus" }],
  },
  {
    name: "成長記録",
    features: [{ slug: "nursery-web-growth-record", path: "/growth-record" }],
  },
  {
    name: "発達記録",
    features: [
      {
        slug: "nursery-web-child-development-record",
        path: "/child-development",
      },
    ],
  },
  {
    name: "行事",
    features: [{ slug: "nursery-web-event-yearly-event", path: "/calendar" }],
  },
  {
    name: "連絡",
    features: [{ slug: "nursery-web-messaging-inbox", path: "/mail/inbox" }],
  },
  {
    name: "経理",
    features: [
      { slug: "nursery-web-accounting-invoice", path: "/invoice" },
      { slug: "nursery-web-accounting-fee", path: "/fee-setting" },
      { slug: "nursery-web-accounting-cashbook", path: "/cashbook" },
      {
        slug: "nursery-web-accounting-nursery-hours-pattern",
        path: "/nursery-hours",
      },
    ],
  },
  {
    name: "園提供サービス",
    features: [
      { slug: "nursery-web-school-service-list", path: "/school-service" },
    ],
  },
  {
    name: "職員勤怠",
    features: [
      {
        slug: "nursery-web-staff-attendance-list",
        path: "/staffAttendance/list",
      },
      {
        slug: "nursery-web-staff-attendance-management",
        path: "/staffAttendance/management",
      },
    ],
  },
  {
    name: "ワークスケジューリング",
    features: [
      {
        slug: "nursery-web-work-scheduling-monthly-schedule",
        path: "/work-scheduling/monthly",
      },
      {
        slug: "nursery-web-work-scheduling-daily-schedule",
        path: "/work-scheduling/daily",
      },
      {
        slug: "nursery-web-work-scheduling-shift-pattern",
        path: "/work-scheduling/shift",
      },
      {
        slug: "nursery-web-work-scheduling-attendance-schedule",
        path: "/work-scheduling/attendance",
      },
      {
        slug: "nursery-web-work-scheduling-placement-setting",
        path: "/work-scheduling/placement-setting",
      },
    ],
  },
  {
    name: "MEMORU",
    features: [{ slug: "nursery-web-memoru-album", path: "/memoru/top" }],
  },
//   {
//     name: "指導計画",
//     features: [
//       { slug: "nursery-web-guidance-plan-list", path: "/guidance-plan/list" },
//       {
//         slug: "nursery-web-guidance-plan-form-setting",
//         path: "/guidance-plan-form/list",
//       },
//       {
//         slug: "nursery-web-guidance-plan-form-item-setting",
//         path: "/guidance-plan-form-item/list",
//       },
//       {
//         slug: "nursery-web-guidance-plan-master-setting",
//         path: "/guidance-plan-form-mast/list",
//       },
//     ],
//   },
  {
    name: "おたより",
    features: [{ slug: "nursery-web-letter-list", path: "/letter/list" }],
  },
  {
    name: "アカウント管理",
    features: [
      { slug: "nursery-web-account-management-children", path: "/children" },
      { slug: "nursery-web-account-management-staff", path: "/setting/staff" },
    ],
  },
  {
    name: "施設設定",
    features: [
      { slug: "nursery-web-school-setting-class", path: "/setting/class" },
      { slug: "nursery-web-school-setting-group", path: "/setting/group" },
      { slug: "nursery-web-school-setting-pasori", path: "/pasori" },
    ],
  },
  {
    name: "その他",
    features: [
      { slug: "nursery-web-setting-download-list", path: "/download/list" },
      {
        slug: "nursery-web-setting-manual",
        href: "https://ccs-staff-manual.super.site/",
        target: "_blank",
      },
      { slug: "nursery-web-setting-system", path: "/system-setting/list" },
      { slug: "nursery-web-setting-auth", path: "/admin" },
      { slug: "nursery-web-setting-logout", path: "/logout" },
    ],
  },
];
