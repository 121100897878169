
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import { NurseryHoursPatternResponse } from "chaild-api/lib";
import { nurseryHoursModule } from "@/store/dataModules/nurseryHoursModule";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";

export default Vue.extend({
  name: "NurseryHours",

  components: {
    PageTitle,
  },

  async mounted() {
    await this.listPattern();
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    patternList: function (): NurseryHoursPatternResponse[] {
      return nurseryHoursModule.patternList;
    },
  },

  methods: {
    async listPattern() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await nurseryHoursModule.listPattern({
          nurseryId,
        });
      }
      this.isLoading = false;
    },
    async clickEditPattern(input: { patternId: string; category: string }) {
      this.isLoading = true;
      const response = await nurseryHoursModule.getPatternDetail({
        patternId: input.patternId,
      });
      if (response) {
        if (input.category == "monthly") {
          // monthly
          nurseryHoursModule.setMonthlyPatternInput(response);
        } else if (input.category == "spotNursery") {
          // spot nursery
          nurseryHoursModule.setSpotNurseryPatternInput(response);
          nurseryHoursModule.setSpansInput({
            patternId: response.patternId,
            spans: response.extendedBillingSpans,
          });
        } else if (input.category == "spotKindergarten") {
          // spot kindergarten
          nurseryHoursModule.setSpotKindergartenPatternInput(response);
          nurseryHoursModule.setSpansInput({
            patternId: response.patternId,
            spans: response.extendedBillingSpans,
          });
        } else if (input.category == "spotCombination") {
          // spot combination
          nurseryHoursModule.setSpotCombinationPatternInput(response);
          nurseryHoursModule.setSpansInput({
            patternId: response.patternId,
            spans: response.normalExtendedBillingSpans,
          });
        } else if (input.category == "spotFlexible") {
          nurseryHoursModule.setSpotFlexiblePatternInput(response);
          nurseryHoursModule.setSpansInput({
            patternId: response.patternId,
            spans: response.extendedBillingSpans,
          });
        }
        this.$router.push({
          path: "/nursery-hours/edit",
          query: {
            category: input.category,
            patternId: input.patternId,
          },
        });
      }
      this.isLoading = false;
    },
    async setDefaultPattern(patternId: string) {
      this.isLoading = true;
      if (patternId != null) {
        const response = await nurseryHoursModule.setDefault({
          patternId,
        });
        if (response) {
          await this.listPattern();
          alert("デフォルトパターンを変更しました");
        }
      }
      this.isLoading = false;
    },
    formatDate(value: string): string {
      return dayjs(value).format("YYYY-MM-DD hh:mm");
    },
    getCategoryName(value: string): string {
      if (value == "monthly") {
        return "月極";
      } else if (value == "spotNursery") {
        return "固定時間枠（保育園）";
      } else if (value == "spotKindergarten") {
        return "固定時間枠（幼稚園）";
      } else if (value == "spotFlexible") {
        return "変動時間枠";
      } else if (value == "spotCombination") {
        return "朝夕通し時間枠";
      } else {
        return "";
      }
    },
  },
});
