
import Vue from "vue";

import { ClassDiaryResponse, NurseryClassResponse } from "chaild-api/lib";
import { classModule } from "@/store/dataModules/classModule";
import dayjs from "dayjs";
import { classDiaryModle } from "@/store/dataModules/classDiaryModue";
import ApiClassDiary from "@/api/ApiClassDiary";
import LocalDataService from "@/service/LocalDataService";
import { nurseryUserModule } from "@/store/dataModules/nurseryUserModule";

interface DataType {
  classId: number | null;
  date: string;
  aim: string;
  activityAm: string;
  activityPm: string;
  assessment: string;
  support: string;
  appliedBy: number | null;

  isSaving: boolean;

  existingError: boolean;
  existingDiary: ClassDiaryResponse | null;
}

interface BreadcrumbItem {
  text: string;
  disabled: boolean;
  href: string;
}

export default Vue.extend({
  computed: {
    breadcrumbs(): Array<BreadcrumbItem> {
      return [
        {
          text: "クラス日誌一覧",
          disabled: false,
          href: "/class-diaries",
        },
        {
          text: "新規作成",
          disabled: true,
          href: "",
        },
      ];
    },
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    isContentValid(): boolean {
      if (this.date && this.date.trim().length > 0) {
        return true;
      }
      return false;
    },
    selectedClassName(): string {
      if (this.classId) {
        const find = this.classes.find((c) => c.classId === this.classId);
        if (find) {
          return find.className;
        }
      }
      return "";
    },
    formattedDate() {
      return dayjs(this.date).format("YYYY年MM月DD日");
    },
    staffOptions() {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`,
        };
      });
    },
  },

  methods: {
    async createAndApply() {
      if (this.classId && this.appliedBy) {
        this.isSaving = true;
        await classDiaryModle.createDiary({
          classId: this.classId,
          date: this.date,
          aim: this.aim,
          activityAm: this.activityAm,
          activityPm: this.activityPm,
          assessment: this.assessment,
          support: this.support,
          status: "applied",
          appliedBy: this.appliedBy,
        });
        this.isSaving = false;

        this.$router.push({ name: "ClassDiaries" });
      }
    },
    async createAsDraft() {
      if (this.classId) {
        this.isSaving = true;
        await classDiaryModle.createDiary({
          classId: this.classId,
          date: this.date,
          aim: this.aim,
          activityAm: this.activityAm,
          activityPm: this.activityPm,
          assessment: this.assessment,
          support: this.support,
          status: "draft",
        });
        this.isSaving = false;

        this.$router.push({ name: "ClassDiaries" });
      }
    },
    async checkExistance() {
      if (this.classId && this.date) {
        const find = await ApiClassDiary.getClassDiary({
          classId: this.classId,
          date: this.date,
        });
        if (find) {
          this.existingDiary = find;
        } else {
          this.existingDiary = null;
        }
      }
    },
  },

  data: (): DataType => {
    return {
      classId: null,
      date: dayjs().format("YYYY-MM-DD"),
      aim: "",
      activityAm: "",
      activityPm: "",
      assessment: "",
      support: "",
      isSaving: false,
      appliedBy: null,

      existingError: false,
      existingDiary: null,
    };
  },

  watch: {
    classId: {
      handler() {
        this.checkExistance();
      },
    },
    date: {
      handler() {
        this.checkExistance();
      },
    },
  },

  mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      classModule.listClass({
        nurseryId: nurseryId,
      });
      nurseryUserModule.listStaffs();
    }

    const classId = this.$route.query.classId;
    if (classId) {
      try {
        const parsed = parseInt(classId as string);
        this.classId = parsed;
      } catch {
        return;
      }
    }
  },
});
