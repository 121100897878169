import { render, staticRenderFns } from "./PasoriVevoContainer.vue?vue&type=template&id=10912cc7&scoped=true"
import script from "./PasoriVevoContainer.vue?vue&type=script&lang=ts"
export * from "./PasoriVevoContainer.vue?vue&type=script&lang=ts"
import style0 from "./PasoriVevoContainer.vue?vue&type=style&index=0&id=10912cc7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10912cc7",
  null
  
)

export default component.exports