import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index'
import { NurseryChildResponse } from 'chaild-api/lib';
import ApiChildren from '@/api/ApiChildren';
import ApiDevice, { PasoriDevice } from '@/api/ApiDevice';
import LocalDataService from '@/service/LocalDataService';

@Module({ dynamic: true, store, name: 'pasori-sensor', namespaced: true })
class PasoriSensorModule extends VuexModule {
  public children: NurseryChildResponse[] = [];
  public sensors: PasoriDevice[] = [];

  @Action
  public async listChildren({
    classId,
  }: {
    classId: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId()
    if (nurseryId) {
      const response = await ApiChildren.listChildren({
        nurseryId,
        classId
      });
      this.setChildren(response);
    }
  }

  @Mutation
  public setChildren(children: NurseryChildResponse[]) {
    this.children = children;
  }

  @Action
  public async listSensors({
    classId,
  }: {
    classId: number;
  }) {
    const nurseryId = LocalDataService.getNurseryId()
    if (nurseryId) {
      const response = await ApiDevice.listSensorDevices({
        nurseryId,
        classId,
      });
      if (response) {
        this.setSensors(response.sensors)
      }
    }
  }

  @Mutation
  public setSensors(sensors: PasoriDevice[]) {
    this.sensors = sensors;
  }

  @Action
  public async registerSensor({
    sid,
    childId,
    classId
  }: {
    sid: string;
    childId: number;
    classId: number;
  }){
    const response = await ApiDevice.registerSensorDevice({
      sid,
      childId,
    });
    this.listSensors({ classId })
  }

  @Action
  public async removeSensor({
    sid,
    childId,
    classId
  }: {
    sid: string;
    childId: number;
    classId: number;
  }){
    const response = await ApiDevice.removeSensorDevice({
      sid,
      childId,
    });
    this.listSensors({ classId })
  }
}

export const pasoriSensorModule = getModule(PasoriSensorModule);
