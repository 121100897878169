import { render, staticRenderFns } from "./ChildGrowthRecordRow.vue?vue&type=template&id=37660b30&scoped=true"
import script from "./ChildGrowthRecordRow.vue?vue&type=script&lang=ts"
export * from "./ChildGrowthRecordRow.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37660b30",
  null
  
)

export default component.exports