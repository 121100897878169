
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import {
  ChildResponse,
  NurseryClassResponse,
  NurseryFeeResponse,
} from "chaild-api/lib";
import { feeModule } from "@/store/dataModules/feeModule";
import LocalDataService from "@/service/LocalDataService";
import AgePicker from "@/components/atoms/AgePicker.vue";
import ClassPicker from "@/components/atoms/ClassPicker.vue";
import { classModule } from "@/store/dataModules/classModule";
import Swal from "sweetalert2";
import ValidationMixin from "@/mixins/ValidationMixin";
import dayjs from "dayjs";

export default Vue.extend({
  name: "FeeSetting",

  mixins: [ValidationMixin],

  components: {
    PageTitle,
    ClassPicker,
    AgePicker,
  },

  watch: {
    editDialog(value) {
      if (!value) {
        this.clearInput();
      }
    },
  },

  data() {
    return {
      isLoading: false,
      editDialog: false,
      searchFilter: {
        gender: "" as "" | "male" | "female",
        age: null as null | number,
        classId: null as null | number,
        isQuit: false,
      },
      genderItems: [
        { text: "すべて", value: "" },
        { text: "男性", value: "male" },
        { text: "女性", value: "female" },
      ],
      certifiedCategoryItems: [
        { text: "1号", value: "one" },
        { text: "2号", value: "two" },
        { text: "3号", value: "three" },
      ],
      childcareCategoryItems: [
        { text: "標準時間保育", value: "normal" },
        { text: "短時間保育", value: "short" },
      ],
      input: {
        childId: "",
        nurseryFeeId: "",
        certificationNumber: "",
        periodStart: "",
        periodEnd: "" as string | null,
        rank: "",
        certifiedCategory: "one",
        childcareCategory: "normal",
        fee: "",
        isFoodCostExempted: false,
        prefecture: "",
        city: "",
        localCode: "",
      },
      isDialogFormValid: false,
    };
  },

  async mounted() {
    await this.searchFee();
    await this.listClass();
  },

  computed: {
    feeList: function (): NurseryFeeResponse[] {
      if (this.searchFilter.isQuit) {
        // return feeModule.feeList.filter((f) => f.child.quitDate != null);
        return feeModule.feeList.filter((f) =>
          dayjs().isAfter(f.child.quitDate)
        );
      } else {
        return feeModule.feeList.filter(
          (f) => f.child.quitDate == null || !dayjs().isAfter(f.child.quitDate)
        );
      }
    },
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
    isUpdate: function (): boolean {
      return this.input.nurseryFeeId !== "";
    },
  },

  methods: {
    async searchFee() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await feeModule.searchFee({
          nurseryId: nurseryId,
          age: this.searchFilter.age
            ? String(this.searchFilter.age)
            : undefined,
          gender: this.searchFilter.age ? this.searchFilter.gender : undefined,
          classId: this.searchFilter.classId
            ? String(this.searchFilter.classId)
            : undefined,
          limit: 1000,
          skip: 0,
        });
      }
      this.isLoading = false;
    },
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId,
        });
      }
    },
    async clickSave() {
      if (this.isUpdate) {
        await this.updateFee();
      } else {
        await this.createFee();
      }
    },
    async createFee() {
      this.isLoading = true;
      const response = await feeModule
        .createFee({ ...this.input })
        .catch(() => {
          alert(
            "認定・保育料の期間指定が不正です。他の期間との重複がないか確認してください。"
          );
          this.editDialog = false;
        });
      if (response) {
        Swal.fire({ title: "保存しました" });
        this.editDialog = false;
        await this.searchFee();
      }
      this.isLoading = false;
    },
    async updateFee() {
      this.isLoading = true;
      const response = await feeModule
        .updateFee({ ...this.input })
        .catch(() => {
          alert(
            "この月の売上情報が確定しているため、 対象期間を含む保育料・認定情報は編集できません。"
          );
          this.editDialog = false;
        });
      if (response) {
        Swal.fire({ title: "保存しました" });
        this.editDialog = false;
        await this.searchFee();
      }
      this.isLoading = false;
    },
    // selectChild(feeId?: number) {
    selectChild(input: { child: ChildResponse; fee?: NurseryFeeResponse }) {
      if (input.child) {
        // const _fee = this.feeList.find(f => f.nurseryFeeId == feeId);
        this.input.childId = String(input.child.childId);
        if (input.fee) {
          this.input = {
            childId: String(input.child.childId),
            nurseryFeeId: String(input.fee.nurseryFeeId),
            certificationNumber: input.fee.certificationNumber || "",
            periodStart: input.fee.periodStart,
            periodEnd: input.fee.periodEnd || "",
            rank: input.fee.rank || "",
            certifiedCategory: input.fee.certifiedCategory,
            childcareCategory: input.fee.childcareCategory || "normal",
            fee: String(input.fee.fee) || "",
            isFoodCostExempted: input.fee.isFoodCostExempted,
            prefecture: input.fee.prefecture || "",
            city: input.fee.city || "",
            localCode: input.fee.localCode || "",
          };
        }
      }
      this.editDialog = true;
    },
    clearInput() {
      this.input = {
        childId: "",
        nurseryFeeId: "",
        certificationNumber: "",
        periodStart: "",
        periodEnd: "" as string | null,
        rank: "",
        certifiedCategory: "one",
        childcareCategory: "normal",
        fee: "",
        isFoodCostExempted: false,
        prefecture: "",
        city: "",
        localCode: "",
      };
    },
  },
});
