
import Vue, { PropType } from 'vue';
import { MeResponse, NurseryNapDetailResponse, NurseryNapSensorLatest } from 'chaild-api/lib';
export default Vue.extend({
  props: {
    sensorData: {
      type: Object as PropType<NurseryNapSensorLatest>,
      required: true,
    },
    updatingChildId: {
      type: Number,
      required: false,
    },
    alertingChildrenIds: {
      type: Array as PropType<number[]>,
      default: [],
    }
  },

  computed: {
    status() {
      if (!this.sensorData.isStart) {
        return 'standby';
      } else if (!this.sensorData.isSleeping) {
        if (
          this.sensorData.isAlert
        ) {
          return 'awake-danger';
        }
        return 'awake';
      } else {
        if (!this.sensorData.posture || this.sensorData.posture === 0) {
          return 'sleep-up';
        }
        if (
          this.sensorData.isAlert
        ) {
          return 'sleep-danger';
        }
        if (this.sensorData.posture <= -1) {
          return 'sleep-right';
        }
        if (this.sensorData.posture >= 1) {
          return 'sleep-left';
        }
        return 'awake';
      }
    },
    isAlerting(): boolean {
      if (this.alertingChildrenIds.indexOf(this.sensorData.childId) >= 0) {
        return true;
      }
      return false;
    },
    isStarted(): boolean {
      return this.sensorData.isStart;
    },
    isSleep(): boolean {
      return this.status.startsWith('sleep');
    },
    isUpdating(): boolean {
      if (this.updatingChildId) {
        return this.sensorData.childId === this.updatingChildId;
      }
      return false;
    },
    image() {
      const status = this.status;
      if (status === 'standby') {
        // return '/awake.png';
        return null;
      }
      if (status.endsWith('danger')) {
        return '/danger.png';
      } else if (status === 'awake') {
        return '/awake.png';
      } else if (status === 'sleep-up') {
        return '/sleep.png';
      } else if (status === 'sleep-right') {
        return '/sleep-right.png';
      } else if (status === 'sleep-left') {
        return '/sleep-left.png';
      }
      return null;
    },
    cardStyle() {
      if (this.status.startsWith('sleep')) {
        return `background-color: #e5f0fc;`
      }
      return null;
    }
  },

  methods: {
    sleepIn() {
      this.$emit('sleepIn');
    },
    getUp() {
      this.$emit('getUp');
    },
    stopAlert() {
      this.$emit('stopAlert', this.sensorData.childId);
    }
  }
})
