import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryHoursPatternResponse } from "chaild-api/lib";
import ApiNurseryHours from "@/api/ApiNurseryHours";

// 月極
export interface CreateMonthlyPatternInput {
  nurseryId: number;
  patternTitle: string;
  billingAmount: number;
  extendedChildcareStartingHour: number;
  extendedChildcareStartingMinute: number;
}

// 固定時間枠:幼稚園
export interface CreateSpotKindergartenInput {
  nurseryId: number;
  patternTitle: string;
  startingHour: number;
  startingMinute: number;
  earlyHoursBillingFee: {
    billingMethod: string; // hourly, monthly
    hourly?: {
      billingMinute?: number;
      billingAmount?: number;
      billingUpperLimitAmount?: number;
    };
    monthly?: {
      billingAmount?: number;
    };
  };
}
// 朝夕通し
export interface CreateSpotCombinationInput {
  nurseryId: number;
  patternTitle: string;
  normalChildcareStartingHour: number;
  normalChildcareStartingMinute: number;
  shortenedChildcareStartingHour: number;
  shortenedChildcareStartingMinute: number;
}

// 固定時間枠:保育園
export interface CreateSpotNurseryInput {
  nurseryId: number;
  patternTitle: string;
  normalChildcareStartingHour: number;
  normalChildcareStartingMinute: number;
  shortenedChildcareStartingHour: number;
  shortenedChildcareStartingMinute: number;
  isGroupFixed: boolean; // trueの場合はhourly, monthlyの金額設定は無視される
  groupFixedBillingFee?: {
    normalChildcareBillingAmount: number;
    shortenedChildcareBillingAmount: number;
  };
  earlyHoursBillingFee?: {
    billingMethod?: string; // hourly, monthly
    hourly?: {
      billingMinute?: number;
      billingAmount?: number;
      billingUpperLimitAmount?: number;
    };
    monthly?: {
      billingAmount?: number;
    };
  };
  shortenedBillingFee?: {
    before: {
      billingMethod?: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    };
    after: {
      billingMethod?: string; // hourly, monthly
      hourly?: {
        billingMinute?: number;
        billingAmount?: number;
        billingUpperLimitAmount?: number;
      };
      monthly?: {
        billingAmount?: number;
      };
    };
  };
}

export interface CreateSpotFlexibleInput {
  nurseryId: number;
  patternTitle: string;
}

export interface CreateSpansInput {
  patternId: string;
  spans: {
    startingHour?: number; // 0-23
    startingMinute?: number; // 0-59
    endingHour?: number;
    endingMinute?: number;
    billingMethod?: string; // hourly, monthly
    hourly?: {
      billingMinute?: number;
      billingAmount?: number;
      billingUpperLimitAmount?: number;
    };
    monthly?: {
      billingAmount?: number;
    };
  }[];
  spanCategory?: string; // 朝夕通し時間枠の場合は必須
}

export interface NurseryHoursState {
  patternList: NurseryHoursPatternResponse[];
  // input
  monthlyPatternInput: CreateMonthlyPatternInput | null;
  spotNurseryPatternInput: CreateSpotNurseryInput | null;
  spotKindergartenPatternInput: CreateSpotKindergartenInput | null;
  spotCombinationPatternInput: CreateSpotCombinationInput | null;
  spansInput: CreateSpansInput | null;
}

@Module({ dynamic: true, store, name: "nursery-hours", namespaced: true })
class NurseryHoursModule extends VuexModule implements NurseryHoursState {
  public patternList: NurseryHoursPatternResponse[] = [];
  // input
  public spansInput: CreateSpansInput | null = null;
  public monthlyPatternInput: CreateMonthlyPatternInput | null = null;
  public spotNurseryPatternInput: CreateSpotNurseryInput | null = null;
  public spotKindergartenPatternInput: CreateSpotKindergartenInput | null =
    null;
  public spotCombinationPatternInput: CreateSpotCombinationInput | null = null;
  public spotFlexiblePatternInput: CreateSpotFlexibleInput | null = null;

  @Action({ rawError: true })
  public async listPattern(input: { nurseryId: number }) {
    const response = await ApiNurseryHours.listPattern(input);
    if (response) {
      response.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        } else {
          return 1;
        }
      });
      this.setPatternList(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getPatternDetail(input: { patternId: string }) {
    const response = await ApiNurseryHours.getPatternDetail(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async setDefault(input: { patternId: string }) {
    const response = await ApiNurseryHours.setDefaultPattern(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async saveSpans() {
    if (this.spansInput) {
      // this.setSpansEndingTime();
      const response = await ApiNurseryHours.createSpans({
        ...this.spansInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async updateSpans() {
    if (this.spansInput) {
      // this.setSpansEndingTime();
      const response = await ApiNurseryHours.updateSpans({
        ...this.spansInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  // SpansInputのendingHour,endingMinuteを計算して代入
  // public setSpansEndingTime() {
  //   if (this.spansInput && this.spansInput.spans) {
  //     this.spansInput.spans = this.spansInput.spans.map((s) => {
  //       return s
  //     })
  //   }
  // }

  // 月極
  @Action({ rawError: true })
  public async createMonthlyPattern() {
    if (this.monthlyPatternInput) {
      const response = await ApiNurseryHours.createPatternMonthly({
        ...this.monthlyPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async updateMonthlyPattern(input: { patternId: string }) {
    if (this.monthlyPatternInput) {
      const response = await ApiNurseryHours.updatePatternMonthly({
        patternId: input.patternId,
        ...this.monthlyPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  // 固定時間枠（保育園）
  @Action({ rawError: true })
  public async createSpotNurseryPattern() {
    if (this.spotNurseryPatternInput) {
      const response = await ApiNurseryHours.createPatternSpotNursery({
        ...this.spotNurseryPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async updateSpotNurseryPattern(input: { patternId: string }) {
    if (this.spotNurseryPatternInput) {
      const response = await ApiNurseryHours.updatePatternSpotNursery({
        patternId: input.patternId,
        ...this.spotNurseryPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  // 固定時間枠（幼稚園）
  @Action({ rawError: true })
  public async updateSpotKindergartenPattern(input: { patternId: string }) {
    if (this.spotKindergartenPatternInput) {
      const response = await ApiNurseryHours.updatePatternSpotKindergarten({
        patternId: input.patternId,
        ...this.spotKindergartenPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async createSpotKindergartenPattern() {
    if (this.spotKindergartenPatternInput) {
      const response = await ApiNurseryHours.createPatternSpotKindergarten({
        ...this.spotKindergartenPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  // 朝夕通し時間枠
  @Action({ rawError: true })
  public async createSpotCombinationPattern() {
    if (this.spotCombinationPatternInput) {
      const response = await ApiNurseryHours.createPatternSpotCombination({
        ...this.spotCombinationPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async updateSpotCombinationPattern(input: { patternId: string }) {
    if (this.spotCombinationPatternInput) {
      const response = await ApiNurseryHours.updatePatternSpotCombination({
        patternId: input.patternId,
        ...this.spotCombinationPatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  // 変動時間枠
  @Action({ rawError: true })
  public async createSpotFlexiblePattern() {
    if (this.spotFlexiblePatternInput) {
      const response = await ApiNurseryHours.createPatternSpotFlexible({
        ...this.spotFlexiblePatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async updateSpotFlexiblePattern(input: { patternId: string }) {
    if (this.spotFlexiblePatternInput) {
      const response = await ApiNurseryHours.updatePatternSpotFlexible({
        patternId: input.patternId,
        ...this.spotFlexiblePatternInput,
      }).catch((e) => console.warn(e));
      if (response) {
        return response;
      }
    }
  }

  @Mutation
  public setPatternList(value: NurseryHoursPatternResponse[]) {
    this.patternList = value;
  }

  @Mutation
  public setSpansInput(value: CreateSpansInput) {
    this.spansInput = value;
  }

  @Mutation
  public setMonthlyPatternInput(value: CreateMonthlyPatternInput) {
    this.monthlyPatternInput = value;
  }

  @Mutation
  public setSpotNurseryPatternInput(value: CreateSpotNurseryInput) {
    this.spotNurseryPatternInput = value;
  }

  @Mutation
  public setSpotKindergartenPatternInput(value: CreateSpotKindergartenInput) {
    this.spotKindergartenPatternInput = value;
  }

  @Mutation
  public setSpotCombinationPatternInput(value: CreateSpotCombinationInput) {
    this.spotCombinationPatternInput = value;
  }

  @Mutation
  public setSpotFlexiblePatternInput(value: CreateSpotFlexibleInput) {
    this.spotFlexiblePatternInput = value;
  }
}

export const nurseryHoursModule = getModule(NurseryHoursModule);
