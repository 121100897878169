import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiSchoolService from "@/api/ApiSchoolService";
import {
  TimecardListResponse,
  TimecardResponse,
  TimecardStaffListResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import ApiTimecard from "@/api/ApiTimecard";

@Module({
  dynamic: true,
  store,
  name: "staff-attendance-edit",
  namespaced: true,
})
class StaffAttandanceEditModule extends VuexModule {
  public attendance: TimecardResponse | null = null;

  @Action
  async getAttendance({
    date,
    userId,
  }: {
    date: string;
    userId: string | number;
  }) {
    const response = await ApiTimecard.getAttendance({
      userId,
      date,
    });

    if (response) {
      this.setAttendance(response);
    }
  }

  @Mutation
  setAttendance(attendance: TimecardResponse | null) {
    this.attendance = attendance;
  }
}

export const staffAttandanceEditModule = getModule(StaffAttandanceEditModule);
