
import ValidationMixin from '@/mixins/ValidationMixin';
import Vue from 'vue'

export default Vue.extend({
  name: 'TimeInput',

  mixins: [ValidationMixin],

  props: {
    hour: {
      type: Number,
      default: 6,
    },
    minute: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    inputHour: {
      get(): number {
        return this.hour;
      },
      set(value: number) {
        this.$emit('hour', value);
      }
    },
    inputMinute: {
      get(): number {
        return this.minute;
      },
      set(value: number) {
        this.$emit('minute', value);
      }
    },
  },
})
