import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiChildren from "@/api/ApiChildren";
import { NurseryChildResponse, NurseryClassResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import ApiExport from "@/api/ApiExport";

export interface ChildAccountState {
  childId: number | null;
  child: NurseryChildResponse | null;
  currentClasses: NurseryClassResponse[];
}

@Module({ dynamic: true, store, name: "child-account", namespaced: true })
class ChildAccountModule extends VuexModule implements ChildAccountState {
  public child: NurseryChildResponse | null = null;
  public childId: number | null = null;
  public currentClasses: NurseryClassResponse[] = [];

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Action({ rawError: true })
  public async getChild() {
    if (this.childId) {
      const response = await ApiChildren.getChild(this.childId);
      if (response) {
        this.setChild(response);
      }
    }
  }

  @Action({ rawError: true })
  public async getInvitationLink() {
    if (this.childId) {
      const response = await ApiExport.getInvitationLink(this.childId);
      if (response) {
        return response.url;
      }
    }
    return null;
  }

  @Action({ rawError: true })
  public async updateChild() {
    const nurseryId = LocalDataService.getNurseryId();
    if (this.child && nurseryId) {
      const classIds = this.child.nurseryClasses
        ? this.child.nurseryClasses.map((c) => c.classId)
        : [];
      const groupIds = this.child.nurseryGroups
        ? this.child.nurseryGroups.map((c) => c.groupId)
        : [];
      const response = await ApiChildren.updateChild({
        childId: this.child.childId,
        nurseryId: nurseryId,
        childcareType: this.child.childcareType,
        firstName: this.child.firstName,
        firstNameKana: this.child.firstNameKana || undefined,
        lastName: this.child.lastName,
        lastNameKana: this.child.lastNameKana || undefined,
        gender: this.child.gender,
        dateOfBirth: this.child.dateOfBirth,
        optionalChildCode1: this.child.optionalChildCode1 || undefined,
        optionalChildCode2: this.child.optionalChildCode2 || undefined,
        admissionDate: this.child.admissionDate,
        graduationDate: this.child.graduationDate || null,
        quitDate: this.child.quitDate || null,
        quitReason: this.child.quitReason || undefined,
        note: this.child.note || undefined,
        hoursPatternId: this.child.hoursPattern?.patternId,
        classIds,
        groupIds,
        additionalStaffNum: this.child.additionalStaffNum,
        defaultStartMin: this.child.defaultStartMin,
        defaultEndMin: this.child.defaultEndMin,
        maxSubsidyAmount: this.child.maxSubsidyAmount,
      });
      if (response) {
        this.setChild(response);
      }
    }
  }

  @Action({ rawError: true })
  public async updateChildClass() {
    const nurseryId = LocalDataService.getNurseryId();
    if (this.child && nurseryId) {
      const newClassIds = this.child.nurseryClasses
        .filter((nc) => {
          const find = this.currentClasses.find(
            (cc) => cc.classId === nc.classId
          );
          if (find) {
            return false;
          }
          return true;
        })
        .map((nc) => nc.classId);
    }
  }

  @Mutation
  public setChild(child: NurseryChildResponse) {
    this.child = child;
  }
}

export const childAccountModule = getModule(ChildAccountModule);
