
import PageTitle from "@/components/atoms/PageTitle.vue";
import LocalDataService from "@/service/LocalDataService";
import { nurseryHoursDiaryModule } from "@/store/dataModules/nurseryHoursDiaryModule";
import { NurseryHoursDiaryResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "NurseryHoursDiaries",

  components: {
    PageTitle,
  },

  async mounted() {
    await this.listDiary();
  },

  data() {
    return {
      isLoading: false,
      searchValue: {
        limit: 100,
        skip: 0,
        to: "",
        from: "",
      },
    };
  },

  computed: {
    diaries: function (): NurseryHoursDiaryResponse[] {
      return nurseryHoursDiaryModule.diaries;
    },
  },

  methods: {
    async listDiary() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await nurseryHoursDiaryModule.listDiary({
          nurseryId: String(nurseryId),
          ...this.searchValue,
        });
      }
      this.isLoading = false;
    },
    formatDate(date: string): string {
      if (date) {
        return dayjs(date).format("YYYY-MM-DD");
      }
      return "";
    },
    clickEdit(id: string, date: string) {
      this.$router.push({
        path: "/nursery-hours-diary/edit",
        query: {
          diaryId: id,
          date: this.formatDate(date),
        },
      });
    },
  },
});
