import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiSchoolService from "@/api/ApiSchoolService";
import {
  TimecardListResponse,
  TimecardStaffListResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import dayjs from "dayjs";
import ApiTimecard from "@/api/ApiTimecard";

@Module({
  dynamic: true,
  store,
  name: "staff-attendance-management",
  namespaced: true,
})
class StaffAttandanceManagementModule extends VuexModule {
  public staffList: TimecardStaffListResponse = [];
  public attendances: TimecardListResponse = [];

  @Action
  public async getStaffList() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiTimecard.getStaffList({ nurseryId });
      if (response) {
        this.setStaffList(response);
      }
    }
  }

  @Mutation
  setStaffList(staffList: TimecardStaffListResponse) {
    this.staffList = staffList;
  }

  @Action
  async getAttendances() {
    const nurseryId = LocalDataService.getNurseryId();
    const date = dayjs().format("YYYY-MM-DD");
    if (nurseryId) {
      const response = await ApiTimecard.getAttendanceList({
        nurseryId,
        date,
      });

      if (response) {
        this.setAttendances(response);
      }
    }
  }

  @Mutation
  setAttendances(attendances: TimecardListResponse) {
    this.attendances = attendances;
  }
}

export const staffAttandanceManagementModule = getModule(
  StaffAttandanceManagementModule
);
