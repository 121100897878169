
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import DatePager from "@/components/atoms/DatePager.vue";
import { nurseryReportModule } from "@/store/dataModules/nurseryReportModule";
import { nurseryReportEditModule } from "@/store/dataModules/nurseryReportEditModule";
import LocalDataService from "@/service/LocalDataService";
import Swal from "sweetalert2";
import dayjs from "dayjs";

export default Vue.extend({
  name: "NurseryReport",

  components: {
    PageTitle,
    DatePager,
  },

  computed: {
    classDiaries() {
      if (nurseryReportModule.report) {
        return nurseryReportModule.report.classDiaries;
      }
      return [];
    },
    aim: {
      get() {
        return nurseryReportEditModule.aim;
      },
      set(value: string) {
        nurseryReportEditModule.setAim(value);
      },
    },
    date: {
      get() {
        return nurseryReportEditModule.date;
      },
      set(value: string) {
        nurseryReportEditModule.setDate(value);
        this.getReport();
      },
    },
    activityAm: {
      get() {
        return nurseryReportEditModule.activityAm;
      },
      set(value: string) {
        nurseryReportEditModule.setActivityAm(value);
      },
    },
    activityPm: {
      get() {
        return nurseryReportEditModule.activityPm;
      },
      set(value: string) {
        nurseryReportEditModule.setActivityPm(value);
      },
    },
    comment: {
      get() {
        return nurseryReportEditModule.comment;
      },
      set(value: string) {
        nurseryReportEditModule.setComment(value);
      },
    },
    event: {
      get() {
        return nurseryReportEditModule.event;
      },
      set(value: string) {
        nurseryReportEditModule.setEvent(value);
      },
    },
    absentee: {
      get() {
        return nurseryReportEditModule.absentee;
      },
      set(value: string) {
        nurseryReportEditModule.setAbsentee(value);
      },
    },
    reportExists(): boolean {
      return nurseryReportModule.report?.schoolDiaryId ? true : false;
    },
  },

  methods: {
    async createReport(status?: "draft" | "applied") {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        if (status) {
          nurseryReportEditModule.setStatus(status);
        }
        const editReponse = await nurseryReportEditModule.createReport({
          nurseryId: nurseryId,
        });
        if (editReponse) {
          Swal.fire({ title: "日誌を作成しました", icon: "success" });
          this.getReport();
        }
      }
    },
    async updateReport(status?: "draft" | "applied") {
      const nurseryId = LocalDataService.getNurseryId();
      const schoolDiaryId = nurseryReportModule.report?.schoolDiaryId;
      if (nurseryId && schoolDiaryId) {
        if (status) {
          nurseryReportEditModule.setStatus(status);
        }
        const editReponse = await nurseryReportEditModule.updateReport({
          schoolDiaryId: schoolDiaryId,
          nurseryId: nurseryId,
        });
        if (editReponse) {
          Swal.fire({ title: "日誌更新しました", icon: "success" });
          this.getReport();
        }
      }
    },
    async getReport() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const date = nurseryReportEditModule.date;
        let input;
        if (date) {
          input = {
            nurseryId: nurseryId,
            date: date,
          };
        } else {
          input = {
            nurseryId: nurseryId,
          };
        }
        (this.$refs.form as any).reset();
        const response = await nurseryReportModule.getNurseryReport(input);
        nurseryReportEditModule.setAim(response.aim);
        nurseryReportEditModule.setActivityAm(response.activityAm);
        nurseryReportEditModule.setActivityPm(response.activityPm);
        nurseryReportEditModule.setComment(response.comment);
        nurseryReportEditModule.setEvent(response.event);
        nurseryReportEditModule.setAbsentee(response.absentee);
      }
    },
    setDate(date: string) {
      nurseryReportEditModule.setDate(date);
      this.getReport();
    },
  },

  mounted() {
    const today = dayjs().format("YYYY-MM-DD");
    this.setDate(today);
  },
});
