import ApiClient from '@/api/ApiClient';
import { NurseryNapSensorLatestResponse } from 'chaild-api/lib';

export interface PusherAuthResponse {
  channelId: string;
  auth: string;
  channelName: string;
  subscriptionEndAt: string;
}

export default class ApiSensor {
  // 保育士
  public static async authorizePusher(
    napId: number,
    socketId: string,
    userId: number,
  ) {
    try {
      const response = await ApiClient.get(`/nap/${napId}/private-channel/auth`, {
        socketId,
        'user_id': userId,
      });
      return response as PusherAuthResponse;
    } catch {
      return null;
    }
  }

  public static async getLatestSensorData(
    napId: number,
    nurseryId: number,
  ) {
    try {
      const response = await ApiClient.get(`/nap/${napId}/sensor/latest`, {
        nurseryId,
      });
      return response as NurseryNapSensorLatestResponse;
    } catch {
      return null;
    }
  }

  public static async confirmIntervalCheck(napId: number, confirmedAt: string, recorderId: number) {
    // /nap/{napId}/sensor/confirm
    try {
      const response = await ApiClient.post(`/nap/${napId}/sensor/confirm`, {
        confirmedAt,
        recorderId,
      });
      return response;
    } catch {
      return null;
    }
  }
}