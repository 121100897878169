import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiNurseryUser from "@/api/ApiNurseryUser";
import { NurseryUserResponse } from "chaild-api/src/component/nursery/user/types";
import LocalDataService from "@/service/LocalDataService";

export interface NurseryUserState {
  staffs: NurseryUserResponse[];
}

@Module({ dynamic: true, store, name: "nursery-user", namespaced: true })
class NurseryUserModule extends VuexModule implements NurseryUserState {
  public staffs: NurseryUserResponse[] = [];

  @Action
  public async listStaffs() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiNurseryUser.listStaffs({
        nurseryId,
      });
      if (response) {
        this.setStaffs(response);
      }
    }
  }

  @Mutation
  public setStaffs(staffs: NurseryUserResponse[]) {
    this.staffs = staffs;
  }
}

export const nurseryUserModule = getModule(NurseryUserModule);
