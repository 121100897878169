import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { ClassDiaryResponse } from "chaild-api/lib";
import ApiClassDiary from "@/api/ApiClassDiary";
import LocalDataService from "@/service/LocalDataService";

export interface ClassDiaryState {
  diaries: ClassDiaryResponse[];
  limit: number;
  skip: number;
  classId: number | null;
  from: string | null;
  to: string | null;
  check: boolean;

  diary: ClassDiaryResponse | null;
  appliedBy: number | null;

  totalItemsCount: number;
}

@Module({ dynamic: true, store, name: "class-diary", namespaced: true })
class ClassDiaryModule extends VuexModule implements ClassDiaryState {
  public diaries: ClassDiaryResponse[] = [];
  public limit = 10;
  public skip = 0;
  public classId: number | null = null;
  public from: string | null = null;
  public to: string | null = null;
  public check = false;

  public diary: ClassDiaryResponse | null = null;
  public appliedBy: number | null = null;

  public totalItemsCount = 0;

  @Action({ rawError: true })
  public async getDiary(input: { classId: number; date: string }) {
    const response = await ApiClassDiary.getClassDiary(input);
    if (response) {
      // this.setDiary(response);
    }
  }

  @Action({ rawError: true })
  public async findDiary({
    classId,
    classDiaryId,
  }: {
    classId: number;
    classDiaryId: number;
  }) {
    const response = await ApiClassDiary.findClassDiary(classId, classDiaryId);
    if (response) {
      this.setDiary(response);
    }
  }

  @Action({ rawError: true })
  public async listDiary() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const params: {
        classId?: number;
        nurseryId: number;
        from?: string;
        to?: string;
        check: boolean;
        limit: number;
        skip: number;
      } = {
        nurseryId,
        limit: this.limit,
        skip: this.skip * this.limit,
        check: this.check,
      };

      if (this.classId) {
        params.classId = this.classId;
      }
      if (this.from) {
        params.from = this.from;
      }
      if (this.to) {
        params.to = this.to;
      }

      const response = await ApiClassDiary.listClassDiary(params);
      this.setDiaries(response.diaries as ClassDiaryResponse[]);
      this.setTotalItemsCount(response.totalItemsCount);
    }
  }

  @Action({ rawError: true })
  public async createDiary(input: {
    classId: number;
    date: string;
    aim: string;
    activityAm: string;
    activityPm: string;
    assessment: string;
    support: string;
    appliedBy?: number;
    status: "draft" | "applied";
  }) {
    const response = await ApiClassDiary.createClassDiary(input);
    return response;
  }

  @Mutation
  public setClassId(classId: number | null) {
    this.classId = classId;
  }

  @Mutation
  public setDiary(diary: ClassDiaryResponse | null) {
    if (diary) {
      if (diary.status === "applied") {
        this.appliedBy = diary.createdBy.userNurseryId;
      } else {
        this.appliedBy = null;
      }
    } else {
      this.appliedBy = null;
    }
    this.diary = diary;
  }

  @Mutation
  public setAppliedBy(userId: number | null) {
    this.appliedBy = userId;
  }

  @Mutation
  public setDiaries(diaries: ClassDiaryResponse[]) {
    this.diaries = diaries;
  }

  @Mutation
  public updateDiaryValue(diary: ClassDiaryResponse) {
    this.diary = diary;
  }

  @Action
  public async saveAsDraft() {
    if (this.diary && this.diary.classId && this.diary.date) {
      const response = await ApiClassDiary.updateClassDiary({
        classDiaryId: this.diary.classDiaryId,
        classId: this.diary.classId,
        aim: this.diary.aim || "",
        activityAm: this.diary.activityAm || "",
        activityPm: this.diary.activityPm || "",
        assessment: this.diary.assessment || "",
        support: this.diary.support || "",
        status: "draft",
      });

      if (response) {
        this.setDiary(response);
      }

      return response;
    }
  }

  @Action
  public async saveAndApply() {
    if (this.diary && this.diary.classId && this.diary.date) {
      const response = await ApiClassDiary.updateClassDiary({
        classDiaryId: this.diary.classDiaryId,
        classId: this.diary.classId,
        aim: this.diary.aim || "",
        activityAm: this.diary.activityAm || "",
        activityPm: this.diary.activityPm || "",
        assessment: this.diary.assessment || "",
        support: this.diary.support || "",
        appliedBy: this.appliedBy || undefined,
        status: "applied",
      });

      if (response) {
        this.setDiary(response);
      }

      return response;
    }
  }

  @Action
  public async approve() {
    if (this.diary) {
      const response = await ApiClassDiary.createClassDiaryApproval({
        classDiaryId: this.diary.classDiaryId,
        classId: this.diary.classId,
        approvalStatus: "approved",
      });

      if (response) {
        this.setDiary(response);
      }

      return response;
    }
  }

  @Action
  public async reject() {
    if (this.diary) {
      const response = await ApiClassDiary.createClassDiaryApproval({
        classDiaryId: this.diary.classDiaryId,
        classId: this.diary.classId,
        approvalStatus: "rejected",
      });

      if (response) {
        this.setDiary(response);
      }

      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteClassDiary(input: { classDiaryId: string }) {
    const response = await ApiClassDiary.deleteClassDiary(input);
    if (response) {
      return response;
    }
  }

  @Mutation
  public setFrom(from: string) {
    this.from = from;
  }

  @Mutation
  public setTo(to: string) {
    this.to = to;
  }

  @Mutation
  public setCheck(check: boolean) {
    this.check = check;
  }

  @Mutation
  public setLimit(value: number) {
    this.limit = value;
  }

  @Mutation
  public setSkip(value: number) {
    this.skip = value;
  }

  @Mutation
  public setTotalItemsCount(value: number) {
    this.totalItemsCount = value;
  }
}

export const classDiaryModle = getModule(ClassDiaryModule);
