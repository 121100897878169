
import Vue from 'vue'
import { nurseryHoursModule, CreateMonthlyPatternInput } from '@/store/dataModules/nurseryHoursModule';
import LocalDataService from '@/service/LocalDataService';
import Swal from 'sweetalert2';
import TimeInput from '@/components/organisms/nursery-hours/TimeInput.vue'
import ValidationMixin from '@/mixins/ValidationMixin';

export default Vue.extend({
  name: 'MonthlyPatternContainer',

  mixins: [ValidationMixin],

  components: {
    TimeInput,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  mounted() {
    if (!this.isUpdatable) {
      this.initValues();
    }
  },

  computed: {
    inputValue: {
      get(): CreateMonthlyPatternInput | null {
        return nurseryHoursModule.monthlyPatternInput;
      },
      set(value: CreateMonthlyPatternInput) {
        nurseryHoursModule.setMonthlyPatternInput(value);
      },
    },
    isUpdatable: function(): boolean {
      return (this.$route.query && this.$route.query.category != null); 
    },
  },

  methods: {
    initValues() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        nurseryHoursModule.setMonthlyPatternInput({
          nurseryId: nurseryId,
          patternTitle: '延長パターン',
          billingAmount: 0,
          extendedChildcareStartingHour: 6,
          extendedChildcareStartingMinute: 0,
        });
      }
    },
    async createPattern() {
      this.isLoading = true;
      const response = await nurseryHoursModule.createMonthlyPattern();
      if (response) {
        Swal.fire({
          title: '作成しました',
        });
        this.$router.push({ path: '/nursery-hours' });
      }
      this.isLoading = false;
    },
    async updatePattern() {
      this.isLoading = true;
      const patternId = this.$route.query?.patternId;
      if (patternId) {
        const response = await nurseryHoursModule.updateMonthlyPattern({
          patternId: String(patternId),
        });
        if (response) {
          Swal.fire({
            title: '保存しました',
          });
        }
      }
      this.isLoading = false;
    },
  }
})
