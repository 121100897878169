
import ValidationMixin from '@/mixins/ValidationMixin';
import Vue from 'vue'

const hours = [...Array(24)].map((emp, i) => i)
const minutes = [...Array(4)].map((emp, i) => i * 15)

export default Vue.extend({
  name: 'TimeInput',

  mixins: [ValidationMixin],

  props: {
    value: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    hours,
    minutes
  }),

  computed: {
    inputHour: {
      get(): number {
        return Math.floor(this.value / 60);
      },
      set(value: number) {
        this.$emit('input', value * 60 + this.inputMinute)
      }
    },
    inputMinute: {
      get(): number {
        return this.value % 60;
      },
      set(value: number) {
        this.$emit('input', this.inputHour * 60 + value)
      }
    },
  },
})
