
import Vue, { PropType } from 'vue';

import { NurseryGrowthDetailResponse, NurseryGrowthResponse } from 'chaild-api/lib';

export default Vue.extend({
  props: {
    record: {
      type: Object as PropType<NurseryGrowthDetailResponse>,
      required: true,
    }
  },
  computed: {
    childName(): string {
      return `${this.record.child.lastName} ${this.record.child.firstName}`;
    },
    growthRecord(): NurseryGrowthResponse | null {
      if (this.record.records && this.record.records.length > 0) {
        return this.record.records[0];
      }
      return null;
    },
    height(): number | null {
      if (this.growthRecord) {
        return this.growthRecord.height;
      }
      return null;
    },
    weight(): number | null {
      if (this.growthRecord) {
        return this.growthRecord.weight;
      }
      return null;
    },
    remarks(): string | null {
      if (this.growthRecord) {
        return this.growthRecord.remarks;
      }
      return null;
    },
  },
  methods: {
    inputHeight(value: number) {
      this.$emit('inputHeight', value);
    },
    inputWeight(value: number) {
      this.$emit('inputWeight', value);
    },
    inputRemarks(value: string) {
      this.$emit('inputRemarks', value);
    },
  },
});
