
import {
  DiaryResponse,
  MealResponse,
  NurseryChildResponse,
  NurseryClassResponse,
  NurseryDailyReportResponse,
  SearchNurseryAttendanceResponse,
  NurseryDiarySettingResponse,
} from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import { classDiaryModle } from "@/store/dataModules/classDiaryModue";
import { nurseryUserModule } from "@/store/dataModules/nurseryUserModule";
import Vue from "vue";
import { meModule } from "@/store/dataModules/meModule";
import ApiChildren from "@/api/ApiChildren";
import ApiClassDiary from "@/api/ApiClassDiary";
import ApiAttendance from "@/api/ApiAttendance";
import dayjs from "dayjs";
import ApiChildReport from "@/api/ApiChildDiary";
import { diarySettingModule } from "@/store/dataModules/diarySettingModule";

interface DataType {
  classDiaryId: number | null;
  classId: number | null;
  isFetching: boolean;
  isUpdating: boolean;
  childReportList: ChildReportItem[];
  alertSetting: {
    shouldAlert: boolean;
    children: string[];
  };
}

interface BreadcrumbItem {
  text: string;
  disabled: boolean;
  href: string;
}

interface ChildReportItem {
  child?: NurseryChildResponse;
  report?: NurseryDailyReportResponse;
  attendance?: SearchNurseryAttendanceResponse;
  meal?: MealResponse;
  diary?: DiaryResponse;
}

export default Vue.extend({
  data: (): DataType => {
    return {
      classDiaryId: null,
      classId: null,
      isFetching: false,
      isUpdating: false,
      childReportList: [] as ChildReportItem[],
      alertSetting: {
        shouldAlert: false,
        children: [],
      },
    };
  },

  computed: {
    thisClass(): NurseryClassResponse | null {
      if (!this.diary) {
        return null;
      }
      const classes = classModule.classes;
      const find = classes.find((c) => c.classId === this.diary?.classId);
      if (find) {
        return find as NurseryClassResponse;
      }
      return null;
    },
    breadcrumbs(): Array<BreadcrumbItem> {
      return [
        {
          text: "クラス日誌一覧",
          disabled: false,
          href: "/class-diaries",
        },
        {
          text: this.thisClass ? this.thisClass.className : "",
          disabled: true,
          href: "",
        },
      ];
    },
    diary() {
      return classDiaryModle.diary;
    },
    staffOptions() {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`,
        };
      });
    },
    me() {
      return meModule.me;
    },
    isDirector() {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (currentAccount && currentAccount.isDirector) {
          return true;
        }
      }
      return false;
    },
    isChief() {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (currentAccount && currentAccount.isChief) {
          return true;
        }
      }
      return false;
    },
    isAuthor(): boolean {
      if (this.me && this.diary) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (
          currentAccount &&
          currentAccount.userId === this.diary.createdBy.userNurseryId
        ) {
          return true;
        }
      }
      return false;
    },
    cheifApproveStamp(): string {
      if (this.diary) {
        if (this.diary.approvedBy.chief && this.diary.approvedBy.chief != -1) {
          return `${this.diary.approvedBy.chief.lastName} ${this.diary.approvedBy.chief.firstName}`;
        }
      }
      return "-";
    },
    directorApproveStamp(): string {
      if (this.diary) {
        if (
          this.diary.approvedBy.director &&
          this.diary.approvedBy.director != -1
        ) {
          return `${this.diary.approvedBy.director.lastName} ${this.diary.approvedBy.director.firstName}`;
        }
      }
      return "-";
    },
    recorderStamp(): string {
      if (this.diary) {
        if (this.diary.createdBy) {
          return `${this.diary.createdBy.lastName} ${this.diary.createdBy.firstName}`;
        }
      }
      return "下書き";
    },
    date(): string {
      if (this.diary) {
        return this.diary.date;
      }
      return "";
    },
    applicationStatus(): string {
      if (this.diary) {
        return this.diary.status;
      }
      return "";
    },
    isApproved(): boolean {
      if (this.diary) {
        return (
          this.diary.approvedBy.director != null &&
          this.diary.approvedBy.director != -1
        );
      }
      return false;
    },
    isApprovedByChief(): boolean {
      if (this.diary) {
        return (
          this.diary.approvedBy.chief != null &&
          this.diary.approvedBy.chief != -1
        );
      }
      return false;
    },
    isApprovedByMe(): boolean {
      if (this.diary && meModule.me) {
        if (
          this.diary.approvedBy &&
          this.diary.approvedBy.director &&
          this.diary.approvedBy.director != -1
        ) {
          const directorNuseryId = this.diary.approvedBy.director.nurseryId;
          const directorUserId = this.diary.approvedBy.director.userNurseryId;
          const findAccount = meModule.me.nurserySchools.find(
            (ac) => ac.nurseryId === directorNuseryId
          );
          if (findAccount && findAccount.userId === directorUserId) {
            return true;
          }
        }

        if (
          this.diary.approvedBy &&
          this.diary.approvedBy.chief &&
          this.diary.approvedBy.chief != -1
        ) {
          const chiefNuseryId = this.diary.approvedBy.chief.nurseryId;
          const chiefUserId = this.diary.approvedBy.chief.userNurseryId;
          const findAccount = meModule.me.nurserySchools.find(
            (ac) => ac.nurseryId === chiefNuseryId
          );
          if (findAccount && findAccount.userId === chiefUserId) {
            return true;
          }
        }

        return false;
      }
      return false;
    },
    isDraft(): boolean {
      if (this.diary) {
        return this.diary.status === "draft";
      }
      return false;
    },
    isApplied(): boolean {
      if (this.diary) {
        return this.diary.status === "applied";
      }
      return false;
    },
    appliedBy: {
      get() {
        return classDiaryModle.appliedBy;
      },
      set(value: number | null) {
        classDiaryModle.setAppliedBy(value);
      },
    },
    aim: {
      get(): string | null {
        if (this.diary) {
          return this.diary.aim;
        }
        return null;
      },
      set(value: string) {
        if (this.diary) {
          const diary = { ...this.diary };
          diary.aim = value;
          classDiaryModle.updateDiaryValue(diary);
        }
      },
    },
    activityAm: {
      get(): string | null {
        if (this.diary) {
          return this.diary.activityAm;
        }
        return null;
      },
      set(value: string) {
        if (this.diary) {
          const diary = { ...this.diary };
          diary.activityAm = value;
          classDiaryModle.updateDiaryValue(diary);
        }
      },
    },
    activityPm: {
      get(): string | null {
        if (this.diary) {
          return this.diary.activityPm;
        }
        return null;
      },
      set(value: string) {
        if (this.diary) {
          const diary = { ...this.diary };
          diary.activityPm = value;
          classDiaryModle.updateDiaryValue(diary);
        }
      },
    },
    assessment: {
      get(): string | null {
        if (this.diary) {
          return this.diary.assessment;
        }
        return null;
      },
      set(value: string) {
        if (this.diary) {
          const diary = { ...this.diary };
          diary.assessment = value;
          classDiaryModle.updateDiaryValue(diary);
        }
      },
    },
    support: {
      get(): string | null {
        if (this.diary) {
          return this.diary.support;
        }
        return null;
      },
      set(value: string) {
        if (this.diary) {
          const diary = { ...this.diary };
          diary.support = value;
          classDiaryModle.updateDiaryValue(diary);
        }
      },
    },
    tableHeader() {
      return [
        { text: "園児氏名", value: "name" },
        { text: "睡眠", value: "sleep" },
        { text: "体温", value: "temperature" },
        { text: "排便", value: "defecation" },
        { text: "ミルク", value: "milk" },
        { text: "喫食", value: "meal" },
        { text: "", value: "meal2" },
      ];
    },
    diarySetting: function (): NurseryDiarySettingResponse | null {
      return diarySettingModule.diarySetting;
    },
  },

  methods: {
    async getDiary(classId: number, classDiaryId: number) {
      try {
        await classDiaryModle.findDiary({ classId, classDiaryId });
      } catch {
        this.isFetching = false;
      }
    },
    async saveAsDraft() {
      this.isUpdating = true;
      await classDiaryModle.saveAsDraft();
      this.isUpdating = false;
    },
    async saveAndApply() {
      this.isUpdating = true;
      await classDiaryModle.saveAndApply();
      this.isUpdating = false;
    },
    async approve() {
      this.isUpdating = true;
      if (this.alertSetting.shouldAlert) {
        const answer = confirm(
          `「活動記録/発達状況」が未記入の園児がいます。承認してよろしいでしょうか？\n\n${this.alertSetting.children.join(
            "\n"
          )}`
        );
        if (answer) {
          await classDiaryModle.approve();
        }
      } else {
        await classDiaryModle.approve();
      }
      this.isUpdating = false;
    },
    async reject() {
      this.isUpdating = true;
      await classDiaryModle.reject();
      this.isUpdating = false;
    },
    async listChildrenReport(input: { classId: number }) {
      const nurseryId = LocalDataService.getNurseryId();

      if (input.classId && nurseryId && this.date) {
        // list children
        const childrenResponse = await ApiChildren.listChildren({
          classId: input.classId,
          nurseryId: nurseryId,
          limit: 1000,
        });

        // list report
        const reportResponse = await ApiClassDiary.listChildrenReport({
          classId: input.classId,
          date: this.date,
        });

        // list attendance
        const attendanceResponse = await ApiAttendance.searchAttendance({
          nurseryId: nurseryId,
          classId: input.classId,
          date: this.date,
          limit: 1000,
        });

        // list meal
        const mealResponse = await ApiClassDiary.listChildrenMeal({
          classId: input.classId,
          date: this.date,
        });

        const diaryResponse = await ApiChildReport.searchChildDiary({
          nurseryId: nurseryId,
          classId: input.classId,
          date: this.date,
          limit: 1000,
        });

        childrenResponse.forEach((child) => {
          const childId = child.childId;
          const childReportItem: ChildReportItem = {};
          const theDay = dayjs(this.date);

          // 卒園・転園の場合
          if (child.quitDate) {
            if (theDay && theDay.isAfter(dayjs(child.quitDate))) {
              return;
            }
          }

          // 入園前のものは非表示
          if (child.admissionDate) {
            if (theDay && theDay.isBefore(dayjs(child.admissionDate))) {
              return;
            }
          }

          childReportItem.child = child;
          childReportItem.report = reportResponse.reports.find(
            (r) => r.childId == childId
          );
          childReportItem.attendance = attendanceResponse.attendances.find(
            (a) => a.child.childId == childId
          );
          childReportItem.meal = mealResponse.diaries.find(
            (m) => m.childId == childId
          );
          childReportItem.diary = diaryResponse.diaries.find(
            (d) => d.child.childId == childId
          );

          if (childReportItem.report == undefined) {
            childReportItem.report = {
              childId: childId,
              defecations: [],
              milks: [],
              sleeps: [],
              temperatures: [],
            };
          }

          this.childReportList.push(childReportItem);

          // alert setting
          const classId = Number(this.$route.params.classId);
          if (
            this.diarySetting?.classDiaryConfirmation.targetClasses.some(
              (t) => t.classId == classId
            )
          ) {
            if (!childReportItem.diary || childReportItem.diary.content == "") {
              this.alertSetting.shouldAlert = true;
              this.alertSetting.children.push(
                `${child.lastName}${child.firstName}`
              );
            }
          }
        });
      }
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("HH:mm");
    },
    formatTemperature(value: number): string {
      return value.toFixed(1);
    },
    formatDefecation(defecation: string) {
      if (defecation === "hard") {
        return "かたい";
      } else if (defecation === "normal") {
        return "ふつう";
      } else if (defecation === "soft") {
        return "やわらかい";
      }
    },
    formatMealStatus(status: string) {
      if (status === "finished") {
        return "完食";
      } else if (status === "refill") {
        return "おかわり";
      } else if (status === "unfinished") {
        return "少し残した";
      } else if (status === "half") {
        return "半分食べた";
      } else if (status === "little") {
        return "少し食べた";
      } else if (status === "notEat") {
        return "食べなかった";
      } else if (status === "notServed") {
        return "未提供";
      }
    },
  },

  async mounted() {
    this.isFetching = true;

    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await classModule.listClass({
        nurseryId: nurseryId,
      });
      await nurseryUserModule.listStaffs();
      await diarySettingModule.listDiarySetting({ nurseryId });
    }

    const classId = this.$route.params.classId as string;
    const classDiaryId = this.$route.params.classDiaryId as string;

    if (classId && classDiaryId) {
      try {
        const classIdInt = parseInt(classId);
        const classDiaryIdInt = parseInt(classDiaryId);

        await this.getDiary(classIdInt, classDiaryIdInt);

        await this.listChildrenReport({
          classId: classIdInt,
        });
      } catch {
        return;
      }
    }

    this.isFetching = false;
  },
});
