
import { staffModule } from "@/store/dataModules/staffModule";
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import { classModule } from "@/store/dataModules/classModule";
import { staffEditModule } from "@/store/dataModules/staffEditModule";
import LocalDataService from "@/service/LocalDataService";
import Swal from "sweetalert2";
import ValidationMixin from "@/mixins/ValidationMixin";

export default Vue.extend({
  name: "StaffEdit",

  mixins: [ValidationMixin],

  components: {
    PageTitle,
  },

  mounted() {
    this.getStaff();
    this.listClass();

    if (this.$route.params.id == "new") {
      staffEditModule.clearValues();

      // reset validation
      (this.$refs.form as any).resetValidation();
    }
  },

  data() {
    return {
      valid: false,
      loading: false,
      retireSelectValues: [
        { text: "", value: "" },
        { text: "異動", value: "transfer" },
        { text: "退職", value: "retire" },
        { text: "死亡", value: "die" },
      ],
      categorySelectValues: [
        { text: "一般", value: "general" },
        { text: "ヘルプ", value: "help" },
      ],
      genderSelectValues: [
        { text: "", value: "" },
        { text: "男性", value: "male" },
        { text: "女性", value: "female" },
      ],
    };
  },

  computed: {
    classes: function (): any[] {
      return classModule.classes.map((c) => {
        return {
          className: `${c.year}年度 ${c.className}`,
          classId: c.classId,
        };
      });
    },
    userNurseryId: function () {
      staffEditModule.setUserId(this.$route.params.id);
      return this.$route.params.id;
    },
    isEdit: function (): boolean {
      return this.$route.params.id == "new" ? false : true;
    },
    category: {
      get() {
        return staffEditModule.category;
      },
      set(value: "general" | "help") {
        staffEditModule.setCategory(value);
      },
    },
    firstName: {
      get() {
        return staffEditModule.firstName;
      },
      set(value: string) {
        staffEditModule.setFirstName(value);
      },
    },
    lastName: {
      get() {
        return staffEditModule.lastName;
      },
      set(value: string) {
        staffEditModule.setLastName(value);
      },
    },
    firstNameKana: {
      get() {
        return staffEditModule.firstNameKana;
      },
      set(value: string) {
        staffEditModule.setFirstNameKana(value);
      },
    },
    lastNameKana: {
      get() {
        return staffEditModule.lastNameKana;
      },
      set(value: string) {
        staffEditModule.setLastNameKana(value);
      },
    },
    staffCode1: {
      get() {
        return staffEditModule.staffCode1;
      },
      set(value: string) {
        staffEditModule.setStaffCode1(value);
      },
    },
    staffCode2: {
      get() {
        return staffEditModule.staffCode2;
      },
      set(value: string) {
        staffEditModule.setStaffCode2(value);
      },
    },
    email: {
      get() {
        return staffEditModule.email;
      },
      set(value: string) {
        staffEditModule.setEmail(value);
      },
    },
    isDirector: {
      get() {
        return staffEditModule.isDirector;
      },
      set(value: boolean) {
        staffEditModule.setIsDirector(value);
      },
    },
    isChief: {
      get() {
        return staffEditModule.isChief;
      },
      set(value: boolean) {
        staffEditModule.setIsChief(value);
      },
    },
    isAdmin: {
      get() {
        return staffEditModule.isAdmin;
      },
      set(value: boolean) {
        staffEditModule.setIsAdmin(value);
      },
    },
    birth: {
      get() {
        return staffEditModule.birth;
      },
      set(value: string) {
        staffEditModule.setBirth(value);
      },
    },
    gender: {
      get() {
        return staffEditModule.gender;
      },
      set(value: string) {
        staffEditModule.setGender(value);
      },
    },
    address: {
      get() {
        return staffEditModule.address;
      },
      set(value: string) {
        staffEditModule.setAddress(value);
      },
    },
    phoneNumber1: {
      get() {
        return staffEditModule.phoneNumber1;
      },
      set(value: string) {
        staffEditModule.setPhoneNumber1(value);
      },
    },
    phoneNumber2: {
      get() {
        return staffEditModule.phoneNumber2;
      },
      set(value: string) {
        staffEditModule.setPhoneNumber2(value);
      },
    },
    joinDate: {
      get() {
        return staffEditModule.joinDate;
      },
      set(value: string) {
        staffEditModule.setJoinDate(value);
      },
    },
    assignDate: {
      get() {
        return staffEditModule.assignDate;
      },
      set(value: string) {
        staffEditModule.setAssignDate(value);
      },
    },
    retireDate: {
      get() {
        return staffEditModule.retireDate;
      },
      set(value: string) {
        staffEditModule.setRetireDate(value);
      },
    },
    retireReason: {
      get() {
        return staffEditModule.retireReason;
      },
      set(value: "transfer" | "retire" | "die") {
        staffEditModule.setRetireReason(value);
      },
    },
    retireReasonComment: {
      get() {
        return staffEditModule.retireReasonComment;
      },
      set(value: string) {
        staffEditModule.setRetireReasonComment(value);
      },
    },
    classesInCharge: {
      get() {
        return staffEditModule.classesInCharge;
      },
      set(value: number[]) {
        staffEditModule.setclassesInCharge(value);
      },
    },
    comment: {
      get() {
        return staffEditModule.comment;
      },
      set(value: string) {
        staffEditModule.setComment(value);
      },
    },
  },

  methods: {
    async getStaff() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.isEdit) {
        await staffModule.getStaff({
          nurseryId: String(nurseryId),
          userNurseryId: this.userNurseryId,
        });
        this.setValues();
      }
    },
    async createStaff() {
      this.loading = true;
      if (this.checkValidation()) {
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId) {
          const response = await staffEditModule.createStaff({
            nurseryId: String(nurseryId),
          });
          if (response) {
            Swal.fire({ title: "作成しました", icon: "success" });
            this.$router.push({ path: "/setting/staff" });
          } else {
            Swal.fire({ title: "失敗しました", icon: "error" });
          }
        }
      }
      this.loading = false;
    },
    async updateStaff() {
      this.loading = true;
      if (this.checkValidation()) {
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId) {
          const response = await staffEditModule
            .updateStaff({
              nurseryId: String(nurseryId),
            })
            .catch((e) => {
              return;
            });
          if (response) {
            Swal.fire({ title: "保存しました", icon: "success" });
            this.$router.push({ path: "/setting/staff" });
          } else {
            Swal.fire({
              title: "保存に失敗しました<br>以下の理由が考えられます",
              icon: "error",
              html: "・メールアドレスが他のユーザーと重複している<br>・不正な値の入力<br>・その他",
            });
          }
        }
      }
      this.loading = false;
    },
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId: nurseryId,
        });
      }
    },
    checkValidation(): boolean {
      if (this.birth && this.joinDate && this.assignDate && this.category) {
        return true;
      } else {
        Swal.fire({
          title: "エラー",
          text: "必須項目が入力されていません",
          icon: "warning",
        });
        return false;
      }
    },
    setValues() {
      if (staffModule.staffDetail) {
        staffEditModule.setCategory(staffModule.staffDetail.category);
        staffEditModule.setFirstName(staffModule.staffDetail.firstName);
        staffEditModule.setLastName(staffModule.staffDetail.lastName);
        staffEditModule.setFirstNameKana(staffModule.staffDetail.firstNameKana);
        staffEditModule.setLastNameKana(staffModule.staffDetail.lastNameKana);
        staffEditModule.setIsDirector(staffModule.staffDetail.isDirector);
        staffEditModule.setIsChief(staffModule.staffDetail.isChief);
        staffEditModule.setIsAdmin((staffModule.staffDetail as any).isAdmin);
        staffEditModule.setEmail(staffModule.staffDetail.email);
        staffEditModule.setStaffCode1(staffModule.staffDetail.staffCode1 || "");
        staffEditModule.setStaffCode2(staffModule.staffDetail.staffCode2 || "");
        staffEditModule.setBirth(staffModule.staffDetail.birth);
        staffEditModule.setGender(staffModule.staffDetail.gender);
        staffEditModule.setAddress(staffModule.staffDetail.address || "");
        staffEditModule.setPhoneNumber1(
          staffModule.staffDetail.phoneNumber1 || ""
        );
        staffEditModule.setPhoneNumber2(
          staffModule.staffDetail.phoneNumber2 || ""
        );
        staffEditModule.setJoinDate(staffModule.staffDetail.joinDate);
        staffEditModule.setAssignDate(staffModule.staffDetail.assignDate);
        staffEditModule.setclassesInCharge(
          staffModule.staffDetail.classesInCharge.map((c) => {
            return c.classId;
          })
        );
        staffEditModule.setComment(staffModule.staffDetail.comment || "");
        staffEditModule.setRetireDate(staffModule.staffDetail.retireDate || "");
        staffEditModule.setRetireReason(
          staffModule.staffDetail.retireReason || ""
        );
        staffEditModule.setRetireReasonComment(
          staffModule.staffDetail.retireReasonComment || ""
        );
      }
    },
  },
});
