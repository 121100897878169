
import LocalDataService from "@/service/LocalDataService";
import { meModule } from "@/store/dataModules/meModule";
import { nurseryHoursDiaryModule } from "@/store/dataModules/nurseryHoursDiaryModule";
import { nurseryUserModule } from "@/store/dataModules/nurseryUserModule";
import { NurseryHoursDiaryDetailResponse } from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "NurseryHoursDiaryEdit",

  async mounted() {
    if (!this.isNew) {
      await this.getDiary();
    } else {
      this.inputValue.date = dayjs().format("YYYY-MM-DD");
    }

    await this.listStaff();
  },

  data() {
    return {
      isLoading: false,
      inputValue: {
        date: "",
        activity: "",
        remarks: "",
        message: "",
        status: "draft" as "draft" | "applied",
        appliedBy: undefined as undefined | number,
        staff: [],
      },
    };
  },

  computed: {
    diaryId: function (): any {
      return this.$route.query.diaryId;
    },
    date: function (): any {
      return this.$route.query.date;
    },
    isNew: function (): boolean {
      return this.diaryId && this.date ? false : true;
    },
    diary: function (): NurseryHoursDiaryDetailResponse | null {
      return nurseryHoursDiaryModule.diary;
    },
    monthlyChildrenCount: function (): string {
      if (this.diary) {
        const children = this.diary.children.filter(
          (child) => (child.category as any) == "monthly"
        );
        return String(children.length);
      } else {
        return "";
      }
    },
    spotChildrenCount: function (): string {
      if (this.diary) {
        const children = this.diary.children.filter(
          (child) => (child.category as any) == "spot"
        );
        return String(children.length);
      } else {
        return "";
      }
    },
    breadcrumbs: function () {
      return [
        {
          text: "延長保育日誌",
          disabled: false,
          href: "/nursery-hours-diary/list",
        },
        {
          text: "",
        },
      ];
    },
    staffItems: function () {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`,
        };
      });
    },
    cheifApproveStamp(): string {
      if (this.diary) {
        if (this.diary.approvedBy.chief && this.diary.approvedBy.chief != -1) {
          return `${this.diary.approvedBy.chief.lastName} ${this.diary.approvedBy.chief.firstName}`;
        }
      }
      return "-";
    },
    directorApproveStamp(): string {
      if (this.diary) {
        if (
          this.diary.approvedBy.director &&
          this.diary.approvedBy.director != -1
        ) {
          return `${this.diary.approvedBy.director.lastName} ${this.diary.approvedBy.director.firstName}`;
        }
      }
      return "-";
    },
    appliedBy: function (): number | null {
      if (this.diary) {
        if (this.diary.status == "applied") {
          return this.diary.createdBy.userNurseryId;
        }
      }
      return null;
    },
    me() {
      return meModule.me;
    },
    isDirector() {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (currentAccount && currentAccount.isDirector) {
          return true;
        }
      }
      return false;
    },
    isChief() {
      if (this.me) {
        const nurseryId = LocalDataService.getNurseryId();
        const currentAccount = this.me.nurserySchools.find(
          (s) => s.nurseryId === nurseryId
        );
        if (currentAccount && currentAccount.isChief) {
          return true;
        }
      }
      return false;
    },
    isApprovedByDirector(): boolean {
      if (this.diary) {
        return (
          this.diary.approvedBy.director != null &&
          this.diary.approvedBy.director != -1
        );
      }
      return false;
    },
    isApprovedByChief(): boolean {
      if (this.diary) {
        return (
          this.diary.approvedBy.chief != null &&
          this.diary.approvedBy.chief != -1
        );
      }
      return false;
    },
    isApplied(): boolean {
      if (this.diary) {
        return this.diary.status === "applied";
      }
      return false;
    },
    isDraft(): boolean {
      if (this.diary) {
        return this.diary.status === "draft";
      }
      return false;
    },
  },

  methods: {
    async createDiary(isDraft?: boolean) {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        if (isDraft) {
          this.inputValue.status = "draft";
        } else {
          this.inputValue.status = "applied";
        }

        const response = await nurseryHoursDiaryModule
          .createDiary({
            nurseryId: nurseryId,
            ...this.inputValue,
          })
          .catch(() => (this.isLoading = false));
        if (response) {
          this.$router.push({ path: "/nursery-hours-diary/list" });
        }
      }
      this.isLoading = false;
    },
    async getDiary() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.date) {
        const response = await nurseryHoursDiaryModule.getDiary({
          nurseryId: String(nurseryId),
          date: this.date,
        });
        if (response) {
          this.inputValue = {
            date: this.date,
            activity: (response as any).activity || "",
            remarks: (response as any).remarks || "",
            message: (response as any).message || "",
            status: (response as any).status,
            appliedBy: (response as any).appliedBy || undefined,
            staff:
              response.staff.length != 0
                ? response.staff.map((s) => {
                    return s.userNurseryId;
                  })
                : [],
          };
        }
      }
      this.isLoading = false;
    },
    async updateDiary(isApply?: boolean) {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.diaryId) {
        // apply
        if (isApply) {
          this.inputValue.status = "applied";
        } else {
          this.inputValue.status = "draft";
        }
        const response = await nurseryHoursDiaryModule.updateDiary({
          nurseryId: nurseryId,
          nurseryHoursDiaryId: this.diaryId,
          ...this.inputValue,
        });
        if (response) {
          this.$router.push({ path: "/nursery-hours-diary/list" });
        }
      }
      this.isLoading = false;
    },
    async listStaff() {
      await nurseryUserModule.listStaffs();
    },
    async updateApprovalStatus(isApprove?: boolean) {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (
        nurseryId &&
        this.diaryId &&
        isApprove != undefined &&
        isApprove != null
      ) {
        const response = await nurseryHoursDiaryModule.updateApprovalStatus({
          nurseryId,
          nurseryHoursDiaryId: this.diaryId,
          approvalStatus: isApprove ? "approved" : "rejected",
        });
        if (response) {
          this.$router.push({ path: "/nursery-hours-diary/list" });
        }
      }
      this.isLoading = false;
    },
    formatDate(date: string): string {
      if (date) {
        return dayjs(date).format("HH:mm");
      }
      return "";
    },
    getCategoryName(category: string | null): string {
      if (category == "monthly") {
        return "月極";
      } else if (category == "spot") {
        return "スポット";
      } else {
        return "-";
      }
    },
  },
});
