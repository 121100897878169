
import ApiGuidance from "@/api/ApiGuidance";
import { GuidancePlanTypeItems } from "@/constants/guidance";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import {
  GuidancePlanCategoryType,
  NurseryClassResponse,
  NurseryGuidancePlanFormListResponse,
  NurseryGuidancePlanListResponse,
} from "chaild-api/lib";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "GuidancePlanList",

  async mounted() {
    await this.listPlan();
    await this.listForm();
    await this.listClass();
  },

  data() {
    return {
      loading: false,
      planResponse: null as null | NurseryGuidancePlanListResponse,
      formResponse: null as null | NurseryGuidancePlanFormListResponse,
      headers: [
        { text: "名前", value: "name" },
        { text: "年", value: "year" },
        { text: "月", value: "month" },
        { text: "週", value: "week" },
        { text: "作成日", value: "createdAt" },
        { text: "更新日", value: "updatedAt" },
        { text: "詳細", value: "detail" },
      ],
      searchFilters: {
        limit: 10,
        skip: 0,
        type: "" as GuidancePlanCategoryType,
        year: dayjs().year(),
        month: "",
        week: "",
      },
      input: {
        name: "",
        planFormId: "",
        parentPlanId: "",
        year: dayjs().year(),
        month: dayjs().month(),
        week: 1,
        classId: "",
      },
      editDialog: false,
      guidancePlanTypeItems: GuidancePlanTypeItems,
    };
  },

  computed: {
    planItems: function (): any[] {
      if (this.planResponse) {
        return this.planResponse.data.map((p) => {
          return {
            text: p.name,
            value: p.id,
          };
        });
      } else {
        return [];
      }
    },
    formItems: function (): any[] {
      if (this.formResponse) {
        return this.formResponse.data.map((f) => {
          return {
            text: f.name,
            value: f.id,
          };
        });
      } else {
        return [];
      }
    },
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
    page: {
      get(): number {
        if (this.searchFilters.skip == 0) {
          return 1;
        }
        return Math.ceil(
          this.searchFilters.skip / (this.searchFilters.limit - 1)
        );
      },
      set(value: number) {
        this.searchFilters.skip = (value - 1) * this.searchFilters.limit;
        this.listPlan();
      },
    },
  },

  methods: {
    async listPlan() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.listPlan({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.planResponse = response;
        }
      }
      this.loading = false;
    },
    async createPlan() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.createPlan({
          nurseryId,
          ...this.input,
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          await this.listPlan();
        }
      }
      this.loading = false;
    },
    async listForm() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiGuidance.listPlanForm({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.formResponse = response;
        }
      }
      this.loading = false;
    },
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule
          .listClass({
            nurseryId,
          })
          .catch();
      }
    },
    clearInput() {
      this.input = {
        name: "",
        planFormId: "",
        parentPlanId: "",
        year: dayjs().year(),
        month: dayjs().month(),
        week: 1,
        classId: "",
      };
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
