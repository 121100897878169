
import dayjs from 'dayjs';
import Vue from 'vue';
import PageTitle from '@/components/atoms/PageTitle.vue';

import { childDevelopmentChildModule } from '@/store/viewModules/child-development/childDevelopmentChildModule'
import { ChildDevelopmentResponse } from 'chaild-api/lib';

interface DataType {
  isFetchingRecords: boolean;
  // showQuitChildren: boolean;
  // showDonwloadMessage: boolean;
  childId?: number;
  year?: number;
  selectOptions: SelectOption[];
  isUpdating: boolean;
}

interface SelectOption {
  value: string | null;
  text: string;
}

export default Vue.extend({
  components: {
    PageTitle
  },
  data: (): DataType => {
    return {
      isFetchingRecords: false,
      childId: undefined,
      year: undefined,
      selectOptions: [
        {
          value: 'done',
          text: '◯'
        },
        {
          value: 'close',
          text: '△'
        },
        {
          value: null,
          text: '-'
        },
      ],
      isUpdating: false
    }
  },
  computed: {
    records() {
      return childDevelopmentChildModule.developmentRecords;
    },
    recordsWithMeta(): Array<{
      visibility: {
        may: boolean;
        june: boolean;
        july: boolean;
        aug: boolean;
        sept: boolean;
        oct: boolean;
        nov: boolean;
        dec: boolean;
        jan: boolean;
        feb: boolean;
        mar: boolean;
      };
    }> {
      return childDevelopmentChildModule.developmentRecords.map((r) => {
        return {

          visibility: {
            may: this.shouldShowInput(r, 'may'),
            june: this.shouldShowInput(r, 'june'),
            july: this.shouldShowInput(r, 'july'),
            aug: this.shouldShowInput(r, 'aug'),
            sept: this.shouldShowInput(r, 'sept'),
            oct: this.shouldShowInput(r, 'oct'),
            nov: this.shouldShowInput(r, 'nov'),
            dec: this.shouldShowInput(r, 'dec'),
            jan: this.shouldShowInput(r, 'jan'),
            feb: this.shouldShowInput(r, 'feb'),
            mar: this.shouldShowInput(r, 'mar'),
          }
        }
      })
    },
    child() {
      return childDevelopmentChildModule.child
    },
    childName(): string {
      if (this.child) {
        return `${this.child.lastName} ${this.child.firstName}`
      }
      return ''
    },
  },
  methods: {
    shouldShowInput(record: ChildDevelopmentResponse, key: string) {
      switch (key) {
        case 'may':
          return !(record.milestone.apr === 'done');
        case 'june':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done'
          );
        case 'july':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done'
          );
        case 'aug':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done'
          );
        case 'sept':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done'
          );
        case 'oct':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done' ||
            record.milestone.sept === 'done'
          );
        case 'nov':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done' ||
            record.milestone.sept === 'done' ||
            record.milestone.oct === 'done'
          );
        case 'dec':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done' ||
            record.milestone.sept === 'done' ||
            record.milestone.oct === 'done' ||
            record.milestone.nov === 'done'
          );
        case 'jan':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done' ||
            record.milestone.sept === 'done' ||
            record.milestone.oct === 'done' ||
            record.milestone.nov === 'done' ||
            record.milestone.dec === 'done'
          );
        case 'feb':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done' ||
            record.milestone.sept === 'done' ||
            record.milestone.oct === 'done' ||
            record.milestone.nov === 'done' ||
            record.milestone.dec === 'done' ||
            record.milestone.jan === 'done'
          );
        case 'mar':
          return !(
            record.milestone.apr === 'done' ||
            record.milestone.may === 'done' ||
            record.milestone.june === 'done' ||
            record.milestone.july === 'done' ||
            record.milestone.aug === 'done' ||
            record.milestone.sept === 'done' ||
            record.milestone.oct === 'done' ||
            record.milestone.nov === 'done' ||
            record.milestone.dec === 'done' ||
            record.milestone.jan === 'done' ||
            record.milestone.feb === 'done'
          );
      }
      return true;
    },
    async listRecords() {
      if (this.childId && this.year) {
        this.isFetchingRecords = true;
        await childDevelopmentChildModule.listRecords({
          childId: this.childId,
          businessYear: this.year
        });
        this.isFetchingRecords = false;
      }
    },
    async getChild() {
      if (this.childId) {
        await childDevelopmentChildModule.getChild({
          childId: this.childId,
        });
      }
    },
    async updateRecords() {
      if (this.childId && this.year) {
        this.isFetchingRecords = true;
        this.isUpdating = true;
        await childDevelopmentChildModule.updateRecords({
          childId: this.childId,
          businessYear: this.year
        })
        await childDevelopmentChildModule.listRecords({
          childId: this.childId,
          businessYear: this.year
        });
        this.isFetchingRecords = false;
        this.isUpdating = false;
      }
    },
    formatDate(dateString: string | null) {
      if (!dateString) {
        return '不明';
      }
      return dayjs(dateString).format('YYYY-MM-DD HH:mm')
    },
    getAgeBasedonBirthdate(birthdate: string): number {
      const thisYear = dayjs();
      return thisYear.diff(birthdate, 'year');
    },
  },
  mounted() {
    const childId = this.$route.params.childId;
    if (childId) {
      this.childId = parseInt(childId);
    }

    const year = this.$route.params.businessYear;
    if (year) {
      this.year = parseInt(year);
    }

    this.listRecords();
    this.getChild();
  }
})
