
import Vue from 'vue';

import PageTitle from '@/components/atoms/PageTitle.vue';

import { classModule } from '@/store/dataModules/classModule';
import { growthRecordsModule } from '@/store/viewModules/growth-record/growthRecordsModule';
import LocalDataService from '@/service/LocalDataService';
import { NurseryClassResponse, NurseryGrowthDetailResponse } from 'chaild-api/lib';
import dayjs from 'dayjs';
import ApiGrowthRecord from '@/api/ApiGrowthRecord';
import CommonUtil from '@/utils/CommonUtil';

interface ClassWithRecrods {
  classObj: NurseryClassResponse;
  records: NurseryGrowthDetailResponse[];
}

interface DataType {
  isFetching: boolean;
  showQuitChildren: boolean;
  showDonwloadMessage: boolean;
  year: number;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    isFetching: false,
    showQuitChildren: false,
    showDonwloadMessage: false,
    year: dayjs().year()
  }),

  computed: {
    childrenTableHeaders() {
      return [
        { text: '児童氏名', value: 'name' },
        { text: '性別', value: 'gender' },
        { text: '年齢', value: 'age' },
        { text: '-', value: 'actions' },
      ]
    },
    tableItems() {
      return [
        {
          name: 'テスト',
          gender: 'male',
          age: '1',
          actions: null,
        }
      ]
    },
    classes() {
      return classModule.classes;
    },
    records() {
      return growthRecordsModule.growthRecords;
    },
    classesWithRecords(): ClassWithRecrods[] {
      return this.classes.map((c) => {
        const records = this.records.filter((r) => {
          const findClass = r.child.nurseryClasses.find((cl) => cl.classId === c.classId);
          if (findClass) {
            return true;
          }
          return false;
        })
        .filter((r) => {
          if (!this.showQuitChildren && r.child.quitDate) {
            const now = dayjs();
            const quitDate = dayjs(r.child.quitDate);
            const diff = now.diff(quitDate);
            if (diff > 0) {
              return false;
            }
          }
          return true;
        });
        return {
          classObj: c,
          records: records,
        }
      });
    }
  },

  methods: {
    formatAge(dateOfBirth: string) {
      const now = dayjs();
      const age = now.diff(dayjs(dateOfBirth), 'year');
      return age;
    },
    async exportRecords(classObj: NurseryClassResponse) {
      const nurseryId = LocalDataService.getNurseryId()

      if (nurseryId && classObj.year) {
        this.isFetching = true;
        await ApiGrowthRecord.donwloadRecords({
          nurseryId,
          year: classObj.year,
          classId: classObj.classId
        })
        this.isFetching = false;
        this.showDonwloadMessage = true
      }
    }
  },

  watch: {
    year() {
      const nurseryId = LocalDataService.getNurseryId();

      // const fiscalYear = CommonUtil.getFiscalYear(`${this.year}`)
      if (nurseryId) {
        classModule.listClass({
          nurseryId: nurseryId,
          year: this.year
        })
        growthRecordsModule.listGrowthRecords(
          this.year
        )
      }
    }
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      this.year = parseInt(CommonUtil.getFiscalYear(dayjs().format('YYYY-MM')))
      this.isFetching = true;
      await Promise.all([
        classModule.listClass({
          nurseryId: nurseryId,
          year: this.year
        }),
        growthRecordsModule.listGrowthRecords(
          this.year
        ),
      ]);
      this.isFetching = false;
    }
  }
});
