
import { nurseryHoursModule, CreateSpansInput, CreateSpotFlexibleInput } from '@/store/dataModules/nurseryHoursModule'
// import TimeInput from '@/components/organisms/nursery-hours/TimeInput.vue'
import BillingInput from '@/components/organisms/nursery-hours/BillingInput.vue';
import Vue from 'vue'
import LocalDataService from '@/service/LocalDataService';
import Swal from 'sweetalert2';

export default Vue.extend({
  name: 'SpotFlexiblePatternContainer',
  
  components: {
    BillingInput,
  },

  mounted() {
    if (!this.isUpdatable) {
      this.initValues();
      this.initSpans();
      this.addSpanRow();
    }
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    inputValue: {
      get(): CreateSpotFlexibleInput | null {
        return nurseryHoursModule.spotFlexiblePatternInput;
      },
      set(value: CreateSpotFlexibleInput) {
        nurseryHoursModule.setSpotFlexiblePatternInput(value);
      }
    },
    isUpdatable: function(): boolean {
      return (this.$route.query && this.$route.query.category != null); 
    },
    spans: {
      get(): CreateSpansInput | null {
        return nurseryHoursModule.spansInput;
      },
      set(value: CreateSpansInput) {
        nurseryHoursModule.setSpansInput(value);
      }
    },
  },

  methods: {
    initValues() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.inputValue = {
          nurseryId: nurseryId,
          patternTitle: '延長パターン',
        };
      }
    },
    initSpans() {
      this.spans = {
        patternId: '0',
        spans: [],
        spanCategory: 'normal',
      }
    },
    addSpanRow() {
      this.spans?.spans.push({
        startingHour: 6,
        startingMinute: 0,
        endingHour: 6,
        endingMinute: 59,
        billingMethod: 'hourly',
        hourly: {
          billingMinute: 30,
          billingAmount: 100,
          billingUpperLimitAmount: 1000,
        },
        monthly: {
          billingAmount: 100,
        }
      });
    },
    async createPattern() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await nurseryHoursModule.createSpotFlexiblePattern()
        .catch((e) => {
          alert("パターンの設定に失敗しました");
        });
        if (response) {
          if (response.patternId) {
            await this.createSpans(response.patternId)
            .catch((e) => {
              console.warn(e);
            });
          }
          Swal.fire({
            title: '保存しました',
          });
          this.$router.push({ path: '/nursery-hours' });
        }
      }
      this.isLoading = false;
    },
    async updatePattern() {
      this.isLoading = true;
      const patternId = this.$route.query?.patternId;
      if (patternId) {
        const response = await nurseryHoursModule.updateSpotFlexiblePattern({
          patternId: String(patternId),
        })
        .catch((e) => {
          alert("パターンの設定に失敗しました");
        });
        if (response) {
          if (response.patternId) {
            await this.updateSpans(response.patternId)
            .catch((e) => {
              console.warn(e);
            });
          }
          Swal.fire({
            title: '保存しました',
          });
          this.$router.push({ path: '/nursery-hours' });
        }
      }
      this.isLoading = false;
    },
    async createSpans(patternId: string) {
      if (patternId && nurseryHoursModule.spansInput) {
        nurseryHoursModule.spansInput.patternId = patternId;
        await nurseryHoursModule.saveSpans()
        .catch((e) => {
          alert("不正な値が入力されています。時間枠の設定を見直して再度行ってください");
        });
      }
    },
    async updateSpans(patternId: string) {
      if (patternId && nurseryHoursModule.spansInput) {
        nurseryHoursModule.spansInput.patternId = patternId;
        await nurseryHoursModule.updateSpans()
        .catch((e) => {
          alert("不正な値が入力されています。時間枠の設定を見直して再度行ってください");
        });
      }
    },
  },
})
