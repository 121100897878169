import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiStaff from "@/api/ApiStaff";
import { StaffInfo, StaffInfoDetailResponse } from "chaild-api/lib";

export interface StaffState {
  staff: StaffInfo[];
  staffDetail: StaffInfoDetailResponse | null;
}

@Module({ dynamic: true, store, name: "staff", namespaced: true })
class StaffModule extends VuexModule implements StaffState {
  public staff: StaffInfo[] = [];
  public staffDetail: StaffInfoDetailResponse | null = null;

  @Action({ rawError: true })
  public async listStaff(input: { nurseryId: string }) {
    const response = await ApiStaff.listStaff(input);
    if (response) {
      this.setStaff(response.staffs);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getStaff(input: { nurseryId: string; userNurseryId: string }) {
    const response = await ApiStaff.getStaff(input);
    if (response) {
      this.setStaffDetail(response);
      return response;
    }
  }

  @Mutation
  public setStaff(value: StaffInfo[]) {
    this.staff = value;
  }

  @Mutation
  public setStaffDetail(value: StaffInfoDetailResponse) {
    this.staffDetail = value;
  }
}

export const staffModule = getModule(StaffModule);
