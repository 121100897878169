import { ChildInfoData, ChildInfoResponse, ChildResponse, NurseryChildResponse, ParentInfoData, ParentResponse } from "chaild-api/lib";
import ApiClient from "./ApiClient";
import { DataToUpdate } from '@/store/dataModules/parentDocumentModule';

export interface ParentInfo {
  parent: ParentResponse;
  parentInfo: ParentInfoData[];
}

export default class ApiChildren {
  public static async getParentInfo(childId: number) {
    const response = await ApiClient.get(`/parent-info`, {
      childId,
    });
    if (response) {
      return response as ParentInfo[];
    }
    return null;
  }

  public static async updateParentInfo(childId: number, data: DataToUpdate) {
    const answers = {
      parentId: data.parentId,
      answers: data.data.map((d) => {
        return {
          key: d.key,
          value: d.value,
        };
      })
    }
    const response = await ApiClient.post(`/parent-info`, answers);
    if (response) {
      return response as ParentInfo;
    }
    return null;
  }
}