
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import DatePager from "@/components/atoms/DatePager.vue";

export default Vue.extend({
  name: "ClassReportDetail",

  components: {
    PageTitle,
    DatePager,
  },
});
