
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import MonthlyPatternContainer from "@/containers/nursery-hours/MonthlyPatternContainer.vue";
import SpotNurseryPatternContainer from "@/containers/nursery-hours/SpotNurseryPatternContainer.vue";
import SpotKindergartenPatternContainer from "@/containers/nursery-hours/SpotKindergartenPatternContainer.vue";
import SpotFlexiblePatternContainer from "@/containers/nursery-hours/SpotFlexiblePatternContainer.vue";
import SpotCombinationPatternContainer from "@/containers/nursery-hours/SpotCombinationPatternContainer.vue";

export default Vue.extend({
  name: "NurseryHoursEdit",

  components: {
    PageTitle,
    MonthlyPatternContainer,
    SpotNurseryPatternContainer,
    SpotKindergartenPatternContainer,
    SpotFlexiblePatternContainer,
    SpotCombinationPatternContainer,
  },

  mounted() {
    if (this.$route.query && this.$route.query.category) {
      const _category = this.$route.query.category;
      if (_category == "monthly") {
        this.billingPeriod = "monthly";
      } else if (_category == "spotNursery") {
        this.billingPeriod = "spot";
        this.category = "spot-nursery";
      } else if (_category == "spotKindergarten") {
        this.billingPeriod = "spot";
        this.category = "spot-kindergarten";
      } else if (_category == "spotCombination") {
        this.billingPeriod = "spot";
        this.category = "spot-combination";
      } else if (_category == "spotFlexible") {
        this.billingPeriod = "spot";
        this.category = "spot-flexible";
      }
    }
  },

  data() {
    return {
      billingPeriod: "" as "monthly" | "spot" | "",
      billingPeriodItems: [
        { text: "", value: "" },
        { text: "月極", value: "monthly" },
        { text: "スポット", value: "spot" },
      ],
      category: "",
      categoryItems: [
        { text: "固定時間枠（保育園）", value: "spot-nursery" },
        { text: "固定時間枠（幼稚園）", value: "spot-kindergarten" },
        { text: "変動時間枠", value: "spot-flexible" },
        { text: "朝夕通し時間枠", value: "spot-combination" },
      ],
    };
  },
});
