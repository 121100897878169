
import Vue from "vue";
import { multimediaCategoryType, mediaFormatType } from "@/constants/memoru";
import { memoruModule } from "@/store/dataModules/memoruModule";
import LocalDataService from "@/service/LocalDataService";
import {
  MemoruPurchaseHistoryListResponse,
  NurseryClassResponse,
} from "chaild-api/lib";
import dayjs from "dayjs";
import { classModule } from "@/store/dataModules/classModule";

export default Vue.extend({
  name: "MemoruPurchaseHistory",

  async mounted() {
    await this.searchHistory();
  },

  data() {
    return {
      loading: false,
      multimediaCategoryType: multimediaCategoryType,
      mediaFormatType: mediaFormatType,
      searchFilters: {
        classId: "",
        from: "",
        to: "",
        mediaFormat: "",
        serialNumber: "",
      },
    };
  },

  computed: {
    purchaseHistory: function (): MemoruPurchaseHistoryListResponse | null {
      return memoruModule.purchaseHistory;
    },
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
  },

  methods: {
    async searchHistory() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await memoruModule
          .searchHistory({
            nurseryId,
            ...this.searchFilters,
          })
          .catch(() => (this.loading = false));
      }
      this.loading = false;
    },
    async listClasses() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId,
        });
      }
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
