import ApiClient from "./ApiClient";

export type ListFilesRequest = {
  action: string;
  category: string;
  nurseryId: number;
  date?: string; // YYYY-MM-DD
  limit?: number;
  skip?: number;
};

export default class ApiBatchStreamLog {
  /**
   * ダウンロード可能なファイル一覧取得
   */
  static async listFiles(input: ListFilesRequest) {
    return await ApiClient.get("/batch-stream-log", input);
  }
}
