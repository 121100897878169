
import Vue from "vue";
import { MultimediaAlbumResponse } from "chaild-api/lib";
import dayjs from "dayjs";

export default Vue.extend({
  name: "MemoruAlbumCard",

  props: {
    album: {
      type: Object as () => MultimediaAlbumResponse,
      default: null,
    },
  },

  computed: {
    available: function (): boolean {
      if (this.album) {
        if (
          dayjs().isAfter(this.album.availableAfter) &&
          (!this.album.availableBefore ||
            dayjs().isBefore(this.album.availableBefore))
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
});
