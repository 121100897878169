
import Vue from "vue";

export default Vue.extend({
  name: "GenderPicker",

  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      items: [
        { label: "すべて", value: null },
        { label: "男性", value: "male" },
        { label: "女性", value: "female" },
      ],
    };
  },

  methods: {
    inputGender(gender: string | null | number) {
      if (gender === 0) {
        this.$emit("input", null);
        return;
      }
      this.$emit("input", gender);
    },
  },
});
