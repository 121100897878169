/**
 * 指導計画/帳票
 */

export const GuidancePlanTypeItems = [
  { text: "全体的な計画", value: "general" },
  { text: "年間指導計画", value: "yearly" },
  { text: "月間指導計画", value: "monthly" },
  { text: "週間指導計画", value: "weekly" },
];

export const GuidancePlanFormItemCategoryItems = [
  { text: "見出し", value: "heading" },
  { text: "テキスト", value: "text" },
  { text: "日付", value: "date" },
  { text: "園児別テキスト", value: "childText" },
  { text: "グリッド", value: "grid" },
];
