import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import AttendanceList from "../views/attendance/AttendanceList.vue";
import AttendanceManagement from "../views/attendance/AttendanceManagement.vue";
import AttendanceEdit from "../views/attendance/AttendanceEdit.vue";
import StaffAttendanceManagement from "../views/staffAttendance/StaffAttendanceManagement.vue";
import StaffAttendanceList from "../views/staffAttendance/StaffAttendanceList.vue";
import StaffAttendanceEdit from "../views/staffAttendance/StaffAttendanceEdit.vue";
import ChildReport from "../views/dailyreport/ChildReport.vue";
import ChildReportDetail from "../views/dailyreport/ChildReportDetail.vue";
import ClassReport from "../views/dailyreport/ClassReport.vue";
import ClassReportDetail from "../views/dailyreport/ClassReportDetail.vue";
import NurseryReport from "../views/dailyreport/NurseryReport.vue";
import Calendar from "../views/Calendar.vue";
import MailInbox from "../views/mail/Inbox.vue";
import NewMessage from "../views/mail/NewMessage.vue";
import SignIn from "../views/auth/SignIn.vue";
import SignUp from "../views/auth/SignUp.vue";
import Logout from "../views/auth/Logout.vue";
import NapList from "../views/nap/NapList.vue";
import NapCreate from "../views/nap/NapCreate.vue";
import NapDetail from "../views/nap/NapDetail.vue";
import NapSensor from "../views/nap/NapSensor.vue";
import NapTemplateList from "../views/nap/NapTemplateList.vue";
import NapCheck from "../views/nap/NapCheck.vue";
import ClassSetting from "../views/setting/ClassSetting.vue";
import StaffList from "../views/setting/staff/StaffList.vue";
import StaffDetail from "../views/setting/staff/StaffDetail.vue";
import StaffEdit from "../views/setting/staff/StaffEdit.vue";
import MeSetting from "../views/setting/MeSetting.vue";
import Admin from "../views/Admin.vue";
import ClassDiaries from "../views/classDiary/ClassDiaries.vue";
import ClassDiary from "../views/classDiary/ClassDiary.vue";
import ClassDiaryCreate from "../views/classDiary/ClassDiaryCreate.vue";
import Children from "../views/children/Children.vue";
import ChildDocument from "../views/children/ChildDocument.vue";
import ChildAccount from "../views/children/ChildAccount.vue";
import ChildAccountCreate from "../views/children/ChildAccountCreate.vue";
import ChildAccountEdit from "../views/children/ChildAccountEdit.vue";
import GrowthRecords from "@/views/growth-record/GrowthRecords.vue";
import ChildGrowthRecord from "@/views/growth-record/ChildGrowthRecord.vue";
import ClassGrowthRecord from "@/views/growth-record/ClassGrowthRecord.vue";
import ParentDocument from "../views/parent/ParentDocument.vue";
import SchoolDiaryList from "../views/school-diary/SchoolDiaryList.vue";
import SchoolDiaryEdit from "../views/school-diary/SchoolDiaryEdit.vue";
import SchoolDiaryCreate from "../views/school-diary/SchoolDiaryCreate.vue";
import SchoolServices from "@/views/school-service/SchoolServices.vue";
import SchoolServiceUsages from "@/views/school-service/SchoolServiceUsages.vue";
import FoodMenus from "@/views/food-menu/FoodMenus.vue";
import Maintenance from "@/containers/Maintenance.vue";
import Invoice from "@/views/invoice/Invoice.vue";
import InvoiceDetail from "@/views/invoice/InvoiceDetail.vue";
import FeeSetting from "@/views/fee/FeeSetting.vue";
import CashBook from "@/views/cashbook/CashBook.vue";
import CashBookTable from "@/views/cashbook/CashbookTable.vue";
import NurseryHours from "@/views/nursery-hours/NurseryHours.vue";
import NurseryHoursEdit from "@/views/nursery-hours/NurseryHoursEdit.vue";
import NurseryHoursDiaries from "@/views/nursery-hours-diary/NurseryHoursDiaries.vue";
import NurseryHoursDiaryEdit from "@/views/nursery-hours-diary/NurseryHoursDiaryEdit.vue";
import DiarySetting from "@/views/diary-setting/DiarySetting.vue";
import MemoruTop from "@/views/memoru/MemoruTop.vue";
import MemoruContent from "@/views/memoru/MemoruContent.vue";
import MemoruUpload from "@/views/memoru/MemoruUpload.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Pasori from "@/views/pasori/Pasori.vue";
import GroupSetting from "@/views/setting/GroupSetting.vue";
import ShiftSetting from "@/views/work-scheduling/ShiftSetting.vue";
import MonthlyWorkSchedule from "@/views/work-scheduling/MonthlyWorkSchedule.vue";
import DailyWorkSchedule from "@/views/work-scheduling/DailyWorkSchedule.vue";
import AttendanceSchedule from "@/views/work-scheduling/AttendanceSchedule.vue";
import PlacementSetting from "@/views/work-scheduling/PlacementSetting.vue";
import BatchStreamLogList from "@/views/batch-stream-log/BatchStreamLogList.vue";
import GuidancePlanList from "@/views/guidance/GuidancePlanList.vue";
import GuidancePlanDetail from "@/views/guidance/GuidancePlanDetail.vue";
import GuidancePlanFormList from "@/views/guidance/GuidancePlanFormList.vue";
import GuidancePlanFormMastList from "@/views/guidance/GuidancePlanFormMastList.vue";
import GuidancePlanFormItemList from "@/views/guidance/GuidancePlanFormItemList.vue";
import LetterList from "@/views/letter/LetterList.vue";
import LetterEdit from "@/views/letter/LetterEdit.vue";
import SystemSetting from "@/views/system-setting/SystemSetting.vue";
import ChildDevelopmentTop from "@/views/child-development/ChildDevelopmentTop.vue";
import ChildDevelopmentClass from "@/views/child-development/ChildDevelopmentClass.vue";
import ChildDevelopmentChild from "@/views/child-development/ChildDevelopmentChild.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/attendance/list",
    name: "AttendanceList",
    component: AttendanceList,
  },
  {
    path: "/attendance/management",
    name: "AttendanceManagement",
    component: AttendanceManagement,
  },
  {
    path: "/attendance/:childId/:date/edit",
    name: "AttendanceEdit",
    component: AttendanceEdit,
  },
  {
    path: "/staffAttendance/management",
    name: "StaffAttendanceManagement",
    component: StaffAttendanceManagement,
  },
  {
    path: "/staffAttendance/list",
    name: "StaffAttendanceList",
    component: StaffAttendanceList,
  },
  {
    path: "/staffAttendance/:userId/:date/edit",
    name: "StaffAttendanceEdit",
    component: StaffAttendanceEdit,
  },
  {
    path: "/dailyreport/child",
    name: "ChildReport",
    component: ChildReport,
  },
  {
    path: "/dailyreport/child/:childId/:date",
    name: "ChildReportDetail",
    component: ChildReportDetail,
  },
  {
    path: "/dailyreport/class",
    name: "ClassReport",
    component: ClassReport,
  },
  {
    path: "/dailyreport/class/:id",
    name: "ClassReportDetail",
    component: ClassReportDetail,
  },
  {
    path: "/dailyreport/nursery",
    name: "NurseryReport",
    component: NurseryReport,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/mail/inbox",
    name: "MailInbox",
    component: MailInbox,
  },
  {
    path: "/mail/new",
    name: "NewMessage",
    component: NewMessage,
  },
  {
    path: "/nap/list",
    name: "NapList",
    component: NapList,
  },
  {
    path: "/nap/create",
    name: "NapCreate",
    component: NapCreate,
  },
  {
    path: "/nap/:napId/detail",
    name: "NapDetail",
    component: NapDetail,
  },
  {
    path: "/nap/:napId/sensor",
    name: "NapSensor",
    component: NapSensor,
  },
  {
    path: "/nap/template/list",
    name: "NapTemplateList",
    component: NapTemplateList,
  },
  {
    path: "/nap/check",
    name: "NapCheck",
    component: NapCheck,
  },
  {
    path: "/school-diary",
    name: "SchoolDiaryList",
    component: SchoolDiaryList,
  },
  {
    path: "/school-diary/edit/:date",
    name: "SchoolDiaryEdit",
    component: SchoolDiaryEdit,
  },
  {
    path: "/school-diary/create",
    name: "SchoolDiaryCreate",
    component: SchoolDiaryCreate,
  },
  {
    path: "/setting/class",
    name: "ClassSetting",
    component: ClassSetting,
  },
  {
    path: "/setting/staff",
    name: "StaffList",
    component: StaffList,
  },
  {
    path: "/setting/staff/:id",
    name: "StaffDetail",
    component: StaffDetail,
  },
  {
    path: "/setting/staff/:id/edit",
    name: "StaffEdit",
    component: StaffEdit,
  },
  {
    path: "/setting/me",
    name: "MeSetting",
    component: MeSetting,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/class-diaries",
    name: "ClassDiaries",
    component: ClassDiaries,
  },
  {
    path: "/class-diaries/create",
    name: "ClassDiaryCreate",
    component: ClassDiaryCreate,
  },
  {
    path: "/class-diaries/:classId/:classDiaryId",
    name: "ClassDiary",
    component: ClassDiary,
  },
  {
    path: "/growth-record",
    name: "GrowthRecords",
    component: GrowthRecords,
  },
  {
    path: "/growth-record/child/:childId",
    name: "ChildGrowthRecord",
    component: ChildGrowthRecord,
  },
  {
    path: "/growth-record/class/:classId",
    name: "ClassGrowthRecord",
    component: ClassGrowthRecord,
  },
  {
    path: "/child-development",
    name: "ChildDevelopmentTop",
    component: ChildDevelopmentTop,
  },
  {
    path: "/child-development/class/:businessYear/:classId",
    name: "ChildDevelopmentClass",
    component: ChildDevelopmentClass,
  },
  {
    path: "/child-development/child/:businessYear/:childId",
    name: "ChildDevelopmentChild",
    component: ChildDevelopmentChild,
  },
  {
    path: "/school-service",
    name: "SchoolServices",
    component: SchoolServices,
  },
  {
    path: "/school-service/usage/:classId",
    name: "SchoolServiceUsages",
    component: SchoolServiceUsages,
  },
  {
    path: "/food-menus",
    name: "FoodMenus",
    component: FoodMenus,
  },
  {
    path: "/children",
    name: "Children",
    component: Children,
  },
  {
    path: "/children/document/:childId",
    name: "ChildDocument",
    component: ChildDocument,
  },
  {
    path: "/children/account/:childId",
    name: "ChildAccount",
    component: ChildAccount,
  },
  {
    path: "/children/account/:childId/edit",
    name: "ChildAccountEdit",
    component: ChildAccountEdit,
  },
  {
    path: "/children/account-create",
    name: "ChildAccountCreate",
    component: ChildAccountCreate,
  },
  {
    path: "/parent/:childId/document",
    name: "ParentDocument",
    component: ParentDocument,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/fee-setting",
    name: "FeeSetting",
    component: FeeSetting,
  },
  {
    path: "/invoice/detail",
    name: "InvoiceDetail",
    component: InvoiceDetail,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/cashbook",
    name: "CashBook",
    component: CashBook,
  },
  {
    path: "/cashbook/:yearMonth",
    name: "CashBookTable",
    component: CashBookTable,
  },
  {
    path: "/nursery-hours",
    name: "NurseryHours",
    component: NurseryHours,
  },
  {
    path: "/nursery-hours/edit",
    name: "NurseryHoursEdit",
    component: NurseryHoursEdit,
  },
  {
    path: "/nursery-hours-diary/list",
    name: "NurseryHoursDiaries",
    component: NurseryHoursDiaries,
  },
  {
    path: "/nursery-hours-diary/edit",
    name: "NurseryHoursDiaryEdit",
    component: NurseryHoursDiaryEdit,
  },
  {
    path: "/diary-setting",
    name: "DiarySetting",
    component: DiarySetting,
  },
  {
    path: "/memoru/top",
    name: "MemoruTop",
    component: MemoruTop,
  },
  {
    path: "/memoru/album/:albumId",
    name: "MemoruContent",
    component: MemoruContent,
  },
  {
    path: "/memoru/upload/:albumId?",
    name: "MemoruUpload",
    component: MemoruUpload,
  },
  {
    path: "/pasori",
    name: "Pasori",
    component: Pasori,
  },
  {
    path: "/setting/group",
    name: "GroupSetting",
    component: GroupSetting,
  },
  {
    path: "/work-scheduling/shift",
    name: "ShiftSetting",
    component: ShiftSetting,
  },
  {
    path: "/work-scheduling/daily",
    name: "DailyWorkSchedule",
    component: DailyWorkSchedule,
  },
  {
    path: "/work-scheduling/monthly",
    name: "MonthlyWorkSchedule",
    component: MonthlyWorkSchedule,
  },
  {
    path: "/work-scheduling/attendance",
    name: "AttendanceSchedule",
    component: AttendanceSchedule,
  },
  {
    path: "/work-scheduling/placement-setting",
    name: "PlacementSetting",
    component: PlacementSetting,
  },
  {
    path: "/download/list",
    name: "BatchStreamLogList",
    component: BatchStreamLogList,
  },
  {
    path: "/guidance-plan/list",
    name: "GuidancePlanList",
    component: GuidancePlanList,
  },
  {
    path: "/guidance-plan/detail/:id",
    name: "GuidancePlanDetail",
    component: GuidancePlanDetail,
  },
  {
    path: "/guidance-plan-form/list",
    name: "GuidancePlanFormList",
    component: GuidancePlanFormList,
  },
  {
    path: "/guidance-plan-form-item/list",
    name: "GuidancePlanFormItemList",
    component: GuidancePlanFormItemList,
  },
  {
    path: "/guidance-plan-form-mast/list",
    name: "GuidancePlanFormMastList",
    component: GuidancePlanFormMastList,
  },
  {
    path: "/letter/list",
    name: "LetterList",
    component: LetterList,
  },
  {
    path: "/letter/:letterId?",
    name: "LetterEdit",
    component: LetterEdit,
  },
  {
    path: "/system-setting/list",
    name: "SystemSetting",
    component: SystemSetting,
  },
  {
    name: "PageNotFound",
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  // maintenance
  const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE;
  const toQuery = to.query;
  const fromQuery = from.query;
  const toPath = to.path;

  const isMaintenanceMode = maintenanceMode && maintenanceMode === "true";
  const toMagicWord = toQuery.vadar && toQuery.vadar === "anakin";
  const fromMagicWord = fromQuery.vadar && fromQuery.vadar === "anakin";

  if (isMaintenanceMode) {
    if (toMagicWord) {
      if (toPath === "/maintenance") {
        next("/attendance/list?vadar=anakin");
        return;
      }
      next();
      return;
    }

    if (fromMagicWord) {
      // ここで to のクエリーに from のクエリーを渡す
      const query = Object.assign(to.query, { vadar: "anakin" });
      next({ name: to.name || "", params: to.params, query });
      return;
    }

    // should go to maintenance
    if (toPath === "/maintenance") {
      next();
      return;
    }
    next("/maintenance");
    return;
  }

  // maintenanceMode = false
  if (toPath === "/maintenance") {
    next("/attendance/list");
    return;
  }

  next();
});

export default router;
