
import Vue, { PropType } from 'vue'
import Swal from 'sweetalert2'
import DialogMixin from '@/mixins/DialogMixin'
import DatePicker from '@/components/atoms/DatePicker.vue'
import { eventEditModule } from '@/store/dataModules/eventEditModule'
import LocalDataService from '@/service/LocalDataService'
import { classModule } from '@/store/dataModules/classModule'
import ValidationMixin from '@/mixins/ValidationMixin'

type dialogType = "create" | "update";

export default Vue.extend({
  name: 'EventDialogContainer',

  mixins: [DialogMixin, ValidationMixin],

  components: {
    DatePicker,
  },

  props: {
    dialogType: {
      type: String as PropType<dialogType>,
      default: "create"
    }
  },

  watch: {
    fromDate: {
      handler(val: string) {
        if (val) {
          this.toDate = val;
        }
      },
    },
    isAllDay: {
      handler(val: string) {
        if (val) {
          this.fromTime = "";
          this.toTime = "";
        }
      }
    },
    isOpen : {
      handler(val: boolean) {
        // 終日チェックボックスのfromTimeによる切り替え
        if (val) {
          if (this.fromTime) {
            this.isAllDay = false;
          } else {
            this.isAllDay = true;
          }
        }
      }
    },
  },

  data() {
    return {
      isAllDay: false,
      loading: false,
    }
  },

  computed: {
    classes: function(): any[] {
      return classModule.classes.map((c) => {
        return {
          className: `${c.year}年度 ${c.className}`,
          classId: c.classId,
        }
      });
    },
    eventName: {
      get() {
        return eventEditModule.eventName;
      },
      set(value: string) {
        eventEditModule.setEventName(value);
      }
    },
    classIds: {
      get() {
        return eventEditModule.classIds;
      },
      set(value: number[]) {
        eventEditModule.setClassIds(value);
      }
    },
    fromDate: {
      get() {
        return eventEditModule.fromDate;
      },
      set(value: string) {
        eventEditModule.setFromDate(value);
      }
    },
    toDate: {
      get() {
        return eventEditModule.toDate;
      },
      set(value: string) {
        eventEditModule.setToDate(value);
      }
    },
    fromTime: {
      get(): string {
        return eventEditModule.fromTime ? this.formatTextToTime(eventEditModule.fromTime) : '';
      },
      set(value: string) {
        eventEditModule.setFromTime(this.formatTimeToText(value));
      }
    },
    toTime: {
      get(): string {
        return eventEditModule.toTime ? this.formatTextToTime(eventEditModule.toTime) : '';
      },
      set(value: string) {
        eventEditModule.setToTime(this.formatTimeToText(value));
      }
    },
    valid: function(): boolean {
      if (!this.isAllDay) {
        return (
          this.fromTime != "" &&
          this.toTime != ""
        );
      }

      if (this.fromDate && this.toDate && this.eventName) {
        return true; 
      } else {
        return false;
      }
    }
  },

  methods: {
    async addEvent() {
      this.loading = true;

      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await eventEditModule.addEvent({
          nurseryId: nurseryId
        });
        if (response) {
          Swal.fire({ title: '予定を追加しました', icon: 'success' });
          this.$emit('is-open', false);
        } else {
          Swal.fire({ title: '失敗しました', icon: 'error', });
        }
      }
      this.loading = false;
    },
    async updateEvent() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await eventEditModule.updateEvent({
          nurseryId: nurseryId,
        });
        if (response) {
          Swal.fire({ title: '予定を保存しました', icon: 'success' });
          this.$emit('is-open', false);
        } else {
          Swal.fire({ title: '失敗しました', icon: 'error', });
        }
      }
      this.loading = false;
    },
    async deleteEvent() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await eventEditModule.deleteEvent({
          nurseryId: nurseryId,
        });
        if (response) {
          Swal.fire({ title: '予定を削除しました', icon: 'success' });
          this.$emit('is-open', false);
        } else {
          Swal.fire({ title: '失敗しました', icon: 'error', });
        }
      }
      this.loading = false;
    },
    formatTimeToText(time: string): string {
      return time.replace(':', '');
    },
    formatTextToTime(text: string): string {
      return text.slice(0, 2) + ':' + text.slice(2);
    },
  }
})
