import { Module, VuexModule, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import ApiChildren from "@/api/ApiChildren";

export interface ChildAccountCreateState {
  flag: boolean;
}

@Module({
  dynamic: true,
  store,
  name: "child-account-create",
  namespaced: true,
})
class ChildAccountCreateModule
  extends VuexModule
  implements ChildAccountCreateState
{
  public flag = true;

  @Action
  public async createChild(input: {
    nurseryId: number;
    childcareType?: string;
    firstName?: string;
    firstNameKana?: string;
    lastName?: string;
    lastNameKana?: string;
    gender?: string;
    dateOfBirth?: string;
    optionalChildCode1?: string;
    optionalChildCode2?: string;
    admissionDate?: string;
    graduationDate?: string;
    moveOrLeave?: boolean;
    reason?: string;
    notaBene?: string;
    allergicFoodIds?: number[];
    hoursPatternId?: string;
  }) {
    const response = await ApiChildren.createChild(input);
    if (response) {
      return response;
    }
    return null;
  }
}

export const childAccountCreateModule = getModule(ChildAccountCreateModule);
