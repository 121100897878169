import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { DailyReportResponse, DiaryResponse } from "chaild-api/lib";
import ApiChildDiary from "@/api/ApiChildDiary";
import ApiChildReport from "@/api/ApiChildReport";

export interface ChildDiaryDetailState {
  diary: DiaryResponse | null;
  report: DailyReportResponse | null;

  childId: number | null;
  date: string | null;
}

@Module({ dynamic: true, store, name: "child-diary-detail", namespaced: true })
class ChildDiaryDetailModule
  extends VuexModule
  implements ChildDiaryDetailState
{
  public diary: DiaryResponse | null = null;
  public report: DailyReportResponse | null = null;

  public childId: number | null = null;
  public date: string | null = null;

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Mutation
  public setDate(date: string | null) {
    this.date = date;
  }

  @Action
  public async getDailyReport() {
    if (this.childId && this.date) {
      const response = await ApiChildReport.fetchChildDiary(
        this.childId,
        this.date
      );
      if (response) {
        this.setReport(response);
      }
    }
  }

  @Mutation
  public setReport(report: DailyReportResponse) {
    this.report = report;
  }

  @Action
  public async getDiary() {
    if (this.childId && this.date) {
      const response = await ApiChildDiary.findChildDiary(
        this.childId,
        this.date
      );
      if (response) {
        this.setDiary(response);
      }
    }
  }

  @Mutation
  public setDiary(diary: DiaryResponse | null) {
    this.diary = diary;
  }
}

export const childDiaryDetailModule = getModule(ChildDiaryDetailModule);
