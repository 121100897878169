import ApiClient from '@/api/ApiClient';

/**
 * types
 */
export type ListGroupsRequest = {
  nurseryId: number;
  year?: string;
};

export type CreateGroupRequest = {
  nurseryId: number;
  year: string;
  name: string;
};

export type UpdateGroupRequest = Omit<CreateGroupRequest, "nurseryId"> & GetGroupRequest;

export type GetGroupRequest = {
  groupId: number;
};


/**
 * functions
 */
export default class ApiGroup {

  static async listGroups(input: ListGroupsRequest) {
    return await ApiClient.get('/group', input);
  }

  static async createGroup(input: CreateGroupRequest) {
    return await ApiClient.post('/group', input);
  }

  static async getGroup(input: GetGroupRequest) {
    return await ApiClient.get(`/group/${input.groupId}`, null);
  }

  static async updateGroup(input: UpdateGroupRequest) {
    return await ApiClient.put(`/group/${input.groupId}`, input);
  }

  static async deleteGroup(input: GetGroupRequest) {
    return await ApiClient.delete(`/group/${input.groupId}`, null);
  }

}