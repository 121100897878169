import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiChat from "@/api/ApiChat";
import LocalDataService from "@/service/LocalDataService";
import ApiChildren from "@/api/ApiChildren";
import dayjs from "dayjs";
import { NurseryChildResponse } from "chaild-api/lib";

/* eslint-disable */
export interface ChatEditState {
  title: string;
  body: string;
  classIds: number[];
  groupIds: number[];
  childrenSelectionClassIds: number[];
  childrenSelectionGroupIds: number[];
  attachmentUrls: string[];

  targetVariant: "group" | "children";

  selectedChildren: NurseryChildResponse[];
  childrenOptions: NurseryChildResponse[];
}

@Module({ dynamic: true, store, name: "chat-edit", namespaced: true })
class ChatEditModule extends VuexModule implements ChatEditState {
  public title: string = "";
  public body: string = "";
  public classIds: number[] = [];
  public groupIds: number[] = [];
  public childrenSelectionClassIds: number[] = [];
  public childrenSelectionGroupIds: number[] = [];
  public attachmentUrls: string[] = [];

  public targetVariant: "group" | "children" = "group";

  public selectedChildren: NurseryChildResponse[] = [];
  public childrenOptions: NurseryChildResponse[] = [];

  @Action({ rawError: true })
  public async sendGroupChat() {
    if (this.targetVariant === "group") {
      const response = await ApiChat.sendGroupChat({
        body: this.body,
        classIds: this.classIds,
        groupIds: this.groupIds,
        childIds: [],
        title: this.title,
        attachmentUrls: this.attachmentUrls,
        groupChatIds: [],
      });
      if (response) {
        return response;
      }
    } else {
      const childIds = this.selectedChildren.map((child) => child.childId);
      const response = await ApiChat.sendGroupChat({
        body: this.body,
        childIds,
        classIds: [],
        groupIds: [],
        title: this.title,
        attachmentUrls: this.attachmentUrls,
      });
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async saveAsDraft(input: { groupChatId?: string }) {
    let ob = {};
    if (input.groupChatId) {
      ob = {
        groupChatId: input.groupChatId,
      };
    }

    if (this.targetVariant === "group") {
      const response = await ApiChat.saveGroupChatDraft({
        body: this.body,
        classIds: this.classIds,
        groupIds: this.groupIds,
        title: this.title,
        attachmentUrls: this.attachmentUrls,
        ...ob,
      });
      if (response) {
        return response;
      }
    } else {
      const childIds = this.selectedChildren.map((child) => child.childId);
      const response = await ApiChat.saveGroupChatDraft({
        body: this.body,
        childIds,
        title: this.title,
        attachmentUrls: this.attachmentUrls,
        ...ob,
      });
      if (response) {
        return response;
      }
    }
  }

  @Action({ rawError: true })
  public async deleteDraftGroupChat(input: { groupChatId: string }) {
    const response = await ApiChat.deleteDraftGroupChat({
      groupChatIds: input.groupChatId,
    });
    if (response) {
      return response;
    }
  }

  @Mutation
  public setTitle(value: string) {
    this.title = value;
  }

  @Mutation
  public setBody(value: string) {
    this.body = value;
  }

  @Mutation
  public setClassIds(value: number[]) {
    this.classIds = value;
  }

  @Mutation
  public setGroupIds(value: number[]) {
    this.groupIds = value;
  }

  @Mutation
  public setAttachmentUrls(value: string[]) {
    this.attachmentUrls = value;
  }

  // children
  @Action
  public async addChildrenWithClassIds(classId: number) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildren.listChildren({ classId, nurseryId });
      const activeChildren = response.filter((child) => {
        if (child.quitDate) {
          const now = dayjs();
          const quitDate = dayjs(child.quitDate);
          const diff = now.diff(quitDate);
          if (diff > 0) {
            return false;
          }
        }
        return true;
      });
      if (response) {
        this.addChildren(activeChildren);
      }
    }
  }

  @Action
  public async addChildrenWithGroupId(groupId: number) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildren.listChildren({ groupId, nurseryId });
      const activeChildren = response.filter((child) => {
        if (child.quitDate) {
          const now = dayjs();
          const quitDate = dayjs(child.quitDate);
          const diff = now.diff(quitDate);
          if (diff > 0) {
            return false;
          }
        }
        return true;
      });
      if (response) {
        this.addChildren(activeChildren);
      }
    }
  }

  @Mutation
  public addChildren(children: NurseryChildResponse[]) {
    const currentSelected = [...this.selectedChildren];
    const newSelected: NurseryChildResponse[] = [];

    children.forEach((child) => {
      const findFromSelected = currentSelected.find(
        (c) => c.childId === child.childId
      );
      if (!findFromSelected) {
        newSelected.push(child);
      }
    });
    this.selectedChildren = this.selectedChildren.concat(newSelected);

    const childrenIds = children.map((c) => c.childId);
    this.childrenOptions = this.childrenOptions.filter((c) => {
      if (childrenIds.indexOf(c.childId) >= 0) {
        return false;
      }
      return true;
    });
  }

  @Action
  public removeChild(childId: number) {
    const children = this.selectedChildren.filter((child) => {
      if (child.childId !== childId) {
        return true;
      }
      this.addOptionChild(child);
      return false;
    });
    this.setSelectedChildren(children);
  }

  @Action
  public removeChildrenWithClassId(classId: number) {
    const children = this.selectedChildren.filter((child) => {
      const findClass = child.nurseryClasses.find((c) => c.classId === classId);
      if (findClass) {
        this.addOptionChild(child);
        return false;
      }
      return true;
    });

    this.setSelectedChildren(children);
  }

  @Action
  public removeChildrenWithGroupId(groupId: number) {
    const children = this.selectedChildren.filter((child) => {
      const findGroup = child.nurseryGroups.find((c) => c.groupId === groupId);
      if (findGroup) {
        this.addOptionChild(child);
        return false;
      }
      return true;
    });

    this.setSelectedChildren(children);
  }

  @Mutation
  public addOptionChild(child: NurseryChildResponse) {
    this.childrenOptions.push(child);
  }

  @Mutation
  public setSelectedChildren(children: NurseryChildResponse[]) {
    this.selectedChildren = children;
  }

  @Mutation
  public addSelectedChildren(child: NurseryChildResponse) {
    this.selectedChildren.unshift(child);

    this.childrenOptions = this.childrenOptions.filter(
      (co) => co.childId !== child.childId
    );
  }

  @Mutation
  public setTargetVariant(targetVariant: "group" | "children") {
    this.targetVariant = targetVariant;
  }

  @Mutation
  public setChildrenSelectionClassIds(ids: number[]) {
    this.childrenSelectionClassIds = ids;
  }

  @Mutation
  public setChildrenSelectionGroupIds(ids: number[]) {
    this.childrenSelectionGroupIds = ids;
  }
}

export const chatEditModule = getModule(ChatEditModule);
