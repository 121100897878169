
import Vue from "vue";

export default Vue.extend({
  name: "SignIn",

  methods: {
    goTop() {
      this.$router.push({ path: "/attendance/list" });
    },
  },
});
