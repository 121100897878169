
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import { eventModule } from "@/store/dataModules/eventModule";
import { classModule } from "@/store/dataModules/classModule";
import LocalDataService from "@/service/LocalDataService";
import EventDialogContainer from "@/containers/EventDialogContainer.vue";
import { YearlyEventResponseForNursery } from "chaild-api/lib";
import { eventEditModule } from "@/store/dataModules/eventEditModule";
import dayjs from "dayjs";

interface CalendarType {
  name: string;
  start: string;
  end?: string;
  timed: boolean;
}

export default Vue.extend({
  name: "Calendar",

  components: {
    PageTitle,
    EventDialogContainer,
  },

  mounted() {
    this.listEvents();
    this.listClass();
  },

  watch: {
    createDialog: {
      handler(val: boolean) {
        if (!val) {
          this.listEvents();
          eventEditModule.clearValues();
        }
      },
    },
    updateDialog: {
      handler(val: boolean) {
        if (!val) {
          this.listEvents();
          eventEditModule.clearValues();
        }
      },
    },
  },

  data() {
    return {
      createDialog: false,
      updateDialog: false,
      loading: false,
      model: "",
    };
  },

  computed: {
    events: function (): YearlyEventResponseForNursery[] {
      return eventModule.events;
    },
    formattedEvents: function (): CalendarType[] {
      const list = eventModule.events.map((e) => {
        return {
          name: e.eventName,
          start: e.fromTime
            ? e.date + " " + this.getFormattedTime(e.fromTime)
            : e.date,
          end: e.toTime
            ? e.date + " " + this.getFormattedTime(e.toTime)
            : e.date,
          timed: e.toTime ? true : false,
          event: e, // objectまるごと
        };
      });
      return list;
    },
  },

  methods: {
    async listEvents() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        let ob = {};
        if (this.model) {
          const toDate = dayjs(this.model).endOf("month").format("YYYY-MM-DD");
          const fromDate = dayjs(this.model)
            .startOf("month")
            .format("YYYY-MM-DD");
          ob = {
            from: fromDate,
            to: toDate,
          };
        }
        const response = await eventModule.listEvents({
          nurseryId: nurseryId,
          ...ob,
        });
      }
      this.loading = false;
    },
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId: nurseryId,
          // year: dayjs().year(),
        });
      }
    },
    getFormattedTime(time?: string): string {
      if (!time) {
        return "";
      }
      return time.slice(0, 2) + ":" + time.slice(2);
    },
    clickEvent(event: any) {
      eventEditModule.setEventId(event.event.event.yearlyEventId);
      eventEditModule.setEventName(event.event.event.eventName);
      eventEditModule.setClassIds(event.event.event.classIds);
      eventEditModule.setToDate("");
      eventEditModule.setFromDate(event.event.event.date);
      eventEditModule.setToTime(event.event.event.toTime);
      eventEditModule.setFromTime(event.event.event.fromTime);
      this.updateDialog = true;
    },
  },
});
