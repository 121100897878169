
import Vue from 'vue';

import { foodMenusModule } from '@/store/viewModules/food-menu/foodMenusModule';

import PageTitle from '@/components/atoms/PageTitle.vue';
import { classModule } from '@/store/dataModules/classModule';
import LocalDataService from '@/service/LocalDataService';

interface DataType {
  categoryName: string;
  targetClassIds: number[];

  isCreating: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    categoryName: '',
    targetClassIds: [],

    isCreating: false,
  }),

  computed: {
    isDialogOpen() {
      return foodMenusModule.isCreateCategoryDialogOpen;
    },
    classes() {
      return classModule.classes;
    },
    isCreationValid() {
      if (
        this.categoryName.trim().length > 0
        && this.targetClassIds.length > 0
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    closeDialog() {
      this.categoryName = '';
      this.targetClassIds = [];
      foodMenusModule.setIsCreateCategoryDialogOpen(false);
    },
    async createCategory() {
      this.isCreating = true;
      await foodMenusModule.createCategory({
        name: this.categoryName,
        targetClassIds: this.targetClassIds,
      });
      await foodMenusModule.listMealCategories();
      this.isCreating = false;
      foodMenusModule.setIsCreateCategoryDialogOpen(false);
    }
  },

  mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      classModule.listClass({ nurseryId });
    }
  }
});
