import ApiClient from "@/api/ApiClient";
/* eslint-disable */
export default class ApiChat {
  // 連絡メール
  public static async listGroupChat(input: {
    nurseryId: number;
    limit?: number;
    skip?: number;
    startedAt?: string;
    endedAt?: string;
    title?: string;
    userId?: number | string;
    status?: "inDraft" | "delivered" | "inProgress";
  }) {
    const response = await ApiClient.get("/group-chats", input);
    return response;
  }

  public static async sendGroupChat(input: {
    date?: string;
    groupChatIds?: number[];
    body: string;
    classIds?: number[];
    childIds?: number[];
    groupIds?: number[];
    attachmentUrls?: string[];
    title?: string;
  }) {
    const response = await ApiClient.post("/group-chats", {
      date: input.date,
      groupChatIds: input.groupChatIds,
      body: input.body,
      classIds: input.classIds,
      childIds: input.childIds,
      groupIds: input.groupIds,
      attachmentUrls: input.attachmentUrls,
      title: input.title,
    });
    return response;
  }

  public static async listGroupChatDraft(input: { nurseryId: number }) {
    const response = await ApiClient.get("/group-chats/draft", {
      nurseryId: input.nurseryId,
    });
    return response;
  }

  public static async saveGroupChatDraft(input: {
    date?: string;
    groupChatId?: number;
    body: string;
    classIds?: number[];
    childIds?: number[];
    groupIds?: number[];
    attachmentUrls?: string[];
    title?: string;
  }) {
    const response = await ApiClient.post("/group-chats/draft", {
      date: input.date,
      groupChatId: input.groupChatId,
      body: input.body,
      classIds: input.classIds,
      childIds: input.childIds,
      groupIds: input.groupIds,
      attachmentUrls: input.attachmentUrls,
      title: input.title,
    });
    return response;
  }

  public static async getGroupChat(input: { groupChatId: string }) {
    const response = await ApiClient.get(
      `/group-chats/${input.groupChatId}`,
      null
    );
    return response;
  }

  public static async deleteGroupChat(input: { groupChatIds: string }) {
    const response = await ApiClient.delete("/group-chats", {
      groupChatIds: input.groupChatIds,
    });
    return response;
  }

  public static async deleteDraftGroupChat(input: { groupChatIds: string }) {
    const response = await ApiClient.delete("/group-chats/draft", {
      groupChatIds: input.groupChatIds,
    });
    return response;
  }

  // template
  public static async listTemplates(input: { nurseryId: number }) {
    const response = await ApiClient.get("/group-chats/template", input);
    return response;
  }

  public static async createTemplate(input: {
    nurseryId: number;
    title?: string;
    body: string;
    classIds?: number[];
    groupIds?: number[];
    childIds?: number[];
    attachmentUrls: string[];
  }) {
    const response = await ApiClient.post("/group-chats/template", input);
    return response;
  }

  public static async getTemplate(input: { templateId: string }) {
    const response = await ApiClient.get(
      `/group-chats/template/${input.templateId}`,
      null
    );
    return response;
  }

  public static async updateTemplate(input: {
    nurseryId: number;
    title?: string;
    body: string;
    classIds?: string[];
    childIds: number[];
    groupIds?: number[];
    attachmentUrls: string[];
  }) {
    const response = await ApiClient.put("/group-chats/template", input);
    return response;
  }

  public static async deleteTemplate(input: { templateId: string }) {
    const response = await ApiClient.delete(
      `/group-chats/template/${input.templateId}`,
      null
    );
    return response;
  }
}
