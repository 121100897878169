import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { NurseryGroupListResponse, NurseryGroupResponse } from "chaild-api/lib";
import ApiGroup, { ListGroupsRequest, GetGroupRequest } from "@/api/ApiGroup";

export interface GroupState {
  groups: NurseryGroupListResponse;
  group: NurseryGroupResponse | null;
}

@Module({ dynamic: true, store, name: "group", namespaced: true })
class GroupModule extends VuexModule implements GroupState {
  public groups: NurseryGroupListResponse = [];
  // for edit
  public group: NurseryGroupResponse | null = {
    year: 0,
    groupId: 0,
    name: "",
    createdAt: "",
    updatedAt: null,
  };

  @Action({ rawError: true })
  public async listGroups(input: ListGroupsRequest) {
    const response = await ApiGroup.listGroups(input).catch();
    this.setGroups(response);
    return response;
  }

  @Action({ rawError: true })
  public async getGroup(input: GetGroupRequest) {
    const response = await ApiGroup.getGroup(input).catch();
    if (response) {
      this.setGroup(response);
    }
    return response;
  }

  @Mutation
  public setGroups(value: NurseryGroupListResponse) {
    this.groups = value;
  }

  @Mutation
  public setGroup(value: NurseryGroupResponse) {
    this.group = value;
  }
}

export const groupModule = getModule(GroupModule);
