import ApiClient from "@/api/ApiClient";
import {
  AttendanceScheduleDailyResponse,
  AttendanceScheduleMonthlyListResponse,
  AttendanceScheduleDailyPartSchema,
  ChildDefaultScheduleResponse,
  ChildDefaultSchedulePartSchema,
  WorkSchedulePlacementSettingResponse,
  WorkSchedulePlacementSettingBody,
} from "chaild-api/lib";

export default class ApiShift {
  public static async listAttendanceSchedules({
    year,
    month,
    nurseryId,
    classId,
  }: {
    year: string | number;
    month: string | number;
    nurseryId: string | number;
    classId?: string | number;
  }) {
    try {
      const response = await ApiClient.get(
        `/attendance-schedule/${year}/${month}/children`,
        {
          nurseryId,
          classId,
        }
      );
      return response as AttendanceScheduleMonthlyListResponse;
    } catch {
      return null;
    }
  }

  public static async addDefaultAttendanceSchedules({
    year,
    month,
    nurseryId,
  }: {
    year: string | number;
    month: string | number;
    nurseryId: string | number;
  }) {
    try {
      const response = await ApiClient.post(
        `/attendance-schedule/${year}/${month}/children`,
        {
          nurseryId,
        }
      );
      return response as AttendanceScheduleMonthlyListResponse;
    } catch {
      return null;
    }
  }

  public static async updateChildAttendanceSchedule({
    year,
    month,
    date,
    childId,
    abbreviation,
    color,
    status,
    statusReason,
    statusComment,
    startMin,
    endMin,
  }: {
    year: string | number;
    month: string | number;
    date: string | number | null;
    childId: string | number;
    abbreviation?: string;
    color?: string;
    status?: "attending" | "isAbsent";
    statusReason?: string;
    statusComment?: string;
    startMin?: number;
    endMin?: number;
  }) {
    try {
      const response = await ApiClient.put(
        `/attendance-schedule/${year}/${month}/${date}/children/${childId}`,
        {
          date,
          abbreviation,
          color,
          status,
          statusReason,
          statusComment,
          startMin,
          endMin,
        }
      );
      return response as AttendanceScheduleDailyResponse;
    } catch {
      return null;
    }
  }

  public static async updateChildAttendanceMonthlySchedule({
    year,
    month,
    childId,
    dailySchedules,
  }: {
    year: number | string;
    month: number | string;
    childId: number | string;
    dailySchedules: AttendanceScheduleDailyPartSchema[];
  }) {
    try {
      const response = await ApiClient.put(
        `/attendance-schedule/${year}/${month}/children/${childId}`,
        {
          dailySchedules,
        }
      );
      return response as AttendanceScheduleDailyResponse;
    } catch {
      return null;
    }
  }

  public static async getPlacementSetting({
    nurseryId,
  }: {
    nurseryId: string | number;
  }) {
    try {
      const response = await ApiClient.get(
        `/work-schedule/${nurseryId}/settings/placement`,
        null
      );
      return response as WorkSchedulePlacementSettingResponse;
    } catch {
      return null;
    }
  }

  public static async updatePlacementSetting({
    nurseryId,
    body,
  }: {
    nurseryId: string | number;
    body: WorkSchedulePlacementSettingBody;
  }) {
    try {
      const response = await ApiClient.put(
        `/work-schedule/${nurseryId}/settings/placement`,
        body
      );
      return response as WorkSchedulePlacementSettingResponse;
    } catch {
      return null;
    }
  }

  public static async getChildDefaultSchedule(childId: number) {
    try {
      const response = await ApiClient.get(
        `/attendance-schedule/children`,
        {
          childId
        }
      );
      return response as ChildDefaultScheduleResponse;
    } catch {
      return null;
    }
  }

  public static async updateChildDefaultSchedule(
    arrayAddChildDefaultSchedule: {
      childId: number;
      Mins: ChildDefaultSchedulePartSchema;
    }
  ) {
     try {
       const response = await ApiClient.put(
         `/attendance-schedule/children`,
         arrayAddChildDefaultSchedule
        );
       return response as ChildDefaultScheduleResponse;
     } catch {
       return null;
     }
  }

  public static async deleteChildDefaultSchedule(
    arrayAddChildDefaultSchedule: {
      childId: number;
      Mins: ChildDefaultSchedulePartSchema;
    }
  ) {
     try {
       const response = await ApiClient.put(
         `/attendance-schedule/children`,
         arrayAddChildDefaultSchedule
        );
       return response as ChildDefaultScheduleResponse;
     } catch {
       return null;
     }
  }
}

