
import Vue from "vue";

import { childrenListModule } from "@/store/dataModules/childrenListModule";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import { NurseryChildResponse, NurseryClassResponse } from "chaild-api/lib";

import ClassPicker from "@/components/atoms/ClassPicker.vue";
import GenderPicker from "@/components/atoms/GenderPicker.vue";
import AgePicker from "@/components/atoms/AgePicker.vue";
import dayjs from "dayjs";
import ApiChildren from "@/api/ApiChildren";
import ApiImportExportStatus from "@/api/ApiImportExportStatus";
import ApiResource from "@/api/ApiResource";

interface DataType {
  isFetching: boolean;
  searchFilter: {
    isQuit: boolean;
  };
  selectedChildren: NurseryChildResponse[];
  pdfDialog: boolean;
  memoruPdfDialog: boolean;
  importFile: File | null;
  importDialog: boolean;
  exportChildrenDialog: boolean;
}

export default Vue.extend({
  components: {
    ClassPicker,
    GenderPicker,
    AgePicker,
  },

  data: (): DataType => {
    return {
      isFetching: false,
      searchFilter: {
        isQuit: false,
      },
      selectedChildren: [],
      pdfDialog: false,
      memoruPdfDialog: false,
      importDialog: false,
      importFile: null,
      exportChildrenDialog: false,
    };
  },

  computed: {
    children(): NurseryChildResponse[] {
      if (this.searchFilter.isQuit) {
        // return childrenListModule.children.filter((c) => c.quitDate != null);
        return childrenListModule.children.filter((c) =>
          dayjs().isAfter(c.quitDate)
        );
      } else {
        return childrenListModule.children.filter(
          (c) => c.quitDate == null || !dayjs().isAfter(c.quitDate)
        );
      }
    },
    classes(): NurseryClassResponse[] {
      return classModule.classes;
    },
    classId: {
      get(): number | null {
        return childrenListModule.classId;
      },
      set(value: number | null) {
        childrenListModule.setClassId(value);
      },
    },
    firstName: {
      get(): string | null {
        return childrenListModule.firstName;
      },
      set(value: string | null) {
        childrenListModule.setFirstName(value);
      },
    },
    lastName: {
      get(): string | null {
        return childrenListModule.lastName;
      },
      set(value: string | null) {
        childrenListModule.setLastName(value);
      },
    },
    age: {
      get(): number | null {
        return childrenListModule.age;
      },
      set(value: number | null) {
        childrenListModule.setAge(value);
      },
    },
    gender: {
      get(): string | null {
        return childrenListModule.gender;
      },
      set(value: string | null) {
        childrenListModule.setGender(value);
      },
    },
    childrenTableHeaders: function () {
      return [
        { text: "園児氏名", value: "name" },
        { text: "クラス名", value: "class" },
        { text: "性別", value: "gender" },
        { text: "年齢", value: "age" },
        { text: "紐付け状況", value: "isFamilyAssociated" },
        { text: "", value: "edit" },
      ];
    },
  },

  methods: {
    async search() {
      this.isFetching = true;
      await childrenListModule.search();
      this.isFetching = false;
    },
    getClassName(child: NurseryChildResponse) {
      if (child.nurseryClasses) {
        const latest =
          child.nurseryClasses.length > 0 ? child.nurseryClasses[0] : null;
        if (latest) {
          return latest.className;
        }
      }
      return "-";
    },
    getAgeBasedonBirthdate(birthdate: string): number {
      const thisYear = dayjs();
      return thisYear.diff(birthdate, "year");
    },
    async downloadInvitationPdf(
      isAll: boolean,
      type = "home" as "home" | "memoru"
    ) {
      if (!isAll && this.selectedChildren.length == 0) {
        alert("園児が選択されていません");
        this.pdfDialog = false;
        return;
      }
      this.isFetching = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        // 案内状DLリクエスト
        const invitationResponse = await ApiChildren.getInvitationPdf({
          nurseryId,
          childIds: isAll ? [] : this.selectedChildren.map((c) => c.childId),
          type,
        }).catch(() => {
          this.isFetching = false;
        });

        if (invitationResponse) {
          // ステータス取得
          const interval = setInterval(async () => {
            this.isFetching = true;
            const exportResponse = await ApiImportExportStatus.getStatus({
              statusId: invitationResponse.statusId,
              category: invitationResponse.category,
            }).catch(() => {
              clearInterval(interval);
              this.isFetching = false;
              this.pdfDialog = false;
            });
            if (exportResponse && exportResponse.status == "success") {
              clearInterval(interval);
              window.open(exportResponse.url, "_blank");
              this.isFetching = false;
            }
          }, 1000);
        }
      }
      this.isFetching = false;
    },
    async downloadChildrenCsv(isAll: boolean) {
      if (!isAll && this.selectedChildren.length == 0) {
        alert("園児が選択されていません");
        this.exportChildrenDialog = false;
        return;
      }
      this.isFetching = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        // 園児エクスポート
        const exportResponse = await ApiChildren.exportChildrenCsv({
          nurseryId,
          childrenIds: isAll ? [] : this.selectedChildren.map((c) => c.childId),
        }).catch(() => {
          this.isFetching = false;
        });

        if (exportResponse) {
          // ステータス取得
          const interval = setInterval(async () => {
            this.isFetching = true;
            const statusResponse = await ApiImportExportStatus.getStatus({
              statusId: exportResponse.statusId,
              category: exportResponse.category,
            }).catch(() => {
              clearInterval(interval);
              this.isFetching = false;
              this.exportChildrenDialog = false;
            });
            if (statusResponse && statusResponse.status == "success") {
              clearInterval(interval);
              window.open(statusResponse.url, "_blank");
              this.isFetching = false;
              this.exportChildrenDialog = false;
            }
          }, 1000);
        }
      }
      this.isFetching = false;
    },
    async importCsv() {
      this.isFetching = true;
      const nurseryId = LocalDataService.getNurseryId();
      const imageType = "childCsv";
      if (nurseryId && this.importFile) {
        // get presigned url
        const resourceResponse = await ApiResource.getPresignedUrl({
          imageType: imageType,
          nurseryId,
          filename: this.importFile.name,
        }).catch();
        if (resourceResponse) {
          // upload
          const uploadResponse = await ApiResource.uploadFile({
            url: resourceResponse.url,
            file: this.importFile,
          }).catch();
          if (uploadResponse) {
            // import
            const importResponse = await ApiChildren.importChildrenCsv({
              nurseryId,
              filePath: resourceResponse.filePath,
            }).catch(() => {
              this.isFetching = false;
              this.importDialog = false;
            });
            if (importResponse) {
              const interval = setInterval(async () => {
                this.isFetching = true;
                const statusResponse = await ApiImportExportStatus.getStatus({
                  statusId: importResponse.statusId,
                  category: importResponse.category,
                }).catch(() => {
                  clearInterval(interval);
                  this.isFetching = false;
                  this.importDialog = false;
                });
                if (statusResponse) {
                  if (statusResponse.status == "success") {
                    clearInterval(interval);
                    this.isFetching = false;
                    this.importDialog = false;
                    alert("インポートが完了しました");
                    await this.search();
                  } else if (statusResponse.status == "failed") {
                    clearInterval(interval);
                    this.isFetching = false;
                    this.importDialog = false;
                    alert("インポートが失敗しました");
                  }
                }
              }, 1000);
            }
          }
        }
      }
      this.isFetching = false;
    },
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      this.isFetching = true;
      await classModule.listClass({
        nurseryId: nurseryId,
      });
      this.isFetching = false;
    }
    await this.search();
  },
});
