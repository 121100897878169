
import Vue from 'vue'

import PlacementSettingContainer from '@/containers/work-schedule/PlacementSettingContainer.vue'

export default Vue.extend({
  components: {
    PlacementSettingContainer,
  },
})

