
import Vue from "vue";
import NavigationDrawer from "./components/organisms/NavigationDrawer.vue";
import { auth0Module } from "@/store/dataModules/auth0Module";
import LocalDataService from "@/service/LocalDataService";
import { meModule } from "@/store/dataModules/meModule";
import { MeResponse } from "chaild-api/lib";
import JwtDecode, { JwtPayload } from "jwt-decode";
import { snackbarModule } from "@/store/viewModules/snackbarModule";
import AppBar from "./components/organisms/AppBar.vue";

export default Vue.extend({
  name: "App",

  components: {
    NavigationDrawer,
    AppBar,
  },

  data() {
    return {
      fromAuth0: false,
    };
  },

  async created() {
    await auth0Module.initClient();

    // auth0からの遷移の場合
    if (
      this.$route.query &&
      this.$route.query.code &&
      this.$route.query.state
    ) {
      await auth0Module.handleAuth0().catch((error) => console.warn(error));
      this.fromAuth0 = true;
    }

    // check token
    if (this.tokenExists) {
      const response = await meModule.fetchMe();
      if (
        response &&
        response.nurserySchools &&
        response.nurserySchools.length > 0
      ) {
        const me: MeResponse | null = meModule.me;
        if (me) {
          const currentSchool = me.nurserySchools.find((s) => {
            return s.isDefault == true;
          });
          if (currentSchool) {
            // isDefault == true
            LocalDataService.setNurseryId(currentSchool.nurseryId);
            meModule.setNurseryId(currentSchool.nurseryId);
          } else if (me.nurserySchools.length > 0) {
            // isDefaultが無い場合
            LocalDataService.setNurseryId(me.nurserySchools[0].nurseryId);
            meModule.setNurseryId(me.nurserySchools[0].nurseryId);
          }
        }
        // await auth0Module.refreshToken();

        // check expired
        if (auth0Module.accessToken) {
          const decoded: JwtPayload = JwtDecode(auth0Module.accessToken);
          if (decoded && decoded.exp) {
            const currentTimestamp = Math.round(new Date().getTime() / 1000);
            if (decoded.exp <= currentTimestamp) {
              auth0Module.logout();
            }
          }
        }

        if (this.$route.path == "/signin") {
          console.warn("route == /signin");
          this.goTop();
        }
      } else {
        // no user
        // this.signUp();
        const res = await meModule.postWithNoBody();
        if (res) {
          this.goTop();
        }
      }
    } else {
      auth0Module.signIn();
    }
  },

  computed: {
    tokenExists(): boolean {
      const token = auth0Module.accessToken;
      return token ? true : false;
    },
    snackbar: {
      get() {
        return snackbarModule?.isShow;
      },
      set() {
        snackbarModule.setIsShow(false);
      },
    },
    snackbarMessage: {
      get() {
        return snackbarModule?.message;
      },
    },
    snackbarColor: {
      get() {
        return snackbarModule?.color;
      },
    },
  },

  methods: {
    goTop() {
      this.$router.push({ path: "/attendance/list" });
    },
    signUp() {
      this.$router.push({ path: "/signup" });
    },
  },
});
