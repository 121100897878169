
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import ValidationMixin from "@/mixins/ValidationMixin";
import Swal from "sweetalert2";
import { meModule } from "@/store/dataModules/meModule";
import LocalDataService from "@/service/LocalDataService";
import ApiAdmin from "@/api/ApiAdmin";
import { MeResponse } from "chaild-api/lib";

export default Vue.extend({
  name: "Admin",

  mixins: [ValidationMixin],

  components: {
    PageTitle,
  },

  data() {
    return {
      valid: false,
      tab: null,
      selectedNurseryId: null as number | null,
      loading: false,
      input: {
        nurseryName: "",
        nurseryCode: "",
        localAuthorityCode: "",
        postalCode: "",
        prefecture: "",
        address: "",
        building: "",
        phoneNumber: "",
        faxNumber: "",
        representativeEmail: "",
        companyInfo: {
          companyName: "",
          postalCode: "",
          prefecture: "",
          address: "",
          building: "",
          phoneNumber: "",
          faxNumber: "",
          representativeFirstName: "",
          representativeLastName: "",
          representativeFirstNameKana: "",
          representativeLastNameKana: "",
        },
      },
    };
  },

  async mounted() {
    await this.fetchMe();
    const nurseryId = LocalDataService.getNurseryId();
    const me: MeResponse | null = meModule.me;
    if (nurseryId && me) {
      // isDefault == trueのnurseryIdをデフォルトで選択
      const currentSchool = me.nurserySchools.find((s) => {
        return s.isDefault == true;
      });
      this.selectedNurseryId = currentSchool?.nurseryId
        ? currentSchool.nurseryId
        : nurseryId;
    }
  },

  computed: {
    schools: function () {
      return meModule.me?.nurserySchools;
    },
  },

  methods: {
    async fetchMe() {
      await meModule.fetchMe();
    },
    async saveNurseryId() {
      if (this.selectedNurseryId) {
        LocalDataService.setNurseryId(this.selectedNurseryId);
        meModule.setNurseryId(this.selectedNurseryId);
        const response = await meModule.putMe({
          defaultNurseryId: this.selectedNurseryId,
        });
        if (response) {
          Swal.fire({ title: "変更しました", icon: "success" });
        }
      }
    },
    async updateNursery() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        const response = await ApiAdmin.updateNursery({
          nurseryId: String(nurseryId),
          ...this.input,
        });
        if (response) {
          Swal.fire({ title: "更新しました", icon: "success" });
          this.$router.push({ path: "/setting/staff" });
        } else {
          Swal.fire({ title: "失敗しました", icon: "error" });
        }
      }

      this.loading = false;
    },
  },
});
