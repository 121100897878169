
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import DatePager from "@/components/atoms/DatePager.vue";
import ClassList from "@/components/molecules/ClassList.vue";
import { classModule } from "@/store/dataModules/classModule";
import LocalDataService from "@/service/LocalDataService";

export default Vue.extend({
  name: "ClassReport",

  components: {
    PageTitle,
    DatePager,
    ClassList,
  },

  mounted() {
    this.listClass();
  },

  computed: {
    classList: function () {
      return classModule.classes;
    },
  },

  methods: {
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId: nurseryId,
        });
      }
    },
    clickClass(id: string) {
      this.$router.push({ path: `/dailyreport/class/${id}` });
    },
  },
});
