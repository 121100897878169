
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";

export default Vue.extend({
  name: "MeSetting",

  components: {
    PageTitle,
  },
});
