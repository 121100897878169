
import Vue from "vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import { NurseryClassResponse, InvoiceResponse } from "chaild-api/lib";
import {
  invoiceModule,
  InvoiceStatus,
} from "@/store/dataModules/invoiceModule";
import LocalDataService from "@/service/LocalDataService";
import { classModule } from "@/store/dataModules/classModule";
import dayjs from "dayjs";
import CommonUtil from "@/utils/CommonUtil";
import { paymentMethodItems } from "@/constants/invoice";

export default Vue.extend({
  name: "Invoice",

  components: {
    PageTitle,
  },

  watch: {
    searchFilter: {
      deep: true,
      async handler() {
        await this.searchInvoice();
        await this.getInvoiceStatus();
      },
    },
  },

  data() {
    const month = Number(dayjs().format('M'));
    const date = dayjs().date();
    let Year = dayjs().format('YYYY');
    let Month = month;
    if (date < 15) {
      Month = month - 1;
    }
    if (Month === 0) {
      Month = 12;
    }
    if (Month === 3 && date < 15) {
      Year = dayjs().subtract(1, 'year').format('YYYY');
    }
    return {
      isLoading: false,
      searchFilter: {
        classId: null as null | number,
        // year: dayjs().year(),
        year: Year,
        month: Month,
      },
      childcareCategoryItems: [
        { text: "標準時間保育", value: "normal" },
        { text: "短時間保育", value: "short" },
      ],
      paymentMethodItems: paymentMethodItems,
    };
  },

  async mounted() {
    await this.searchInvoice();
    await this.listClass();
    await this.getInvoiceStatus();
  },

  computed: {
    invoiceList: function (): InvoiceResponse[] {
      const filterMonth = dayjs(
        `${Number(this.searchFilter.year) + 1}/${this.searchFilter.month}`
      ).format("YYYY/MM");
      invoiceModule.invoiceList.filter((i) => {
        if (i.child.quitDate) {
          console.log("quitDate", i.child.quitDate);
          console.log("filterMonth", filterMonth);
          console.log("isAfter", dayjs(i.child.quitDate).isAfter(filterMonth));
        }
      });
      return invoiceModule.invoiceList.filter(
        (i) =>
          i.child.quitDate == null ||
          dayjs(i.child.quitDate).isAfter(filterMonth)
      );
    },
    classes: function (): NurseryClassResponse[] {
      return classModule.classes;
    },
    monthItems: function (): number[] {
      return [...Array(12).keys()].map((i) => ++i);
    },
    invoiceStatus: function (): InvoiceStatus | null {
      return invoiceModule.status;
    },
  },

  methods: {
    async searchInvoice() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await invoiceModule.listInvoice({
          nurseryId,
          classId: this.searchFilter.classId || undefined,
          year: Number(this.searchFilter.year) || undefined,
          month: Number(this.searchFilter.month) || undefined,
        });
      }
      this.isLoading = false;
    },
    async listClass() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId,
        });
      }
      this.isLoading = true;
    },
    async getInvoiceStatus() {
      this.isLoading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await invoiceModule.getInvoiceStatus({
          nurseryId,
          year: Number(this.searchFilter.year),
          month: Number(this.searchFilter.month),
        });
      }
      this.isLoading = false;
    },
    async updateInvoiceStatus(status: "applied" | "canceled") {
      this.isLoading = true;
      let answer: boolean;
      if (status == "applied") {
        answer = confirm(
          `${this.searchFilter.year}年${this.searchFilter.month}月分を確定しますか？`
        );
      } else {
        answer = confirm(
          `${this.searchFilter.year}年${this.searchFilter.month}月分の確定を取り消しますか？`
        );
      }
      if (answer) {
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId) {
          const response = await invoiceModule
            .updateInvoiceStatus({
              nurseryId,
              year: Number(this.searchFilter.year),
              month: Number(this.searchFilter.month),
              status: status,
            })
            .catch(() => {
              if (status == "applied") {
                alert(
                  "確定できませんでした。既に運営側で売上が確定しているか確認してください。またサービス設定および保育料等の設定を行ってください"
                );
              } else {
                alert(
                  "確定取り消しできませんでした。サービス設定および保育料等の設定を行ってください"
                );
              }
            });
          if (response) {
            await this.getInvoiceStatus();
          }
        }
      }
      this.isLoading = false;
    },
    getAgeFromBirthdate(date: string): number {
      return dayjs().diff(dayjs(date), "year");
    },
    clickDetail(invoice: InvoiceResponse) {
      this.$router.push({
        path: "/invoice/detail",
        query: {
          // year: String(invoice.invoices[0].year),
          // month: String(invoice.invoices[0].month),
          year: String(this.searchFilter.year),
          month: String(this.searchFilter.month),
          childId: String(invoice.child.childId),
        },
      });
    },
    formatDate(value: string): string {
      if (!value) {
        return "-";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
    getPaymentMethodText(value?: string): string {
      if (!value) {
        return "集金";
      }
      return this.paymentMethodItems.find((p) => p.value == value)?.text || "";
    },
  },
});
