import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import LocalDataService from "@/service/LocalDataService";

export interface Auth0State {
  auth0Client: Auth0Client | null;
  accessToken: string | null;
}

/* eslint-disable */
@Module({ dynamic: true, store, name: "auth0", namespaced: true })
class Auth0Module extends VuexModule implements Auth0State {
  public auth0Client: Auth0Client | null = null;
  public accessToken: string | null = null;

  @Action
  public async initClient() {
    if (
      process.env.VUE_APP_AUTH0_DOMAIN &&
      process.env.VUE_APP_AUTH0_CLIENT_ID
    ) {
      try {
        const redirectUri = window.location.origin + "/signin";
        const client = await createAuth0Client({
          domain: process.env.VUE_APP_AUTH0_DOMAIN,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          redirect_uri: redirectUri,
          useRefreshTokens: true,
        });
        this.setClient(client);

        const claims = await client.getIdTokenClaims();
        if (claims) {
          this.setAccessToken(claims.__raw);
        }
      } catch (e) {
        if (
          e &&
          (e as any).error &&
          (e as any).error === "interaction_required"
        ) {
        }
      }
    }
  }

  @Action
  public async retrieveToken() {
    if (this.auth0Client) {
      const isAuthenticated = await this.auth0Client.isAuthenticated();
      const claims = await this.auth0Client.getIdTokenClaims();
    }
  }

  @Action
  public async refreshToken() {
    if (this.auth0Client) {
      const token = await this.auth0Client.getTokenSilently({
        redirect_uri: window.location.origin + "/signin",
        scope: "openid profile email offline_access",
      });
      if (token) {
        this.setAccessToken(`Bearer ${token}`);
      }
    }
  }

  @Mutation
  public setClient(client: Auth0Client) {
    this.auth0Client = client;
  }

  @Mutation
  public setAccessToken(token: string) {
    this.accessToken = token;
    LocalDataService.setAccessToken(`Bearer ${token}`);
  }

  @Action
  public signIn() {
    if (this.auth0Client) {
      this.auth0Client.loginWithRedirect({
        // redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
        redirect_uri: window.location.origin + "/signin",
      });
    }
  }

  @Action
  public logout() {
    if (this.auth0Client) {
      this.auth0Client.logout({
        // returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URI,
        // returnTo: window.location.origin + '/',
        returnTo: window.location.origin + "/signin",
      });
    }
  }

  @Action
  public async handleAuth0() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      try {
        await this.auth0Client.handleRedirectCallback();
        const claim = await this.auth0Client.getIdTokenClaims();
        const accessToken = claim?.__raw;
        if (accessToken) {
          this.setAccessToken(accessToken);
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }
}

export const auth0Module = getModule(Auth0Module);
