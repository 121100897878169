/*
 * brief    NFC Port Library of WebClient
 * date     2020/05/15
 * author   Copyright 2019,2020 Sony Imaging Products & Solutions Inc.
 */
/* eslint-disable */
export {
  NFCPortLib,
  NFCPortError,
  Configuration,
  DetectionOption,
  CommunicationOption,
  TargetCard,
};
function def_val(e, r) {
  let t;
  return (t = void 0 === e ? r : e), t;
}
function bytes2hexs(e, r) {
  return (
    (r = def_val(r, ' ')),
    e
      .map(function (e) {
        let r = e.toString(16);
        return e < 16 && (r = '0' + r), r;
      })
      .join(r)
      .toUpperCase()
  );
}
export function array_tohexs(e, r, t) {
  let o;
  return (
    (o = array_slice(e, (r = def_val(r, 0)), (t = def_val(t, e.length - r)))), bytes2hexs(o, '')
  );
}
function array_copy(e, r, t, o, i) {
  let s;
  for (o = def_val(o, 0), i = def_val(i, t.length), s = 0; s < i; s++) e[r + s] = t[o + s];
  return e;
}
function array_slice(e, r, t) {
  let o;
  return (o = []), array_copy(o, 0, e, r, (t = def_val(t, e.length - r))), o;
}
function array_compare(e, r, t, o, i) {
  let s;
  for (s = 0; s < i; s++) {
    if (void 0 === e[r + s]) return -1;
    if (void 0 === t[o + s]) return -1;
    if (e[r + s] != t[o + s]) return -1;
  }
  return 0;
}
function dataview_to_array(e) {
  let r, t;
  for (r = new Array(e.byteLength), t = 0; t < e.byteLength; t++) r[t] = e.getUint8(t);
  return r;
}
function array_append(e, r, t, o) {
  let i;
  for (t = def_val(t, 0), o = def_val(o, r.length - t), i = 0; i < o; i++) e.push(r[t + i]);
  return e;
}
function array_set_uint16l(e, r, t) {
  (e[r] = 255 & t), (e[r + 1] = (t >> 8) & 255);
}
function array_get_uint16l(e, r) {
  return (e[r + 1] << 8) | e[r];
}
function array_get_uint32l(e, r) {
  return (e[r + 3] << 24) | (e[r + 2] << 16) | (e[r + 1] << 8) | e[r];
}
var syslog = syslog || (() => {});
function enableDebugLog() {
  syslog = console.log;
}
function wait_async(e) {
  return new Promise((r, t) => {
    setTimeout(r, e);
  });
}
class NFCPortUtil {
  static get LINE_NUMBER() {
    return NFCPortUtil.getLine();
  }
  static getLine() {
    const e = Error.prepareStackTrace;
    Error.prepareStackTrace = (e, r) => r[1].getLineNumber();
    let r = {};
    Error.captureStackTrace(r, NFCPortUtil.getLine);
    const t = r.stack;
    return (Error.prepareStackTrace = e), t;
  }
}
class NFCPortError extends Error {
  static get INTERNAL_ERROR() {
    return -2;
  }
  static get UNKNOWN_ERROR() {
    return -1;
  }
  static get INVALID_PARAMETER() {
    return 1002;
  }
  static get CANTCALLOUT_LIBRARY_STATUS() {
    return 1004;
  }
  static get API_CALL_IN_PROGRESS() {
    return 1008;
  }
  static get DEVICE_NOT_FOUND() {
    return 2018;
  }
  static get DEVICE_OPEN_ERROR() {
    return 2019;
  }
  static get DEVICE_FATAL_ERROR() {
    return 2020;
  }
  static get TRANSFER_ERROR() {
    return 3001;
  }
  static get INVALID_RESPONSE() {
    return 3003;
  }
  static get INVALID_STATUS() {
    return 3004;
  }
  static get ACK_TIMEOUT() {
    return 3005;
  }
  static get DATA_RECEIVE_TIMEOUT() {
    return 3006;
  }
  static get CARD_NOT_DETECTED() {
    return 4e3;
  }
  static get AUTHENTICATION_ERROR() {
    return 4001;
  }
  static get THRU_RESPONSE_PACKET_NOT_RECEIVED() {
    return 4002;
  }
  constructor(e, r, t, o) {
    super(r),
      (this.errorType = e),
      (this.fileName = t),
      (this.lineNumber = o),
      (this.communicationStatus = 0);
  }
}
class CommunicationBase {
  async open() {}
  async transmit(e) {
    return 0;
  }
  async receive(e, r) {
    return null;
  }
  async clear() {}
  async close() {}
}
class GenericUSB extends CommunicationBase {
  constructor(e) {
    super(),
      (this._fileName = 'GenericUSB.js'),
      (this.device = null),
      (this.recv_buffer = []),
      (this.receiver = null),
      (this.max_packet_size = 0),
      (this.receive_buffer_size = e);
  }
  async open(e, r, t, o, i) {
    if ((syslog('open begin'), null == this.device)) {
      try {
        this.device = await window.navigator.usb.requestDevice({
          filters: [{ vendorId: e }],
        });
      } catch (e) {
        return (
          syslog(e.message),
          Promise.reject(
            new NFCPortError(NFCPortError.DEVICE_NOT_FOUND, e.message, this._fileName, 36),
          )
        );
      }
      syslog(this.device.productName),
        syslog(this.device.manufacturerName),
        this.device.opened ? syslog('device opened') : syslog('device not open');
      try {
        await this.device.open(),
          await this.device.selectConfiguration(r),
          await this.device.claimInterface(t);
      } catch (e) {
        return (
          (this.device = null),
          syslog(e.message),
          Promise.reject(
            new NFCPortError(NFCPortError.DEVICE_OPEN_ERROR, e.message, this._fileName, 54),
          )
        );
      }
      if (
        ((this.endno_out = o),
        (this.endno_in = i),
        (this.max_packet_size = this.get_max_packet_size(this.device, this.endno_out)),
        0 == this.max_packet_size)
      ) {
        this.close();
        const e = 'USB endpoint not found';
        return (
          syslog(e),
          Promise.reject(new NFCPortError(NFCPortError.DEVICE_OPEN_ERROR, e, this._fileName, 65))
        );
      }
      syslog('open end');
    } else syslog('open end (Already opened)');
  }
  async auto_open(e, r, t, o, i) {
    let s;
    if ((syslog('auto_open begin'), null == this.device)) {
      try {
        s = await navigator.usb.getDevices();
      } catch (e) {
        return (
          syslog(e.message),
          Promise.reject(
            new NFCPortError(NFCPortError.DEVICE_NOT_FOUND, e.message, this._fileName, 90),
          )
        );
      }
      if (null == s || 0 == s.length) return await this.open(e, r, t, o, i);
      if (
        (s.forEach((r) => {
          syslog(r.productName),
            syslog(r.manufacturerName),
            r.opened ? syslog('device opened') : syslog('device not open'),
            r.vendorId != e || (this.device = r);
        }),
        null == this.device)
      )
        return await this.open(e, r, t, o, i);
      syslog(this.device.productName), syslog(this.device.manufacturerName);
      try {
        await this.device.open(),
          await this.device.selectConfiguration(r),
          await this.device.claimInterface(t);
      } catch (e) {
        return (
          (this.device = null),
          syslog(e.message),
          Promise.reject(
            new NFCPortError(NFCPortError.DEVICE_OPEN_ERROR, e.message, this._fileName, 130),
          )
        );
      }
      if (
        ((this.endno_out = o),
        (this.endno_in = i),
        (this.max_packet_size = this.get_max_packet_size(this.device, this.endno_out)),
        0 == this.max_packet_size)
      ) {
        this.close();
        const e = 'USB endpoint not found';
        return (
          syslog(e),
          Promise.reject(new NFCPortError(NFCPortError.DEVICE_OPEN_ERROR, e, this._fileName, 141))
        );
      }
      syslog('auto_open end');
    } else syslog('auto_open end (Already opened)');
  }
  get_max_packet_size(e, r) {
    syslog('get_max_packet_size begin');
    let t = 0,
      o = [];
    if (
      (e.configuration.interfaces.forEach((e) => {
        e.claimed && (o = e.alternate.endpoints.filter((e) => e.endpointNumber == r));
      }),
      o.length > 0)
    )
      for (let e of o) t = e.packetSize;
    else syslog('Error: endpoint cannot be found');
    return syslog('get_max_packet_size end'), t;
  }
  async transmit(e) {
    let r, t;
    return (
      syslog('transmit begin'),
      syslog('Send length : %d', e.length),
      syslog('Send : ' + array_tohexs(e)),
      (r = await this.device.transferOut(this.endno_out, Uint8Array.from(e)).then(
        (r) =>
          'ok' != r.status
            ? ((t = 'transferOut Error'),
              syslog(t),
              Promise.reject(new NFCPortError(NFCPortError.TRANSFER_ERROR, t, this._fileName, 190)))
            : e.length,
        (e) => (
          syslog(e.message),
          Promise.reject(
            new NFCPortError(NFCPortError.TRANSFER_ERROR, e.message, this._fileName, 198),
          )
        ),
      )),
      0 != this.max_packet_size
        ? r % this.max_packet_size == 0 &&
          (await this.device.transferOut(this.endno_out, Uint8Array.from([])).then(
            (e) => {
              if ('ok' != e.status)
                return (
                  (t = 'transferOut Error'),
                  syslog(t),
                  Promise.reject(
                    new NFCPortError(NFCPortError.TRANSFER_ERROR, t, this._fileName, 209),
                  )
                );
            },
            (e) => (
              syslog(e.message),
              Promise.reject(
                new NFCPortError(NFCPortError.TRANSFER_ERROR, e.message, this._fileName, 215),
              )
            ),
          ))
        : syslog('Error: max_packet_size is 0'),
      syslog('transmit end'),
      r
    );
  }
  get_buffer(e) {
    let r;
    return (
      syslog('get_buffer begin'),
      this.recv_buffer.length >= e
        ? ((r = array_slice(this.recv_buffer, 0, e)),
          (this.recv_buffer = array_slice(this.recv_buffer, e, this.recv_buffer.length - e)))
        : (r = []),
      syslog('get_buffer end'),
      r
    );
  }
  async receive(e, r) {
    let t, o, i, s, _, a, n, l, N;
    return (
      syslog('receive begin (req_len=' + e + ', timeout=' + r + ')'),
      (t = new Date()),
      0 != e && this.recv_buffer.length >= e
        ? ((o = this.get_buffer(e)), syslog('receive end(1)'), Promise.resolve(o))
        : ((i = !1),
          (o = this.transferin().then(
            () =>
              i
                ? (syslog('receive in canceled function'), [])
                : this.recv_buffer.length < e
                ? ((_ = new Date()),
                  (a = _.getTime() - t.getTime()),
                  a > r
                    ? ((s = 'receive data receive timeout'),
                      syslog(s),
                      Promise.reject(
                        new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, s, this._fileName, 284),
                      ))
                    : this.receive(e, r - a))
                : this.get_buffer(e),
            (e) => (
              syslog(e.message),
              Promise.reject(
                new NFCPortError(NFCPortError.TRANSFER_ERROR, e.message, this._fileName, 295),
              )
            ),
          )),
          (n = new Promise((e) => {
            l = setTimeout(e, r);
          }).then(
            () => (
              (i = !0),
              (s = 'receive data receive timeout'),
              syslog(s),
              Promise.reject(
                new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, s, this._fileName, 303),
              )
            ),
          )),
          (N = await Promise.race([o, n])),
          clearTimeout(l),
          syslog('receive end(2)'),
          N)
    );
  }
  async transferin() {
    let e, r;
    if ((syslog('transferin begin'), null == this.receiver)) {
      if ((syslog('receiver == null'), !this.device.opened))
        return (
          (this.receiver = null),
          (e = 'Device is closed.'),
          syslog(e),
          Promise.reject(new NFCPortError(NFCPortError.TRANSFER_ERROR, e, this._fileName, 347))
        );
      this.receiver = this.device.transferIn(this.endno_in, this.receive_buffer_size).then(
        (t) => (
          syslog('transferIn status : ' + t.status),
          syslog('           data.byteLength : ' + t.data.byteLength),
          'ok' != t.status || 0 == t.data.byteLength
            ? ((e = 'transferIn Error'),
              syslog(e),
              Promise.reject(new NFCPortError(NFCPortError.TRANSFER_ERROR, e, this._fileName, 328)))
            : ((r = dataview_to_array(t.data)),
              syslog('transferIn data : ' + array_tohexs(r)),
              array_copy(this.recv_buffer, this.recv_buffer.length, r, 0, r.length),
              (this.receiver = null),
              Promise.resolve())
        ),
        (e) => (
          syslog(e.message),
          Promise.reject(
            new NFCPortError(NFCPortError.TRANSFER_ERROR, e.message, this._fileName, 341),
          )
        ),
      );
    } else syslog('receiver != null');
    return syslog('transferin end'), this.receiver;
  }
  async clear() {
    syslog('clear begin'), (this.recv_buffer = []), syslog('clear end');
  }
  async close() {
    if ((syslog('close begin'), null != this.device)) {
      try {
        await this.device.close();
      } catch (e) {
        syslog(e.message);
      }
      this.device = null;
    }
    syslog('close end');
  }
}
class TargetCard {
  constructor(e, r, t, o, i, s) {
    (this.idm = e),
      (this.pmm = r),
      (this.uid = t),
      (this.pupi = o),
      (this.systemCode = i),
      (this.baudRate = s);
  }
}
class Configuration {
  constructor(e, r, t, o) {
    (this.ackTimeout = e),
      (this.receiveTimeout = r),
      (this.autoBaudRate = t),
      (this.autoDeviceSelect = o);
  }
}
class DetectionOption {
  constructor(e, r, t, o, i, s) {
    (this.systemCode = e),
      (this.timeSlot = r),
      (this.requestSystemCode = t),
      (this.requestBaudRate = o),
      (this.fsdi = i),
      (this.cid = s);
  }
}
class CommunicationOption {
  constructor(e, r, t, o, i, s, _, a, n, l) {
    (this.mifareAuthentication = e),
      (this.key = r),
      (this.keyType = t),
      (this.blockNumber = o),
      (this.uid = i),
      (this.appendCrc = s),
      (this.discardCrc = _),
      (this.insertParity = a),
      (this.expectParity = n),
      (this.txNumberOfValidBits = l);
  }
}
class ReaderWriterBase {
  constructor() {
    (this._deviceName = null), (this._targetCardBaudRate = null);
  }
  get deviceName() {
    return this._deviceName;
  }
  get targetCardBaudRate() {
    return this._targetCardBaudRate;
  }
  async init(e) {}
  async open() {}
  async detectCard(e, r) {}
  async communicateThru(e, r, t) {}
  async switchRF(e) {}
  async close() {}
}
function TypebCard(e, r, t) {
  (this.pupi = e), (this.application_data = r), (this.protocol_info = t);
}
function ISO14443_4_CC_UNIT_MS(e) {
  return (e + 13560 - 1) / 13560;
}
function ISO14443_4_CC_FWT(e) {
  return ISO14443_4_CC_UNIT_MS(4096 * (1 << e));
}
const ISO14443_4_CC_DEFAULT_FWI = 4,
  NFC100_TYPEA_FDT_LISTEN_MAX = ISO14443_4_CC_UNIT_MS(1152) + ISO14443_4_CC_UNIT_MS(84),
  ISO14443_4_CC_FWT_DELTA = ISO14443_4_CC_UNIT_MS(16);
class NFCPort100 extends ReaderWriterBase {
  static get VENDOR_ID() {
    return 1356;
  }
  static get SELECT_CONFIG() {
    return 1;
  }
  static get CLAIM_INTERFACE() {
    return 0;
  }
  static get ENDPOINT_IN() {
    return 1;
  }
  static get ENDPOINT_OUT() {
    return 2;
  }
  static get COMMAND_TYPE_0() {
    return 0;
  }
  static get COMMAND_TYPE_1() {
    return 1;
  }
  static get NFC100_COMMAND_CODE() {
    return 214;
  }
  static get NFC100_RESPONSE_CODE() {
    return 215;
  }
  static get SCC_SET_COMMAND_TYPE() {
    return 42;
  }
  static get SRC_SET_COMMAND_TYPE() {
    return 43;
  }
  static get SCC_IN_SET_RF() {
    return 0;
  }
  static get SRC_IN_SET_RF() {
    return 1;
  }
  static get SCC_IN_SET_PROTOCOL() {
    return 2;
  }
  static get SRC_IN_SET_PROTOCOL() {
    return 3;
  }
  static get SCC_IN_COMM_RF() {
    return 4;
  }
  static get SRC_IN_COMM_RF() {
    return 5;
  }
  static get SCC_SWITCH_RF() {
    return 6;
  }
  static get SRC_SWITCH_RF() {
    return 7;
  }
  static get SCC_GET_PDDATA_VERSION() {
    return 34;
  }
  static get SRC_GET_PDDATA_VERSION() {
    return 35;
  }
  static get SCC_GET_PROPERTY() {
    return 36;
  }
  static get SRC_GET_PROPERTY() {
    return 37;
  }
  static get SCC_IN_SET_RCT() {
    return 48;
  }
  static get SRC_IN_SET_RCT() {
    return 49;
  }
  static get SCC_IN_GET_RCT() {
    return 50;
  }
  static get SRC_IN_GET_RCT() {
    return 51;
  }
  static get SCC_READ_REGISTER() {
    return 54;
  }
  static get SRC_READ_REGISTER() {
    return 55;
  }
  static get SCC_DIAGNOSE() {
    return 240;
  }
  static get SRC_DIAGNOSE() {
    return 241;
  }
  static get STATUS_SUCCESS() {
    return 0;
  }
  static get STATUS_PARAMETER_ERROR() {
    return 1;
  }
  static get STATUS_PB_ERROR() {
    return 2;
  }
  static get STATUS_RFCA_ERROR() {
    return 3;
  }
  static get STATUS_TEMPERATURE_ERROR() {
    return 4;
  }
  static get STATUS_PWD_ERROR() {
    return 5;
  }
  static get STATUS_RECEIVE_ERROR() {
    return 6;
  }
  static get STATUS_COMMANDTYPE_ERROR() {
    return 7;
  }
  static get STATUS_INTTEMPRFOFF_ERROR() {
    return 9;
  }
  static get COM_STATUS_PROTOCOL_ERROR() {
    return 1;
  }
  static get COM_STATUS_PARITY_ERROR() {
    return 2;
  }
  static get COM_STATUS_CRC_ERROR() {
    return 4;
  }
  static get COM_STATUS_COLLISION_ERROR() {
    return 8;
  }
  static get COM_STATUS_OVERFLOW_ERROR() {
    return 16;
  }
  static get COM_STATUS_TEMPERATURE_ERROR() {
    return 64;
  }
  static get COM_STATUS_REC_TIMEOUT_ERROR() {
    return 128;
  }
  static get COM_STATUS_CRYPTO1_ERROR() {
    return 256;
  }
  static get COM_STATUS_RFCA_ERROR() {
    return 512;
  }
  static get COM_STATUS_INTTEMPRFOFF_ERROR() {
    return 4096;
  }
  static get SWITCH_RF_RFON() {
    return 1;
  }
  static get SWITCH_RF_RFOFF() {
    return 0;
  }
  static get REG_ADR_RF_STATE() {
    return 20;
  }
  static get REG_ADR_RF_STATE_ON() {
    return 131;
  }
  static get REG_ADR_RF_STATE_OFF() {
    return 128;
  }
  static get NFC100_MAX_SWICH_RF_RETRY_COUNT() {
    return 3;
  }
  static get NFC100_MAX_SWICH_RF_EXECUTION_COUNT() {
    return NFCPort100.NFC100_MAX_SWICH_RF_RETRY_COUNT + 1;
  }
  static get DIAGNOSE_COMMUNICATION_LINE_TEST() {
    return 0;
  }
  static get DIAGNOSE_ROM_TEST() {
    return 1;
  }
  static get DIAGNOSE_RAM_TEST() {
    return 2;
  }
  static get DIAGNOSE_POLLING_TEST() {
    return 3;
  }
  static get DIAGNOSE_RF_REGULATION_TEST() {
    return 4;
  }
  static get DIAGNOSE_GET_UPDATE_SUCCESS_COUNT() {
    return 5;
  }
  static get DIAGNOSE_OUTPUT_TRIGGER_SIGNAL() {
    return 6;
  }
  static get DIAGNOSE_TEMP_ABNORMAL_MEMORY_MANAGE() {
    return 7;
  }
  static get DIAGNOSE_TEST_MIN() {
    return NFCPort100.DIAGNOSE_COMMUNICATION_LINE_TEST;
  }
  static get DIAGNOSE_TEST_MAX() {
    return NFCPort100.DIAGNOSE_TEMP_ABNORMAL_MEMORY_MANAGE;
  }
  static get DIAGNOSE_TIMEOUT_COMMUNICATION_LINE_TEST() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_ROM_TEST() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_RAM_TEST() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_POLLING_TEST() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_RF_REGULATION_TEST() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_GET_UPDATE_SUCCESS_COUNT() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_OUTPUT_TRIGGER_SIGNAL() {
    return 1e3;
  }
  static get DIAGNOSE_TIMEOUT_TEMP_ABNORMAL_MEMORY_MANAGE() {
    return 1e3;
  }
  static get NFC100_RBT_INITIATOR_ISO18092_212K() {
    return 1;
  }
  static get NFC100_RBT_INITIATOR_ISO18092_424K() {
    return 1;
  }
  static get NFC100_RBT_INITIATOR_ISO14443A_106K() {
    return 2;
  }
  static get NFC100_RBT_INITIATOR_ISO14443B_106K() {
    return 3;
  }
  static get NFC100_RBT_INITIATOR_ISO14443B_212K() {
    return 3;
  }
  static get NFC100_RBT_INITIATOR_ISO14443B_424K() {
    return 3;
  }
  static get NFC100_RBT_INITIATOR_ISO14443B_848K() {
    return 3;
  }
  static get NFC100_RBT_INITIATOR_ISO14443A_212K() {
    return 4;
  }
  static get NFC100_RBT_INITIATOR_ISO14443A_424K() {
    return 5;
  }
  static get NFC100_RBT_INITIATOR_ISO14443A_848K() {
    return 6;
  }
  static get NFC100_RF_INITIATOR_ISO18092_212K() {
    return 1;
  }
  static get NFC100_RF_INITIATOR_ISO18092_424K() {
    return 2;
  }
  static get NFC100_RF_INITIATOR_ISO18092_106K() {
    return 3;
  }
  static get NFC100_RF_INITIATOR_ISO14443A_106K() {
    return 3;
  }
  static get NFC100_RF_INITIATOR_ISO14443A_212K() {
    return 4;
  }
  static get NFC100_RF_INITIATOR_ISO14443A_424K() {
    return 5;
  }
  static get NFC100_RF_INITIATOR_ISO14443A_848K() {
    return 6;
  }
  static get NFC100_RF_INITIATOR_ISO14443B_106K() {
    return 7;
  }
  static get NFC100_RF_INITIATOR_ISO14443B_212K() {
    return 8;
  }
  static get NFC100_RF_INITIATOR_ISO14443B_424K() {
    return 9;
  }
  static get NFC100_RF_INITIATOR_ISO14443B_848K() {
    return 10;
  }
  static get NFC100_IN_SET_RCT_SETTING_NUM_MAX() {
    return 16;
  }
  static get RF_NOISE_RESISTANT_IMPROVEMENT() {
    return [26, 192, 64];
  }
  static get ACK_CMD() {
    return [0, 0, 255, 0, 255, 0];
  }
  static get WAIT_AFTER_SEND_ACK() {
    return 5;
  }
  static get BUFFER_CLEAR_TIMEOUT() {
    return 500;
  }
  static get FELICA_CMD_POLLING() {
    return 0;
  }
  static get FELICA_RES_POLLING() {
    return 1;
  }
  static get FELICA_POLLING_TIMESLOT1() {
    return 0;
  }
  static get FELICA_POLLING_TIMESLOT2() {
    return 1;
  }
  static get FELICA_POLLING_TIMESLOT4() {
    return 3;
  }
  static get FELICA_POLLING_TIMESLOT8() {
    return 7;
  }
  static get FELICA_POLLING_TIMESLOT16() {
    return 15;
  }
  static get FELICA_POLLING_OPTION_NONE() {
    return 0;
  }
  static get FELICA_POLLING_OPTION_REQ_SYSCODE() {
    return 1;
  }
  static get FELICA_POLLING_OPTION_REQ_BAUDRATE() {
    return 2;
  }
  static get FELICA_POLLING_BAUDRATE_212K() {
    return 1;
  }
  static get FELICA_POLLING_BAUDRATE_424K() {
    return 2;
  }
  static get FELICA_POLLING_BAUDRATE_AUTO_DETECT() {
    return 128;
  }
  static get felica_default_protocol() {
    return [
      0, 21, 1, 1, 2, 1, 3, 0, 4, 0, 5, 0, 6, 0, 7, 8, 8, 0, 9, 0, 10, 0, 11, 0, 12, 0, 14, 0, 15,
      5, 16, 255, 17, 0, 18, 0, 19, 6,
    ];
  }
  static get NFC100_TYPEA_CMD_REQA() {
    return 38;
  }
  static get NFC100_TYPEA_CMD_WUPA() {
    return 82;
  }
  static get NFC100_TYPEA_CMD_HLTA() {
    return 80;
  }
  static get NFC100_TYPEA_CMD_SEL_CL_1() {
    return 147;
  }
  static get NFC100_TYPEA_CMD_SEL_CL_2() {
    return 149;
  }
  static get NFC100_TYPEA_CMD_SEL_CL_3() {
    return 151;
  }
  static get NFC100_TYPEA_CMD_PPS() {
    return 208;
  }
  static get typea_default_protocol() {
    return [
      0, 6, 1, 1, 2, 1, 3, 0, 4, 1, 5, 1, 6, 0, 7, 8, 8, 0, 9, 0, 10, 0, 11, 0, 12, 0, 14, 4, 15, 0,
      16, 0, 17, 0, 18, 0, 19, 6,
    ];
  }
  static get NFC100_TYPEB_CMD_REQB() {
    return 5;
  }
  static get NFC100_TYPEB_CMD_HLTB() {
    return 80;
  }
  static get NFC100_TYPEB_CMD_ATTRIB() {
    return 29;
  }
  static get TYPEB_CARD_MAX_ATTRIB_LEN() {
    return 256;
  }
  static get typeb_default_protocol() {
    return [
      0, 20, 1, 1, 2, 1, 3, 0, 4, 0, 5, 0, 6, 0, 7, 8, 8, 0, 9, 1, 10, 1, 11, 1, 12, 1, 14, 4, 15,
      0, 16, 0, 17, 0, 18, 0, 19, 6,
    ];
  }
  static get ISO14443_4_CC_DEFAULT_SFGI() {
    return 14;
  }
  static get LT_INFO_TABLE() {
    return [
      [0, 0, 1, 1, 139, 91, 9, 236, 122, 221, 197, 129, 0, 151, 75, 95, 164, 118, 161, 213],
      [0, 1, 1, 0, 213, 159, 243, 133, 168, 199, 47, 105, 44, 65, 173, 1, 230, 180, 145, 103],
      [0, 6, 1, 0, 106, 206, 150, 130, 181, 221, 246, 214, 152, 205, 55, 232, 219, 31, 152, 186],
      [0, 8, 1, 0, 71, 212, 66, 85, 79, 225, 65, 241, 115, 21, 127, 202, 181, 114, 86, 210],
    ];
  }
  static get NFC100_RR_INFO() {
    return [15, 30, 45, 60, 75, 90, 105, 123, 135, 150, 165, 180, 195, 210, 225, 240];
  }
  static get PROTOCOL_ISO18092() {
    return 'iso18092';
  }
  static get PROTOCOL_FELICA() {
    return 'FeliCa';
  }
  static get PROTOCOL_ISO14443_3A() {
    return 'iso14443-3A';
  }
  static get PROTOCOL_ISO14443_4A() {
    return 'iso14443-4A';
  }
  static get PROTOCOL_ISO14443_4B() {
    return 'iso14443-4B';
  }
  static get NFC100_FELICA_DETECT_TIMEOUT() {
    return 100;
  }
  static get NFC100_DEFAULT_ACK_TIMEOUT() {
    return 1e3;
  }
  static get NFC100_DEFAULT_RECEIVE_TIMEOUT() {
    return 1e3;
  }
  static get COMMUNICATE_THRU_COMMAND_LENGTH_MAX() {
    return 290;
  }
  static get COMMUNICATE_THRU_MIN_TIMEOUT() {
    return 400;
  }
  static get COMMUNICATE_THRU_DEFAULT_TIMEOUT() {
    return 400;
  }
  static get NFC100_TYPEA_DEFAULT_FSDI() {
    return 8;
  }
  static get NFC100_TYPEA_DEFAULT_CID() {
    return 2;
  }
  static get NFC100_TYPEB_DEFAULT_FSDI() {
    return 8;
  }
  static get NFC100_TYPEB_DEFAULT_CID() {
    return 2;
  }
  static get BAUDRATE_106K() {
    return 106;
  }
  static get BAUDRATE_212K() {
    return 212;
  }
  static get BAUDRATE_424K() {
    return 424;
  }
  static get BAUDRATE_848K() {
    return 848;
  }
  static get MAX_RESPONSE_PACKET_SIZE() {
    return 17 + NFCPort100.COMMUNICATE_THRU_COMMAND_LENGTH_MAX;
  }
  constructor() {
    super(),
      (super._deviceName = 'NFCPort100'),
      (this._fileName = 'NFCPort100.js'),
      (this.communicator = new GenericUSB(NFCPort100.MAX_RESPONSE_PACKET_SIZE)),
      (this.config = null),
      (this.protocol = ''),
      (this.bAbnormalState = !1),
      (this.currentProtocol = new Array(NFCPort100.felica_default_protocol.length / 2 + 1));
  }
  async init(e) {
    let r;
    if ((syslog('init begin'), null == e))
      return (
        (r = 'Parameter config is not specified'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 328))
      );
    (this.config = e), (this.protocol = ''), (this.bAbnormalState = !1), syslog('init end');
  }
  async open() {
    let e;
    if ((syslog('open begin'), null == this.config))
      return (
        (e = 'not initialized'),
        syslog(e),
        Promise.reject(new NFCPortError(NFCPortError.INTERNAL_ERROR, e, this._fileName, 349))
      );
    if (this.bAbnormalState)
      return (
        (e = 'Status is abnormaled'),
        syslog(e),
        Promise.reject(new NFCPortError(NFCPortError.INTERNAL_ERROR, e, this._fileName, 357))
      );
    (this.protocol = ''),
      (this.bAbnormalState = !1),
      this.config.autoDeviceSelect
        ? await this.communicator.auto_open(
            NFCPort100.VENDOR_ID,
            NFCPort100.SELECT_CONFIG,
            NFCPort100.CLAIM_INTERFACE,
            NFCPort100.ENDPOINT_OUT,
            NFCPort100.ENDPOINT_IN,
          )
        : await this.communicator.open(
            NFCPort100.VENDOR_ID,
            NFCPort100.SELECT_CONFIG,
            NFCPort100.CLAIM_INTERFACE,
            NFCPort100.ENDPOINT_OUT,
            NFCPort100.ENDPOINT_IN,
          ),
      await this.send_ack();
    try {
      await this.SetCommandType(NFCPort100.COMMAND_TYPE_1);
    } catch (e) {
      if (e.errorType != NFCPortError.INVALID_STATUS) return Promise.reject(e);
      await this.SetCommandType(NFCPort100.COMMAND_TYPE_0);
    }
    await this.set_felica_rf_speed_and_protocol(NFCPort100.BAUDRATE_212K),
      (this.protocol = NFCPort100.PROTOCOL_FELICA),
      await this.rf_on(),
      syslog('open end');
  }
  async detectCard(e, r) {
    let t, o;
    if ((syslog('detectCard begin'), this.bAbnormalState))
      return (
        (o = 'Status is abnormaled'),
        syslog(o),
        Promise.reject(new NFCPortError(NFCPortError.INTERNAL_ERROR, o, this._fileName, 399))
      );
    if (e == NFCPort100.PROTOCOL_ISO18092 || e == NFCPort100.PROTOCOL_FELICA)
      (this.protocol = NFCPort100.PROTOCOL_ISO18092), (t = await this.detectCard_FeliCa(r));
    else if (e == NFCPort100.PROTOCOL_ISO14443_3A)
      (this.protocol = NFCPort100.PROTOCOL_ISO14443_3A), (t = await this.detectCard_TypeA(r));
    else if (e == NFCPort100.PROTOCOL_ISO14443_4A)
      (this.protocol = NFCPort100.PROTOCOL_ISO14443_4A), (t = await this.detectCard_TypeA(r));
    else {
      if (e != NFCPort100.PROTOCOL_ISO14443_4B)
        return (
          (o = 'Invalid parameter (protocol)'),
          syslog(o),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, o, this._fileName, 417))
        );
      (this.protocol = NFCPort100.PROTOCOL_ISO14443_4B), (t = await this.detectCard_TypeB(r));
    }
    return syslog('detectCard end'), t;
  }
  async communicateThru(e, r, t) {
    let o, i;
    if ((syslog('communicateThru begin'), this.bAbnormalState))
      return (
        (o = 'Status is abnormaled'),
        syslog(o),
        Promise.reject(new NFCPortError(NFCPortError.INTERNAL_ERROR, o, this._fileName, 433))
      );
    if (
      (this.protocol != NFCPort100.PROTOCOL_ISO14443_3A || null == t || !t.mifareAuthentication) &&
      (null == e || e.length <= 0)
    )
      return (
        (o = 'Invalid parameter (command)'),
        syslog(o),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, o, this._fileName, 440))
      );
    if (null != e && e.length > NFCPort100.COMMUNICATE_THRU_COMMAND_LENGTH_MAX)
      return (
        (o = 'The command is incorrect size.'),
        syslog(o),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, o, this._fileName, 444))
      );
    if (
      (this.protocol == NFCPort100.PROTOCOL_ISO14443_3A &&
        null != t &&
        t.mifareAuthentication &&
        (await this.typea_mifareAuth(t)),
      await this.setCommunicateThruProtocol(t),
      null != e && e.length > 0)
    ) {
      r < NFCPort100.COMMUNICATE_THRU_MIN_TIMEOUT && (r = NFCPort100.COMMUNICATE_THRU_MIN_TIMEOUT);
      let t = await this.InCommRF(e, r);
      i = new Uint8Array(t);
    } else i = null;
    return syslog('communicateThru end'), i;
  }
  async switchRF(e) {
    let r;
    return (
      syslog('switchRF begin'),
      this.bAbnormalState
        ? ((r = 'Status is abnormaled'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INTERNAL_ERROR, r, this._fileName, 485)))
        : null == e
        ? ((r = 'Invalid parameter (on)'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INTERNAL_ERROR, r, this._fileName, 491)))
        : (e ? await this.rf_on() : await this.rf_off(), void syslog('switchRF end'))
    );
  }
  async close() {
    if ((syslog('close begin'), !this.bAbnormalState))
      try {
        await this.rf_off();
      } catch (e) {}
    try {
      await this.communicator.close();
    } catch (e) {}
    (this.protocol = ''), (this.bAbnormalState = !1), syslog('close end');
  }
  async detectCard_FeliCa(e) {
    let r, t, o, i, s, _, a, n, l;
    if (
      (syslog('detectCard_FeliCa begin'),
      null == e ||
        null == e.systemCode ||
        2 != e.systemCode.length ||
        null == e.timeSlot ||
        (e.timeSlot != NFCPort100.FELICA_POLLING_TIMESLOT1 &&
          e.timeSlot != NFCPort100.FELICA_POLLING_TIMESLOT2 &&
          e.timeSlot != NFCPort100.FELICA_POLLING_TIMESLOT4 &&
          e.timeSlot != NFCPort100.FELICA_POLLING_TIMESLOT8 &&
          e.timeSlot != NFCPort100.FELICA_POLLING_TIMESLOT16) ||
        null == e.requestSystemCode ||
        null == e.requestBaudRate ||
        (e.requestSystemCode && e.requestBaudRate))
    )
      return (
        (r = 'Invalid parameter (option)'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 558))
      );
    await this.set_felica_rf_speed_and_protocol(NFCPort100.BAUDRATE_212K),
      await this.rf_on(),
      (t = [6, NFCPort100.FELICA_CMD_POLLING, e.systemCode[0], e.systemCode[1], 0, 0]),
      e.requestSystemCode
        ? (t[4] = NFCPort100.FELICA_POLLING_OPTION_REQ_SYSCODE)
        : e.requestBaudRate || this.config.autoBaudRate
        ? (t[4] = NFCPort100.FELICA_POLLING_OPTION_REQ_BAUDRATE)
        : (t[4] = NFCPort100.FELICA_POLLING_OPTION_NONE),
      null != e && null != e.timeSlot && (t[5] = e.timeSlot);
    try {
      o = await this.InCommRF(t, NFCPort100.NFC100_FELICA_DETECT_TIMEOUT);
    } catch (e) {
      return (
        e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
          ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
        Promise.reject(e)
      );
    }
    if (o.length < 18)
      return (
        (r = 'Illegal polling response length'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 592))
      );
    if (
      ((i = o.length >= 20 ? array_slice(o, 18, 2) : []),
      t[4] == NFCPort100.FELICA_POLLING_OPTION_REQ_SYSCODE
        ? ((s = i), (_ = null))
        : t[4] == NFCPort100.FELICA_POLLING_OPTION_REQ_BAUDRATE
        ? ((s = null), (_ = i))
        : ((s = null), (_ = null)),
      (a = e.requestSystemCode ? new Uint8Array(s) : null),
      (n = e.requestBaudRate ? new Uint8Array(_) : null),
      (l = new TargetCard(
        new Uint8Array(array_slice(o, 2, 8)),
        new Uint8Array(array_slice(o, 10, 8)),
        null,
        null,
        a,
        n,
        null,
        null,
      )),
      syslog('IDm :' + array_tohexs(l.idm)),
      syslog('PMm :' + array_tohexs(l.pmm)),
      null != l.systemCode && syslog('SystemCode :' + array_tohexs(l.systemCode)),
      this.config.autoBaudRate)
    ) {
      if (t[4] != NFCPort100.FELICA_POLLING_OPTION_REQ_BAUDRATE) {
        t[4] = NFCPort100.FELICA_POLLING_OPTION_REQ_BAUDRATE;
        try {
          o = await this.InCommRF(t, NFCPort100.NFC100_FELICA_DETECT_TIMEOUT);
        } catch (e) {
          return (
            e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
              ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
            Promise.reject(e)
          );
        }
        o.length >= 20 && (_ = array_slice(o, 18, 2));
      }
      null != _ &&
        2 == _.length &&
        0 != (_[1] & NFCPort100.FELICA_POLLING_BAUDRATE_AUTO_DETECT) &&
        0 != (_[1] & NFCPort100.FELICA_POLLING_BAUDRATE_424K) &&
        (await this.set_felica_rf_speed_and_protocol(NFCPort100.BAUDRATE_424K));
    }
    return syslog('detectCard_FeliCa end'), l;
  }
  async detectCard_TypeA(e) {
    let r, t, o, i, s, _, a, n, l;
    return (
      syslog('detectCard_TypeA begin'),
      (r = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_106K),
      (t = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_106K),
      (o = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_106K),
      (i = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_106K),
      await this.InSetRF(r, t, o, i),
      await this.InSetProtocol(NFCPort100.typea_default_protocol),
      (super._targetCardBaudRate = NFCPort100.BAUDRATE_106K),
      await this.rf_on(),
      (s = await this.typea_reqa_wupa()),
      (_ = await this.typea_anticollision()),
      null != _ && (_ = new Uint8Array(_)),
      this.protocol == NFCPort100.PROTOCOL_ISO14443_4A &&
        ((a = null != e.fsdi ? e.fsdi : NFCPort100.NFC100_TYPEA_DEFAULT_FSDI),
        (n = null != e.cid ? e.cid : NFCPort100.NFC100_TYPEA_DEFAULT_CID),
        await this.typea_iso14443_4_activate(a, n, this.config.autoBaudRate)),
      await this.InSetProtocol(NFCPort100.typea_default_protocol),
      (l = new TargetCard(null, null, _, null, null, null)),
      syslog('detectCard_TypeA end'),
      l
    );
  }
  async detectCard_TypeB(e) {
    let r, t, o, i, s, _, a, n, l, N, C, c, E, T;
    return (
      syslog('detectCard_TypeB begin'),
      (r = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_106K),
      (t = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_106K),
      (o = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_106K),
      (i = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_106K),
      await this.InSetRF(r, t, o, i),
      await this.InSetProtocol(NFCPort100.typeb_default_protocol),
      (super._targetCardBaudRate = 106),
      await this.rf_on(),
      (s = 0),
      (_ = !0),
      (a = !1),
      (n = !1),
      (l = await this.typeb_reqb_wupb(0, !0, !1, !1)),
      0 != (1 & l.protocol_info[1]) &&
        ((N = null != e.fsdi ? e.fsdi : NFCPort100.NFC100_TYPEB_DEFAULT_FSDI),
        (C = null != e.cid ? e.cid : NFCPort100.NFC100_TYPEB_DEFAULT_CID),
        (c = null),
        await this.typeb_iso14443_4_activate(
          l.pupi,
          N,
          l.protocol_info,
          C,
          null,
          this.config.autoBaudRate,
        )),
      await this.InSetProtocol(NFCPort100.typeb_default_protocol),
      (E = null != l.pupi ? new Uint8Array(l.pupi) : null),
      (T = new TargetCard(null, null, null, E, null, null)),
      syslog('detectCard_TypeB end'),
      T
    );
  }
  async rf_on() {
    let e, r;
    for (syslog('rf_on begin'), e = 0; e < NFCPort100.NFC100_MAX_SWICH_RF_EXECUTION_COUNT; e++) {
      await this.SwitchRF(NFCPort100.SWITCH_RF_RFON);
      try {
        if (
          ((r = await this.ReadRegister(NFCPort100.NFC100_RR_INFO, NFCPort100.REG_ADR_RF_STATE)),
          r == NFCPort100.REG_ADR_RF_STATE_ON)
        )
          break;
      } catch (e) {}
    }
    e >= NFCPort100.NFC100_MAX_SWICH_RF_EXECUTION_COUNT
      ? syslog('rf_on Failed')
      : syslog('rf_on end');
  }
  async rf_off() {
    let e, r;
    for (syslog('rf_off begin'), e = 0; e < NFCPort100.NFC100_MAX_SWICH_RF_EXECUTION_COUNT; e++) {
      await this.SwitchRF(NFCPort100.SWITCH_RF_RFOFF);
      try {
        if (
          ((r = await this.ReadRegister(NFCPort100.NFC100_RR_INFO, NFCPort100.REG_ADR_RF_STATE)),
          r == NFCPort100.REG_ADR_RF_STATE_OFF)
        )
          break;
      } catch (e) {}
      e < NFCPort100.NFC100_MAX_SWICH_RF_EXECUTION_COUNT - 1 &&
        (await this.SwitchRF(NFCPort100.SWITCH_RF_RFON));
    }
    e >= NFCPort100.NFC100_MAX_SWICH_RF_EXECUTION_COUNT
      ? syslog('rf_off Failed')
      : syslog('rf_off end');
  }
  async send_ack() {
    syslog('send_ack begin'),
      await this.communicator.clear(),
      await this.communicator.transmit(NFCPort100.ACK_CMD),
      await wait_async(NFCPort100.WAIT_AFTER_SEND_ACK),
      syslog('send_ack end');
  }
  async set_felica_rf_speed_and_protocol(e) {
    let r, t, o, i, s, _, a;
    syslog('set_felica_rf_speed begin'),
      null != e && e == NFCPort100.BAUDRATE_424K
        ? ((r = NFCPort100.NFC100_RBT_INITIATOR_ISO18092_424K),
          (t = NFCPort100.NFC100_RF_INITIATOR_ISO18092_424K),
          (o = NFCPort100.NFC100_RBT_INITIATOR_ISO18092_424K),
          (i = NFCPort100.NFC100_RF_INITIATOR_ISO18092_424K))
        : ((e = NFCPort100.BAUDRATE_212K),
          (r = NFCPort100.NFC100_RBT_INITIATOR_ISO18092_212K),
          (t = NFCPort100.NFC100_RF_INITIATOR_ISO18092_212K),
          (o = NFCPort100.NFC100_RBT_INITIATOR_ISO18092_212K),
          (i = NFCPort100.NFC100_RF_INITIATOR_ISO18092_212K)),
      await this.InSetRF(r, t, o, i);
    try {
      (s = await this.get_lt_info()),
        null != s &&
          ((_ = await this.InGetRCT(s)),
          null != _ &&
            ((a = await this.update_rct_setting(
              _.in_receive_setting,
              NFCPort100.RF_NOISE_RESISTANT_IMPROVEMENT,
            )),
            null != a && (await this.InSetRCT(s, _.in_send_setting, _.in_receive_setting))));
    } catch (e) {}
    await this.InSetProtocol(NFCPort100.felica_default_protocol),
      (super._targetCardBaudRate = e),
      syslog('set_felica_rf_speed end');
  }
  async receive_ack(e) {
    let r, t, o, i, s, _;
    for (syslog('receive_ack begin (timeout=' + e + ')'), r = new Date(), t = []; ; ) {
      if (((i = new Date()), (s = i.getTime() - r.getTime()), s > e))
        return (
          (_ = 'receive_ack receive Timeout'),
          syslog(_),
          Promise.reject(new NFCPortError(NFCPortError.ACK_TIMEOUT, _, this._fileName, 963))
        );
      if (
        ((o = await this.communicator.receive(NFCPort100.ACK_CMD.length - t.length, e - s)),
        syslog('Receive : ' + array_tohexs(o)),
        array_append(t, o),
        t.length >= NFCPort100.ACK_CMD.length)
      ) {
        if (0 == array_compare(t, 0, NFCPort100.ACK_CMD, 0, NFCPort100.ACK_CMD.length)) break;
        t = array_slice(t, 1);
      }
    }
    return syslog('receive_ack end'), t;
  }
  async send_packet_ext(e) {
    let r, t;
    return (
      syslog('send_packet_ext begin'),
      await this.communicator.clear(),
      (r = new Uint8Array(8 + e.length + 2)),
      (r[0] = 0),
      (r[1] = 0),
      (r[2] = 255),
      (r[3] = 255),
      (r[4] = 255),
      array_set_uint16l(r, 5, e.length),
      (r[7] = this.get_packet_length_checksum(e.length)),
      array_copy(r, 8, e, 0, e.length),
      (r[8 + e.length] = this.get_packet_data_checksum(e)),
      (r[8 + e.length + 1] = 0),
      (t = await this.communicator.transmit(r)),
      syslog('send_packet_ext end'),
      t
    );
  }
  get_packet_length_checksum(e) {
    return 255 & -(((e >> 8) & 255) + (255 & e));
  }
  get_packet_data_checksum(e) {
    let r,
      t = 0;
    for (t = 0, r = 0; r < e.length; r++) t += e[r];
    return 255 & -t;
  }
  async receive_packet(e, r) {
    let t, o, i, s, _, a;
    return (
      syslog('receive_packet begin (timeout=' + r + ')'),
      (t = new Date()),
      syslog('start time=' + t.getTime()),
      (o = 0),
      (i = await this.communicator
        .receive(5, r)
        .then(
          (e) => (
            syslog('Frame : ' + array_tohexs(e)),
            0 == e[0] && 0 == e[1] && 255 == e[2] && 255 == e[3] && 255 == e[4]
              ? ((s = new Date()),
                (_ = s.getTime() - t.getTime()),
                _ > r
                  ? ((a = 'receive_packet reslen checksum receive timeout'),
                    syslog(a),
                    Promise.reject(
                      new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, a, this._fileName, 1053),
                    ))
                  : this.communicator
                      .receive(3, r - _)
                      .then(
                        (e) => (
                          (o = array_get_uint16l(e, 0)),
                          this.get_packet_length_checksum(o) != e[2]
                            ? ((a = 'receive_packet response reslen checksum error'),
                              syslog(a),
                              Promise.reject(
                                new NFCPortError(
                                  NFCPortError.INVALID_RESPONSE,
                                  a,
                                  this._fileName,
                                  1064,
                                ),
                              ))
                            : ((s = new Date()),
                              (_ = s.getTime() - t.getTime()),
                              _ > r
                                ? ((a = 'receive_packet response-data receive timeout'),
                                  syslog(a),
                                  Promise.reject(
                                    new NFCPortError(
                                      NFCPortError.DATA_RECEIVE_TIMEOUT,
                                      a,
                                      this._fileName,
                                      1074,
                                    ),
                                  ))
                                : this.communicator.receive(o, r - _))
                        ),
                      ))
              : ((o = e[3]),
                this.get_packet_length_checksum(o) != e[4]
                  ? ((a = 'receive_packet Error-response reslen checksum error'),
                    syslog(a),
                    Promise.reject(
                      new NFCPortError(NFCPortError.INVALID_RESPONSE, a, this._fileName, 1084),
                    ))
                  : ((s = new Date()),
                    (_ = s.getTime() - t.getTime()),
                    _ > r
                      ? ((a = 'receive_packet response-data receive timeout'),
                        syslog(a),
                        Promise.reject(
                          new NFCPortError(
                            NFCPortError.DATA_RECEIVE_TIMEOUT,
                            a,
                            this._fileName,
                            1092,
                          ),
                        ))
                      : this.communicator.receive(o, r - _)))
          ),
        )
        .then(
          (r) => (
            syslog('Receive : ' + array_tohexs(r)),
            e != r[0]
              ? ((a = 'receive_packet invalid res-code'),
                syslog(a),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, a, this._fileName, 1105),
                ))
              : r
          ),
        )
        .then(
          (e) => (
            (s = new Date()),
            (_ = s.getTime() - t.getTime()),
            _ > r
              ? ((a = 'receive_packet dcs receive timeout'),
                syslog(a),
                Promise.reject(
                  new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, a, this._fileName, 1116),
                ))
              : this.communicator
                  .receive(2, r - _)
                  .then((r) =>
                    this.get_packet_data_checksum(e) != r[0] || 0 != r[1]
                      ? ((a = 'receive_packet dcs checksum error'),
                        syslog(a),
                        Promise.reject(
                          new NFCPortError(NFCPortError.INVALID_RESPONSE, a, this._fileName, 1126),
                        ))
                      : e,
                  )
          ),
        )),
      syslog('receive_packet end'),
      i
    );
  }
  async receive_ack_and_response(e, r) {
    let t, o, i, s, _, a, n, l, N;
    for (
      syslog('receive_ack_and_response begin'),
        null == r &&
          (r =
            null != this.config && null != this.config.receiveTimeout
              ? this.config.receiveTimeout
              : NFCPort100.NFC100_DEFAULT_RECEIVE_TIMEOUT),
        t = new Date(),
        o = [];
      ;

    ) {
      for (syslog('search ACK'); ; ) {
        if (((i = new Date()), (s = i.getTime() - t.getTime()), s > r))
          return (
            (_ = 'transceive ACK receive Timeout'),
            syslog(_),
            Promise.reject(new NFCPortError(NFCPortError.ACK_TIMEOUT, _, this._fileName, 1170))
          );
        if (
          (o.length < NFCPort100.ACK_CMD.length &&
            ((a = await this.communicator.receive(NFCPort100.ACK_CMD.length - o.length, r - s)),
            syslog('Receive : ' + array_tohexs(a)),
            array_append(o, a)),
          o.length >= NFCPort100.ACK_CMD.length)
        ) {
          if (0 == array_compare(o, 0, NFCPort100.ACK_CMD, 0, NFCPort100.ACK_CMD.length)) {
            o = array_slice(o, NFCPort100.ACK_CMD.length);
            break;
          }
          o = array_slice(o, 1);
        }
      }
      if (o.length < 5) {
        if (((i = new Date()), (s = i.getTime() - t.getTime()), s > r))
          return (
            (_ = 'transceive Response receive Timeout'),
            syslog(_),
            Promise.reject(new NFCPortError(NFCPortError.ACK_TIMEOUT, _, this._fileName, 1202))
          );
        (n = 0), (a = await this.communicator.receive(5 - o.length, r - s)), array_append(o, a);
      }
      if (
        (syslog('Preamble & SPC & Ext Frame Code : ' + array_tohexs(o, 0, 5)),
        0 == o[0] && 0 == o[1] && 255 == o[2] && 255 == o[3] && 255 == o[4])
      ) {
        if (((o = array_slice(o, 5)), o.length < 3)) {
          if (
            ((i = new Date()),
            syslog('current time=' + i.getTime()),
            (s = i.getTime() - t.getTime()),
            syslog('elapsed = ' + s),
            s > r)
          )
            return (
              (_ = 'receive_packet reslen checksum receive timeout'),
              syslog(_),
              Promise.reject(
                new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, _, this._fileName, 1226),
              )
            );
          (a = await this.communicator.receive(3 - o.length, r - s)), array_append(o, a);
        }
        if (
          (syslog('Length & LCS : ' + array_tohexs(o, 0, 3)),
          (n = array_get_uint16l(o, 0)),
          n >= 2 && this.get_packet_length_checksum(n) == o[2])
        ) {
          if (((l = array_slice(o, 3)), l.length < 2)) {
            if (((i = new Date()), (s = i.getTime() - t.getTime()), s > r))
              return (
                (_ = 'receive_packet response-data receive timeout'),
                syslog(_),
                Promise.reject(
                  new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, _, this._fileName, 1248),
                )
              );
            (a = await this.communicator.receive(2 - l.length, r - s)),
              array_append(o, a),
              array_append(l, a);
          }
          if (
            (syslog('Res Comand + SubRes Command : ' + array_tohexs(l, 0, 2)),
            NFCPort100.NFC100_RESPONSE_CODE == l[0] && e == l[1])
          ) {
            if (((o = l), o.length < n)) {
              if (((i = new Date()), (s = i.getTime() - t.getTime()), s > r))
                return (
                  (_ = 'receive_packet response-data receive timeout'),
                  syslog(_),
                  Promise.reject(
                    new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, _, this._fileName, 1268),
                  )
                );
              (a = await this.communicator.receive(n - o.length, r - s)), array_append(o, a);
            }
            if (
              (syslog('Res data(remain) : ' + array_tohexs(o, 2, n - 2)),
              (N = array_slice(o, 0, n)),
              (o = array_slice(o, n)),
              o.length < 2)
            ) {
              if (((i = new Date()), (s = i.getTime() - t.getTime()), s > r))
                return (
                  (_ = 'receive_packet dcs receive timeout'),
                  syslog(_),
                  Promise.reject(
                    new NFCPortError(NFCPortError.DATA_RECEIVE_TIMEOUT, _, this._fileName, 1290),
                  )
                );
              (a = await this.communicator.receive(2 - o.length, r - s)), array_append(o, a);
            }
            if (
              (syslog('DCS & Postamble : ' + array_tohexs(o)),
              this.get_packet_data_checksum(N) != o[0] || 0 != o[1])
            )
              return (
                (_ = 'receive_packet dcs checksum error'),
                syslog(_),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, _, this._fileName, 1305),
                )
              );
            break;
          }
        }
      }
    }
    return syslog('receive_ack_and_response end'), l;
  }
  async error_recovery(e) {
    syslog('error_recovery begin');
    try {
      await this.send_ack(),
        e && (await this.communicator.receive(0, NFCPort100.BUFFER_CLEAR_TIMEOUT));
    } catch (e) {}
    syslog('error_recovery end');
  }
  async tranceive_ext(e) {
    let r, t;
    syslog('tranceive_ext begin');
    try {
      await this.send_packet_ext(e);
    } catch (e) {
      return (
        syslog('tranceive_ext send error catch'), await this.error_recovery(!1), Promise.reject(e)
      );
    }
    r =
      null != this.config && null != this.config.ackTimeout
        ? this.config.ackTimeout
        : NFCPort100.NFC100_DEFAULT_ACK_TIMEOUT;
    try {
      t = await this.receive_ack(r).then(
        (e) => (
          (r =
            null != this.config && null != this.config.receiveTimeout
              ? this.config.receiveTimeout
              : NFCPort100.NFC100_DEFAULT_RECEIVE_TIMEOUT),
          this.receive_packet(NFCPort100.NFC100_RESPONSE_CODE, r)
        ),
      );
    } catch (e) {
      return (
        syslog('tranceive_ext receive error catch'),
        await this.error_recovery(!0),
        Promise.reject(e)
      );
    }
    return syslog('tranceive_ext end'), t;
  }
  async get_lt_info() {
    let e, r, t, o, i, s, _, a;
    for (
      syslog('get_lt_info begin'), e = await this.GetProperty(), r = [e[14], e[15]], t = !1, o = 0;
      o < NFCPort100.LT_INFO_TABLE.length;
      o++
    )
      if (((i = NFCPort100.LT_INFO_TABLE[o]), i[0] == r[0] && i[1] == r[1])) {
        t = !0;
        break;
      }
    if (!t) return (s = 'LT-Info unsuported Reader/Writer.'), syslog(s), null;
    for (
      _ = await this.GetPDDataVersion(), a = null, o = 0;
      o < NFCPort100.LT_INFO_TABLE.length;
      o++
    )
      if (
        ((i = NFCPort100.LT_INFO_TABLE[o]),
        i[0] == r[0] && i[1] == r[1] && i[2] == _[0] && i[3] == _[1])
      ) {
        a = array_slice(i, 4);
        break;
      }
    return null == a
      ? ((s = 'LT-Info unsuported Reader/Writer.'), syslog(s), null)
      : (syslog('get_lt_info end'), a);
  }
  async update_rct_setting(e, r) {
    let t, o, i, s;
    for (syslog('update_rct_setting begin'), t = e.length / 3, o = 0; o < t; o++)
      if (((i = 3 * o), e[i] == r[0] && e[i + 1] == r[1]))
        return e[i + 2] == r[2] ? null : ((e[i + 2] = r[2]), e);
    return t >= NFCPort100.NFC100_IN_SET_RCT_SETTING_NUM_MAX
      ? null
      : ((s = array_append(e, r)), syslog('update_rct_setting end'), s);
  }
  async SetCommandType(e) {
    let r, t, o;
    if ((syslog('SetCommandType begin'), null == e))
      return (
        (r = 'Invalid parameter (type)'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 1479))
      );
    (t = new Uint8Array(3)),
      (t[0] = NFCPort100.NFC100_COMMAND_CODE),
      (t[1] = NFCPort100.SCC_SET_COMMAND_TYPE),
      (t[2] = e),
      (o = await this.tranceive_ext(t).then((e) =>
        3 != e.length
          ? ((r = 'Illegal response length'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1494),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((r = 'Illegal response code'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1498),
            ))
          : e[1] != NFCPort100.SRC_SET_COMMAND_TYPE
          ? ((r = 'Illegal sub-response code'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1502),
            ))
          : e[2] != NFCPort100.STATUS_SUCCESS
          ? ((r = 'Invalid Status'),
            syslog(r),
            Promise.reject(new NFCPortError(NFCPortError.INVALID_STATUS, r, this._fileName, 1506)))
          : void 0,
      )),
      syslog('SetCommandType end');
  }
  async InSetRF(e, r, t, o) {
    let i, s, _;
    if ((syslog('InSetRF begin'), null == e || null == r || null == t || null == o))
      return (
        (i = 'Invalid parameter'),
        syslog(i),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 1526))
      );
    (s = new Uint8Array(6)),
      (s[0] = NFCPort100.NFC100_COMMAND_CODE),
      (s[1] = NFCPort100.SCC_IN_SET_RF),
      (s[2] = e),
      (s[3] = r),
      (s[4] = t),
      (s[5] = o),
      (_ = await this.tranceive_ext(s).then((e) =>
        3 != e.length
          ? ((i = 'Illegal response length'),
            syslog(i),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, i, this._fileName, 1544),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((i = 'Illegal response code'),
            syslog(i),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, i, this._fileName, 1548),
            ))
          : e[1] != NFCPort100.SRC_IN_SET_RF
          ? ((i = 'Illegal sub-response code'),
            syslog(i),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, i, this._fileName, 1552),
            ))
          : e[2] != NFCPort100.STATUS_SUCCESS
          ? ((i = 'Invalid Status'),
            syslog(i),
            Promise.reject(new NFCPortError(NFCPortError.INVALID_STATUS, i, this._fileName, 1556)))
          : void 0,
      )),
      syslog('InSetRF end');
  }
  async InSetProtocol(e) {
    let r, t, o;
    if ((syslog('InSetProtocol begin'), null == e || 0 == e.length))
      return (
        (r = 'Invalid parameter (protocol_array)'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 1576))
      );
    (e = this.getUpdateProtocol(e)).length > 0 &&
      ((t = new Uint8Array(2 + e.length)),
      (t[0] = NFCPort100.NFC100_COMMAND_CODE),
      (t[1] = NFCPort100.SCC_IN_SET_PROTOCOL),
      array_copy(t, 2, e, 0, e.length),
      (o = await this.tranceive_ext(t).then((e) =>
        3 != e.length
          ? ((r = 'Illegal response length'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1593),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((r = 'Illegal response code'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1597),
            ))
          : e[1] != NFCPort100.SRC_IN_SET_PROTOCOL
          ? ((r = 'Illegal sub-response code'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1601),
            ))
          : e[2] != NFCPort100.STATUS_SUCCESS
          ? ((r = 'Invalid Status'),
            syslog(r),
            Promise.reject(new NFCPortError(NFCPortError.INVALID_STATUS, r, this._fileName, 1605)))
          : void 0,
      )),
      this.setCurrentProtocol(e)),
      syslog('InSetProtocol end');
  }
  async SwitchRF(e) {
    let r, t, o;
    if ((syslog('SwitchRF begin'), null == e))
      return (
        (r = 'Invalid parameter (type)'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 1629))
      );
    (t = new Uint8Array(3)),
      (t[0] = NFCPort100.NFC100_COMMAND_CODE),
      (t[1] = NFCPort100.SCC_SWITCH_RF),
      (t[2] = e),
      (o = await this.tranceive_ext(t).then((e) =>
        3 != e.length
          ? ((r = 'Illegal response length'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1644),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((r = 'Illegal response code'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1648),
            ))
          : e[1] != NFCPort100.SRC_SWITCH_RF
          ? ((r = 'Illegal sub-response code'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, r, this._fileName, 1652),
            ))
          : e[2] == NFCPort100.STATUS_TEMPERATURE_ERROR ||
            e[2] == NFCPort100.STATUS_INTTEMPRFOFF_ERROR
          ? ((this.bAbnormalState = !0),
            (r = 'Temperature error'),
            syslog(r),
            Promise.reject(
              new NFCPortError(NFCPortError.DEVICE_FATAL_ERROR, r, this._fileName, 1658),
            ))
          : e[2] != NFCPort100.STATUS_SUCCESS
          ? ((r = 'Invalid Status'),
            syslog(r),
            Promise.reject(new NFCPortError(NFCPortError.INVALID_STATUS, r, this._fileName, 1662)))
          : void 0,
      )),
      syslog('SwitchRF end');
  }
  async GetPDDataVersion() {
    let e, r, t;
    return (
      syslog('GetPDDataVersion begin'),
      (e = new Uint8Array(2)),
      (e[0] = NFCPort100.NFC100_COMMAND_CODE),
      (e[1] = NFCPort100.SCC_GET_PDDATA_VERSION),
      (r = await this.tranceive_ext(e).then((e) =>
        4 != e.length
          ? ((t = 'Illegal response length'),
            syslog(t),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1688),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((t = 'Illegal response code'),
            syslog(t),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1692),
            ))
          : e[1] != NFCPort100.SRC_GET_PDDATA_VERSION
          ? ((t = 'Illegal sub-response code'),
            syslog(t),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1696),
            ))
          : [e[3], e[2]],
      )),
      syslog('GetPDDataVersion end'),
      r
    );
  }
  async GetProperty() {
    let e, r, t;
    return (
      syslog('GetProperty begin'),
      (e = new Uint8Array(2)),
      (e[0] = NFCPort100.NFC100_COMMAND_CODE),
      (e[1] = NFCPort100.SCC_GET_PROPERTY),
      (r = await this.tranceive_ext(e).then((e) =>
        34 != e.length
          ? ((t = 'Illegal response length'),
            syslog(t),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1724),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((t = 'Illegal response code'),
            syslog(t),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1728),
            ))
          : e[1] != NFCPort100.SRC_GET_PROPERTY
          ? ((t = 'Illegal sub-response code'),
            syslog(t),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1732),
            ))
          : array_slice(e, 2),
      )),
      syslog('GetProperty end'),
      r
    );
  }
  async InSetRCT(e, r, t) {
    let o, i;
    syslog('InSetRCT begin'),
      (o = new Uint8Array(18 + (r.length + 1) + (t.length + 1))),
      (o[0] = NFCPort100.NFC100_COMMAND_CODE),
      (o[1] = NFCPort100.SCC_IN_SET_RCT),
      array_copy(o, 2, e),
      (o[18] = r.length / 3),
      array_copy(o, 19, r),
      (o[19 + r.length] = t.length / 3),
      array_copy(o, 19 + r.length + 1, t),
      await this.tranceive_ext(o).then((e) =>
        3 != e.length
          ? ((i = 'Illegal response length'),
            syslog(i),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, i, this._fileName, 1764),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((i = 'Illegal response code'),
            syslog(i),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, i, this._fileName, 1768),
            ))
          : e[1] != NFCPort100.SRC_IN_SET_RCT
          ? ((i = 'Illegal sub-response code'),
            syslog(i),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, i, this._fileName, 1772),
            ))
          : e[2] == NFCPort100.STATUS_PARAMETER_ERROR
          ? ((i = 'PARAMETER_ERROR'),
            syslog(i),
            Promise.reject(new NFCPortError(NFCPortError.UNKNOWN_ERROR, i, this._fileName, 1776)))
          : e[2] == NFCPort100.STATUS_PWD_ERROR
          ? ((i = 'PWD_ERROR'),
            syslog(i),
            Promise.reject(new NFCPortError(NFCPortError.UNKNOWN_ERROR, i, this._fileName, 1780)))
          : e[2] != NFCPort100.STATUS_SUCCESS
          ? ((i = 'Invalid status'),
            syslog(i),
            Promise.reject(new NFCPortError(NFCPortError.INVALID_STATUS, i, this._fileName, 1784)))
          : void 0,
      ),
      syslog('InSetRCT end');
  }
  async InGetRCT(e) {
    let r, t, o, i, s, _, a, n;
    return (
      syslog('InGetRCT begin'),
      (r = new Uint8Array(18)),
      (r[0] = NFCPort100.NFC100_COMMAND_CODE),
      (r[1] = NFCPort100.SCC_IN_GET_RCT),
      array_copy(r, 2, e),
      (t = await this.tranceive_ext(r).then((e) =>
        e.length < 11
          ? ((o = 'Illegal response length'),
            syslog(o),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, o, this._fileName, 1818),
            ))
          : e[0] != NFCPort100.NFC100_RESPONSE_CODE
          ? ((o = 'Illegal response code'),
            syslog(o),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, o, this._fileName, 1822),
            ))
          : e[1] != NFCPort100.SRC_IN_GET_RCT
          ? ((o = 'Illegal sub-response code'),
            syslog(o),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_RESPONSE, o, this._fileName, 1826),
            ))
          : e[2] == NFCPort100.STATUS_PWD_ERROR
          ? ((o = 'PWD_ERROR'),
            syslog(o),
            Promise.reject(new NFCPortError(NFCPortError.UNKNOWN_ERROR, o, this._fileName, 1830)))
          : e[2] != NFCPort100.STATUS_SUCCESS
          ? ((o = 'Invalid status'),
            syslog(o),
            Promise.reject(new NFCPortError(NFCPortError.INVALID_STATUS, o, this._fileName, 1834)))
          : array_slice(e, 3),
      )),
      (s = 3 * t[0]),
      (_ = array_slice(t, 1, s)),
      (a = 3 * t[1 + s]),
      (n = array_slice(t, 1 + s + 1, a)),
      (i = {}),
      (i.in_send_setting = _),
      (i.in_receive_setting = n),
      syslog('InGetRCT end'),
      i
    );
  }
  async ReadRegister(e, r) {
    let t, o, i;
    return (
      syslog('ReadRegister begin'),
      null == e || null == r
        ? ((t = 'Invalid parameter'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 1865)))
        : ((o = new Uint8Array(19)),
          (o[0] = NFCPort100.NFC100_COMMAND_CODE),
          (o[1] = NFCPort100.SCC_READ_REGISTER),
          array_copy(o, 2, e, 0, e.length),
          (o[18] = r),
          (i = await this.tranceive_ext(o).then((e) =>
            5 != e.length
              ? ((t = 'Illegal response length'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1881),
                ))
              : e[0] != NFCPort100.NFC100_RESPONSE_CODE
              ? ((t = 'Illegal response code'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1885),
                ))
              : e[1] != NFCPort100.SRC_READ_REGISTER
              ? ((t = 'Illegal sub-response code'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1889),
                ))
              : e[2] != NFCPort100.STATUS_SUCCESS
              ? ((t = 'Invalid Status'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_STATUS, t, this._fileName, 1893),
                ))
              : e[3] != r
              ? ((t = 'Invalid read address'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1897),
                ))
              : e[4],
          )),
          syslog('ReadRegister end'),
          i)
    );
  }
  async InCommRF(e, r) {
    let t, o, i, s, _, a, n;
    return (
      syslog('InCommRF begin'),
      null == e || null == r
        ? ((t = 'Invalid parameter'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 1923)))
        : (syslog('SendData : ' + array_tohexs(e)),
          (o = new Uint8Array(4 + e.length)),
          (o[0] = NFCPort100.NFC100_COMMAND_CODE),
          (o[1] = NFCPort100.SCC_IN_COMM_RF),
          array_set_uint16l(o, 2, 10 * r),
          array_copy(o, 4, e, 0, e.length),
          (i = await this.tranceive_ext(o).then((e) => {
            if (e.length < 6)
              return (
                (t = 'Illegal response length'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1940),
                )
              );
            if (e[0] != NFCPort100.NFC100_RESPONSE_CODE)
              return (
                (t = 'Illegal response code'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1944),
                )
              );
            if (e[1] != NFCPort100.SRC_IN_COMM_RF)
              return (
                (t = 'Illegal sub-response code'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1948),
                )
              );
            if (0 != array_get_uint32l(e, 2))
              return (
                (s = array_get_uint32l(e, 2)),
                s == NFCPort100.COM_STATUS_TEMPERATURE_ERROR ||
                s == NFCPort100.COM_STATUS_INTTEMPRFOFF_ERROR
                  ? ((_ = NFCPortError.DEVICE_FATAL_ERROR),
                    (t = 'Invalid Status(Temperature error)'),
                    (this.bAbnormalState = !0))
                  : s == NFCPort100.COM_STATUS_REC_TIMEOUT_ERROR
                  ? ((_ = NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED),
                    (t = 'Invalid Status(Card response timeout)'))
                  : ((_ = NFCPortError.INVALID_STATUS), (t = 'Invalid Status')),
                syslog(t),
                (a = new NFCPortError(_, t, this._fileName, 1964)),
                (a.communicationStatus = s),
                Promise.reject(a)
              );
            if (6 == e.length)
              return (
                (t = 'Illegal response length'),
                syslog(t),
                Promise.reject(
                  new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1970),
                )
              );
            if (
              8 != e[6] &&
              (this.protocol == NFCPort100.PROTOCOL_ISO14443_3A ||
                this.protocol == NFCPort100.PROTOCOL_ISO14443_4A)
            ) {
              const r = e[6];
              if (!(1 <= r && r <= 7 && e.length > 7))
                return (
                  (t = 'Illegal RxLastBit'),
                  syslog(t),
                  Promise.reject(
                    new NFCPortError(NFCPortError.INVALID_RESPONSE, t, this._fileName, 1979),
                  )
                );
              {
                const t = (1 << r) - 1;
                e[e.length - 1] &= t;
              }
            }
            return (
              (n = new Array(e.length - 7)),
              e.length - 7 > 0 && array_copy(n, 0, e, 7, e.length - 7),
              syslog('Response :' + array_tohexs(n)),
              n
            );
          })),
          syslog('InCommRF end'),
          i)
    );
  }
  async typea_reqa_wupa() {
    syslog('typea_reqa_wupa begin');
    const e = [NFCPort100.NFC100_TYPEA_CMD_REQA];
    let r, t, o;
    await this.InSetProtocol([1, 0, 2, 0, 4, 0, 5, 1, 6, 0, 7, 7, 8, 0]),
      (r = NFC100_TYPEA_FDT_LISTEN_MAX);
    try {
      t = await this.InCommRF(e, r);
    } catch (e) {
      return (
        e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
          ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
        Promise.reject(e)
      );
    }
    return 2 != t.length
      ? ((o = 'Invalid ATQA length'),
        syslog('Invalid ATQA length'),
        Promise.reject(
          new NFCPortError(
            NFCPortError.CARD_NOT_DETECTED,
            'Invalid ATQA length',
            this._fileName,
            2034,
          ),
        ))
      : (syslog('typea_reqa_wupa ATQA=' + array_tohexs(t)), syslog('typea_reqa_wupa end'), t);
  }
  async typea_anticollision() {
    syslog('typea_anticollision begin');
    const e = [1, 1, 2, 1, 6, 0],
      r = [1, 0, 2, 0, 6, 1];
    let t, o, i, s, _, a, n, l, N;
    for (
      await this.InSetProtocol([4, 1, 6, 1, 7, 8]), t = NFC100_TYPEA_FDT_LISTEN_MAX, o = [], i = 1;
      i <= 3;
      i++
    ) {
      (s = NFCPort100.NFC100_TYPEA_CMD_SEL_CL_1),
        2 == i
          ? (s = NFCPort100.NFC100_TYPEA_CMD_SEL_CL_2)
          : 3 == i && (s = NFCPort100.NFC100_TYPEA_CMD_SEL_CL_3),
        (_ = [s, 32]);
      try {
        a = await this.InCommRF(_, t);
      } catch (e) {
        return (
          e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
            ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
          Promise.reject(e)
        );
      }
      if (5 != a.length)
        return (
          (n = 'Invalid uid-cln length'),
          syslog(n),
          Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, n, this._fileName, 2104))
        );
      await this.InSetProtocol(e), (l = [s, 112, 0, 0, 0, 0, 0]), array_copy(l, 2, a, 0, a.length);
      try {
        N = await this.InCommRF(l, t);
      } catch (e) {
        return (
          e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
            ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
          Promise.reject(e)
        );
      }
      if (1 != N.length)
        return (
          (n = 'Invalid SAK length'),
          syslog(n),
          Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, n, this._fileName, 2127))
        );
      if (0 == (4 & N[0])) {
        array_append(o, a, 0, 4);
        break;
      }
      array_append(o, a, 1, 3), await this.InSetProtocol(r);
    }
    return (
      syslog('typea_anticollision SAK=' + array_tohexs(N)),
      syslog('typea_anticollision UID=' + array_tohexs(o)),
      syslog('typea_anticollision end'),
      new Uint8Array(o)
    );
  }
  async typea_iso14443_4_activate(e, r, t) {
    let o, i, s, _, a, n, l, N, C, c, E, T, P, I, F, u, g;
    syslog('typea_iso14443_4_activate begin'),
      (o = [224, 0]),
      (o[1] = ((e << 4) & 240) | (15 & r)),
      (i = ISO14443_4_CC_UNIT_MS(71680)),
      (i += 20);
    try {
      s = await this.InCommRF(o, i);
    } catch (e) {
      return (
        e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
          ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
        Promise.reject(e)
      );
    }
    if ((syslog('typea_iso14443_4_activate ATS=' + array_tohexs(s)), s.length < 0))
      return (
        (_ = 'Invalid ATS length'),
        syslog(_),
        Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, _, this._fileName, 2189))
      );
    if (s.length < 5 || s[0] < 5)
      return (
        (_ = 'Invalid ATS length'),
        syslog(_),
        Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, _, this._fileName, 2196))
      );
    if (
      ((a = 15 & s[1]),
      (n = (s[2] >> 4) & 3),
      (l = 3 & s[2]),
      await wait_async(NFCPort100.ISO14443_4_CC_DEFAULT_SFGI),
      (P = NFCPort100.BAUDRATE_106K),
      t &&
        (0 != n || 0 != l) &&
        (0 != (2 & n) && 0 != (2 & l)
          ? ((N = 2), (C = 2))
          : 0 != (1 & n) && 0 != (1 & l)
          ? ((N = 1), (C = 1))
          : ((N = 0), (C = 0)),
        0 != N || 0 != C))
    ) {
      (c = [208, 17, 0]),
        (c[0] = 208 + (15 & r)),
        (c[2] = ((N << 2) & 12) | (3 & C)),
        (E = 4),
        (i = ISO14443_4_CC_FWT(4)),
        (i += ISO14443_4_CC_FWT_DELTA),
        (i += 20);
      try {
        T = await this.InCommRF(c, i);
      } catch (e) {
        return (
          e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
            ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
          Promise.reject(e)
        );
      }
      if ((syslog('typea_iso14443_4_activate PPSRES=' + array_tohexs(T)), 1 != T.length))
        return (
          (_ = 'Invalid PPS-Response length'),
          syslog(_),
          Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, _, this._fileName, 2240))
        );
      if (208 != (240 & T[0]))
        return (
          (_ = 'PPS-Response status error'),
          syslog(_),
          Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, _, this._fileName, 2244))
        );
      2 == N
        ? ((I = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_424K),
          (F = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_424K),
          (P = NFCPort100.BAUDRATE_424K))
        : 1 == N
        ? ((I = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_212K),
          (F = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_212K),
          (P = NFCPort100.BAUDRATE_212K))
        : ((I = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_106K),
          (F = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_106K)),
        2 == C
          ? ((u = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_424K),
            (g = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_424K))
          : 1 == C
          ? ((u = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_212K),
            (g = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_212K))
          : ((u = NFCPort100.NFC100_RBT_INITIATOR_ISO14443A_106K),
            (g = NFCPort100.NFC100_RF_INITIATOR_ISO14443A_106K)),
        await this.InSetRF(I, F, u, g);
    }
    (super._targetCardBaudRate = P), syslog('typea_iso14443_4_activate end');
  }
  async typea_mifareAuth(e) {
    syslog('typea_mifareAuth begin');
    let r, t, o, i;
    if (null == e)
      return (
        (r = 'Invalid parameter'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 2299))
      );
    if (null == e.key || 6 != e.key.length)
      return (
        (r = 'Invalid key length'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 2303))
      );
    if ('KeyA' != e.keyType && 'KeyB' != e.keyType)
      return (
        (r = 'Invalid keyType'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 2307))
      );
    if (e.blockNumber < 0 || e.blockNumber > 255)
      return (
        (r = 'Invalid blockNumber'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 2311))
      );
    if (null == e.uid || 0 == e.uid.length)
      return (
        (r = 'Invalid uid'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 2315))
      );
    await this.InSetProtocol([8, 1]),
      (t = [96, e.blockNumber, 0, 0, 0, 0, 0, 0]),
      'KeyA' == e.keyType ? (t[0] = 96) : (t[0] = 97),
      array_copy(t, 2, e.key, 0, 6),
      array_append(t, e.uid),
      (o = NFCPort100.COMMUNICATE_THRU_DEFAULT_TIMEOUT);
    try {
      if (((i = await this.InCommRF(t, o)), 0 != i.length))
        return (
          (r = 'Invalid Mifare authentication response length'),
          syslog(r),
          Promise.reject(
            new NFCPortError(NFCPortError.AUTHENTICATION_ERROR, r, this._fileName, 2336),
          )
        );
    } catch (e) {
      return (
        (r = 'mifare authentication error'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.AUTHENTICATION_ERROR, r, this._fileName, 2343))
      );
    }
    syslog('typea_mifareAuth end');
  }
  async typeb_reqb_wupb(e, r, t, o) {
    let i, s, _, a, n, l, N, C;
    syslog('typeb_reqb_wupb begin'),
      (i = [NFCPort100.NFC100_TYPEB_CMD_REQB, e, 0]),
      r || (i[2] |= 8),
      t && (i[2] |= 16),
      o && (i[2] |= 32),
      (s = ISO14443_4_CC_UNIT_MS(7680)),
      (s += 20);
    try {
      _ = await this.InCommRF(i, s);
    } catch (e) {
      return (
        e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
          ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
        Promise.reject(e)
      );
    }
    return (
      syslog('typeb_reqb_wupb ATQB=' + array_tohexs(_)),
      (t && 12 != _.length && 13 != _.length) || (!t && 12 != _.length)
        ? ((a = 'Invalid ATQB length'),
          syslog('Invalid ATQB length'),
          Promise.reject(
            new NFCPortError(
              NFCPortError.CARD_NOT_DETECTED,
              'Invalid ATQB length',
              this._fileName,
              2397,
            ),
          ))
        : ((n = array_slice(_, 1, 4)),
          (l = array_slice(_, 5, 4)),
          (N = array_slice(_, 9, _.length - 9)),
          (C = new TypebCard(n, l, N)),
          syslog('pupi : ' + array_tohexs(n)),
          syslog('application_data : ' + array_tohexs(l)),
          syslog('protocol_info : ' + array_tohexs(N)),
          syslog('typeb_reqb_wupb end'),
          C)
    );
  }
  async typeb_iso14443_4_activate(e, r, t, o, i, s) {
    let _, a, n, l, N, C, c, E, T, P, I, F, u, g, R;
    syslog('typeb_iso14443_4_activate begin'),
      syslog('  param pupi : ' + array_tohexs(e)),
      syslog('  param fsdi : ' + r),
      syslog('  param protocol_info : ' + array_tohexs(t)),
      syslog('  param cid=' + o),
      syslog('  param autoBaudRate=' + s),
      (_ = (t[0] >> 4) & 3),
      (a = 3 & t[0]),
      (n = 0),
      (l = 0),
      !s ||
        (0 == _ && 0 == a) ||
        (0 != (2 & _) && 0 != (2 & a)
          ? ((n = 2), (l = 2))
          : 0 != (1 & _) && 0 != (1 & a) && ((n = 1), (l = 1))),
      (N = 9),
      null != i && 0 != i.length
        ? (syslog('  param higher_layer_inf=' + array_tohexs(i)), (N += i.length))
        : syslog('  param higher_layer_inf=(null)'),
      (C = new Uint8Array(N)),
      (C[0] = NFCPort100.NFC100_TYPEB_CMD_ATTRIB),
      array_copy(C, 1, e, 0, 4),
      (C[5] = 0),
      (C[6] = ((n << 6) & 192) | ((l << 4) & 48) | r),
      (C[7] = 15 & t[1]),
      (C[8] = o),
      null != i && 0 != i.length && array_copy(C, 9, i, 0, i.length),
      (c = 4),
      (E = ISO14443_4_CC_FWT(4)),
      (E += ISO14443_4_CC_FWT_DELTA),
      (E += 20);
    try {
      T = await this.InCommRF(C, E);
    } catch (e) {
      return (
        e.errorType == NFCPortError.THRU_RESPONSE_PACKET_NOT_RECEIVED &&
          ((e.errorType = NFCPortError.CARD_NOT_DETECTED), (e.message = 'Card not detected')),
        Promise.reject(e)
      );
    }
    if (
      (syslog('typeb attrib_data=' + array_tohexs(T)),
      T.length > NFCPort100.TYPEB_CARD_MAX_ATTRIB_LEN)
    )
      return (
        (P = 'Invalid attrib_data length'),
        syslog(P),
        Promise.reject(new NFCPortError(NFCPortError.CARD_NOT_DETECTED, P, this._fileName, 2494))
      );
    (I = NFCPort100.BAUDRATE_106K),
      (0 == n && 0 == l) ||
        (2 == n
          ? ((F = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_424K),
            (u = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_424K),
            (I = NFCPort100.BAUDRATE_424K))
          : 1 == n
          ? ((F = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_212K),
            (u = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_212K),
            (I = NFCPort100.BAUDRATE_212K))
          : ((F = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_106K),
            (u = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_106K)),
        2 == l
          ? ((g = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_424K),
            (R = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_424K))
          : 1 == l
          ? ((g = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_212K),
            (R = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_212K))
          : ((g = NFCPort100.NFC100_RBT_INITIATOR_ISO14443B_106K),
            (R = NFCPort100.NFC100_RF_INITIATOR_ISO14443B_106K)),
        await this.InSetRF(F, u, g, R)),
      (super._targetCardBaudRate = I),
      await wait_async(NFCPort100.ISO14443_4_CC_DEFAULT_SFGI),
      syslog('typeb_iso14443_4_activate end');
  }
  async setCommunicateThruProtocol(e) {
    syslog('setCommunicateThruProtocol begin');
    let r = NFCPort100.felica_default_protocol;
    this.protocol == NFCPort100.PROTOCOL_FELICA || this.protocol == NFCPort100.PROTOCOL_ISO18092
      ? (r = NFCPort100.felica_default_protocol)
      : this.protocol == NFCPort100.PROTOCOL_ISO14443_3A ||
        this.protocol == NFCPort100.PROTOCOL_ISO14443_4A
      ? (r = NFCPort100.typea_default_protocol)
      : this.protocol == NFCPort100.PROTOCOL_ISO14443_4B && (r = NFCPort100.typeb_default_protocol);
    let t = [1, r[3], 2, r[5], 4, r[9], 5, r[11], 7, r[15]];
    if (null != e) {
      let r = 1;
      if (
        (null != e.appendCrc && (e.appendCrc ? (t[r] = 1) : (t[r] = 0)),
        (r += 2),
        null != e.discardCrc && (e.discardCrc ? (t[r] = 1) : (t[r] = 0)),
        (r += 2),
        null != e.insertParity && (e.insertParity ? (t[r] = 1) : (t[r] = 0)),
        (r += 2),
        null != e.expectParity && (e.expectParity ? (t[r] = 1) : (t[r] = 0)),
        (r += 2),
        null != e.txNumberOfValidBits)
      ) {
        if (!(1 <= e.txNumberOfValidBits && e.txNumberOfValidBits <= 8)) {
          const e = 'Invalid TX number of valid bits';
          return (
            syslog(e),
            Promise.reject(
              new NFCPortError(NFCPortError.INVALID_PARAMETER, e, this._fileName, 2609),
            )
          );
        }
        t[r] = e.txNumberOfValidBits;
      }
    }
    await this.InSetProtocol(t), syslog('setCommunicateThruProtocol end');
  }
  getUpdateProtocol(e) {
    if (this.currentProtocol.length == e.length / 2 + 1) return e;
    {
      let r = new Array();
      for (let t = 0; t < e.length; t += 2)
        (null != this.currentProtocol[e[t]] && this.currentProtocol[e[t]] == e[t + 1]) ||
          r.push(e[t], e[t + 1]);
      return r;
    }
  }
  setCurrentProtocol(e) {
    for (let r = 0; r < e.length; r += 2) this.currentProtocol[e[r]] = e[r + 1];
  }
}
const NFCPORTLIB_STATUS_UNINITIALIZED = 'S0',
  NFCPORTLIB_STATUS_INITIALIZED = 'S1',
  NFCPORTLIB_STATUS_OPENED = 'S2',
  NFCPORTLIB_STATUS_CLOSED = 'S3',
  NFCPORTLIB_STATUS_ABNORMAL = 'S4',
  COMMUNICATE_THRU_TIMEOUT_MIN = 0,
  COMMUNICATE_THRU_TIMEOUT_MAX = 6553,
  COMMUNICATE_THRU_TIMEOUT_VALID_MIN = 400,
  CONFIGURATION_ACK_TIMEOUT_MIN = 0,
  CONFIGURATION_ACK_TIMEOUT_MAX = 6553,
  CONFIGURATION_ACK_TIMEOUT_DEFAULT = 1e3,
  CONFIGURATION_ACK_TIMEOUT_VALID_MIN = 400,
  CONFIGURATION_RECEIVE_TIMEOUT_MIN = 0,
  CONFIGURATION_RECEIVE_TIMEOUT_MAX = 6553,
  CONFIGURATION_RECEIVE_TIMEOUT_DEFAULT = 1e3,
  CONFIGURATION_RECEIVE_TIMEOUT_VALID_MIN = 400,
  CONFIGURATION_AUTO_BAUDRATE_DEFAULT = !1,
  CONFIGURATION_AUTO_DEVICE_SELECT_DEFAULT = !0,
  DETECT_OPTION_SYSCODE_DEFAULT = new Uint8Array([255, 255]),
  DETECT_OPTION_TIMESLOT_DEFAULT = 0,
  DETECT_OPTION_REQUEST_SYSCODE_DEFAULT = !0,
  DETECT_OPTION_REQUEST_BAUDRATE_DEFAULT = !1;
var NFCPortLib_calling_flag = !1;
class NFCPortLib {
  constructor() {
    (this._fileName = 'NFCPortLib.js'),
      (this.deviceName = null),
      (this.targetCardBaudRate = null),
      (this._rw = null),
      (this._config = null),
      (this._status = 'S0'),
      'undefined' != typeof NFCPortLibDebugMode && enableDebugLog();
  }
  async init(e) {
    let r;
    if ((syslog('init begin'), NFCPortLib_calling_flag))
      return (
        (r = 'A call occurred during a library call.'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.API_CALL_IN_PROGRESS, r, this._fileName, 96))
      );
    NFCPortLib_calling_flag = !0;
    try {
      await this.init_internal(e);
    } catch (e) {
      return (NFCPortLib_calling_flag = !1), Promise.reject(e);
    }
    (NFCPortLib_calling_flag = !1), syslog('init end');
  }
  async open() {
    let e;
    if ((syslog('open begin'), NFCPortLib_calling_flag))
      return (
        (e = 'A call occurred during a library call.'),
        syslog(e),
        Promise.reject(new NFCPortError(NFCPortError.API_CALL_IN_PROGRESS, e, this._fileName, 138))
      );
    NFCPortLib_calling_flag = !0;
    try {
      await this.open_internal();
    } catch (e) {
      return (NFCPortLib_calling_flag = !1), Promise.reject(e);
    }
    (NFCPortLib_calling_flag = !1), syslog('open end');
  }
  async detectCard(e, r) {
    let t, o;
    if ((syslog('detectCard begin'), NFCPortLib_calling_flag))
      return (
        (t = 'A call occurred during a library call.'),
        syslog(t),
        Promise.reject(new NFCPortError(NFCPortError.API_CALL_IN_PROGRESS, t, this._fileName, 192))
      );
    NFCPortLib_calling_flag = !0;
    try {
      o = await this.detectCard_internal(e, r);
    } catch (e) {
      return (NFCPortLib_calling_flag = !1), Promise.reject(e);
    }
    return (NFCPortLib_calling_flag = !1), syslog('detectCard end'), o;
  }
  async communicateThru(e, r, t) {
    let o, i;
    if ((syslog('communicateThru begin'), NFCPortLib_calling_flag))
      return (
        (i = 'A call occurred during a library call.'),
        syslog(i),
        Promise.reject(new NFCPortError(NFCPortError.API_CALL_IN_PROGRESS, i, this._fileName, 244))
      );
    NFCPortLib_calling_flag = !0;
    try {
      o = await this.communicateThru_internal(e, r, t);
    } catch (e) {
      return (NFCPortLib_calling_flag = !1), Promise.reject(e);
    }
    return (NFCPortLib_calling_flag = !1), syslog('communicateThru end'), o;
  }
  async switchRF(e) {
    let r;
    if ((syslog('switchRF begin'), NFCPortLib_calling_flag))
      return (
        (r = 'A call occurred during a library call.'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.API_CALL_IN_PROGRESS, r, this._fileName, 291))
      );
    NFCPortLib_calling_flag = !0;
    try {
      await this.switchRF_internal(e);
    } catch (e) {
      return (NFCPortLib_calling_flag = !1), Promise.reject(e);
    }
    (NFCPortLib_calling_flag = !1), syslog('switchRF end');
  }
  async close() {
    let e;
    if ((syslog('close begin'), NFCPortLib_calling_flag))
      return (
        (e = 'A call occurred during a library call.'),
        syslog(e),
        Promise.reject(new NFCPortError(NFCPortError.API_CALL_IN_PROGRESS, e, this._fileName, 332))
      );
    NFCPortLib_calling_flag = !0;
    try {
      await this.close_internal();
    } catch (e) {
      return (NFCPortLib_calling_flag = !1), Promise.reject(e);
    }
    (NFCPortLib_calling_flag = !1), syslog('close end');
  }
  async init_internal(e) {
    let r;
    if ((syslog('init_internal begin'), null != e)) {
      if ('object' != typeof e)
        return (
          (r = 'The config is incorrect type.'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 366))
        );
      if (null != e.ackTimeout && 'number' != typeof e.ackTimeout)
        return (
          (r = 'config.ackTimeout is incorrect type'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 370))
        );
      if (null != e.ackTimeout && (e.ackTimeout < 0 || e.ackTimeout > 6553))
        return (
          (r = 'config.ackTimeout is out of range'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 375))
        );
      if (null != e.receiveTimeout && 'number' != typeof e.receiveTimeout)
        return (
          (r = 'config.receiveTimeout is incorrect type'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 379))
        );
      if (null != e.receiveTimeout && (e.receiveTimeout < 0 || e.receiveTimeout > 6553))
        return (
          (r = 'config.receiveTimeout is out of range'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 384))
        );
      if (null != e.autoBaudRate && 'boolean' != typeof e.autoBaudRate)
        return (
          (r = 'config.autoBaudRate is incorrect type'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 388))
        );
      if (null != e.autoDeviceSelect && 'boolean' != typeof e.autoDeviceSelect)
        return (
          (r = 'config.autoDeviceSelect is incorrect type'),
          syslog(r),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 392))
        );
    }
    if (
      (null == e
        ? (e = new Configuration(1e3, 1e3, !1, !0))
        : (null != e.ackTimeout ? e.ackTimeout < 400 && (e.ackTimeout = 400) : (e.ackTimeout = 1e3),
          null != e.receiveTimeout
            ? e.receiveTimeout < 400 && (e.receiveTimeout = 400)
            : (e.receiveTimeout = 1e3),
          null == e.autoBaudRate && (e.autoBaudRate = !1),
          null == e.autoDeviceSelect && (e.autoDeviceSelect = !0)),
      null != this._rw)
    )
      try {
        await this._rw.close();
      } catch (e) {}
    (this._rw = null),
      (this.deviceName = null),
      (this.targetCardBaudRate = null),
      (this._config = e),
      (this._status = 'S1'),
      syslog('init_internal end');
  }
  async open_internal() {
    let e, r;
    if ((syslog('open_internal begin'), 'S1' != this._status && 'S3' != this._status))
      return (
        (r = 'Can not callout Library Status (Status=' + this._status + ')'),
        syslog(r),
        Promise.reject(
          new NFCPortError(NFCPortError.CANTCALLOUT_LIBRARY_STATUS, r, this._fileName, 482),
        )
      );
    e = new NFCPort100();
    try {
      await e.init(this._config), await e.open();
    } catch (e) {
      return Promise.reject(e);
    }
    (this.deviceName = e.deviceName),
      (this._rw = e),
      (this._status = 'S2'),
      syslog('open_internal end');
  }
  async detectCard_internal(e, r) {
    let t, o;
    if ((syslog('detectCard_internal begin'), null == e))
      return (
        (t = 'The protocol is not specified.'),
        syslog(t),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 499))
      );
    if ('string' != typeof e)
      return (
        (t = 'The protocol is incorrect type.'),
        syslog(t),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 503))
      );
    if (null != r) {
      if ('object' != typeof r)
        return (
          (t = 'The option is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 508))
        );
      if (
        null != r.systemCode &&
        ('object' != typeof r.systemCode ||
          'Uint8Array' != r.systemCode.constructor.name ||
          (0 != r.systemCode.length && 2 != r.systemCode.length))
      )
        return (
          (t = 'The option.systemCode is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 515))
        );
      if (null != r.timeSlot && 'number' != typeof r.timeSlot)
        return (
          (t = 'The option.timeSlot is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 519))
        );
      if (null != r.requestSystemCode && 'boolean' != typeof r.requestSystemCode)
        return (
          (t = 'The option.requestSystemCode is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 523))
        );
      if (null != r.requestBaudRate && 'boolean' != typeof r.requestBaudRate)
        return (
          (t = 'The option.requestBaudRate is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 527))
        );
      if (null != r.fsdi && 'number' != typeof r.fsdi)
        return (
          (t = 'The option.fsdi is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 531))
        );
      if (null != r.cid && 'number' != typeof r.cid)
        return (
          (t = 'The option.cid is incorrect type.'),
          syslog(t),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, t, this._fileName, 535))
        );
    }
    if (
      (null == r
        ? (r = new DetectionOption(DETECT_OPTION_SYSCODE_DEFAULT, 0, !0, !1))
        : (null == r.systemCode && (r.systemCode = DETECT_OPTION_SYSCODE_DEFAULT),
          null == r.timeSlot && (r.timeSlot = 0),
          null == r.requestSystemCode && (r.requestSystemCode = !0),
          null == r.requestBaudRate && (r.requestBaudRate = !1)),
      'S2' != this._status)
    )
      return (
        (t = 'Can not callout Library Status (Status=' + this._status + ')'),
        syslog(t),
        Promise.reject(
          new NFCPortError(NFCPortError.CANTCALLOUT_LIBRARY_STATUS, t, this._fileName, 586),
        )
      );
    try {
      o = await this._rw.detectCard(e, r);
    } catch (e) {
      return this._rw.bAbnormalState && (this._status = 'S4'), Promise.reject(e);
    }
    return (
      (this.targetCardBaudRate = this._rw.targetCardBaudRate), syslog('detectCard_internal end'), o
    );
  }
  async communicateThru_internal(e, r, t) {
    let o, i;
    if ((syslog('communicateThru_internal begin'), null != e)) {
      if ('object' != typeof e || 'Uint8Array' != e.constructor.name)
        return (
          (i = 'The command is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 604))
        );
      if (null == r)
        return (
          (i = 'The timeout is not specified.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 608))
        );
      if ('number' != typeof r)
        return (
          (i = 'The timeout is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 612))
        );
      if (r < 0 || r > 6553)
        return (
          (i = 'The timeout is out of range'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 616))
        );
      r < 400 && (r = 400);
    } else if (null == t)
      return (
        (i = 'The command is not specified'),
        syslog(i),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 628))
      );
    if (null != t) {
      if ('object' != typeof t)
        return (
          (i = 'The option is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 637))
        );
      if (null != t.mifareAuthentication && 'boolean' != typeof t.mifareAuthentication)
        return (
          (i = 'The option.mifareAuthentication is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 641))
        );
      if (null != t.key && ('object' != typeof t.key || 'Uint8Array' != t.key.constructor.name))
        return (
          (i = 'The option.key is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 645))
        );
      if (null != t.keyType && 'string' != typeof t.keyType)
        return (
          (i = 'The option.keyType is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 649))
        );
      if (null != t.blockNumber && 'number' != typeof t.blockNumber)
        return (
          (i = 'The option.blockNumber is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 653))
        );
      if (null != t.uid && ('object' != typeof t.uid || 'Uint8Array' != t.uid.constructor.name))
        return (
          (i = 'The option.uid is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 657))
        );
      if (null != t.appendCrc && 'boolean' != typeof t.appendCrc)
        return (
          (i = 'The option.appendCrc is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 661))
        );
      if (null != t.discardCrc && 'boolean' != typeof t.discardCrc)
        return (
          (i = 'The option.discardCrc is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 665))
        );
      if (null != t.insertParity && 'boolean' != typeof t.insertParity)
        return (
          (i = 'The option.insertParity is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 669))
        );
      if (null != t.expectParity && 'boolean' != typeof t.expectParity)
        return (
          (i = 'The option.expectParity is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 673))
        );
      if (null != t.txNumberOfValidBits && 'number' != typeof t.txNumberOfValidBits)
        return (
          (i = 'The option.txNumberOfValidBits is incorrect type.'),
          syslog(i),
          Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, i, this._fileName, 677))
        );
    }
    if ('S2' != this._status)
      return (
        (i = 'Can not callout Library Status (Status=' + this._status + ')'),
        syslog(i),
        Promise.reject(
          new NFCPortError(NFCPortError.CANTCALLOUT_LIBRARY_STATUS, i, this._fileName, 697),
        )
      );
    try {
      o = await this._rw.communicateThru(e, r, t);
    } catch (e) {
      return this._rw.bAbnormalState && (this._status = 'S4'), Promise.reject(e);
    }
    return syslog('communicateThru_internal end'), o;
  }
  async switchRF_internal(e) {
    let r;
    if ((syslog('switchRF_internal begin'), null == e))
      return (
        (r = 'Parameter is not specified.'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 713))
      );
    if ('boolean' != typeof e)
      return (
        (r = 'Parameter is incorrect type.'),
        syslog(r),
        Promise.reject(new NFCPortError(NFCPortError.INVALID_PARAMETER, r, this._fileName, 717))
      );
    if ('S2' != this._status)
      return (
        (r = 'Can not callout Library Status (Status=' + this._status + ')'),
        syslog(r),
        Promise.reject(
          new NFCPortError(NFCPortError.CANTCALLOUT_LIBRARY_STATUS, r, this._fileName, 737),
        )
      );
    try {
      await this._rw.switchRF(e);
    } catch (e) {
      return this._rw.bAbnormalState && (this._status = 'S4'), Promise.reject(e);
    }
    syslog('switchRF_internal end');
  }
  async close_internal() {
    let e;
    if ((syslog('close_internal begin'), 'S2' == this._status || 'S4' == this._status)) {
      if (null != this._rw) {
        try {
          await this._rw.close();
        } catch (e) {
          syslog(e.message);
        }
        this._rw = null;
      }
      this._status = 'S3';
    } else (e = 'Do nothing Library Status (Status=' + this._status + ')'), syslog(e);
    syslog('close_internal end');
  }
}
