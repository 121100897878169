export const categoryItems = [
  { text: "", value: "" },
  { text: "出席簿(日次)", value: "attendance" },
  { text: "出席簿(月次)", value: "attendanceSummary" },
  { text: "クラス日誌", value: "classDiary" },
  { text: "午睡", value: "nap" },
  { text: "成長記録", value: "growth" },
  { text: "月次スタッフ予定", value: "shiftPatternMonthly" },
  { text: "日次スタッフ予定", value: "shiftPatternDaily" },
];

export const statusItems = [
  { text: "", value: "" },
  { text: "完了", value: "completed" },
  { text: "処理中", value: "inProgress" },
  { text: "タイムアウト", value: "timeout" },
  { text: "失敗", value: "failed" },
];
