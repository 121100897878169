
import Vue from "vue";

import PageTitle from "@/components/atoms/PageTitle.vue";
import SchoolServiceCreateContainer from "@/containers/school-service/SchoolServiceCreateContainer.vue";
import SchoolServiceEditContainer from "@/containers/school-service/SchoolServiceEditContainer.vue";
import SchoolServiceDailyContianer from "@/containers/school-service/SchoolServiceDailyContianer.vue";
import SchoolServiceMonthlyContainer from "@/containers/school-service/SchoolServiceMonthlyContainer.vue";
import dayjs from "dayjs";
import LocalDataService from "@/service/LocalDataService";
import CommonUtil from "@/utils/CommonUtil";
import { classModule } from "@/store/dataModules/classModule";

interface DataType {
  year: number;
}

export default Vue.extend({
  components: {
    PageTitle,
    SchoolServiceDailyContianer,
    SchoolServiceCreateContainer,
    SchoolServiceEditContainer,
    SchoolServiceMonthlyContainer,
  },
  data: (): DataType => ({
    year: dayjs().year(),
  }),
  methods: {
    listClasses() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        classModule.listClass({
          nurseryId: nurseryId,
          year: this.year,
        });
      }
    },
  },
  watch: {
    year() {
      this.listClasses();
    },
  },
  async mounted() {
    this.year = parseInt(CommonUtil.getFiscalYear(dayjs().format("YYYY-MM")));
    this.listClasses();
  },
});
