import ApiClient from "@/api/ApiClient";
import {
  ChildDevelopmentClassesResponse,
  ChildDevelopmentImportFieldsResponse,
  ChildDevelopmentListResponse,
  ChildDevelopmentMilestoneSchema,
  PreSignedUrlResponse,
} from "chaild-api/lib";

export type ChildDevelopmentUpdateRequestBody = {
  childDevelopmentId: number;
  milestone: {
    prevYears: "done" | "close" | null;
  } & ChildDevelopmentMilestoneSchema;
};

/* eslint-disable */
export default class ApiChildDevelopment {
  public static async listChildDevelopments({
    nurseryId,
    businessYear,
  }: {
    nurseryId: number;
    businessYear: number;
  }) {
    const response = await ApiClient.get(
      `/child-development/${nurseryId}/${businessYear}/classes`,
      null
    );
    return response as ChildDevelopmentClassesResponse;
  }

  public static async updateAnalytics({
    nurseryId,
    businessYear,
  }: {
    nurseryId: number;
    businessYear: number;
  }) {
    const response = await ApiClient.post(
      `/child-development/${nurseryId}/${businessYear}/classes`,
      null
    );
    return response as ChildDevelopmentClassesResponse;
  }

  public static async getChildDevelopments({
    nurseryId,
    businessYear,
    childId,
  }: {
    nurseryId: number;
    businessYear: number;
    childId: number;
  }) {
    const response = await ApiClient.get(
      `/child-development/${nurseryId}/${businessYear}/children/${childId}`,
      null
    );
    return response as ChildDevelopmentListResponse;
  }

  public static async updateChildDevelopments({
    nurseryId,
    businessYear,
    childId,
    body,
  }: {
    nurseryId: number;
    businessYear: number;
    childId: number;
    body: ChildDevelopmentUpdateRequestBody[];
  }) {
    const response = await ApiClient.put(
      `/child-development/${nurseryId}/${businessYear}/children/${childId}`,
      body
    );
    return response as ChildDevelopmentListResponse;
  }

  public static async getImportUploadUrl({
    nurseryId,
    fileType,
  }: {
    nurseryId: number;
    fileType: string;
  }) {
    const response = await ApiClient.post(
      `/child-development/${nurseryId}/fields/import`,
      {
        fileType,
      }
    );
    return response as ChildDevelopmentImportFieldsResponse;
  }

  public static async getExportUrl({
    nurseryId,
    fileType,
  }: {
    nurseryId: number;
    fileType: string;
  }) {
    const response = await ApiClient.post(
      `/child-development/${nurseryId}/fields/export`,
      {
        fileType,
      }
    );
    return response as PreSignedUrlResponse;
  }
}
