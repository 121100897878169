
import Vue from 'vue'

import AttendanceScheduleContainer from '@/containers/work-schedule/AttendanceScheduleContainer.vue'

export default Vue.extend({
  components: {
    AttendanceScheduleContainer,
},
})

