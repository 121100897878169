
import Vue from 'vue';
import ShiftPatternContainer from '@/containers/work-schedule/ShiftPatternContainer.vue';
import WorkDescriptionContainer from '@/containers/work-schedule/WorkDescriptionContainer.vue';

export default Vue.extend({
    data: () => {
        return {
            tab: 0,
        };
    },
    components: { 
      ShiftPatternContainer,
      WorkDescriptionContainer
    }
})
