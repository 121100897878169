
import Vue from "vue";
import ApiLetter from "@/api/ApiLetter";
import LocalDataService from "@/service/LocalDataService";
import { LetterListResponse, LetterTemplateResponse } from "chaild-api/lib";
import { monthItems } from "@/constants/letter";

export default Vue.extend({
  name: "LetterList",

  async mounted() {
    await this.listLetters();
  },

  watch: {
    templateInput: {
      deep: true,
      async handler(val) {
        if (val.month != "") {
          await this.listTemplates();
        }
      },
    },
  },

  data() {
    return {
      loading: false,
      lettersResponse: null as null | LetterListResponse,
      templateResponse: null as null | LetterTemplateResponse,
      editDialog: false,
      headers: [
        { text: "登録日", value: "date" },
        { text: "作成者", value: "createdBy" },
        { text: "おたより名", value: "name" },
        { text: "おたよりの種類", value: "category" },
        { text: "編集", value: "edit" },
        { text: "流用作成", value: "duplicate" },
        { text: "削除", value: "delete" },
      ],
      fullcolorItems: [
        { text: "カラー", value: true },
        { text: "モノクロ", value: false },
      ],
      monthItems: monthItems,
      templateInput: {
        isFullColor: true,
        month: "",
      },
      searchFilters: {
        skip: 0,
        limit: 20,
      },
    };
  },

  computed: {
    page: {
      get(): number {
        if (this.searchFilters.skip == 0) {
          return 1;
        }
        return Math.ceil(
          this.searchFilters.skip / (this.searchFilters.limit - 1)
        );
      },
      set(value: number) {
        this.searchFilters.skip = (value - 1) * this.searchFilters.limit;
        this.listLetters();
      },
    },
  },

  methods: {
    async listLetters() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        this.loading = true;
        const response = await ApiLetter.listLetters({
          nurseryId,
          ...this.searchFilters,
        }).catch(() => (this.loading = false));
        if (response) {
          this.lettersResponse = response;
        }
      }
      this.loading = false;
    },
    async duplicateLetter(letterId: string) {
      const answer = confirm("流用作成しますか？");
      if (answer && letterId) {
        this.loading = true;
        const response = await ApiLetter.duplicateLetter({
          letterId,
        }).catch(() => (this.loading = false));
        if (response && response.letterId) {
          this.$router.push({ path: `/letter/${response.letterId}` });
        }
      }
      this.loading = false;
    },
    async deleteLetter(letterId: string) {
      const answer = confirm("削除しますか？");
      if (answer && letterId) {
        this.loading = true;
        const response = await ApiLetter.deleteLetter({
            letterId: letterId,
          }).catch(() => (this.loading = false));
          if (response) {
            await this.listLetters();
          }
        this.loading = false;
      }
    },
    async listTemplates() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.templateInput.month != "") {
        this.loading = true;
        const response = await ApiLetter.listTemplates({
          nurseryId,
          ...this.templateInput,
        }).catch(() => (this.loading = false));
        if (response) {
          this.templateResponse = response;
        }
      }
      this.loading = false;
    },
  },
});
