import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiStaff from "@/api/ApiStaff";

/* eslint-disable */
export interface StaffEditState {
  userId: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  isAdmin: boolean;
  isDirector: boolean;
  isChief: boolean;
  email: string;
  category: "general" | "help" | string;
  staffCode1: string;
  staffCode2: string;
  birth: string;
  gender: string;
  address: string;
  phoneNumber1: string;
  phoneNumber2: string;
  joinDate: string;
  assignDate: string;
  retireDate: string;
  retireReason: "transfer" | "retire" | "die" | string;
  retireReasonComment: string;
  classesInCharge: number[];
  comment: string;
}

@Module({ dynamic: true, store, name: "staff-edit", namespaced: true })
class StaffEditModule extends VuexModule implements StaffEditState {
  public userId: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public firstNameKana: string = "";
  public lastNameKana: string = "";
  public isAdmin: boolean = false;
  public isDirector: boolean = false;
  public isChief: boolean = false;
  public email: string = "";
  public category: "general" | "help" | string = "general";
  public staffCode1: string = "";
  public staffCode2: string = "";
  public birth: string = "";
  public gender: string = "";
  public address: string = "";
  public phoneNumber1: string = "";
  public phoneNumber2: string = "";
  public joinDate: string = "";
  public assignDate: string = "";
  public retireDate: string = "";
  public retireReason: "transfer" | "retire" | "die" | string = "";
  public retireReasonComment: string = "";
  public classesInCharge: number[] = [];
  public comment: string = "";

  @Action({ rawError: true })
  public async createStaff(input: { nurseryId: string }) {
    let retireValues = {};
    if (this.retireReason && this.retireDate) {
      retireValues = {
        retireDate: this.retireDate,
        retireReason: this.retireReason,
        retireReasonComment: this.retireReasonComment,
      };
    }
    const response = await ApiStaff.createStaff({
      nurseryId: input.nurseryId,
      category: this.category,
      firstName: this.firstName,
      lastName: this.lastName,
      firstNameKana: this.firstNameKana,
      lastNameKana: this.lastNameKana,
      isDirector: this.isDirector,
      isChief: this.isChief,
      isAdmin: this.isAdmin,
      email: this.email,
      staffCode1: this.staffCode1 || undefined,
      staffCode2: this.staffCode2 || undefined,
      birth: this.birth,
      gender: this.gender,
      address: this.address || undefined,
      phoneNumber1: this.phoneNumber1 || undefined,
      phoneNumber2: this.phoneNumber2 || undefined,
      joinDate: this.joinDate,
      assignDate: this.assignDate,
      classesInCharge: this.classesInCharge,
      comment: this.comment || undefined,
      ...retireValues,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateStaff(input: { nurseryId: string }) {
    let retireValues = {};
    retireValues = {
      retireDate: this.retireDate || "",
      retireReason: this.retireReason || "",
      retireReasonComment: this.retireReasonComment || "",
    };
    const response = await ApiStaff.updateStaff({
      nurseryId: input.nurseryId,
      userNurseryId: this.userId,
      category: this.category,
      firstName: this.firstName,
      lastName: this.lastName,
      firstNameKana: this.firstNameKana,
      lastNameKana: this.lastNameKana,
      isDirector: this.isDirector,
      isChief: this.isChief,
      isAdmin: this.isAdmin,
      staffCode1: this.staffCode1 || undefined,
      staffCode2: this.staffCode2 || undefined,
      birth: this.birth,
      gender: this.gender,
      address: this.address || undefined,
      phoneNumber1: this.phoneNumber1 || undefined,
      phoneNumber2: this.phoneNumber2 || undefined,
      joinDate: this.joinDate,
      assignDate: this.assignDate,
      classesInCharge: this.classesInCharge,
      comment: this.comment || undefined,
      email: this.email,
      ...retireValues,
    });
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteStaff(input: {
    nurseryId: string;
    userNurseryId: string;
  }) {
    const response = await ApiStaff.deleteStaff({
      nurseryId: input.nurseryId,
      userNurseryId: input.userNurseryId,
    });
    if (response) {
      return response;
    }
  }

  @Mutation
  public setUserId(value: string) {
    this.userId = value;
  }

  @Mutation
  public setFirstName(value: string) {
    this.firstName = value;
  }

  @Mutation
  public setLastName(value: string) {
    this.lastName = value;
  }

  @Mutation
  public setFirstNameKana(value: string) {
    this.firstNameKana = value;
  }

  @Mutation
  public setLastNameKana(value: string) {
    this.lastNameKana = value;
  }

  @Mutation
  public setIsAdmin(value: boolean) {
    this.isAdmin = value;
  }

  @Mutation
  public setIsDirector(value: boolean) {
    this.isDirector = value;
  }

  @Mutation
  public setIsChief(value: boolean) {
    this.isChief = value;
  }

  @Mutation
  public setEmail(value: string) {
    this.email = value;
  }

  @Mutation
  public setCategory(value: "general" | "help" | string) {
    this.category = value;
  }

  @Mutation
  public setStaffCode1(value: string) {
    this.staffCode1 = value;
  }

  @Mutation
  public setStaffCode2(value: string) {
    this.staffCode2 = value;
  }

  @Mutation
  public setBirth(value: string) {
    this.birth = value;
  }

  @Mutation
  public setGender(value: string) {
    this.gender = value;
  }

  @Mutation
  public setAddress(value: string) {
    this.address = value;
  }

  @Mutation
  public setPhoneNumber1(value: string) {
    this.phoneNumber1 = value;
  }

  @Mutation
  public setPhoneNumber2(value: string) {
    this.phoneNumber2 = value;
  }

  @Mutation
  public setJoinDate(value: string) {
    this.joinDate = value;
  }

  @Mutation
  public setAssignDate(value: string) {
    this.assignDate = value;
  }

  @Mutation
  public setRetireDate(value: string) {
    this.retireDate = value;
  }

  @Mutation
  public setRetireReason(value: "transfer" | "retire" | "die" | string) {
    this.retireReason = value;
  }

  @Mutation
  public setRetireReasonComment(value: string) {
    this.retireReasonComment = value;
  }

  @Mutation
  public setclassesInCharge(value: number[]) {
    this.classesInCharge = value;
  }

  @Mutation
  public setComment(value: string) {
    this.comment = value;
  }

  @Mutation
  public clearValues() {
    this.userId = "";
    this.firstName = "";
    this.lastName = "";
    this.firstNameKana = "";
    this.lastNameKana = "";
    this.email = "";
    this.isAdmin = false;
    this.isDirector = false;
    this.isChief = false;
    this.staffCode1 = "";
    this.staffCode2 = "";
    this.birth = "2000-01-01";
    this.gender = "";
    this.address = "";
    this.phoneNumber1 = "";
    this.phoneNumber2 = "";
    this.joinDate = "2020-04-01";
    this.assignDate = "2020-05-01";
    this.retireDate = "";
    this.retireReasonComment = "";
    this.classesInCharge = [];
    this.comment = "";
    this.category = "general";
  }
}

export const staffEditModule = getModule(StaffEditModule);
