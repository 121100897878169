import axios from 'axios';
import ApiClient from '@/api/ApiClient';
import { UniqueServiceMastListResponse, UniqueServiceMastResponse, UniqueServiceRegistrationResponse, UniqueServiceRegistrationDetailResponse, UniqueServiceRegistrationListResponse } from 'chaild-api/lib';


// perUse: 'perUse', // 回数
// perUseMonth: 'useMonth', // 利用月額
// monthly: 'monthly' // 固定月額
export default class ApiSchoolService {
  public static async listSchoolServiceUsageWithDate({
    nurseryId,
    date,
    serviceType,
  }: {
    nurseryId: number;
    date: string;
    serviceType?: string;
  }) {
    const response = await ApiClient.get('/unique-service/children', {
      nurseryId,
      date,
      serviceType,
    });
    if (response) {
      return response as UniqueServiceRegistrationListResponse;
    }
    return null;
  }

  public static async listSchoolServiceUsageWithMonth({
    classId,
    month,
    serviceType,
  }: {
    classId: number;
    month: string;
    serviceType?: string;
  }) {
    try {
      const response = await ApiClient.get(`/unique-service/children/month`, {
        classId,
        month,
        serviceType,
      });
      return response as UniqueServiceRegistrationListResponse;
    } catch {
      return null;
    }
  }

  public static async registerSchoolServiceUsage({
    childId,
    uniqueServiceId,
    date,
  }: {
    childId: number;
    uniqueServiceId: number | string;
    date: string;
  }) {
    try {
      const response = await ApiClient.post(`/unique-service/children/${childId}/registration`, {
        date,
        uniqueServiceId,
      });
      return response as UniqueServiceRegistrationDetailResponse;
    } catch (err) {
      err.catch((errorRes) => {
        const errorData = errorRes.data;
        const errorCode = errorData ? errorData.error : null;
        switch (errorCode) {
          case 'E151':
            alert('この月の売上情報が確定しているため, 対象期間を含む園提供サービスは編集できません。')
            break;
          case 'E126':
            alert('既に利用情報が登録されています。画面をリロードして確認してください')
            break;
          default:
            break;
        }
      })
      return null;
    }
  }

  public static async deleteSchoolServiceUsage({
    childId,
    registrationId,
  }: {
    childId: number;
    registrationId: number | string;
  }) {
    try {
      const response = await ApiClient.delete(`/unique-service/children/${childId}/registration/${registrationId}`, null);
      return response as UniqueServiceRegistrationDetailResponse;
    } catch {
      return null;
    }
  }

  public static async listSchoolServices({
    nurseryId
  }: {
    nurseryId: number;
  }) {
    try {
      const response = await ApiClient.get('/unique-service', {
        nurseryId,
      });
  
      if (response && response.uniqueServices) {
        return response.uniqueServices as UniqueServiceMastResponse[];
      }
      return null;
    } catch {
      return null;
    }
  }

  public static async createSchoolService ({
    nurseryId,
    uniqueServiceName,
    serviceType,
    paymentMonthType,
    price,
    maximumPrice,
    accountType,
  }: {
    nurseryId: number;
    uniqueServiceName: string;
    serviceType: string;
    paymentMonthType: number;
    price: number;
    maximumPrice?: number;
    accountType: string;
  }) {
    const response = await ApiClient.post('/unique-service', {
      nurseryId,
      uniqueServiceName,
      serviceType,
      paymentMonthType,
      price,
      maximumPrice,
      accountType,
    });
    if (response) {
      return true;
    }
    return false;
  }

  public static async updateSchoolService ({
    uniqueServiceId,
    uniqueServiceName,
  }: {
    uniqueServiceId: string;
    uniqueServiceName: string;
  }) {
    const response = await ApiClient.put(`/unique-service/${uniqueServiceId}`, {
      uniqueServiceName,
    });
    if (response) {
      return true;
    }
    return false;
  }

  public static async deleteSchoolService({
    uniqueServiceId
  }: {
    uniqueServiceId: string;
  }) {
    const response = await ApiClient.delete(`/unique-service/${uniqueServiceId}`, null);
    if (response) {
      return true;
    }
    return false;
  }
}