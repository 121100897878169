
import Vue from "vue";
export default Vue.extend({
  name: "DatePicker",

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    updateValue(value: string) {
      this.menu = false;
      this.$emit("input", value);
    },
  },
});
