
import Vue from 'vue';

import DatePager from '@/components/atoms/DatePager.vue';
import LocalDataService from '@/service/LocalDataService';

import dayjs from 'dayjs';
import { NurseryChildResponse, NurseryClassResponse, UniqueServiceMastResponse, UniqueServiceRegistrationDetailResponse, UniqueServiceRegistrationResponse } from 'chaild-api/lib';

import { classModule } from '@/store/dataModules/classModule';
import { schoolServiceUsageModule } from '@/store/viewModules/school-service/schoolServiceUsageModule';
import { schoolServiceModule } from '@/store/dataModules/schoolServiceModule';

interface DataType {
  isFetching: boolean;
  date: string;
}

interface ClassWithUsages {
  class: NurseryClassResponse;
  usages: ChildWithPerUseService[];
}

interface ChildWithPerUseService {
  child: NurseryChildResponse;
  services: PerUseServiceWithUsage[];
}

interface PerUseServiceWithUsage {
  service: UniqueServiceMastResponse;
  hasUsed: boolean;
  registrationId?: string | number | null;
}

export default Vue.extend({
  components: {
    DatePager,
  },

  data: (): DataType => ({
    isFetching: false,
    date: dayjs().format('YYYY-MM-DD'),
  }),

  computed: {
    classes() {
      return classModule.classes;
    },
    classesWithUsages(): ClassWithUsages[] {
      return this.classes.map((c) => {
        const usages = schoolServiceUsageModule.usages.filter((u) => {
          const find = u.child.nurseryClasses.find((cls) => cls.classId === c.classId);
          if (find) {
            return true;
          }
          return false;
        }).map((u) => {
          const perUseServicesWithUsage = this.schoolServices.map((service) => {
            const findUsage = u.uniqueServices.find((s) => s.uniqueServiceId === service.uniqueServiceId);
            if (findUsage) {
              const registrationId = findUsage.registrations.length > 0 ? findUsage.registrations[0].registrationId : null;
              return {
                service,
                hasUsed: true,
                registrationId,
              }  
            }
            return {
              service,
              hasUsed: false,
            }
          });
          return {
            child: u.child,
            services: perUseServicesWithUsage
          }
        })
        .filter((u) => {
          if (u.child.quitDate) {
            const now = dayjs(this.date);
            const quitDate = dayjs(u.child.quitDate);

            const diff = now.diff(quitDate);
            if (diff > 0) {
              return false;
            }
          }
          return true;
        });

        return {
          class: c,
          usages,
        };
      });
    },
    schoolServices() {
      return schoolServiceModule.schoolServices.filter((s) => s.serviceType === 'perUse');
    },
    tableWidth(): number {
      return 120 + 80 * this.schoolServices.length;
    }
  },

  methods: {
    async toggleUsage(usage: PerUseServiceWithUsage, child: NurseryChildResponse) {
      if (this.isFetching) {
        return;
      }
      if (!usage.hasUsed) {
        this.isFetching = true;
        await schoolServiceUsageModule.registerUsage({
          childId: child.childId,
          uniqueServiceId: usage.service.uniqueServiceId,
          date: this.date,
        }).catch((err) => {
          console.warn(err)
        });
        this.isFetching = false;
      } else {
        if (usage.registrationId) {
          this.isFetching = true;
          await schoolServiceUsageModule.deleteUsage({
            childId: child.childId,
            registrationId: usage.registrationId,
          });
          this.isFetching = false;
        }
      }
    },
    async listUsages() {
      this.isFetching = true;
      await schoolServiceUsageModule.listUsages({
        date: this.date,
      });
      this.isFetching = false;
    }
  },

  watch: {
    date: {
      handler: function() {
        this.listUsages();
      },
    }
  },

  async mounted() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      this.isFetching = true;
      await Promise.all([
        // growthRecordsModule.listGrowthRecords(),
        schoolServiceUsageModule.listUsages({
          date: this.date,
        }),
        schoolServiceModule.listSchoolServices()
      ]);
      this.isFetching = false;
    }
  }
});
