
import Vue from "vue";
import { meModule } from "@/store/dataModules/meModule";
import { MeNurserySchoolResponse, MeResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";
import { snackbarModule } from "@/store/viewModules/snackbarModule";

export default Vue.extend({
  name: "AppBar",

  computed: {
    me: function (): MeResponse | null {
      return meModule.me;
    },
    nurseryId: function (): number | null {
      // return meModule.nurseryId;
      return LocalDataService.getNurseryId();
    },
    currentSchool: function (): MeNurserySchoolResponse | null {
      if (this.me && this.nurseryId) {
        // const currentSchool = this.me.nurserySchools.find((s) => {
        //   return s.isDefault == true;
        // });
        const currentSchool = this.me.nurserySchools.find((s) => {
          return s.nurseryId == this.nurseryId;
        });
        return currentSchool || null;
      } else {
        return null;
      }
    },
    username: function (): string {
      return this.currentSchool
        ? `${this.currentSchool?.lastName || ""} ${
            this.currentSchool?.firstName || ""
          }`
        : "";
    },
    currentNurseryName: function (): string {
      return this.currentSchool ? this.currentSchool.nurseryName : "";
    },
  },

  methods: {
    async setNurseryId(id: number) {
      const response = await meModule
        .putMe({
          defaultNurseryId: id,
        })
        .catch();
      if (response) {
        LocalDataService.setNurseryId(id);
        meModule.setNurseryId(id);

        location.reload();
      } else {
        snackbarModule.snackOn({
          message: "失敗しました",
          color: "error",
        });
      }
    },
  },
});
